import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppConfig } from 'src/app/services/app.config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit {
  @ViewChild('emailInput') emailRef!: any;
  Form: FormGroup;
  submitted: boolean = false;
  isCopied: boolean;
  name = '';
  email = '';
  isEmailValid = false;
  group = this.organizationalStructureService.emptyGroup
  isHaveName = true;
  linkLimit = 0;
  token: any;
  validate: boolean = false;
  loading: boolean = false;
  invitemem: any;
  deleteMember: any;
  // @Input() invite: any[];
  page = 1;
  pageSize = 10;
  get link() {
    // return `https://flexyc/${this.group.name}/id?=${this.group.id}&limit?=${this.linkLimit}`
    return AppConfig.settings.apiServer.baseUrl + 'Organization/RegisterInvitee'
  }

  constructor(public organizationalStructureService: OrganizationalStructureService,
    private authService: AuthService, private error: ErrorHandlerService, private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.setInviteToShow();
    this.getInvite();
    this.Form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
    })

  }
  get f() { return this.Form.controls }


  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.Form.invalid) {
      this.loading = false;
      return;
    }
    else {
      this.authService.registerinvitee(this.f['email'].value, this.f['name'].value).subscribe({
        next: (data: any) => {
          this.error.openToast({ title: 'SUCCESS', type: 'success', message: 'Invited successfully!' })
          this.submitted = false;
          this.loading = false;
          this.Form.reset();
          this.getInvite();
        },
        error: (err: any) => {

          var a = err.error.result
          this.loading = false;
          this.error.openToast({ title: 'ERROR', type: 'error', message: a });
          this.submitted = false;

        }
      })
      this.isSendDisable();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setInviteToShow();
  }
  setInviteToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.invitemem = this.invitemem?.slice(start, start + this.pageSize);
  }

  getInvite() {
    this.authService.getInviteMem().subscribe((data: any) => {
      this.invitemem = data.result;
    })
  }
  deleteInvitemem(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "do you want to delete invitee!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.deleteInviteMem(id).subscribe({
          next: (data: any) => {
            this.error.openToast({title:'SUCCESS',type:'success',message:'Deleted successfully!'});
            this.getInvite();
          },
          error: (err: any) => {
            this.error.openToast({title:'ERROR',type:'error',message:err.message});
          }
        });
      }
    })
  }
  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  //   async registerinvitee(){
  // var a={
  //   email:this.email,
  //   name:this.name
  // }

  // if(a.email==''||a.name==''){

  //  this.validate=true;
  // this.eHandle()

  //   return;

  // }
  // else{
  // this.validate=false;
  //     this.authService.registerinvitee(this.email).subscribe({
  //     next:(data:any)=>{
  //       this.error.openToast({title:'',type:'success',message:'Invited successfully!'})
  //       this.email='';
  //       this.name='';
  //     },
  //     error:(err:any)=>{

  //     var a=err.error.result
  //     this.error.openToast({title:'',type:'error',message:a})
  //     this.email='';
  //     this.name='';
  //     }
  //    })

  //   this.isSendDisable();

  // }


  //   }

  isSendDisable() {
    if (!this.emailRef) return true;
    if (!this.isHaveName) return !!this.emailRef?.invalid
    return (!!this.emailRef?.invalid) || !this.name;
  }

  //  onCopyFailure() {
  //  alert('copy fail!');
  //}

}


