import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, tap } from 'rxjs/operators';
import { AppState } from '../store/app.store';
import { ErrorHandlerService } from './error-handler.service';
import { GlobalVariablesService } from './global-variables.service';
import * as AuthActions from '../store/actions/auth.actions'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { globalComponent } from '../global-component.service';
import { TokenService } from '../token/token.service';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserValue: any;
  navigate(arg0: string[]) {
    throw new Error("Method not implemented.");
  }

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private globalVariablesService: GlobalVariablesService,
    private store: Store<AppState>,
    private tokenService: TokenService,
    private router: Router
  ) {

  }

  public getInviteMem() {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.InviteeList}`)
  }

  public deleteInviteMem(id: any) {
    return this.http.delete<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.Invitee}${id}`)
  }

  public register(body: any) {
    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.register}`, body);
  }

  public login(memberIdentifier: string, password: string) {
    //console.log()
    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.login}`, { email: memberIdentifier, password: password })
  }
  registerinvitee(email: string, name: string) {
    //console.log(email)
    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.inviteMem}`, { emailid: email, name: name })
  }

  public autoLogin() {
    const userData = JSON.parse(localStorage.getItem('auth'));
    if (!userData) return;
    if (userData.token) this.store.dispatch(new AuthActions.login(userData.token))
  }

  public logout() {
    this.store.dispatch(new AuthActions.logout());
    this.tokenService.removeToken();
    this.tokenService.removeUser();
    this.router.navigate(['/login']);
  }
}
