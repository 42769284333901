import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-manage-patients',
  templateUrl: './manage-patients.component.html',
  styleUrls: ['./manage-patients.component.scss']
})
export class ManagePatientsComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  patients = []
  search = ''

  constructor(
    private accountService: PortalAccountsService,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.portalAccounts$.subscribe(accounts => {
        const patients = accounts.filter(account => account.type === 'patient')
        this.updatePatients(patients);
      })
    )
    this.accountService.loadPortalAccounts(4);

  }

  updatePatients(patients) {
    this.patients = patients.map(patient => ({ ...patient, isCheck: false }));
  }

  filterPatients() {
    return this.patients.filter(patient => (
      patient.data.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      patient.contactInfo.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  deletepatient(patient) {
    this.accountService.delete(patient.id, 4).toPromise();
  }

  getNumOfSelected() {
    return this.patients.reduce((acc, patient) => {
      if (patient.isCheck) acc++
      return acc
    }, 0)
  }

  unselect(){
    this.updatePatients(this.patients)
  }

  deleteSelected(){
    const selected = this.patients.filter(employee=>employee.isCheck)
    selected.forEach(patient=>{
      this.deletepatient(patient)
    })
    
  }

}
