import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { globalComponent } from '../global-component.service';
import { AppConfig } from './app.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {

  private BASE_URL = AppConfig.settings.apiServer.baseUrl;

  constructor(private http: HttpClient) {

  }

  public recover(email: string) {
    return this.http.post<any>(`${this.BASE_URL}${globalComponent.recoverpass}`, { email: email })
  }
  public reset(pass: string, tok: string) {
    let val = {
      'Authorization': `Bearer ${tok}`
    }
    let headers = { headers: new HttpHeaders(val) }
    return this.http.post<any>(`${this.BASE_URL}${globalComponent.resetpass}`, { password: pass }, headers);
  }

  public VerifyPwd(pass: string) {
    return this.http.post<any>(`${this.BASE_URL}${globalComponent.verifypass}`, { password: pass });
  }
  public ChangePwd(pass: string, onetimepass: any) {
    return this.http.post<any>(`${this.BASE_URL}${globalComponent.changepass}`, { password: pass, onetimepwd: onetimepass });
  }
}
