import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-account-posts',
  templateUrl: './portal-account-posts.component.html',
  styleUrls: ['./portal-account-posts.component.scss']
})
export class PortalAccountPostsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
