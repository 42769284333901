<div dirControl class="main-card card mb-4">
    <div class="card-body">
        <h3 class="card-title">{{'moderators'|translatePipe}}</h3>
        <div dir="ltr" class="flipped">
            <div class="tree-container">
                <ul class="tree">
                    <li tree [group]="group" (onSelectChild)="onChooseGroup($event)"></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="currGroup">
    <div dirControl class="main-card card mb-3 administrators">
        <div class="card-body">
            <div class="space-between">

                <h3 class="card-title">
                    {{
                    currGroup.type==='organization'?
                    currGroup.name+' - '+('global administrators'|translatePipe):
                    ('moderators of'|translatePipe) + ' ' + currGroup.type + ' ' + currGroup.name
                    }}
                </h3>
                <button (click)="open()" class="add"><i class="fa fa-fw" aria-hidden="true"
                        title="{{'add'|translatePipe}}"></i></button>
            </div>

            <div class="row" *ngIf="moderators.length > 0">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2" *ngFor="let member of moderators">
                    <app-toggle-member [member]="member" (remove)="openSureModal($event,true)"></app-toggle-member>
                </div>
            </div>

            <div class="row" *ngIf="moderators.length === 0">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                    <p>{{'Nomoderators'|translatePipe}}</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #addMember let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{'add'|translatePipe}}
            {{
            currGroup.type==='organization'?
            ('administrator'|translatePipe):
            ('moderator'|translatePipe)
            }}
        </h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-dropdown-input [(text)]="administratorSearch" [dropDownOpions]="notModerators"
            (chooseOption)="openSureModal($event,fasle,close)">
        </app-dropdown-input>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            {{'Close'|translatePipe}}
        </button>
    </div>
</ng-template>

<ng-template #sureModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{isSureDelete?('remove'|translatePipe):('Grant'|translatePipe)}}
            {{
            currGroup.type==='organization'?
            ('admin'|translatePipe):
            ('moderator'|translatePipe)
            }}
            {{'permissions'|translatePipe}}
        </h5>

        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{'Are you sure you want to'|translatePipe}}
            {{isSureDelete? ('remove'|translatePipe) : ('add'|translatePipe)}}
            <strong>{{choosenMember.identifier}}</strong>
            {{currGroup.type==='organization'? ('as admin'|translatePipe):('as moderator'|translatePipe)}}
            {{isSureDelete? ('from'|translatePipe) : ('for'|translatePipe)}} {{currGroup.type}}
            <strong>{{currGroup.name}}</strong>?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" [class.btn-danger]="isSureDelete" [class.btn-primary]="!isSureDelete"
            (click)="onToggleModerator(close)">
            {{isSureDelete?('remove'|translatePipe):('give'|translatePipe)}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            {{'Close'|translatePipe}}
        </button>
    </div>
</ng-template>