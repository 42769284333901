import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-patients-table',
  templateUrl: './manage-patients-table.component.html',
  styleUrls: ['./manage-patients-table.component.scss']
})
export class ManagePatientsTableComponent implements OnInit {

  dataSource = new MatTableDataSource
  setsColumns: string[] = []
  page = 1;
  pageSize = 10;
  // displayedColumns = ['account name', 'member identifier', 'options'];
  patientsToShow=[];
  @Input() patients: any[];
  @Output() deletePatients = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
    this.setPatientsToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setPatientsToShow()
  }

  getClass() {
    return `checkbox-container ${this.patients.find(member => member.isCheck) ? 'checked' : ''}`
  }

  setPatientsToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.patientsToShow = this.patients?.slice(start, start + this.pageSize);
  } 

}
