import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Group } from 'src/app/interfaces/group';
import { ReferForm, Role } from 'src/app/models/eums';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { EventsService } from 'src/app/services/events.service';
import { FilesService } from 'src/app/services/files.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { TokenService } from 'src/app/token/token.service';
@Component({
  selector: 'app-event-display',
  templateUrl: './event-display.component.html',
  styleUrls: ['./event-display.component.scss']
})
export class EventDisplayComponent implements OnInit {
  public loginForm: FormGroup;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>
  submitted: boolean = false;
  public error: { code: number, message: string } | null = null;
  subscription: Subscription[] = [];
  organization: Group
  email = '';
  isEmailValid = false;
  password = '';
  isPasswordValid = false;
  isHaveAuth = true;
  isShowAuth = false;
  verifyCode = ''
  // isKeepLogin = false
  //
  loading: boolean = false;
  currentUserValue = '';

  currDate = new Date();
  event: any;
  backgroundPhotoUrl: any;
  headerStyle: any;

  eventNotFetched = false;
  fetchingDetails = true;
  expired = false;
  guestId: any;
  tok: any;
  showStatus = false;
  currentStatus = '';
  guestDetails: any;

  constructor(
    private modal: NgbModal,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private tokenService: TokenService,
    private eventService: EventsService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tok = params.reply;
      let decTok = this.tokenService.decodeToken(this.tok);
      if (decTok) {
        this.fetchingDetails= true;
        this.guestId = decTok.primarysid;
        this.getEventDetails(decTok.certthumbprint, this.tok);
      }else {
        this.expired = true;
      }
    })
  }

  // get headerStyle() {
  //   return this.domSanitizer.bypassSecurityTrustStyle(
  //     'background-image: linear-gradient(rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.3)),  url(' + this.backgroundPhotoUrl + '); '
  //   );
  // }

  async getEventDetails(id, tok) {
    let resp: any = await this.eventService.getEvent(id, tok).toPromise();
    this.fetchingDetails = false;
    let event = resp.result;
    if (typeof event !== 'object') {
      this.eventNotFetched = true;
      return;
    }
    let guests = event.guestInvitees.filter(guest => guest.eventGuestId === Number.parseInt(this.guestId));
    let members = event.memberInvitees?.filter(member => member.inviteEventMemberId === Number.parseInt(this.guestId));
    if (guests.length > 0) {
      this.guestDetails = guests[0];
      
    } else if (members?.length > 0) {
      this.guestDetails = members[0];
    } else {
      this.eventNotFetched = true;
      return;
    }
    this.setStatus(this.guestDetails.inviteStatus);
    this.modal.open(this.modalContent, { size: 'lg', backdrop: 'static', keyboard : false  });
    let hours = event.durationType === 1 ? event.duration : event.duration / 60;
    let sdate = new Date(event.eventDate);
    let edate = new Date(event.eventDate);
    var calEvt: any = {
      start: sdate,
      end: new Date(edate.setTime(edate.getTime() + hours * 60 * 60 * 1000)),
      title: event.name,
      id: event.id,
      description: event.content,
      imgLoc: event.canPlaceImageAbove ? 'above' : 'below'
    }
    var data = {
      fkId: calEvt.id,
      formType: ReferForm.CalendarEvent,
    };
    this.fileService.getFileFkid(data, tok).subscribe((files: any) => {
      if (files.result === 'InvalidDetailsToRetrieveTheFile') {
        return
      }
      if (files.result && files.result.length > 0) {
        this.fileService.getFilebyId(files.result[0].id, tok).subscribe((data: any) => {
          this.event.image = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
        })
      }
    });
    this.event = calEvt;
  }

  setStatus(status){
    switch(status) {
      case 'Declined':
        this.currentStatus = 'declined invite';
        break;
      case 'Maybe':
        this.currentStatus = 'maybe invite';
        break;
      case 'Accepted':
        this.currentStatus = 'accepted invite';
        break;
      case 'Pending' :
        this.currentStatus = 'pending';
        break;
    }
  }

  sendResponse(status) {
    this.eventService.changeStatus(this.guestId, status, this.guestDetails.identifier ? 'member' : 'guest', this.tok).subscribe({
      next: (data: any) => {
        this.showStatus = true;
        this.setStatus(status);
        this.errorHandler.openToast({ title: "SUCCESS", type: "success", message: "Status updated." });
      },
      error: (err: any) => {
        this.errorHandler.openToast({ title: "ERROR", type: "error", message: "Response could not be sent." });
      }
    })
  }
}







