<div class="row">
    <div class="mb-2 card">
        <div class="card-body">
            <h3 class="card-title">{{ group.type +' Information'|translatePipe}}</h3>
            <div class="row">
                <form class="col-lg-6 col-md-6">
                    <app-input-label #nameInput [required]="true" name="name" [label]="'name'|translatePipe"
                        [(value)]="name"></app-input-label>
                    <app-textarea-label name="description" [label]="'description'|translatePipe" style="height: 85px;"
                        [(value)]="description" row="4"></app-textarea-label>
                    <br>
                    <div class="col-lg-13 col-md-13">
                        <button class="btn btn-success mb-4 col-12" [disabled]="isSubmitDisable"
                            (click)="updateOrganizationalStructureData();save=false">{{'Save'|translatePipe}}
                            <span class="spinner-border" *ngIf="loading"
                                style="width:20px;height:20px;margin-left: 10px;margin-bottom: -5px;"></span>
                        </button>
                    </div>
                </form>
                <div class="col-lg-3 col-md-3" style=" margin-top: -16px;" *ngIf="group.aId>0">
                    <app-image-picker [label]="'logo'|translatePipe" [(value)]="logoUrl"
                        [uplDetails]="imgDet()"></app-image-picker>
                </div>
            </div>
        </div>
    </div>
</div>