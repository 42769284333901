import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// // Pages

import { OrganizationalStructureComponent } from './pages/organizational-structure/organizational-structure.component';
import { OrganizationalStructureEditComponent } from './pages/organizational-structure-edit/organizational-structure-edit.component';
import { OrganizationalStructureMembersComponent } from './pages/organizational-structure-members/organizational-structure-members.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DuplicateOptionComponent } from './pages/duplicate-option/duplicate-option.component';
import { SystemSettingsComponent } from './pages/system-settings/system-settings.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SearchMembersComponent } from './pages/search-members/search-members.component';
import { MembersResaultsComponent } from './pages/members-resaults/members-resaults.component';
import { ManageMembersComponent } from './pages/manage-members/manage-members.component';
import { MemberProfileViewComponent } from './pages/member-profile-view/member-profile-view.component';
import { InviteMemberComponent } from './pages/invite-member/invite-member.component';
import { GlobalSearchComponent } from './pages/global-search/global-search.component';
import { GlobalSearchResaultsComponent } from './pages/global-search-resaults/global-search-resaults.component';
import { CardViewComponent } from './pages/card-view/card-view.component';
import { CardManagementComponent } from './pages/card-management/card-management.component';
import { CardViewGiveComponent } from './pages/card-view-give/card-view-give.component';
import { CardUsageComponent } from './pages/card-usage/card-usage.component';
import { CardManagementTableComponent } from './pages/card-management-table/card-management-table.component';
import { EventManagementComponent } from './pages/event-management/event-management.component';
import { EventViewComponent } from './pages/event-view/event-view.component';
import { EventAttendanceComponent } from './pages/event-attendance/event-attendance.component';
import { SetViewComponent } from './pages/set-view/set-view.component';
import { PropertyListComponent } from './pages/property-list/property-list.component';
import { SetManagementComponent } from './pages/set-management/set-management.component';
import { ChatSettingsComponent } from './pages/chat-settings/chat-settings.component';
import { LogSearchComponent } from './pages/log-search/log-search.component';
import { LogResaultsComponent } from './pages/log-resaults/log-resaults.component';
import { GlobalAdministratorsComponent } from './pages/global-administrators/global-administrators.component';
import { LocalAdministratorsComponent } from './pages/local-administrators/local-administrators.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { RecycleBinComponent } from './pages/recycle-bin/recycle-bin.component';
import { BackupsComponent } from './pages/backups/backups.component';
import { MediaComponent } from './pages/media/media.component';
import { CustomerServiceComponent } from './pages/customer-service/customer-service.component';
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component';
import { DigitalWalletComponent } from './pages/digital-wallet/digital-wallet.component';
import { SocialNetworkComponent } from './pages/social-network/social-network.component';
import { RegisterClubComponent } from './pages/register-club/register-club.component';
import { LoginOrganizationComponent } from './pages/login-organization/login-organization.component';
import { ForgotPasswordOrganizationComponent } from './pages/forgot-password-organization/forgot-password-organization.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { MemberProfileComponent } from './pages/member-profile/member-profile.component';
import { ClubSocialNetworkComponent } from './pages/club-social-network/club-social-network.component';
import { GroupViewComponent } from './pages/group-view/group-view.component';
import { GroupInviteComponent } from './pages/group-invite/group-invite.component';
import { OrganizationDashboardComponent } from './pages/organization-dashboard/organization-dashboard.component';
import { B2cLayoutComponent } from './Layout/b2c-layout/b2c-layout.component';
import { CardsComponent } from './pages/cards/cards.component';
import { SetsComponent } from './pages/sets/sets.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { ChatComponent } from './pages/chat/chat.component';
import { UserControlPannelComponent } from './pages/user-control-pannel/user-control-pannel.component';
import { ModuleManagmentComponent } from './pages/module-managment/module-managment.component';
import { AccountViewComponent } from './pages/account-view/account-view.component';
import { PortalEventViewComponent } from './pages/portal-event-view/portal-event-view.component';
import { PortalPostsComponent } from './pages/portal-posts/portal-posts.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ManageEmployeesComponent } from './pages/manage-employees/manage-employees.component';
import { PortalEventManagmentComponent } from './pages/portal-event-managment/portal-event-managment.component';
import { PortalEventViewReadonlyComponent } from './pages/portal-event-view-readonly/portal-event-view-readonly.component';
import { ManageCostumersComponent } from './pages/manage-costumers/manage-costumers.component';
import { EmployeeDirectoryComponent } from './pages/employee-directory/employee-directory.component';
import { CustomerDirectoryComponent } from './pages/customer-directory/customer-directory.component';
import { PortalEventViewReadonlyAccountComponent } from './pages/portal-event-view-readonly-account/portal-event-view-readonly-account.component';
import { ManageCandidatesComponent } from './pages/manage-candidates/manage-candidates.component';
import { OrganizationMediaComponent } from './pages/organization-media/organization-media.component';
import { OrganizationLandingPageComponent } from './pages/organization-landing-page/organization-landing-page.component';
import { OrganizationEccomerceComponent } from './pages/organization-eccomerce/organization-eccomerce.component';
import { EmployeePortalComponent } from './pages/employee-portal/employee-portal.component';
import { ManagePatientsComponent } from './pages/manage-patients/manage-patients.component';
import { CustomerPortalComponent } from './pages/customer-portal/customer-portal.component';
import { CandidateDirectoryComponent } from './pages/candidate-directory/candidate-directory.component';
import { CandidatePortalComponent } from './pages/candidate-portal/candidate-portal.component';
import { PortalEmployeePostsComponent } from './pages/portal-employee-posts/portal-employee-posts.component';
import { PortalCostumerPostsComponent } from './pages/portal-costumer-posts/portal-costumer-posts.component';
import { PortalCandidatePostsComponent } from './pages/portal-candidate-posts/portal-candidate-posts.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AuthGuard } from './auth/auth.guard';
import { RoleViewComponent } from './pages/role-view/role-view.component';
import { RoleManagmentComponent } from './pages/role-managment/role-managment.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TaskManagmentComponent } from './pages/task-managment/task-managment.component';
import { TriggerViewComponent } from './pages/trigger-view/trigger-view.component';
import { TriggerManagmentComponent } from './pages/trigger-managment/trigger-managment.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { AccountViewServerComponent } from './pages/account-view-server/account-view-server.component';
import { PortalEventViewServerComponent } from './pages/portal-event-view-server/portal-event-view-server.component';
import { ManageCustomerServerComponent } from './pages/manage-customer-server/manage-customer-server.component';
import { ManageCandidateServerComponent } from './pages/manage-candidate-server/manage-candidate-server.component';
import { AccountEventViewServerComponent } from './pages/account-event-view-server/account-event-view-server.component';
import { ManageEmployeesServerComponent } from './pages/manage-employees-server/manage-employees-server.component';
import { StructureServerComponent } from './pages/structure-server/structure-server.component';
import { StructureViewServerComponent } from './pages/structure-view-server/structure-view-server.component';
import { GroupManageMembersServerComponent } from './pages/group-manage-members-server/group-manage-members-server.component';
import { MemberViewServerComponent } from './pages/member-view-server/member-view-server.component';
import { ManageMembersServerComponent } from './pages/manage-members-server/manage-members-server.component';
import { Role } from './models/eums';
import { ManageAccountsComponent } from './pages/manage-account/manage-accounts.component';
import { PortalAccountPostsComponent } from './pages/portal-account-posts/portal-account-posts.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { SendNotificationComponent } from './components/send-notification/send-notification.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { AboutComponent } from './pages/about/about.component';
// import { PasswordRecoverComponent} from
import { title } from 'process';
import { RecoverPasswordService } from './services/recover-password.service';
import { PasswordRecoverComponent } from './pages/password-recover/password-recover.component';
import { AccountPortalComponent } from './components/account-portal/account-portal.component';
import { EventDisplayComponent } from './pages/event-display/event-display.component';
// import { ChatComponent } from './DemoPages/Applications/chat/chat.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'components',
        loadChildren: () => import('./DemoPages/Components/Components.module').then(m => m.ComponentsDrawerModule)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./DemoPages/Dashboards/Dashboards.module').then(m => m.DashboardsModule),

      },
      {
        path: 'elements',
        loadChildren: () => import('./DemoPages/Elements/Elements.module').then(m => m.ElementsModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./DemoPages/Forms/Elements/form-elements.module').then(m => m.FormElementModule)
      },
      {
        path: 'form-widgets',
        loadChildren: () => import('./DemoPages/Forms/Widgets/forms-widgets.module').then(m => m.FormWidgetsModule)
      },
      {
        path: 'button-indicators',
        loadChildren: () => import('./DemoPages/Material/ButtonsIndicators/buttonsIndicators.module').then(m => m.MateriaButoonIndicatorslModule)
      },
      {
        path: 'layout',
        loadChildren: () => import('./DemoPages/Material/Layout/layout.module').then(m => m.MaterialLayoutModule)
      },
      {
        path: 'form-controls',
        loadChildren: () => import('./DemoPages/Material/FormControls/formcontrols.module').then(m => m.MaterialFormControlModule)
      },
      {
        path: 'material',
        loadChildren: () => import('./DemoPages/Material/Material.module').then(m => m.MaterialModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./DemoPages/Widgets/Widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'tables/bootstrap',
        loadChildren: () => import('./DemoPages/Tables/tables-main/tables-main.module').then(m => m.TablesMainModule)
      },
      {
        path: 'editor',
        component: OrganizationalStructureComponent,
        data: {
          title: 'OrganizationalStructure'
        }
      },
      {
        path: 'structure-server',
        component: StructureServerComponent,
        data: {
          title: 'StructureServer'
        }
      },
      {
        path: 'structure-view-server/parent/:parent',
        component: StructureViewServerComponent,
        data: {
          title: 'StructureViewServer'
        }
      },
      {
        path: 'structure-view-server/:structure',
        component: StructureViewServerComponent,
        data: {
          title: 'StructureViewServer'
        }
      },
      {
        path: 'editor/:id',
        component: OrganizationalStructureEditComponent,
        data: {
          title: 'OrganizationalStructureEdit'
        }
      },
      {
        path: 'editor/parent/:parentId',
        component: OrganizationalStructureEditComponent,
        data: {
          title: 'OrganizationalStructureEdit'
        }
      },
      {
        path: 'editor/:id/members',
        component: OrganizationalStructureMembersComponent,
        data: {
          title: 'OrganizationalStructureMembers'
        }
      },
      {
        path: 'group-manage-members/:id',
        component: GroupManageMembersServerComponent,
        data: {
          title: 'GroupManageMembersServer'
        }
      },
      {
        path: 'duplicate/:id',
        component: DuplicateOptionComponent,
        data: {
          title: 'DuplicateOption'
        }

      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'system_settings',
        component: SystemSettingsComponent,
        data: {
          title: 'System Settings'
        }
      },
      {
        path: 'search-members',
        component: SearchMembersComponent,
        data: {
          title: 'Search Member'
        }
      },
      {
        path: 'members-resaults',
        component: MembersResaultsComponent,
        data: {
          title: 'MembersResaults'
        }
      },
      {
        path: 'manage-members',
        component: ManageMembersComponent,
        data: {
          title: 'Manage Members'
        }
      },
      {
        path: 'manage-members-server',
        component: ManageMembersServerComponent,
        data: {
          title: 'ManageMembersServer'
        }
      },
      {
        path: 'member-view/:id',
        component: MemberProfileViewComponent,
        data: {
          title: 'View Member'
        }
      },
      {
        path: 'member-view',
        component: MemberProfileViewComponent,
        data: {
          title: 'Add Member'
        }
      },
      {
        path: 'member-view-server/:id',
        component: MemberViewServerComponent,
        data: {
          title: 'MemberViewServer'
        }
      },
      {
        path: 'member-view-server',
        component: MemberViewServerComponent,
        data: {
          title: 'MemberViewServer'
        }
      },
      {
        path: 'task-view',
        component: TaskViewComponent,
        data: {
          title: 'View Task'
        }
      },
      {
        path: 'task-view/:id',
        component: TaskViewComponent,
        data: {
          title: 'View Task'
        }
      },
      {
        path: 'trigger-view',
        component: TriggerViewComponent,
        data: {
          title: 'TriggerView'
        }
      },
      {
        path: 'trigger-view/:id',
        component: TriggerViewComponent,
        data: {
          title: 'TriggerView'
        }
      },
      {
        path: 'trigger-view/:id',
        component: TriggerViewComponent,
        data: {
          title: 'TriggerView'
        }
      },
      {
        path: 'trigger-managment',
        component: TriggerManagmentComponent,
        data: {
          title: 'TriggerManagment'
        }
      },
      {
        path: 'task-managment',
        component: TaskManagmentComponent,
        data: {
          title: 'TriggerManagment'
        }
      },
      {
        path: 'invite-member',
        component: InviteMemberComponent,
        data: {
          title: 'InviteMember'
        }
      },
      {
        path: 'global-search',
        component: GlobalSearchComponent,
        data: {
          title: 'GlobalSearch'
        }
      },
      {
        path: 'log-search',
        component: LogSearchComponent,
        data: {
          title: 'LogSearch'
        }
      },
      {
        path: 'log-resaults',
        component: LogResaultsComponent,
        data: {
          title: 'LogResaults'
        }
      },
      {
        path: 'global-search-resaults',
        component: GlobalSearchResaultsComponent,
        data: {
          title: 'GlobalSearchResaults'
        }
      },
      {
        path: 'card-view/:id',
        component: CardViewComponent,
        data: {
          title: 'CardView'
        }
      },
      {
        path: 'card-management',
        component: CardManagementComponent,
        data: {
          title: 'CardManagement'
        }
      },
      {
        path: 'card-management/card/:id',
        component: CardManagementTableComponent,
        data: {
          title: 'CardManagementTable'
        }
      },
      {
        path: 'card-management/user/id',
        component: CardManagementTableComponent,
        data: {
          title: 'CardManagementTable'
        }
      },
      {
        path: 'card-view',
        component: CardViewComponent,
        data: {
          title: 'CardView'
        }
      },
      {
        path: 'role-view/:id',
        component: RoleViewComponent,
        data: {
          title: 'RoleView'
        }
      },
      {
        path: 'role-managment',
        component: RoleManagmentComponent,
        data: {
          title: 'RoleManagment'
        }
      },
      {
        path: 'role-view',
        component: RoleViewComponent,
        data: {
          title: 'RoleView'
        }
      },
      {
        path: 'card-give/:id',
        component: CardViewGiveComponent,
        data: {
          title: 'CardViewGive'
        }
      },
      {
        path: 'account-view/type/:typeid',
        component: AccountViewComponent,
        data: {
          title: 'Create Account'
        }
      },
      {
        path: 'account-view-server/type/:type',
        component: AccountViewServerComponent,
        data: {
          title: 'AccountViewServer'
        }
      },
      {
        path: 'accounts/:type',
        component: ManageAccountsComponent,
        data: {
          title: 'ManageAccounts'
        }
      },
      {
        path: 'employees-server',
        component: ManageEmployeesServerComponent,
        data: {
          title: 'ManageEmployeesServer'
        }
      },
      {
        path: 'customers-server',
        component: ManageCustomerServerComponent,
        data: {
          title: 'ManageCustomerServer'
        }
      },
      {
        path: 'candidates-server',
        component: ManageCandidateServerComponent,
        data: {
          title: 'ManageCandidateServer'
        }
      },
      {
        path: 'portal-event-view/account/:accountId',
        component: PortalEventViewComponent,
        data: {
          title: 'Add Event'
        }
      },
      {
        path: 'account-event-view-server/account/:typeAndId',
        component: AccountEventViewServerComponent,
        data: {
          title: 'AccountEventViewServer'
        }
      },
      {
        path: 'portal-event-view-server/account/:accountId',
        component: PortalEventViewServerComponent,
        data: {
          title: 'PortalEventViewServer'
        }
      },
      {
        path: 'portal-event-view/event/readonly/account/:id/:eventId',
        component: PortalEventViewReadonlyComponent,
        data: {
          title: 'Event Details'
        }
      },
      {
        path: 'portal-event-view/event/:id/:eventId',
        component: PortalEventViewComponent,
        data: {
          title: 'PortalEventView'
        }
      },
      {
        path: 'portal-event-view-server/event/:eventId',
        component: AccountEventViewServerComponent,
        data: {
          title: 'AccountEventViewServer'
        }
      },
      {
        path: 'portal-event-managment/:accountId',
        component: PortalEventManagmentComponent,
        data: {
          title: 'PortalEventManagment'
        }
      },
      {
        path: 'account-view/account/:id',
        component: AccountViewComponent,
        data: {
          title: 'Account Details'
        }
      },
      {
        path: 'account-view-server/account/:typeAndId',
        component: AccountViewServerComponent,
        data: {
          title: 'AccountViewServer'
        }
      },
      {
        path: 'portal-posts',
        component: PortalPostsComponent,
        data: {
          title: 'PortalPosts'
        }
      },
      {
        path: 'portal-account-posts/:type',
        component: PortalAccountPostsComponent,
        data: {
          title: 'PortalAccountPosts'
        }
      },
      {
        path: 'card-give/edit/:cardId',
        component: CardViewGiveComponent,
        data: {
          title: 'CardViewGive'
        }
      },
      {
        path: 'card-usage',
        component: CardUsageComponent,
        data: {
          title: 'CardUsage'
        }
      },
      {
        path: 'card-usage/:id',
        component: CardUsageComponent,
        data: {
          title: 'CardUsage'
        }
      },
      {
        path: 'event-management',
        component: EventManagementComponent,
        data: {
          title: 'Manage Events'
        }
      },
      {
        path: 'event-view',
        component: EventViewComponent,
        data: {
          title: 'Add Event'
        }
      },
      {
        path: 'event-view/:id',
        component: EventViewComponent,
        data: {
          title: 'View Event'
        }
      },
      {
        path: 'event-attendance/:id',
        component: EventAttendanceComponent,
        data: {
          title: 'Event Attendees'
        }
      },
      {
        path: 'set-management',
        component: SetManagementComponent,
        data: {
          title: 'SetManagement'
        }
      },
      {
        path: 'set-view/:id',
        component: SetViewComponent,
        data: {
          title: 'SetView'
        }
      },
      {
        path: 'set-view/property/:property',
        component: SetViewComponent,
        data: {
          title: 'SetView'
        }
      },
      {
        path: 'chat-settings',
        component: ChatSettingsComponent,
        data: {
          title: 'Chat Settings'
        }
      },
      {
        path: 'property-list',
        component: PropertyListComponent,
        data: {
          title: 'PropertyList'
        }
      },
      {
        path: 'custoumer-service',
        component: CustomerServiceComponent,
        data: {
          title: 'CustomerService'
        }
      },
      {
        path: 'media',
        component: MediaComponent,
        data: {
          title: 'Media'
        }
      },
      {
        path: 'landing-page',
        component: LandingPageComponent,
        data: {
          title: 'LandingPage'
        }
      },
      {
        path: 'ecommerce',
        component: EcommerceComponent,
        data: {
          title: 'Ecommerce'
        }
      },
      {
        path: 'social-network',
        component: SocialNetworkComponent,
        data: {
          title: 'SocialNetwork'
        }
      },
      {
        path: 'notification',
        component: SendNotificationComponent,
        data: {
          title: 'SendNotification'
        }
      },
      {
        path: 'notifications',
        component: NotificationListComponent,
        data: {
          title: 'Notifications'
        }
      },
      {
        path: 'digital-wallet',
        component: DigitalWalletComponent,
        data: {
          title: 'DigitalWallet'
        }
      },
      {
        path: 'global-search-resaults',
        component: GlobalSearchResaultsComponent,
        data: {
          title: 'GlobalSearchResaults'
        }
      },
      {
        path: 'global-administrators',
        component: GlobalAdministratorsComponent,
        data: {
          title: 'GlobalAdministrators'
        }
      },
      {
        path: 'local-administrators',
        component: LocalAdministratorsComponent,
        data: {
          title: 'LocalAdministrators'
        }
      },
      {
        path: 'archive',
        component: ArchiveComponent,
        data: {
          title: 'Archive'
        }
      },
      {
        path: 'recycle-bin',
        component: RecycleBinComponent,
        data: {
          title: 'RecycleBin'
        }
      },
      {
        path: 'module-memnegment',
        component: ModuleManagmentComponent,
        data: {
          title: 'ModuleManagment'
        }
      },
      {
        path: 'backups',
        component: BackupsComponent,
        data: {
          title: 'Backups'
        }
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      }
    ],
    data: { roles: [Role.Administrator] }
  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [
      // User Pages
      {
        path: 'login', component: LoginComponent,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'register', component: RegisterComponent,

      },

      {
        path: 'forgot-password', component: ForgotPasswordComponent,
      },
      {
        path: 'forgot-password/:id', component: ForgotPasswordOrganizationComponent,

      },
      {
        path: 'forgot-password/:id', component: ForgotPasswordOrganizationComponent,

      },
      {
        path: 'register/:id', component: RegisterClubComponent,
        data: {
          title: 'Register'
        }
      },
      {
        path: 'login/:id', component: LoginOrganizationComponent,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'recover', 
        component: PasswordRecoverComponent,
        data: {
          title: 'Recover Password'
        }
      },
      {
        path: 'event',
        component: EventDisplayComponent,
        data: {
          title: 'Event Invitation'
        }
      }
    ]
  },
  {
    path: '',
    component: B2cLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'calendar/:eventId', component: EventManagementComponent,
        data: {
          title: 'Calendar'
        }
      },
      {
        path: 'calendar/:groupId/:memId', component: EventManagementComponent,
        data: {
          title: 'Calendar'
        }
      },
      {
        path: 'profile', component: ProfileComponent,
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'cards', component: CardsComponent,
        data: {
          title: 'Cards'
        }
      },
      {
        path: 'sets', component: SetsComponent,
        data: {
          title: 'Sets'
        }
      },
      {
        path: 'groups', component: GroupsComponent,
        data: {
          title: 'Groups'
        }
      },
      {
        path: 'tasks', component: TasksComponent,
        data: {
          title: 'Tasks'
        }
      },
      {
        path: 'organization-media', component: OrganizationMediaComponent,
        data: {
          title: 'OrganizationMedia'
        }
      },
      {
        path: 'organization-landing-page', component: OrganizationLandingPageComponent,
        data: {
          title: 'OrganizationLandingPage'
        }
      },
      {
        path: 'organization-ecommerce', component: OrganizationEccomerceComponent,
        data: {
          title: 'OrganizationEccomerce'
        }
      },
      {
        path: 'chat', component: ChatComponent,
        data: {
          title: 'Chat'
        }
      },
      {
        path: 'calendar', component: EventManagementComponent,
        data: {
          title: 'Calendar'
        }
      },
      {
        path: 'user-control-pannel', component: UserControlPannelComponent,
        data: {
          title: 'UserControlPannel'
        }
      },
      {
        path: 'group-view/:id', component: GroupViewComponent,
        data: {
          title: 'GroupView'
        }
      },
      {
        path: 'dashboard/:id', component: OrganizationDashboardComponent,
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'group-invite/:id', component: GroupInviteComponent,
        data: {
          title: 'GroupInvite'
        }
      },
      {
        path: 'member-profile/:id', component: MemberProfileComponent,
        data: {
          title: 'MemberProfile'
        }
      },
      {
        path: 'club-social-network', component: ClubSocialNetworkComponent,
        data: {
          title: 'ClubSocialNetwork'
        }
      },
      {
        path: 'portal-event-view/event/readonly/account/:eventId',
        component: PortalEventViewReadonlyAccountComponent,
        data: {
          title: 'PortalEventViewReadonlyAccount'
        }
      },
      {
        path: 'account-directory/:accId',
        component: AccountInfoComponent,
        data: {
          title: 'Account Info'
        }
      },
      {
        path: 'account-portal/:accId',
        component: AccountPortalComponent,
        data: {
          title: 'Account Portal'
        }
      },
      {
        path: 'customer-directory',
        component: AccountInfoComponent,
        data: {
          title: 'Account Info'
        }
      },
      {
        path: 'customer-portal',
        component: CustomerPortalComponent,
        data: {
          title: 'Customer Portal'
        }
      },
      {
        path: 'candidate-directory',
        component: AccountInfoComponent,
        data: {
          title: 'Account Info'
        }
      },
      {
        path: 'candidate-portal',
        component: CandidatePortalComponent,
        data: {
          title: 'Candidate Portal'
        }
      },
      {
        path: 'about',
        component: AboutComponent,
        data: {
          title: 'About'
        }
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
