<!-- <div class="row"> -->
<!-- <div class="col-7"> -->
<section>
    <div dirControl class="main-card card mb-3">
        <div class="card-body">
            <h3 *ngIf="member.id" class="card-title">{{'Edit Member'|translatePipe}}</h3>
            <h3 *ngIf="member.id==false" class="card-title">{{'New Member'|translatePipe}}</h3>

            <div class="row">
                <div class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-9">
                    <div class="row">
                        <div class="col-6">
                            <app-input-label #userName [required]="true" type="text" [label]="'Username'|translatePipe"
                                name="userName" [(value)]="member.identifier" [asyncValidators]="['usernameTaken']">
                            </app-input-label>
                        </div>
                        <div class="col-6">
                            <app-input-label #fullName [required]="true" type="text" [label]="'Fullname'|translatePipe"
                                name="fullName" [(value)]="member.fullName" [asyncValidators]="['fullnameTaken']">
                            </app-input-label>
                        </div>
                    </div>

                    <app-input-label #email [required]="true" [email]="true" type="text" [label]="'email'|translatePipe"
                        name="email" [(value)]="member.email">
                    </app-input-label>
                    <div class="temp-pass-container">
                        <div class="input-container">
                            <app-input-label type="text" [label]="'Password'|translatePipe" name="tempPassword"
                                [(value)]="tempPassword">
                            </app-input-label>
                        </div>
                        <button (click)="generateTempPassword()"
                            class="btn btn-primary">{{'generate'|translatePipe}}</button>
                    </div>

                    <div class="mb-3">
                        <app-multiselect-dropdown [label]="'add to'|translatePipe" [(data)]="memberGroups"
                            [options]="addToOptions">
                        </app-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-3" *ngIf="member.id > 0">
                    <ng-container>
                        <app-image-picker [isCircle]="true"
                            [label]="globalProfileImgSet?.title || 'profile image'|translatePipe"
                            [uplDetails]="imgDet()" (ImgContent)="img($event)">
                        </app-image-picker>
                    </ng-container>
                    <!-- <ng-template #noImg>
                            <app-image-picker [isCircle]="true" [label]="'profile image'|translatePipe" [disabled]="!!globalProfileImgSet">
                            </app-image-picker>
                        </ng-template> -->
                </div>
            </div>
        </div>
    </div>

    <!--TODO: Sets-->
    <!-- <div dirControl class="main-card card mb-3">
            <div class="card-body">
                <h3 class="card-title">{{'global sets'|translatePipe}}</h3>
                <ng-container *ngFor="let set of setMap.global">
                    <app-set-input *ngIf="set.id!==globalProfileImgSet?.id" [set]="set" [(value)]="member.setMap[set.id]">
                    </app-set-input>
                </ng-container>
            </div>
        </div> -->


    <div dirControl *ngIf="localSets.length" class="main-card card mb-3">
        <div class="card-body">
            <h3 class="card-title">{{'local sets'|translatePipe}}</h3>

            <div class="tabs-btns mb-4">
                <button *ngFor="let groupSets of localSets" [class.curr]="groupSets.id===currLocalSetGroupId"
                    (click)="currLocalSetGroupId= groupSets.id">
                    {{groupSets.title}}
                </button>
            </div>
            <div *ngFor="let set of setMap[currLocalSetGroupId]">
                <app-set-input [set]="set" [(value)]="member.setMap[set.id]"></app-set-input>
            </div>

        </div>
    </div>

    <div dirControl>
        <div class="main-card card mb-3">
            <div class="card-body">
                <button (click)="saveUser();save=true" [disabled]="loading" class="btn btn-success btn-icon col-12"> <i
                        class="pe-7s-add-user"></i> {{'save member'|translatePipe}}
                </button>

            </div>
        </div>
    </div>
</section>