<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-6">
      <!-- <div class="app-logo-inverse mx-auto mb-3"></div> -->
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-auto mb-3 text-center">
              <img src="/assets/images/flexyc-logo.png" style="width: 100px; height: 55px;"/>
            </div>
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <span>Forgot your password?</span>
              </h4>
            </div>
            <div *ngIf="!showMsg">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
                <div class="row">
                  <div class="col-md-12">
                    <fieldset class="mb-3" id="__BVID__132">
                      <div tabindex="-1" role="group">
                        <label for="exampleEmail" class="form-label">Email</label>
                        <input id="exampleEmail" name="email" type="email" formControlName="email" placeholder="Email here..." class="form-control">
                        <!-- <app-input-label label="email" name="email" [required]="true" [email]="true" type="text"
                          [(value)]="email">
                        </app-input-label> -->
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="d-flex">
                  <button type="submit"  class="btn btn-primary btn-lg ms-auto">
                    <span class="d-flex align-items-center"> {{ 'recover pass'|translatePipe }} <span class="spinner-border"
                      *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;"></span></span>
                  </button>
                </div>
                
              </form>
            </div>
            <div *ngIf="showMsg" class="text-center">
              <p style="color: #2c2929;">You will receive an email with the instructions to reset your password if your email is registered with us.</p>
            </div>
            <div class="divider"></div>
            <h6 class="mb-0"><a routerLink="/login" class="text-primary">Sign in to an existing account</a></h6>
          </div>
          <!-- <div class="modal-footer clearfix">
            <button type="submit" class="btn btn-primary btn-lg">
              <span class="d-flex align-items-center"> {{ 'recover pass'|translatePipe }} </span>
            </button>
          </div> -->
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Flexyc {{currYear}}
      </div>
    </div>
  </div>
</div>