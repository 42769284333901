import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-employee-directory',
  templateUrl: './employee-directory.component.html',
  styleUrls: ['./employee-directory.component.scss']
})
export class EmployeeDirectoryComponent implements OnInit {



  account: any = this.portalAccountService.getEmptyWithOutType();
  memberIdentidier = '';
  loggedInAccount = 0;
  accountEvents:any = []
  subscription: Subscription[] = [];



  constructor(
    private portalAccountService: PortalAccountsService,
    private portalEventService: PortalEventService,
    private tokenService: TokenService
    
  ) { }

  async ngOnInit(): Promise<void> {
    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    let acc = await this.portalAccountService.getById(this.loggedInAccount).toPromise();
    acc.result.typeData[2].fieldValue = JSON.parse(acc.result.typeData[2].fieldValue)
    acc.result.typeData[3].fieldValue = JSON.parse(acc.result.typeData[3].fieldValue)
    this.account = acc.result;
    this.memberIdentidier = this.account.contactInfo.member.identifier;
    //this.portalEventService.loadPortalEvents().toPromise();
    

    // this.subscription.push(
    //   this.portalEventService.portalEvents$.subscribe(events => {
    //     this.accountEvents = events.filter(event => (event.accountId === this.account.id) && !event.isVisivaleToAccount);
    //   })
    // )
this.accountEvents=this.account.events       
  }
}
