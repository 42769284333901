import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, forkJoin } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { AppState } from "../store/app.store";
import { login } from "../store/actions/auth.actions";
import { TokenService } from "../token/token.service";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private tokenService: TokenService,
        private auth: AuthService

    ) {
    }

    ngOnInit() {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let token = this.tokenService.decodedToken()
        if (token) {
            // check if route is restricted by role
            if(this.tokenService.checkTokenValid(this.tokenService.getToken())) {
                const { roles } = route.data;
                if (roles && !roles.includes(token.role)) {
                    // role not authorized so redirect to home page
                    this.router.navigate([`/dashboard/${token.primarysid}`]);
                    return false;
                }
                return true;
            }
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
        return false;
    }
}