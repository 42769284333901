import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-group-picker-input',
  templateUrl: './group-picker-input.component.html',
  styleUrls: ['./group-picker-input.component.scss']
})
export class GroupPickerInputComponent implements OnInit, AfterViewInit, AfterContentInit {

  @Input() groups?: any[]
  @Input() label?: string = 'Group'
  @Input() disabled?: boolean = false
  subscription: Subscription[] = []

  @Input() set currGroup(val: any) {
    if (val && (this._currGroup)) {
      this.currGroupChange.emit(val);
    }
    this._currGroup = val;
  }
  @Output() currGroupChange = new EventEmitter<any>();
  get currGroup() {
    return this._currGroup;
  }
  private _currGroup;

  constructor(
    private organizationalStructureService: OrganizationalStructureService
  ) { }


  ngAfterContentInit(): void {
    if (!this.groups) {
      this.subscription.push(
        this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
          this.groups = organizationalStructure.map(group => {
            const { aId, name, type, id } = group
            return { aId, name, type, id }
          })
          if (this.currGroup?.aId) {
            if (this.groups.length) {
              this.currGroup = this.groups.find(group => group?.aId === this.currGroup?.aId);
            }
          }
          else {
            this.currGroup = this.groups.find(group => group?.type === 'organization');
          }
        })
      )
      this.organizationalStructureService.loadOrganizationalStructure();
    }
    if (this.currGroup?.aId && this.groups.length) {
      this.currGroup = this.groups.find(group => group?.aId == this.currGroup?.aId);
    }
  }


  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  onSelectChild(childId) {
    this.currGroup = this.groups.find(group => group.id === childId)
  }

}
