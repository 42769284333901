<div class="organizational-structure">
    <app-description-and-legend (toggleView)="toggleView()"></app-description-and-legend>
    <div class="main-card card mb-3">
        <div class="card-body">
            <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
            <div *ngIf="isViewA; else viewB" class="view-a">
                <!-- view a tree view -->
                <app-tree-container [isShowButtons]="true" (onSetCurrAction)="onSetCurrAction($event)"
                    [action]="currAction" [group]="organizationalStructure[0]"
                    (onSelectChild)="onSelectGroupChild($event)" (click)="getSelectedDataById(group)"
                    (toggleSpinner)="showSpinner($event)">
                </app-tree-container>
            </div>
            <ng-template #viewB>
                <!-- view b table view -->
                <app-table-container (onSetCurrAction)="onSetCurrAction($event)"
                    (onSelectGroupChild)="onSelectGroupChild($event)"
                    [group]="organizationalStructure[0]"></app-table-container>
            </ng-template>
        </div>
    </div>
    <div modal #modal title="{{'Delete organization '+elementType|translatePipe}}" [info]=" dataSendToModal()"
        [info2]="info2" [info3]="info3" (accept)="onDeleteOrganizationalStructureChild($event)">
    </div>
</div>