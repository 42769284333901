import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Group } from 'src/app/interfaces/group';
import { ReferForm } from 'src/app/models/eums';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Component({
  selector: 'app-system-settings-general-information',
  templateUrl: './system-settings-general-information.component.html',
  styleUrls: ['./system-settings-general-information.component.scss']
})
export class SystemSettingsGeneralInformationComponent implements OnInit, AfterViewInit {
  @Input() group !: Group;
  @ViewChild('nameInput') nameInput!: any;
  isAfterViewInit = false;
  name = '';
  domain = '';
  description = '';
  color = '#3f6ad7'
  logoUrl = '';
  selectedType = 'organization & users';
  typeOptions = ['organization & users', 'communnity & members', 'business & employees']
  clubTypeOptions = [
    {
      Terminology: {
        Collective: "Organization",
        Individual: "Users"
      },
      Value: 'organization & users'
    },
    {
      Terminology: {
        Collective: "Community",
        Individual: "Members"
      },
      Value: 'communnity & members'
    },
    {
      Terminology: {
        Collective: "Business",
        Individual: "Employees"
      },
      Value: 'business & employees'
    }
  ];
  selectedCountry: string = 'Israel';
  countryOptions = [
    "Israel",
    "Worldwide"
  ];

  constructor(
    public domSanitizer: DomSanitizer,
    private cludinaryService: CloudinaryService
  ) { }

  ngOnInit(): void {
    //console.log(this.group);
  }

  ngAfterViewInit(): void {
    this.isAfterViewInit = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(this.group);
    if (this.group) {
      this.updateData();
    }
  }

  updateData() {
    this.name = this.group?.name;
    this.description = this.group?.description;
    this.logoUrl = this.group?.logoUrl;
    this.domain = this.group?.domain;
    this.color = this.group?.brandColor;
    //console.log(this.logoUrl);
    this.selectedCountry = this.group?.country === 117 ? this.countryOptions[0] : this.countryOptions[1];
    let termObj = JSON.parse(this.group?.clubType);
    this.selectedType = this.clubTypeOptions.filter((val) => {
      return val.Terminology.Collective === termObj.Collective && val.Terminology.Individual === termObj.Individual;
    })[0].Value;
  }

  getImg() {
    return this.logoUrl || 'assets/images/upload-img.png'
  }

  async uploadImg(ev: any) {
    try {
      const newImg = await this.cludinaryService.uploadImg(ev);
      this.logoUrl = newImg
    } catch (err) {
    }
  }

  onRemoveLogo(): void {
    this.logoUrl = '';
  }

  imgDet() {
    if (this.group.aId != 0) {
      var uploadData = {
        fkid: this.group.aId,
        formtype: ReferForm.Organization,
        isAttachment: false
      }
      return uploadData;
    }
  }
}
