import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AccountType, ReferForm } from 'src/app/models/eums';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { SetsService } from 'src/app/services/sets.service';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent implements OnInit {

  @ViewChild('description') description;


  private _memberIdentidier = '';
  subscription: Subscription[] = [];
  account: any = this.portalAccountService.getEmptyWithOutType();
  accountEvents = [];
  isEdit = false;
  members: any = [];
  filteredMembers = [];
  emailSetId = '';
  phoneSetId = '';
  type = '';
  typeId = 0;
  id: any;
  accountDescription: any = "";
  //
  singleDropdown: any = "singleDropdown"
  Singlemember: any = [];
  imageSource: any = '';
  currProccess = 'onboarding';
  baseAccount = null
  faevent = faCalendarCheck;
  proccessOptions = ['onboarding', 'development']
  uploadData: any;
  get memberIdentidier() {

    return this._memberIdentidier;
  }

  set memberIdentidier(value) {
    this._memberIdentidier = value;
    this.filterMembers();
  }

  get directManagerShowData() {
    return this.account.typeData[3].fieldValue.map(directManager => directManager.identifier).join(', ')
  }
  get seconderyContactShowData() {
    return this.account.typeData.seconderyContact.map(contact => contact.identifier).join(', ')
  }

  constructor(
    private route: ActivatedRoute,
    private portalAccountService: PortalAccountsService,
    private portalEventService: PortalEventService,
    private memberService: MembersService,
    private setService: SetsService,
    private modalService: NgbModal,
    private router: Router,
    private errhandle: ErrorHandlerService,
    private organizationStructure: OrganizationalStructureService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer,
    private tokenService: TokenService
  ) { }

  async ngOnInit(): Promise<void> {
    let user = this.tokenService.getUser();
    this.memberService.loadMembers();
    this.subscription.push(
      this.memberService.members$.subscribe((members) => {
        this.members = members;
        //TODO - when we work on sets
        // const sets = await this.setService.getSets().toPromise();
        // sets.forEach(set => {
        //   if (set.property.type === 'phone') this.phoneSetId = set.id
        //   else if (set.property.type === 'email') this.emailSetId = set.id
        // })
      })
    );

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const type = params.get("typeid");
        const id = params.get("id");
        this.id = id;
        if (type) {
          this.typeId = Number.parseInt(type);
          this.portalAccountService.getAccountTypeById(this.typeId).subscribe({
            next: (data: any) => {
              this.type = data.result.name;
              this.account.type = this.type;
              if (data.result.typeFields) {
                data.result.typeFields.forEach((field: any) => {
                  if (field.fieldType === 2) {
                    field.fieldValue = new Date().toISOString();
                  }
                });
              }
              this.account.typeData = data.result.typeFields;
            },
            error: (err: any) => { },
          });
          this.isEdit = true;
        } else if (id) {
          var a = await this.portalAccountService.getById(id).toPromise();
          a.result.typeData.forEach((data) => {

            if (
              data.fieldType === 5 &&
              data.fieldValue &&
              data.fieldValue !== "null"
            ) {
              let orgData = JSON.parse(data.fieldValue);
              data.fieldValue = {
                aId: orgData.aId,
                name: orgData.name,
                type: orgData.type,
              };
            } else if (
              data.fieldType === 6 &&
              data.fieldValue &&
              data.fieldValue !== "null"
            ) {
              data.fieldValue = JSON.parse(data.fieldValue);
            }
          });
          this.account = a.result;
          this.accountDescription = this.account.description;
          this.baseAccount = JSON.parse(JSON.stringify(this.account));
          this.type = this.account.type;
          this.typeId = this.account.typeId;
          this.accountEvents = this.account.events;
          this.memberIdentidier = this.account.contactInfo.member.identifier;
          this.Singlemember.push(this.account.contactInfo.member)
          var data = {
            fkId: this.account.contactInfo.member.id,
            formType: ReferForm.Members,
          };
          this.fileService.getFileFkid(data).subscribe((data: any) => {
            if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
              this.fileService
                .getFilebyId(data.result[0].id)
                .subscribe((data: any) => {
                  this.imageSource =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                      `data:image/png;base64, ${data.result.fileContent}`
                    );
                });
            }
          });
        }
      })
    );
  }

  filterMembers() {
    //if (this.memberIdentidier) {
    this.filteredMembers = this.members.filter((member) =>
      member.identifier
        .toLowerCase()
        .includes(this.memberIdentidier.toLowerCase())
    );
    // }else {
    //   this.filteredMembers = this.members;
    // }
  }

  toggleIsEdit() {
    if (this.isEdit) {
      this.account = JSON.parse(JSON.stringify(this.baseAccount));
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
  }

  chooseMember(member) {

    this.memberIdentidier = member.identifier;
    this.account.contactInfo.member = {
      id: member.id,
      identifier: member.identifier,
    };
    if (this.emailSetId) {
      const email = member.setMap[this.emailSetId];
      if (email) this.account.contactInfo.email = email;
    }
    if (this.phoneSetId) {
      const phone = member.setMap[this.phoneSetId];
      if (phone) this.account.contactInfo.phone = phone;
    }
  }

  async saveAccount() {
    let dataToSend = {
      accountId: 0,
      typeId: this.typeId,
      Fullname: this.account.data.fullName,
      title: this.account.data.title,
      description: this.account.description ? this.account.description : "",
      email: this.account.contactInfo.email,
      phone: this.account.contactInfo.phone,
      gender: this.account.contactInfo.gender,
      address: this.account.contactInfo.address,
      memberId: this.account.contactInfo.member.id,
      fieldData: [],
    };

    if (this.account.typeData) {
      this.account.typeData.forEach((data: any) => {
        let value = "";
        if (data.name === "Member") {
          value =
            data.fieldValue.length > 0
              ? data.fieldValue
                .map((directManager) => directManager.uniqueId)
                .join(",")
              : "";
        } else if (data.name === "Node") {
          value = data.fieldValue.id;
        }
        if (typeof data.fieldValue === "boolean") {
          data.fieldValue = data.fieldValue.toString();
        }
        let fd = {
          fieldId: data.id,
          value:
            value === "" && data.name !== "Member" ? data.fieldValue : value,
        };
        dataToSend.fieldData.push(fd);
      });
    }

    //return;
    if (this.account.id) {
      dataToSend.accountId = this.account.id;
      this.portalAccountService.updateAccount(dataToSend).subscribe({
        next: (data: any) => {
          this.errhandle.openToast({
            title: "SUCCESS",
            type: "success",
            message: "Account details updated.",
          });
          this.router.navigate(["account-view", "account", this.account.id]);
          this.baseAccount = JSON.parse(JSON.stringify(this.account));
          this.isEdit = false;
          this.router.navigate(["/accounts/" + this.typeId]);
        },
        error: (err: any) => {
          this.errhandle.openToast({
            title: "ERROR",
            type: "error",
            message: "Failed to update. Please try again later.",
          });
        },
      });
    } else {
      this.portalAccountService.add(dataToSend).subscribe({
        next: (data: any) => {
          this.errhandle.openToast({
            title: "SUCCESS",
            type: "success",
            message: "Account details added.",
          });
          this.router.navigate(["/accounts/" + this.typeId]);
        },
        error: (err: any) => {
          this.errhandle.openToast({
            title: "ERROR",
            type: "error",
            message: "Failed to add. Please try again later.",
          });
        },
      });
    }
  }

  // setAccountEventMap() {
  //   const accountEventMap = {
  //     recent: [],
  //     onboarding: [],
  //     development: [],
  //     termination: [],
  //   }

  //   this.accountEvents.forEach(event => {
  //     this.proccessOptions = ['onboarding', 'development']
  //     if (!event.proccess) accountEventMap.recent.push(event)
  //     else {
  //       accountEventMap[event.proccess].push(event);
  //       if (event.proccess === 'termination') this.proccessOptions = ['onboarding', 'development', 'termination']
  //     }
  //   })

  //   this.accountEventMap = accountEventMap;

  // }

  async updateDirectManagers(groupId) {
    const members = await this.memberService.getMembersPromise();
    this.account.typeData.directManagers = members.filter((member) =>
      member.moderatorOf.some(
        (moderatorOfListGroupId) => moderatorOfListGroupId === groupId
      )
    );
  }

  onGroupChange(group, typeData) {
    if (group?.aId && typeData.fieldValue.aId !== group.aId) {
      typeData.fieldValue = group;
      //this.updateDirectManagers(group.aId);
    }
  }

  openDescription() {
    this.modalService.open(this.description).result.then(
      (result) => { },
      (reason) => { }
    );
  }

  eventDeleted($eventId) {
    this.accountEvents = this.accountEvents.filter(
      (event) => event.id !== $eventId
    );
  }

  imgDet() {
    if (this.account.id) {
      var uploadData = {
        fkid: this.id,
        formtype: ReferForm.Members,
        isAttachment: false,
      };
      return uploadData;
    }
  }

  DescriptionSave(ev: any) {
    if (ev == "save") {
      this.accountDescription = this.accountDescription;
      this.account.description = this.accountDescription;
    } else if (ev == "close") {
      this.accountDescription = this.account.description;
      this.account.description = this.accountDescription
    }
  }
}
