import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalPostService } from 'src/app/services/portal-post.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-account-portal',
  templateUrl: './account-portal.component.html',
  styleUrls: ['./account-portal.component.scss']
})
export class AccountPortalComponent implements OnInit {

  subscription: Subscription[] = []
  posts = []
  loggedInAccount: any;
  constructor(
    private portalPostService: PortalPostService,
    private tokenService: TokenService,
    private portalAccountService: PortalAccountsService
  ) { }

  async ngOnInit(): Promise<void> {

  }

}
