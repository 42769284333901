<div dirControl class="card mb-3">
    <div class="card-body">
        <h3 class="card-title">{{'generalInformation'|translatePipe}}</h3>
        <div class="row">
            <form class="col-12 col-md-6 col-lg-8">
                <app-input-label #nameInput [required]="true" [label]="'name'|translatePipe" name="name" type="text"
                    [(value)]="name">
                </app-input-label>
                <app-input-label [required]="true" [label]="'domain'|translatePipe" name="domain" type="text" [(value)]="domain">
                </app-input-label>
                <app-textarea-label [(value)]="description" [label]="'description'|translatePipe" name="description">
                </app-textarea-label>
                <app-dropdown-label [label]="'club type'|translatePipe" name="club-type" [(value)]="selectedType"
                    [options]="typeOptions">
                </app-dropdown-label>
                <app-dropdown-label [label]="'country'|translatePipe" name="country" [(value)]="selectedCountry"
                    [options]="countryOptions"></app-dropdown-label>
                <div class="mb-3">
                    <app-color-picker [label]="'brand color'|translatePipe" [(color)]="color"></app-color-picker>
                </div>
            </form>
            <div class="col-12 col-md-6 col-lg-4"  *ngIf="group!=undefined">
                <app-image-picker [label]="'logo'|translatePipe" [(value)]="logoUrl"   [uplDetails]="imgDet()"></app-image-picker>
            </div>
        </div>
    </div>
</div>