import { Component, DoCheck, Input, IterableDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Member } from 'src/app/interfaces/member';
import { Set } from 'src/app/interfaces/set';
import { ReferForm } from 'src/app/models/eums';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';
import { UtilService } from 'src/app/services/util.service';
import { TokenService } from 'src/app/token/token.service';
import { AppAsyncValidators } from 'src/app/validators/async-validators';

@Component({
  selector: 'app-member-profile-view',
  templateUrl: './member-profile-view.component.html',
  styleUrls: ['./member-profile-view.component.scss']
})
export class MemberProfileViewComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('modal') modal!: any;
  @ViewChild('userName') userName!: any;
  @ViewChild('email') email!: any;

  @Input() isEditable: boolean;

  subscription: Subscription[] = []
  localSets = []
  iterableDiffer: any
  currLocalSetGroupId = ''
  member: Member = this.memberService.emptyMember;
  ////
  mem: any = [];
  addToOptions: any[] = [];
  tempPassword: string = '';
  memberGroups: any[] = [];
  originalSetsMap = {}
  globalProfileImgSet: Set;
  loggedInAccount: any;
  setMap = {
    global: [],
  }
  loading = false;
  currentmem: any;
  orgMem: any;
  org: any = [];
  fullMemberGroups: any;
  orgm: any;
  gpdata: any = [];
  save: boolean = false;

  // choosenOption;
  get isSubmitDisable() {
    const isUserNameValid = true
    const isEmailValid = this.email?.formControler?.valid
    return !(isEmailValid && isUserNameValid)
  }

  updateLocalSets() {
    this.localSets.splice(0, this.localSets.length);
    Object.keys(this.setMap).forEach(key => {
      if (key !== 'global' && this.memberGroups.some(group => group.id === this.setMap[key][0].group.id)) {
        if (!this.localSets.length) this.currLocalSetGroupId = this.setMap[key][0].group.id
        this.localSets.push({ title: this.setMap[key][0].group.name, id: this.setMap[key][0].group.id, list: this.setMap[key] })
      }
    })

    const globalMemberSetIds = this.setMap.global.map(set => set.id);
    let localMemberSetIds = [];
    this.localSets.forEach(group => {
      group.list.forEach(set => localMemberSetIds.push(set.id))
    })

    const allMemberSetIds = [...globalMemberSetIds, ...localMemberSetIds]
    Object.keys(this.member.setMap).forEach(setId => {
      if (!allMemberSetIds.some(id => id === setId)) delete this.member.setMap[setId]
    })
  }

  // get currOption() {
  //   return 'choose Group/Department/Organization';
  // }

  // set currOption(value) {
  //   this.onChooseOption(value);
  // }

  constructor(
    public memberService: MembersService,
    public organizationalStructureService: OrganizationalStructureService,
    public setService: SetsService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private modalService: NgbModal,
    private router: Router,
    public asyncValidators: AppAsyncValidators,
    private authService: AuthService, private fileService: FilesService,

    iterableDiffers: IterableDiffers,
    private errhandle: ErrorHandlerService,
    private tokenService: TokenService
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }


  ngDoCheck(): void {
    let changes = this.iterableDiffer.diff(this.memberGroups);
    if (changes) {
      this.updateSets()
    }
  }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(params => {
        const memberId = params.get('id');
        if (memberId) {
          this.subscription.push(
            this.memberService.getMember(memberId).subscribe((member: any) => {
              //this.originalSetsMap = {...member.setMap}
              this.member = member.result;
              this.mem = member.result;
              //this.updateSets();
            }))
        }
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe((org: any) => {
        this.orgm = org;
        this.addToOptions = this.orgm.map(child => ({
          id: child.id,
          type: child.type,
          name: child.name,
          string: `${child.type.charAt().toUpperCase()}${child.type.slice(1)}: ${child.name}`
        }));

        if (this.orgm.length > 0) {
          for (let i of this.orgm) {
            if (i.members != null) {
              for (let l of i.members) {
                if (this.mem.uniqueId == l) {
                  this.gpdata = i;
                  this.memberGroups = this.addToOptions.filter(option => this.gpdata.some(group => group.id === option.id))
                }
              }
            }
          }
          // this.fullMemberGroups = org.filter((child:any) => child.members.some((memberId:any) => memberId === this.mem.uniqueId))
        }

        this.sortAddToOptions();
      })
    )
    // this.subscription.push(
    //   this.organizationalStructureService.organizationalStructure$.subscribe((org:any) => {
    //     this.orgMem =org
    //     this.addToOptions = org.map(child => ({
    //       id: child.id,
    //       type: child.type,
    //       name: child.name,
    //       string: `${child.type}: ${child.name}`
    //     }));
    //     const fullMemberGroups = this.currentmem.filter(child => child.members.some(memberId => memberId === this.mem.uniqueId));
    //     this.memberGroups = this.addToOptions.filter(option => fullMemberGroups.some(group => group.id === option.id));
    //     this.sortAddToOptions();
    //   })
    // )

    // this.subscription.push(
    //   this.setService.set$.subscribe(sets => {
    //     this.setMap = {
    //       global: [],
    //     }
    //     sets.forEach(set => {
    //       if (set.group.type === 'organization') {
    //         if (set.property.type === 'url') this.globalProfileImgSet = set;
    //         this.setMap.global.push(set);
    //         if (!this.member.setMap[set.id]) this.member.setMap[set.id] = set.default
    //       } else {
    //         if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set)
    //         else this.setMap[set.group.id] = [set]
    //       }
    //     })
    //     this.updateSets()
    //   })
    // )


    this.organizationalStructureService.loadOrganizationalStructure()
    this.memberService.loadMembers();
    // this.setService.loadSets();
  }

  ngOnDestroy(): void {

    if (this.member.id) {
      const savedMember = this.memberService.getMembers().find(member => member.id === this.member.id);
      if (this.member.identifier !== savedMember.identifier || this.member.fullName !== savedMember.fullName ||
        this.member.email !== savedMember.email) {
          if(this.save !== true){
            const isWantSave = confirm('There are unsaved changes; are you sure you want to leave this page?');
            if (isWantSave) {
              if (this.member.id) {
                this.loading = true;
                this.memberService.editMember(this.member.id, this.member.identifier, this.member.fullName, this.member.email).subscribe({
                  next: (data: any) => {
                    this.errhandle.openToast({ title: 'Success', type: 'success', message: 'Added successfully!' })
                    this.loading = false;
                  },
                  error: (err: any) => {
                    this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result });
                    this.loading = false;
                  }
                })
              }
            }
          }
          else {
            this.router.navigate(['manage-members'])
          }
      }
      
    } else {
      console.log(this.member);
      if (this.member.email !== '' || this.member.identifier !== '' || this.member.fullName !== '') {
        const isWantSave = confirm('There are unsaved changes; are you sure you want to leave this page?');
        if (isWantSave) {
          this.navigateHere()
        }
      }
    }
  }

  navigateHere() {
    const router = this.router
    function nav() {
      router.navigate(['member-view'])
    }
    setTimeout(nav, 10);
  }


  async saveUser() {
    // if (this.member.id) {
    //   this.memberService.updateMember(this.member).toPromise()
    // } else {
    //   this.memberService.addMember(this.member)
    //   this.member = this.memberService.emptyMember;
    // }
    if (this.loading)
      return;
    if (this.member.id) {
      this.loading = true;
      this.memberService.editMember(this.member.id, this.member.identifier, this.member.fullName, this.member.email).subscribe({
        next: (data: any) => {
          this.errhandle.openToast({ title: 'Success', type: 'success', message: 'Added successfully!' })
          this.loading = false;
          this.router.navigate(['/manage-members']);
        },
        error: (err: any) => {
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result });
          this.loading = false;
        }
      })
    }
    else {
      let reqBody = { identifier: this.member.identifier, fullName: this.member.fullName, email: this.member.email, password: this.tempPassword, isSelfRegistration: false }
      this.authService.register(reqBody).subscribe({
        next: (data: any) => {
          this.member.email = this.member.identifier = this.member.fullName = '';//
          this.member = this.memberService.emptyMember;
          this.errhandle.openToast({ title: 'Success', type: 'success', message: 'Added successfully!' });
          this.loading = false;
          this.router.navigate(['/manage-members']);
        },
        error: (err: any) => {
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result });
          this.loading = false;
        }
      })
    }
  }

  generateTempPassword() {
    this.tempPassword = this.utilService.generateTempPassword()
  }

  onChooseOption(option) {
    this.addToOptions = this.addToOptions.filter(addToOption => addToOption.id !== option.id);
    this.memberGroups = [...this.memberGroups, option];
  }

  // onRemoveOption(option) {
  //   const organizationStructure = this.organizationalStructureService.getOrganizationalStructure();
  //   const parentId = organizationStructure.find(group => group.id === option.id).parent;
  //   //console.log(organizationStructure.find(group => group.id === parentId));

  //   //console.log(parentId);

  //   //console.log(organizationStructure.find(group => group.id === parentId));

  //   // if (organizationStructure.find(group => group.id === (organizationStructure.find(group => group.id === option.id).parent)).type !== 'department') {
  //   //   //open popup
  //   //   // this.choosenOption = option
  //   //   // this.open();
  //   // } else {
  //   //   this.memberGroups = this.memberGroups.filter(group => group.id !== option.id);
  //   //   this.addToOptions = [...this.addToOptions, option];
  //   // }
  // }

  sortAddToOptions() {
    this.addToOptions = this.addToOptions.filter(option => !this.memberGroups.some(group => group.id === option.id))
    this.addToOptions.sort((optionA, optionB) => {
      if (optionA.type > optionB.type) return 1
      if (optionA.type < optionB.type) return -1
      else return 0
    })
  }

  // onPopupDelete(cb: Function) {
  //   this.memberGroups = this.memberGroups.filter(group => group.id !== this.choosenOption.id);
  //   this.addToOptions = [...this.addToOptions, this.choosenOption];
  //   cb('Close click')
  // }

  open() {
    this.modalService.open(this.modal).result.then((result) => {
    }, (reason) => {
    });
  }

  async updateSets() {
    if (this.globalProfileImgSet) {
      if (this.originalSetsMap[this.globalProfileImgSet.id]) this.member.setMap[this.globalProfileImgSet.id] = this.originalSetsMap[this.globalProfileImgSet.id]
      else this.member.setMap[this.globalProfileImgSet.id] = this.globalProfileImgSet.default
      // if (!this.member.setMap[this.globalProfileImgSet.id]) this.member.setMap[this.globalProfileImgSet.id] = this.globalProfileImgSet.default
      // else this.member.setMap[this.globalProfileImgSet.id]= this.
    }
    Object.keys(this.setMap).forEach(key => {
      this.setMap[key].forEach(set => {
        if (this.memberGroups.some(group => group.id === set.group.id)) {
          if (this.originalSetsMap[set.id]) this.member.setMap[set.id] = this.originalSetsMap[set.id]
          else this.member.setMap[set.id] = set.default
        }
      })
    })
    this.updateLocalSets()
  }


  imgDet() {
    if (this.member != undefined) {
      var uploadData = {
        fkid: this.member.id,
        formtype: ReferForm.Members,
        isAttachment: false
      }

      return uploadData;
    }



  }

  img(ev: any) {
    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    if (this.member.id == this.loggedInAccount) {
      this.fileService.imgCont(ev);
    }

  }



}


