import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Structure } from '../interfaces/structure';
import { GlobalVariablesService } from './global-variables.service';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class OrganizationServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'organization/';
  private url = AppConfig.settings.apiServer.baseUrl + 'department';
  private _structure$ = new BehaviorSubject<Structure>(null);
  public structure$ = this._structure$.asObservable();

  // LGBLW6J61G5ZTSWA

  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
  ) { }

  loadStructure() {
    this.getStracture().pipe(
      tap(structure => this._structure$.next(structure))
    ).toPromise();
  }

  getStracture() {
    return this.http.post<Structure>(this.url + 'department', { headers: { ouid: "LGBLW6J61G5ZTSWA" } });
  }
  updater(name: any, description: any) {
    //console.log(name, description)
    return this.http.post(`${this.url}`, { description: description, title: name })
  }
  updatev(id: string, name: any, description: any) {
    //console.log(name, description)
    return this.http.put(`${this.url}`, { description: description, title: name })
  }
}
