<div class="search-container flexyc-input mb-4" ngbDropdown>
    <form>
        <label [for]="name" [class.input-empty]="!input.value && !isFocus" *ngIf="label">{{label | titleCase}}</label>
        <input (focus)="isFocus=true" (blur)="isFocus=false" #input [disabled]="disabled" id="member" ngbDropdownToggle [name]="name" type="text" autocomplete="new-password"
            class="form-control" [placeholder]="placeholder" [(ngModel)]="text" [id]="name">
        <div *ngIf="options.length" ngbDropdownMenu class="dropdown-container">
            <button *ngFor="let option of options" ngbDropdown (click)="onChooseOption(option)"
                class="dropdown-item">{{option.identifier}}</button>
        </div>
    </form>
</div>