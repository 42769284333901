import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-event-attendance-progress-bar',
  templateUrl: './event-attendance-progress-bar.component.html',
  styleUrls: ['./event-attendance-progress-bar.component.scss']
})
export class EventAttendanceProgressBarComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) { }

  @Input() length: number;
  @Input() inviteMapKeys: any;
  @Input() inviteMap: any;


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inviteMapKeys.forEach(key => {
      const elInvite = this.elementRef.nativeElement.querySelector('.' + key.split(' ')[0]);
      const width = (this.inviteMap[key].listGuest.length + this.inviteMap[key].listMember.length) / this.length * 100;
      if (elInvite) elInvite.style.width = `${width}%`

    })
  }

  ngAfterViewInit(): void {
    this.inviteMapKeys.forEach(key => {
      const elInvite = this.elementRef.nativeElement.querySelector('.' + key.split(' ')[0]);
      const width = (this.inviteMap[key].listGuest.length + this.inviteMap[key].listMember.length) / this.length
      if (elInvite) elInvite.style.width = `${width}%`

    })
  }

}
