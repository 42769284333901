<div dirControl class="description-and-legend-container">
    <div class="mini-menu-container">
        <app-flexyc-mini-menu>
            <button (click)="toggleView.emit()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary">
                <i class="pe-7s-look btn-icon-wrapper"></i>
                {{ 'changeView' | translatePipe}}
            </button>
            <button (click)="toggleSlideSituation()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary">
                <i *ngIf="slideSituation==='out'" class="pe-7s-angle-up btn-icon-wrapper"></i>
                <i *ngIf="slideSituation==='in'" class="pe-7s-angle-down btn-icon-wrapper"></i>
                {{(slideSituation==="in"? 'show':'hide') | translatePipe}}
            </button>
        </app-flexyc-mini-menu>
    </div>
    <div [@slide-vertical]="slideSituation" class="row description-and-legend">
        <div class="description-container col-sm-6 col-md-9 col-lg-9 col-xl-9">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <h3 class="card-title">{{'legendWelcome' | translatePipe}}</h3>
                    <p class="description">{{'legendText' | translatePipe}}</p>
                    <p>{{'legendTexts'| translatePipe}}</p>
                </div>
            </div>
        </div>
        <div class="legend-container col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <div class="main-card mb-3 card">

                <div class="card-body">
                    <h3 class="card-title">{{'legend'| translatePipe}}</h3>
                    <div class="legend" *ngFor="let legend of legends">
                        <div [style.background-color]="legend.color" class="square"></div>
                        <p style="font-size: 17px;">{{legend.description | translatePipe}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="view-stuation col-12 col-md-12 col-lg-3 text-center mb-3">
        <button (click)="toggleView.emit()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary">
            <i class="pe-7s-look btn-icon-wrapper"></i>
            {{ 'changeView' | translatePipe}}
        </button>
        <button (click)="toggleSlideSituation()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary">
            <i *ngIf="slideSituation==='out'" class="pe-7s-angle-up btn-icon-wrapper"></i>
            <i *ngIf="slideSituation==='in'" class="pe-7s-angle-down btn-icon-wrapper"></i>
            {{(slideSituation==="in"? 'show':'hide') | translatePipe}}
        </button>
    </div> -->

</div>