import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalPostService } from 'src/app/services/portal-post.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {


  loggedInAccount: any = [];
  subscription: Subscription[] = []

  posts: any = [];
  acctype = '';

  constructor(
    private portalPostService: PortalPostService,
    private token: TokenService,
    private portalAccountService: PortalAccountsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.posts=[];
    //this.loggedInAccount = this.token.decodedToken().nameid;
    this.route.paramMap.subscribe((params) => {
      this.loggedInAccount = params.get('accId');
      this.portalAccountService.getById(this.loggedInAccount).subscribe((data: any) => {
        this.acctype = data.result.type;
        ////console.log(data.result);
        this.portalPostService.getPostById(data.result.typeId).subscribe((data: any) => {
          if (data.result != "Unable to retrieve the Account Posts details.") {
            this.posts = data.result;
          }
        })
      });
    });
  }
}
