import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { statusConfig } from "src/app/global-component.service";
import { ErrorHandlerService } from "src/app/services/error-handler.service";

@Component({
  selector: "app-task-status",
  templateUrl: "./task-status.component.html",
  styleUrls: ["./task-status.component.scss"],
})
export class TaskStatusComponent implements OnInit {
  @Output() updateStatus = new EventEmitter<any>();
  @ViewChild("chooseTaskPop", { static: true }) elChooseTask: any;
  @Input() status: string;
  @Input() task: any[];

  statusConfig = statusConfig;

  taskName: any = "";

  constructor(
    private modal: NgbModal,
    private errhandle: ErrorHandlerService
  ) { }

  ngOnInit(): void { }

  getColorStatus() {
    const currColor: any = this.statusConfig?.find(
      (statusConfigItem: any) => statusConfigItem.title === this.status
    );
    return currColor.color;
  }

  open(status: any) {
    this.taskName = status.title;
    if (this.status === "done") {
      this.errhandle.openToast({
        title: "ERROR",
        type: "error",
        message: "Tasks marked as done cannot be modified.",
      });
      return;
    }
    this.modal.open(this.elChooseTask, { size: "md" });
  }

  chooseStatus(status, cb?) {
    if (cb) cb();
    this.updateStatus.emit(status.title);
  }
}
