<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <!-- <div class="app-logo-inverse mx-auto mb-3"></div> -->

      
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-auto mb-3 text-center">
              <img src="/assets/images/flexyc-logo.png" style="width: 100px; height: 55px;"/>
            </div>
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>Welcome back</div>
                <span>Please sign in to your account below.</span>
              </h4>
            </div>
            <!-- <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
              class="mb-3">
              <div> -->
            <!-- <app-input-label label="email" name="email" [required]="true" [email]="true" type="text"
                  [(value)]="email">
                </app-input-label> -->
            <!-- <app-input-label [(isValid)]="isEmailValid" [label]="'email'|translatePipe" name="email" [required]="true" [email]="true" type="email"
                  [(value)]="email">
                </app-input-label> -->
            <!-- <input id="exampleInput1" type="email" placeholder="Enter email..." required="required"
                  aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_" /> -->
            <!-- <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small> -->
            <!-- </div>
            </div>
            <div id="exampleInputGroup2" role="group" class="mb-3">
              <div> -->
            <!-- <input id="exampleInput2" type="password" placeholder="Enter password..." required="required"
                  aria-required="true" class="form-control" /> -->
            <!-- <app-input-label label="password" name="password" [required]="true" type="password"
                  [(value)]="password">
                </app-input-label> -->
            <!-- <app-input-label [(isValid)]="isPasswordValid" [label]="'password'|translatePipe" name="password" [required]="true" type="password"
                 [syncValidators]="['password']"  [(value)]="password">
                </app-input-label> -->
            <!-- </div>
            </div> -->

            <!-- new form -->


            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

              <div class="mb-3">
                <label class="form-label" for="username">Email</label><span class="text-danger">*</span>
                <input type="email" class="form-control" id="email" formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" placeholder="Enter email">
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                  <div *ngIf="f['email'].errors['required']">Email is required</div>
                  <div *ngIf="f['email'].errors['email']">Email must be a valid email address
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label" for="password-input">Password</label><span class="text-danger">*</span>
                <div class="position-relative auth-pass-inputgroup mb-3">
                  <input class="form-control pe-5" placeholder="Enter password" id="password-input"
                    formControlName="password" type="password"
                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                  <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                    <span *ngIf="f['password'].errors['required']">Password is
                      required</span>
                  </div>
                </div>
              </div>

              <div class="mt-4">
              </div>
              <button style="visibility:hidden" type="submit" class="btn btn-primary btn-lg"></button>


            </form>

            <!-- <div class="custom-control custom-checkbox form-check"> -->
            <!-- <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input"
                value="true" />
              <label for="exampleCheck" class="form-label form-check-label">
                Keep me logged in
              </label> -->
            <!-- <app-check-box-label [(isCheck)]="isKeepLogin" label="Keep me logged in"></app-check-box-label>
            </div> -->
            <!-- <div class="divider"></div>
            <h6 class="mb-0">
              No account?
              <a routerLink="/register" class="text-primary">Sign up now</a>
            </h6> -->
          </div>
          <div class="modal-footer clearfix">
            <div class="float-start">
              <a routerLink="/forgot-password" class="btn-lg btn btn-link">Forgot
                password</a>
            </div>
            <div class="float-end">
              <!-- <button type="button" class="btn btn-primary btn-lg">
                Login
              </button> -->
              <!-- <button (click)="login()" type="button" class="btn btn-primary btn-lg">
                {{ 'Login'|translatePipe }}
              </button> -->
              <button (click)="onSubmit()" type="submit" class="btn btn-primary btn-lg">
                <span class="d-flex align-items-center"> {{ 'Login'|translatePipe }} <span class="spinner-border"
                    *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;"></span></span>
              </button>
              <a routerLink="../register" class="btn btn-link">Register</a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Flexyc {{currDate | date:'yyyy'}}
      </div>
    </div>
  </div>
</div>