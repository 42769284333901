import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalPostService } from 'src/app/services/portal-post.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-customer-portal',
  templateUrl: './customer-portal.component.html',
  styleUrls: ['./customer-portal.component.scss']
})
export class CustomerPortalComponent implements OnInit {

  subscription: Subscription[] = [];
  loggedInAccount: any;
  posts = []

  constructor(
    private portalPostService: PortalPostService,
    private tokenService: TokenService,
    private portalAccountService: PortalAccountsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    let acc = await this.portalAccountService.getById(this.loggedInAccount).toPromise();
    this.portalPostService.loadPortalPosts(acc.result.typeId);
    this.subscription.push(
      this.portalPostService.portalPosts$.subscribe(posts => {
        this.posts = posts;
      })
    )
    //this.portalPostService.loadPortalPosts();
  }

}
