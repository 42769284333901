import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReferForm } from 'src/app/models/eums';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { TokenService } from 'src/app/token/token.service';
import { Format, question } from 'src/app/interfaces/event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-portal-event-view-readonly',
  templateUrl: './portal-event-view-readonly.component.html',
  styleUrls: ['./portal-event-view-readonly.component.scss']
})
export class PortalEventViewReadonlyComponent implements OnInit {

  event = null
  subscription: Subscription[] = [];
  account = null;
  loggedInAccount: any = '';
  id: any;
  evtImageSource: any = '';
  accountImgSrc: any;
  @Input() isFromAccount = false;
  icon: any;
  icons: any;

  get eventParticipantsStr() {
    return this.event?.participants.map(member => member.identifier).join(', ')
  }

  constructor(
    private route: ActivatedRoute,
    private portalEventService: PortalEventService,
    private portalAccountService: PortalAccountsService,
    private router: Router,
    private tokenService: TokenService,
    private errHandler: ErrorHandlerService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const accountId = params.get('accountId');
        const eventId = params.get('eventId');
        const id = params.get('id');
        this.id = id;
        if (accountId) {
          this.event.accountId = accountId;
          let accDets = await this.portalAccountService.getById(accountId).toPromise();
          this.account = accDets.result;
          var evtImg: any = {
            fkId: this.event.id,
            formType: ReferForm.AccountEvents
          }
          this.fileService.getFileFkid(evtImg).subscribe({
            next: (data: any) => {
              if (data.result === 'InvalidDetailsToRetrieveTheFile') {
                return;
              }
              var files = data.result;
              if (files && files.length > 0) {
                this.fileService.getFilebyId(files[0].id).subscribe((data: any) => {
                  this.evtImageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
                })
              }
            }, error: (data: any) => {
            }
          })

          var accImage = {
            fkId: this.account.contactInfo.member.id,
            formType: ReferForm.Members
          }
          this.fileService.getFileFkid(accImage).subscribe((data: any) => {
            if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
              this.fileService.getFilebyId(data.result[0].id).subscribe((data: any) => {
                this.accountImgSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
              })
            }
          })
        }
        else if (eventId) {
          var acc = await this.portalEventService.getById(eventId).toPromise();
          let accDets = await this.portalAccountService.getById(this.id).toPromise();
          this.account = accDets.result;
          this.event = acc.result;
          this.icons = this.account.events
          for (let i of this.icons) {
            this.icon = i.typeImageURL
          }
          var evtImg: any = {
            fkId: this.event.id,
            formType: ReferForm.AccountEvents
          }
          this.fileService.getFileFkid(evtImg).subscribe({
            next: (data: any) => {
              if (data.result === 'InvalidDetailsToRetrieveTheFile') {
                return;
              }
              var files = data.result;
              if (files && files.length > 0) {
                this.fileService.getFilebyId(files[0].id).subscribe((data: any) => {
                  this.evtImageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
                })
              }
            }, error: (data: any) => {
            }
          })

          var accImg = {
            fkId: this.account.contactInfo.member.id,
            formType: ReferForm.Members
          }
          this.fileService.getFileFkid(accImg).subscribe((data: any) => {
            if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
              this.fileService.getFilebyId(data.result[0].id).subscribe((data: any) => {
                this.accountImgSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
              })
            }
          })
          this.loggedInAccount = this.tokenService.decodedToken().primarysid;
          // this.event = await this.portalEventService.getById(eventId).toPromise();
          // this.account = await this.portalAccountService.getById(this.event.accountId).toPromise();
          if (this.event.accountTypeEvent.formatData.length > 0) {
            this.event.formatData = {
              name: this.event.accountTypeEvent.identifier,
              questions: []
            }
            this.event.accountTypeEvent.formatData.forEach(field => {
              let propObj = JSON.parse(field.properties);
              let question: question = {
                id: field.id,
                data: field.fieldValue,
                value: field.fieldValue,
                default: field.fieldValue,
                advancedOptions: {
                  isRequired: propObj.IsRequired
                },
                options: field.dropdownValues.split(','),
                property: {
                  type: this.convertType(field.dataType)
                },
                title: field.name
              }
              this.event.formatData.questions.push(question);
            });
          }
          this.event.participants = this.event.participants.filter((member) => { return !member.isOwner })
        }
      })
    )
  }

  deleteEvent() {
    this.portalEventService.delete(this.event.id).subscribe({
      next: (data: any) => {
        this.errHandler.openToast({ title: 'SUCCESS', type: 'success', message: 'Event deleted.' });
        this.router.navigate(['account-view', 'account', this.id]);
      }
    })
  }
  warningModel(content: any) {
    this.modalService.open(content, {
      backdrop: "static",
      centered: true,
      keyboard: false,
    });
  }

  /**
    None,
    Number,
    Date,
    Toggle,
    Text,
    Node,
    Member,
    DropDown,
    Rating,
    TypeDropDown,
    CheckBox,
    LongText,
   */
  convertType(type: string) {
    switch (type) {
      case 'Date':
        return 'date';
      case 'Number':
        return 'number';
      case 'Text':
        return 'text';
      case 'CheckBox':
        return 'check';
      case 'Rating':
        return 'rate';
      case 'DropDown':
        return 'select';
      case 'LongText':
        return 'long-text';
    }
  }

  reConvertType(type: string) {
    switch (type) {
      case 'date':
        return 'Date';
      case 'number':
        return 'Number';
      case 'text':
        return 'Text';
      case 'check':
        return 'CheckBox';
      case 'rate':
        return 'Rating';
      case 'select':
        return 'DropDown';
      case 'long-text':
        return 'LongText';
    }
  }
}
