import { Component, Input, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';

@Component({
  selector: 'app-group-mini-data',
  templateUrl: './group-mini-data.component.html',
  styleUrls: ['./group-mini-data.component.scss']
})
export class GroupMiniDataComponent implements OnInit {

  @Input() group?: Group;

  imageSource:any='';
  constructor() { }

  ngOnInit(): void {
  }

}
