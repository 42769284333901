<div class="task-status">
    <div (click)="open(task)" [ngStyle]="{'background-color': getColorStatus()}" class="curr-status status">
        <span>
            {{status | translatePipe | titleCase}}
        </span>
    </div>

</div>

<ng-template #chooseTaskPop let-close="close">
    <div dirControl class="modal-header">
        <h5 class="modal-title">{{'Select status'|translatePipe}}</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <h3 class="d-flex justify-content-center mb-3">{{taskName}}</h3>
        <div class="status-options">
            <div *ngFor="let status of statusConfig" (click)="chooseStatus(status,close)"
                [ngStyle]="{'background-color': status.color}" class="status">
                <span>
                    {{status.title | translatePipe }}
                </span>
            </div>
        </div>
    </div>
</ng-template>