<div (click)="onAddEvent()" class="add-event">

    <div class="mx-auto my-auto">
        <a class="flow btn-icon btn-icon-only btn btn-success ms-auto me-auto mt-2 mb-2">
            <i class="fa fa-fw" aria-hidden="true" title="{{'add event'|translatePipe}}"></i>
        </a>
        <div class="d-flex justify-content-center align-items-center mb-2">
            <!-- <fa-icon [icon]="faevent" style="font-size: 20px;"></fa-icon> -->
            <p class="event-name" style="margin-bottom: 0px;">{{'add event'|translatePipe}}</p>
        </div>
    </div>
</div>