import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Event } from '../interfaces/event';
import { AppConfig } from './app.config';
import { globalComponent } from '../global-component.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private base_Url = AppConfig.settings.apiServer.baseUrl;
  private _event$ = new BehaviorSubject<Event[]>([])
  public event$ = this._event$.asObservable();
  public empEvent = {
      id: '',
      name: '',
      content: '',
      timeToSend: '',
      duration: 0,
      canPlaceImageAbove: 'below',
      eventDate: new Date().toISOString(),
      time: {
        hour: 0,
        minute: 0,
        second: 0
      },
      length: {
        unit: 'hours',
        amount: 1
      },
      members: [],
      guests:[],
      eventNode: {
        id: '',
        type: '',
        name: ''
      },
      imageUrl:''
  }
  public emptyEvent: Event = {
    info: {
      group: {
        id: '',
        type: '',
        name: ''
      },
      timestamp: Date.now(),
      title: '',
      time: {
        hour: 0,
        minute: 0,
        second: 0
      },
      description: '',
      length: {
        unit: 'hours',
        amount: 1
      },
      image: {
        location: 'above',
        url: ''
      }
    },
    invitees: {
      members: [],
      guests: []
    },
    id: ''
  }

  constructor(private http: HttpClient) { }

  
  // public addEvent(event: Event) {
  //   return this.http.post<Event>(this.BASE_URL, event).pipe(
  //     map(event => {
  //       this.loadEvents();
  //       return event
  //     })
  //   )
  // }
  public addEvent(post) {
    return this.http.post<any>(`${this.base_Url}${globalComponent.calenderevent}`, post);
  }
  
  public getEvents(){
    return this.http.get<any>(`${this.base_Url}${globalComponent.calenderevent}`);
  }

  public editEvent(event) {
    return this.http.patch<Event>(`${this.base_Url}${globalComponent.calenderevent}`, event);
  }

  public getEvent(id, tok) {
    if (tok) {
      var header = {
        headers: new HttpHeaders({'Authorization':  `Bearer ${tok}`})
      }
      return this.http.get<any>(`${this.base_Url}${globalComponent.calenderevent}?id=${id}`, header);
    } else {
      return this.http.get<any>(`${this.base_Url}${globalComponent.calenderevent}?id=${id}`)
    }
  }

  public deleteEvent(id: string) {
    return this.http.delete<Event>(`${this.base_Url}${globalComponent.calenderevent}/${id}`);
  }

  public addInvitesMemberToEvent(details) {
    return this.http.post<any>(`${this.base_Url}${globalComponent.calenderevent}${globalComponent.inviteMember}`, details);
  }

  public removeInvitesMemberToEvent(type, id) {
    return this.http.delete<Event>(`${this.base_Url}${globalComponent.calenderevent}${globalComponent.inviteMember}/${type}/${id}`);
  }

  public changeStatus(id, status, type, tok?) {
    if (tok) 
    {
      var header = {
        headers: new HttpHeaders({'Authorization':  `Bearer ${tok}`})
      }
      return this.http.patch<any>(`${this.base_Url}${globalComponent.calenderevent}${globalComponent.inviteMember}?type=${type}&id=${id}&status=${status}`, {}, header);
    } else {
      return this.http.patch<any>(`${this.base_Url}${globalComponent.calenderevent}${globalComponent.inviteMember}?type=${type}&id=${id}&status=${status}`, {});
    }
  }
}
