
<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="membersToShow" [dataSource]="notifications"
        [dataItemKeys]="['ownerName','description']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member since'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.createdOn | date: 'dd/MM/YYYY HH:mm'}}
                    </div>
                </div>
            </div>
        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="notifications.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>