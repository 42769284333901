import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss'],
})
export class MembersTableComponent implements OnInit {
  @Input() members: any[];
  @Input() groupId: string;
  @Output() mem = new EventEmitter<any>();
  @Output() toggleIsModerator = new EventEmitter<any>();
  @Output() onRemoveMemberFromGroup = new EventEmitter<string>();
  dataSource = new MatTableDataSource;
  membersToShow = [];


  displayedColumns = ['identifier', 'memberSince', 'isModerator', 'options'];
  page = 1;
  pageSize = 10;
  faCrown = faCrown;
  constructor() { }

  ngOnInit(): void {
    this.setMembersToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setMembersToShow()
  }

  getClass() {
    return `checkbox-container ${this.members.find(member => member.isCheck) ? 'checked' : ''}`
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  setMembersToShow() {
    this.mem.emit(this.members)
    const start = (this.page - 1) * this.pageSize;
    this.membersToShow = this.members?.slice(start, start + 10);
  }

  modClickEvent(mem: any, setMod: boolean) {
    this.toggleIsModerator.emit({member: mem, setMod: setMod});
  }

}
