<div class="main-card card mb-3 portal-menegmant-event-view">
    <div class="card-body">
        <div (click)="(stopProp($event))" class="menu-container">
            <button (click)="isMenuOpen=!isMenuOpen" class="btn menu-open-btn">
                <i class="fa fa-fw"></i>
            </button>
            <div (clickOutside)="isMenuOpen=false" [@slide-vertical]="isMenuOpen?'out':'in'" [class.close]="!isMenuOpen"
                class="menu">
                <a [routerLink]="'/portal-event-view/event/'+event.id">
                    <i class="fa fa-fw"></i>
                    <span>Edit</span>

                </a>
                <button (click)="deleteEvent(event.id)">
                    <i class="fa fa-fw"></i>
                    <span>Delete</span>
                </button>
            </div>
        </div>
        <div class="flex">
            <div class="mini-view-container">
                <app-portal-event-mini-view [isInManage]="true" [event]="event" [id]="id"></app-portal-event-mini-view>
            </div>
            <div class="details-container flex">
                <div class="table-key-value-container">
                    <div class="table-keys">
                        <div *ngIf="event.type" class="icon-text-container key-container">
                            <i class="pe-7s-file"></i>
                            <p>Type</p>
                        </div>
                        <div *ngIf="event.eventDate" class="icon-text-container key-container">
                            <i class="pe-7s-file"></i>
                            <p>Date</p>
                        </div>
                        <div *ngIf="event.eventLocation" class="icon-text-container key-container">
                            <i class="pe-7s-map-marker"></i>
                            <p>Location</p>
                        </div>
                        <div *ngIf="event.participants.length" class="icon-text-container key-container">
                            <i class="pe-7s-users"></i>
                            <p>Event participants</p>
                        </div>
                        <div *ngIf="event.isVisivaleToAccount" class="icon-text-container key-container">
                            <i class="pe-7s-look"></i>
                            <p>Visible only managers</p>
                        </div>
                    </div>
                    <div class="table-values">
                        <div *ngIf="event.type" class="value-container">
                            <p>{{event.type | titlecase}}</p>
                        </div>
                        <div *ngIf="event.eventDate" class="value-container">
                            <p>{{event.eventDate}}</p>
                        </div>
                        <div *ngIf="event.eventLocation" class="value-container">
                            <p>{{event.eventLocation}}</p>
                        </div>
                        <div *ngIf="event.participants.length" class="value-container">
                            <p>{{eventParticipantsString}}</p>
                        </div>
                        <div *ngIf="event.isVisivaleToAccount" class="value-container">
                            <p>{{event.isVisivaleToAccount? 'True':'False'}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="event.eventDescription" class="description">
                    <div class="table-key-value-container">
                        <div class="table-keys">

                            <div class="icon-text-container key-container">
                                <i class="pe-7s-note"></i>
                                <p>Description</p>
                            </div>
                        </div>
                        <div class="table-values">

                            <div class="value-container">
                                <p>{{event.eventDescription}}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>