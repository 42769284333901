import { Component, Input, OnInit } from '@angular/core';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';

@Component({
  selector: 'app-mini-menu',
  templateUrl: './mini-menu.component.html',
  styleUrls: ['./mini-menu.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class MiniMenuComponent implements OnInit {

  isMenuOpen = false;
  @Input() buttons: any[];




  constructor() { }

  ngOnInit(): void {
  }
  stopProp(ev) {
    ev.stopPropagation();
  }
}
