<div dirControl class="main-card card  total-sets-card">
    <div class="card-body">

        <h3 class="card-title text-center">{{'User Control Panel'|translatePipe}}</h3>


        <ng-container *ngIf="isVerifyCodeOpen; else elseTemplate">
            <p>{{'what a code did you receive'|translatePipe}}?</p>
            <app-verify-code [(value)]="verifyCode"></app-verify-code>
            <ul>
                <li class="fw-bold">{{'OTP Mail has been sent to registed Email Id please check'|translatePipe}}</li>
            </ul>
            <!-- <ul class="verify-btns">
                <li>{{'code has been send to'|translatePipe}} {{newEmail}}. <span (click)="isVerifyCodeOpen=false">{{'change it'|translatePipe}}</span> </li>
                <li>{{'what code'|translatePipe}}? <span (click)="sendCode()">{{'i did not received anything'|translatePipe}}...</span> </li>
            </ul> -->
            <div class="btns-container">
               <button (click)="onSave()" class="btn btn-success">{{'save'|translatePipe}}</button>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="row">
                <div  class="col-sm-8 col-md-9 col-xl-10">
                    <app-input-label [syncValidators]="['password']" [(isValid)]="isCurrPasswordValid" [required]="true"
                    [label]="'Current password'|translatePipe" [(value)]="currPassword" type="password"> </app-input-label>
                    <app-input-label #userNameInput [asyncValidators]="['usernameTaken']" name="newUserName" [required]="true"
                    [label]="'Username'|translatePipe" [(value)]="data.name" type="text"> </app-input-label>
                    <app-input-label  [email]="true" name="newEmail" [required]="true"
                    [label]="'email'|translatePipe" [(value)]="data.emailId" type="email"></app-input-label>
                   
                    <app-register-password labelPassword="new password" labelRepeatPassword="repeat new password"
                    [(repeatPassword?)]="newPassword" [(isPasswordValid)]="isNewPasswordValid"   (repeatPasswordChange)="newPass($event)">
                    </app-register-password>
                
                
                </div>
                <div class="col-sm-4 col-md-3 col-xl-2"  *ngIf="imgUploadDetails!=undefined">
                    <app-image-picker   [uplDetails]="imgUploadDetails"   (ImgContent)="imgContent($event)"></app-image-picker>
                </div>
            </div>
            <!-- [disabled]="!isCurrPasswordValid"  -->
            <button (click)="onSave()" class="btn btn-success">{{'next'|translatePipe}}</button>
        </ng-template>
    </div>
</div>