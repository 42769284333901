import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { globalComponent } from '../global-component.service';
import { AppConfig } from './app.config';
@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private BASE_URL = AppConfig.settings.apiServer.baseUrl + 'Task';
  private _tasks$ = new BehaviorSubject<any[]>([])
  public tasks$ = this._tasks$.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  getEmpty() {
    return {
      title: '',
      group: {
        aId: 0,
        name: '',
        type: ''
      },
      members: [],
      files: [],
      content: '',
      startDate: new Date().toISOString(),
      deadline: new Date().toISOString(),
      priority: 1,
      id: "",
      status:'pending'
    }
  }

  public loadTasks() {
    this.http.get<any[]>(this.BASE_URL)
      .subscribe((tasks:any) => {
        this._tasks$.next(tasks.result); 
      })
  }

  public getTask(id:any) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.getTask}id=${id}`);
  }

  public addTask(task: any) {
    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.task}`, task)
  }

  public editTaskStatus(id:any, status:any) {
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.updateTask}`+new URLSearchParams({taskId: id, status: status}), {}).pipe(
      tap(_ => {
        this.loadTasks(); 
      })
    )
  }

  public editTask(task) {
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.task}`, task).pipe(
      tap(_ => {
        this.loadTasks(); 
      })
    )
  }

  public removeTask(taskId: any) {
    return this.http.delete<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.deleteTask}${taskId}`).pipe(
      tap(_ => {
        this.loadTasks();
      })
    )
  }
}
