<div [class.editable]="isEditable" class="main-card card mb-2 post post-view">
    <div *ngIf="isEditable" class="control-btns ">
        <button class="edit" (click)="toggleIsEdit(imgDetails?.id)">
            <ng-container *ngIf="isEdit; else closeEdit">
                <i class="pe-7s-close"></i>
            </ng-container>
            <ng-template #closeEdit>
                <i class="fa fa-fw" aria-hidden="true" title="{{'edit'|translatePipe}}"></i>

            </ng-template>

        </button>
        <button class="delete" *ngIf="!isEdit" (click)="onDelete()">
            <i class="fa fa-fw" aria-hidden="true" title="{{'delete'|translatePipe}}"></i>
        </button>
    </div>
    <small class="date opacity-6">
        <div *ngIf="DateString!=''">
            <p>
                <fa-icon [icon]="faClock" class=""></fa-icon>

                {{

                DateString
                }} {{post.postedOn | date: 'dd/MM/YYYY HH:mm'}}
            </p>
            <!-- <p  *ngIf="modified">
                <fa-icon [icon]="faClock" class=""></fa-icon>
                
                {{
                'Edited at '+ (DateString) 
                }} {{post.modifiedOn.slice(-6)}}
               </p> -->

        </div>
        <div *ngIf="DateString==''">
            <p>
                <fa-icon [icon]="faClock" class="me-1"></fa-icon>

                {{

                DateString
                }} {{post.postedOn | date: 'dd/MM/YYYY HH:mm'}}
            </p>
            <!-- <p  *ngIf="modified">
                <fa-icon [icon]="faClock" class="me-1"></fa-icon>
                
                {{
                'Edited at '+ (DateString) 
                }} {{post.modifiedOn}}
               </p> -->

        </div>


    </small>
    <div class="card-body">
        <ng-container *ngIf="isEdit; else elseTemplate">
            <div class="row mt-4">
                <div class="col-sm-8 col-md-9 col-xl-10">
                    <app-input-label [(value)]="post.name" label="title" name="new-post-title"></app-input-label>
                    <app-textarea-label [(value)]="post.content" label="content" name="new-post-content">
                    </app-textarea-label>
                </div>
                <div class="col-sm-4 col-md-3 col-xl-2">
                    <app-image-picker [(value)]="imageSource" label="image" [delIdfrmParentComp]="imgId"
                        (ImgDataOtp)="fileFrmChild($event)"></app-image-picker>
                </div>
            </div>
            <button (click)="onUpdate()" class="btn btn-success">Save
                <span class="spinner-border" *ngIf="loading"
                    style="width:20px;height:20px;margin-left: 10px;margin-bottom: -5px;"></span>
            </button>
        </ng-container>


        <ng-template #elseTemplate>
            <div *ngIf="post!=undefined">
                <h3 *ngIf="post.name" class="card-title">{{post.name}}</h3>
                <div class="row">
                    <div class="col-8 col-md-10">
                        <p *ngIf="post.name">{{post.content}}</p>
                    </div>
                    <div class="col-4 col-md-2 mt-3">
                        <img *ngIf="imageSource" fullScreenImg [src]="imageSource" alt="post-image">
                    </div>
                </div>
            </div>
            <div *ngIf="post==undefined">
                <p>{{'There is no post to show.'|translatePipe}}</p>
            </div>
        </ng-template>
    </div>
</div>