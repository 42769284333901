import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-log-resaults-table',
  templateUrl: './log-resaults-table.component.html',
  styleUrls: ['./log-resaults-table.component.scss']
})
export class LogResaultsTableComponent implements OnInit, OnChanges {

  @Input() log: any[] = []
  dataSource = new MatTableDataSource
  displayedColumns = ['appliedBy', 'appliedOn', 'logType', 'description'];
  logToShow=[]
  pageSize = 10;
  private _page = 1;

  get page() {
    return this._page
  }

  set page(value) {
    this._page = value;
    this.setLogToShow()
  }

  constructor() { }

  ngOnInit(): void {
    this.setLogToShow()
  }

  ngOnChanges(changes): void {
    this.setLogToShow()
  }

  setLogToShow() {
    //console.log(this.log);
    
    const start = (this.page - 1) * this.pageSize;
    this.logToShow = this.log?.slice(start, start + this.pageSize);
  }

}
