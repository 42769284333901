import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Format, question } from 'src/app/interfaces/event';
import { ReferForm } from 'src/app/models/eums';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-portal-event-view',
  templateUrl: './portal-event-view.component.html',
  styleUrls: ['./portal-event-view.component.scss']
})
export class PortalEventViewComponent implements OnInit {

  @ViewChild('content') content!: ElementRef;

  event = this.portalEventService.getEmpty();
  // proccessOptions = ['', 'onboarding', 'development', 'termination'];
  // formatOptions = this.portalEventService.getFormatList();
  // typeOptions = ['salary raise']
  subscription: Subscription[] = [];
  account = null;
  id: any;
  typeMap = {}
  typeMapKeys = [];
  currCategory = ''
  //
  Imgfiles: any;
  imgEdit: any;
  files: any;
  constructor(
    private portalEventService: PortalEventService,
    private route: ActivatedRoute,
    private portalAccountService: PortalAccountsService,
    private errhandle: ErrorHandlerService,
    private modalService: NgbModal,
    private fileService: FilesService
  ) { }

  ngOnInit(): void {

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const accountId = params.get('accountId');
        const eventId = params.get('eventId');
        const id = params.get('id');
        if (accountId) {
          let accRes = await this.portalAccountService.getById(accountId).toPromise();
          this.account = accRes.result;
          this.setFormats();
        }
        else if (eventId) {
          this.id = id;
          var data: any = {
            fkid: eventId,
            formtype: ReferForm.AccountEvents,
            isAttachment: false
          }
          this.imgEdit = data;

          // var Fdata:any={
          //   fkId:eventId,
          //   formType:9
          // }
          // //console.log("cccc")
          // this.fileService.getFileFkid(Fdata).subscribe((data:any)=>{
          //   //console.log(data)
          //  this.event.attachFiles=data.result;

          // })
          let evRes = await this.portalAccountService.getEventById(eventId).toPromise();
          this.setEventDetails(evRes); 
          let accRes = await this.portalAccountService.getById(this.id).toPromise();
          this.account = accRes.result;
          this.setFormats();
          this.getFile();
          // let accRes = await this.portalAccountService.getById(this.event.accountType.id).toPromise();
          // this.account = accRes.result;
          // //console.log(this.account);
          // this.portalEventService.getEventTypes(this.account.typeId).subscribe({
          //   next: (data: any) => {
          //     //console.log(data);
          //   }
          // })

          // this.portalEventService.typeEvent(this.account.typeId).subscribe({
          //   next: (data: any) => {
          //     data.result.forEach((typ: any) => {
          //       this.typeMapKeys.push(typ.identifier)
          //     });
          //   },
          //   error: (err: any) => {
          //   }
          // })
          // this.event = await this.portalEventService.getById(eventId).toPromise();
          // this.account = await this.portalAccountService.getById(this.event.accountId).toPromise();
          //this.setFormats();
        }
      })
    )
  }

  async saveEvent() {
    let dataToSend = {
      acctypeEventId: 0,
      owner: this.account.contactInfo.member.id,
      eventId: 0,
      name: this.event.name,
      content: this.event.content,
      image_url: this.event.imageURL,
      eventDate: this.event.eventDate,
      location: this.event.eventLocation,
      isVisible: this.event.isVisible,
      members: this.event.participants ? this.event.participants.map((member) => member.id) : [],
      fieldData: this.event.formatData && this.event.formatData.questions ? this.event.formatData.questions.map((ques: any) => ({
        fieldType: this.reConvertType(ques.property.type),
        fieldId: ques.id,
        value: ques.data
      })) : []
    }
    if (this.event.id) {
      dataToSend.eventId = this.event.id;
      ////console.log(this.event.participants);
      ////console.log(dataToSend);
      this.portalEventService.update(dataToSend).subscribe({
        next: (data: any) => {
          this.errhandle.openToast({ title: 'SUCCESS', type: 'success', message: 'Event updated.' });
        },
        error: (err: any) => {
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: 'Failed to update. Please try again later.' });
        }
      });
    }
    else {
      dataToSend.acctypeEventId = this.event.format.id;
      ////console.log(dataToSend);
      this.portalEventService.add(dataToSend).subscribe({
        next: (data: any) => {
          this.errhandle.openToast({ title: 'SUCCESS', type: 'success', message: 'Event created.' });
          if (this.Imgfiles) {
            let imgData = {
              files: this.Imgfiles,
              fkid: data.result.id,
              formType: ReferForm.AccountEvents,
              isAttachment: false
            }
            this.fileService.uploadFile(imgData).subscribe({
              next: (data: any) => {
              }
              , error: (data: any) => {
              }
            })
          }
        },
        error: (err: any) => {
          ////console.log(err);
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: 'Failed to create. Please try again later.' });
        }
      })
    }
  }

  setEventDetails(apiResp: any) {
    this.event = apiResp.result;
    if (this.event.accountTypeEvent) {
      this.event.format = {
        identifier: this.event.accountTypeEvent.identifier,
        icon: this.event.accountTypeEvent.imageURL,
        id: this.event.accountTypeEvent.id
      }
      if (this.event.accountTypeEvent.formatData.length > 0) {
        this.event.formatData = {
          name: this.event.accountTypeEvent.identifier,
          questions: []
        }
        this.event.accountTypeEvent.formatData.forEach(field => {
          let propObj = JSON.parse(field.properties);
          let question: question = {
            id: field.id,
            data: field.fieldValue,
            value: field.fieldValue,
            default: field.fieldValue,
            advancedOptions: {
              isRequired: propObj.IsRequired
            },
            options: field.dropdownValues.split(','),
            property: {
              type: this.convertType(field.dataType)
            },
            title: field.name
          }
          this.event.formatData.questions.push(question);
        });
      }
    }
    this.event.participants = this.event.participants.filter((member) => {return !member.isOwner})
    ////console.log(this.event);
  }

  saveAndContinue() {
    if (this.event.formatData) return;
    this.saveEvent();
  }

  changeFormat(format) {
    if (format) {
      this.event.format = format;
      this.portalEventService.getEventFields(format.id).subscribe({
        next: (data: any) => {
          if (!data.success) {
            this.event.formatData = null;
          } else {
            let formatData: Format = {
              name: format.identifier,
              questions: []
            }
            data.result.forEach(field => {
              let propObj = JSON.parse(field.properties);
              let question: question = {
                id: field.id,
                data: field.fieldValue,
                value: field.fieldValue,
                advancedOptions: {
                  isRequired: propObj.IsRequired
                },
                default: '',
                options: field.dropdownValues.split(','),
                property: {
                  type: this.convertType(field.dataType)
                },
                title: field.name
              }
              formatData.questions.push(question);
            })
            this.event.formatData = formatData;
          }
        }
      });
    } else {
      this.event.format = null
      this.event.formatData = null
    }
  }

  setFormats() {
    this.portalEventService.getEventTypes(this.account.typeId).subscribe({
      next: (data: any) => {
        data.result.forEach((cat: any) => {
          this.typeMap[cat.identifier] = cat.categoryEvents.map((evt: any) => ({
            identifier: evt.identifier,
            icon: evt.imageURL,
            id: evt.id
          }));
          this.typeMapKeys.push(cat.identifier);
        });
        ////console.log(this.typeMap);
      }
    });
  }

  open() {
    this.modalService.open(this.content, {
      size: 'lg'
    })
  }

  chooseType(type, cb?) {
    if (cb) cb();
    if (type) this.event.name = type.identifier;
    this.event.type = type.identifier;
    this.changeFormat(type);
    //None of the images in cloudinary are available anymore. So we need AMIT to provide images for all events.
    //this.setImage(type)
  }

  imgDet(det: any) {
    if (det != undefined) {
      this.Imgfiles = det[0];
    }
    if (this.imgEdit != undefined) {
      return this.imgEdit;
    }
  }

  //for upload file's
  fileAttach() {
    for (let l of this.event.attachFiles) {
      var Fdata: any = {
        files: l,
        fkid: this.event.id,
        formType: ReferForm.AccountEvents,
        isAttachment: "true",
      }
      this.fileService.uploadFile(Fdata).subscribe({
        next: (data: any) => {

        },
        error: (err: any) => {

        }
      });
    }
  }


  getFile() {

    var data = {
      fkId: this.event.id,
      formType: ReferForm.AccountEvents
    }
    this.fileService.getFileFkid(data).subscribe((data: any) => {

      if (data.result === 'InvalidDetailsToRetrieveTheFile') {
        return;
      }

      ////console.log(data);
      // this.event.attachFiles=data.result; 
      //This condition for oly get files not image.
      if (data.result && data.result.length > 0) {
        // data.result.forEach(e => {
        //   if (e.isAttachment != false) {
        //     this.event.attachFiles.push(e);
        //   }else {
        //     //assume image
        //     this.event.imageURL = e
        //   }
        // });

        for(let l of data.result){
         if(l.isAttachment!=false){
            this.event.attachFiles.push(l);
          }
        }
      } 
    })
  }

  /*setImage(type) {
    switch (type) {
      case 'אישורים':
      case 'אישורי העסקה':
      case 'תנאי סף לבדיקה':
      case 'ביטול הרשאות למערכות החברה':
      case 'הרשאות למערכות החברה':
      case 'הגדרת יעדים':
      case 'עמידה ביעדים':
      case 'אבחון אמינות':
        this.event.imageURL = ''
        break
      case 'ידע מקדים':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/gyofzzdrwjl4pz9cwary.jpg'
        break
      case 'ביטול הרשאות למערכות החברה קליטה מנהל ישיר':
      case 'שיחת סיום':
      case 'החלפת תפקיד שיחת קליטה':
      case 'שיחת סיכום תפקיד':
      case 'שיחת קליטה מנהל ישיר':
      case 'שיחת נזיפה':
      case 'שיחה אישית':
      case 'חוות דעת ממליצים':
      case 'ראיון מנהל ישיר':
      case 'ראיון מנהל מחלקה':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/svvuehrrwm1cgq1cjiyr.jpg'
        break
      case 'יום ראשון בעבודה':
      case 'יום אחרון בעבודה':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/lkpz2dccu7rcothglyyl.jpg'
        break
      case 'החזרת ציוד':
      case 'קבלה וחתימה על ציוד':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/dysevhjlztuxdkwmwlce.jpg'
        break
      case 'הדרכה על מערכות החברה':
      case 'מעבר על תקנון למניעת הטרדות מיניות':
      case 'הערכת ביצועים':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/dysevhjlztuxdkwmwlce.jpg'
        break
      case 'הצמדת חונך / מנטור':
      case 'לקוח חדש':
      case 'ראיון משאבי אנוש':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/zakhqjije7hvqwx9pzou.jpg'
        break
      case 'סיבת העזבה':
      case 'סיבת פיטורים':
      case 'סיום שירות':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/ha2cygnbmzcwb35fhhyf.jpg'
        break
      case 'מכתב התפטרות':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/n8nwyax9la62wu2srl1g.jpg'
        break
      case 'שימוע':
      case 'סיכום שימוע':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/jvwzni8cq18fgjvigzko.jpg'
        break
      case 'דיון פנים אירגוני':
      case 'כנס':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/fas3ss4iomtnz8gwe2s6.jpg'
        break
      case 'קידום':
      case 'העלאת משכורת':
      case 'פתיחת מסגרת אשראי':
      case 'עדכון מחירונים':
      case 'עדכון מבצעים':
      case 'פתיחת מסגרת אשראי':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/q3ogqe4urz45d5wy8gkg.jpg'
        break
      case 'מעבר מחלקה':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/usaf8nvewnqcjgk3yqpi.jpg'
        break
      case 'רילוקיישן':
      case 'שינוי מקום':
      case 'שינוי כתובת לקוח':
      case 'שינוי כתובת אספקה':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/qbpfoqvxwhehbwkfpgrz.jpg'
        break
      case 'לומדה':
      case 'השתלמות':
      case 'הכשרה':
      case 'לימודים (שאינם במסגרת עבודה)':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/sklbsxlnr4rpr3xzkf7u.jpg'
        break
      case 'ציון לשבח':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/dm639rv8vizhbr5cp1wh.jpg'
        break
      case 'לידה':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/sbxg3rxdijwh602jewb5.jpg'
        break
      case 'חופשת לידה':
      case 'מחלה':
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/s4ewsoyf8q2qnmhnxcla.jpg'
        break

      default:
        this.event.imageURL = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/hpk415zehgopqjbm8tc8.jpg'

    }
  }*/

  /**
    None,
    Number,
    Date,
    Toggle,
    Text,
    Node,
    Member,
    DropDown,
    Rating,
    TypeDropDown,
    CheckBox,
    LongText,
   */
  convertType(type: string) {
    switch (type) {
      case 'Date':
        return 'date';
      case 'Number':
        return 'number';
      case 'Text':
        return 'text';
      case 'CheckBox':
        return 'check';
      case 'Rating':
        return 'rate';
      case 'DropDown':
        return 'select';
      case 'LongText':
        return 'long-text';
    }
  }

  reConvertType(type: string) {
    switch (type) {
      case 'date':
        return 'Date';
      case 'number':
        return 'Number';
      case 'text':
        return 'Text';
      case 'check':
        return 'CheckBox';
      case 'rate':
        return 'Rating';
      case 'select':
        return 'DropDown';
      case 'long-text':
        return 'LongText';
    }
  }
}
