import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Group } from 'src/app/interfaces/group';
import { Role } from 'src/app/models/eums';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { TokenService } from 'src/app/token/token.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;


  submitted: boolean = false;
  public error: { code: number, message: string } | null = null;
  subscription: Subscription[] = [];
  organization: Group
  email = '';
  isEmailValid = false;
  password = '';
  isPasswordValid = false;
  isHaveAuth = true;
  isShowAuth = false;
  verifyCode = ''
  // isKeepLogin = false
  //
  loading: boolean = false;
  currentUserValue = '';

  currDate = new Date();
  returnTo = '';

  constructor(
    public organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService, private tokenService: TokenService, 
    private formBuilder: FormBuilder, private errhandle: ErrorHandlerService,
    private loaderService: NgxUiLoaderService) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if(param.returnUrl) {
        this.returnTo = param.returnUrl;
      }
      let decodedToken = this.tokenService.decodedToken();
      if (decodedToken) {
        if (decodedToken.role === Role.Administrator) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate([`/dashboard/${decodedToken.primarysid}`]);
        }
      }

      this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
      });
      
      this.loaderService.startLoader("loader-01");
      setTimeout(() => {
        this.loaderService.stopLoader("loader-01"); 
      }, 500);
    });
  }

  get f() { return this.loginForm.controls; }
  //

  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    else {
      this.authService.login(this.f['email'].value, this.f['password'].value).subscribe({
        next: (data: any) => {
          this.loading = false;
          const storeToken = data.result.token;
          let userData = data.result;
          userData.color = this.getRandomColor();
          this.tokenService.setToken(storeToken);
          this.tokenService.setUser(JSON.stringify(userData));
          this.currentUserValue = data.result;
          var decodedToken = this.tokenService.decodedToken();
          if (decodedToken.role === Role.Administrator) {
            if (this.returnTo) 
              this.router.navigate([`/${this.returnTo}`]);
            else
              this.router.navigate(['/dashboard']);
          }
          else {
            if (this.returnTo) 
              this.router.navigate([`/${this.returnTo}`]);
            else
              this.router.navigate([`/dashboard/${decodedToken.primarysid}`])
          }
        },
        error: (err: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result })
        }
      })
    }
  }



  getRandomColor(): string {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  //  login()
  // {
  //   this.authService.login(this.email,this.password).subscribe({
  //     next:(data:any)=>{
  //       //console.log(data)
  //       this.router.navigate(['/dashboard']);
  //     },
  //     error:(err:any)=>{
  //       //console.log(err)
  //     }
  //   })

  // localStorage.setItem('result',JSON.stringify({token:token}));

  // this.navigateToDash();
  // }
  // navigateToDash()
  // {
  //   this.router.navigate(['/dashboard']);
  // }
  sendCode() {
  }
  submitVerifyCode() {
  }

}







