import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { OtechI18nModule } from 'projects/otech-i18n/src/lib/otech-i18n.module';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { TokenService } from 'src/app/token/token.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  user: any;

  accountTypes: any;
  currentMem: any;
  loggedInAccount: any;
  loggedUser: any = '';
  fontSize: any = '15px';
  //
  imageSource: any = '';
  loggedInUser: any;
  color: any;

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private i18nService: OtechI18nService,
    public translateService: OtechI18nService,
    private tokenService: TokenService,
    private accountService: PortalAccountsService,
    private fileService: FilesService
  ) { }

  setLanguage() {
    if (this.i18nService.locale == 'en') { this.i18nService.locale = 'he' }
    else { this.i18nService.locale = 'en' }
  }

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    localStorage.setItem('sb', this.globals.toggleSidebar ? 'close' : 'open');
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {

    setTimeout(() => {
      let sbState = localStorage.getItem('sb');
      this.globals.toggleSidebar = sbState === 'close';
      this.globals.sidebarHover = !this.globals.toggleSidebar;
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.loggedInUser = this.tokenService.getUser();
    this.currentMem = this.loggedInUser.name.split('')[0];
    this.color = this.loggedInUser.backColor;
    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;
    this.user = this.tokenService.getUser();

    this.accountService.getAccountTypes().subscribe({
      next: data => {
        this.accountTypes = data.result;
        this.accountTypes.forEach(acc => {
          acc.imageURL = JSON.parse(acc.imageURL);
          acc.imageURL.D = String.fromCharCode(parseInt(acc.imageURL.D, 16))
        });
      }
    })

    this.fileService.imageContent_$.subscribe((data: any) => {
      this.imageSource = data
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }


}
