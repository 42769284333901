<h3 class="card-title text-center">{{'modules'|translatePipe}}</h3>
<div dirControl class="row">
    <div *ngFor="let module of moduleKeys" class="col-sm-6 col-md-4 col-lg-3">

        <div [class.unavilable]="!modulesMap[module].isInPlan" class="main-card card mb-4">
            <div class="card-body text-center">
                <div class="icon-container">
                    <i [ngClass]="modulesMap[module].iconClass"></i>
                </div>
                <p class="module-title mt-4">
                    {{module|translatePipe}}
                </p>
                <bSwitch [switch-disabled]="!modulesMap[module].isInPlan" [switch-animate]="false" switch-size="mini" name="side-select"
                    [(ngModel)]="modulesMap[module].isActive">
                </bSwitch>
            </div>
        </div>
    </div>
</div>