import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Member } from 'src/app/interfaces/member';
import { ReferForm } from 'src/app/models/eums';
import { FilesService } from 'src/app/services/files.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-toggle-member',
  templateUrl: './toggle-member.component.html',
  styleUrls: ['./toggle-member.component.scss']
})
export class ToggleMemberComponent implements OnInit, OnChanges {

  constructor(private fileServices: FilesService,
    private domSanitizer: DomSanitizer,
    private tokenService: TokenService) { }

  @Input() member: Member
  @Output() remove = new EventEmitter<Member>();

  imageSource: any = '';
  currentMem: any;
  data: any;
  user: any;
  loggedInUser: any
  color: any;
  ngOnInit(): void {
    this.loggedInUser = this.tokenService.getUser();
    this.color = this.loggedInUser.backColor;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value: any = changes['member'];
    this.currentMem = value.currentValue.identifier.split('')[0];
    var data: any = {
      fkId: value.currentValue.id,
      formType: ReferForm.Members
    }

    if (data.fkId != '') {

      this.fileServices.getFileFkid(data).subscribe((data: any) => {
        if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
          this.fileServices.getFilebyId(data.result[0].id).subscribe((data: any) => {
            this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
          })
        }
        else {
          this.imageSource = '';
        }
      })
    }
  }

}
