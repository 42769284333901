import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-manage-candidates',
  templateUrl: './manage-candidates.component.html',
  styleUrls: ['./manage-candidates.component.scss']
})
export class ManageCandidatesComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  candidates = []
  search = ''

  constructor(
    private accountService: PortalAccountsService,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.portalAccounts$.subscribe(accounts => {
        const candidates = accounts.filter(account => account.type === 'candidate')
        this.updateCandidates(candidates);
      })
    )
    this.accountService.loadPortalAccounts(3);

  }

  updateCandidates(candidates) {
    this.candidates = candidates.map(candidates => ({ ...candidates, isCheck: false }));
  }

  filterCandidates() {
    return this.candidates.filter(candidate => (
      candidate.data.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      candidate.contactInfo.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  deleteCandidates(candidate) {
    this.accountService.delete(candidate.id, 3).toPromise();
  }

  getNumOfSelected() {
    return this.candidates.reduce((acc, candidate) => {
      if (candidate.isCheck) acc++
      return acc
    }, 0)
  }

  unselect() {
    this.updateCandidates(this.candidates)
  }

  deleteSelected() {
    const selected = this.candidates.filter(candidate => candidate.isCheck)
    selected.forEach(candidate => {
      this.deleteCandidates(candidate)
    })
  }
}
