import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Group } from 'src/app/interfaces/group';
import { ReferForm } from 'src/app/models/eums';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {

  @ViewChild('nameInput') nameInput!: any;

  @Input() group!: Group;
  @Input() parent!: Group;
  @Output() onUpdateGroup = new EventEmitter<null>();
  name = '';
  description = '';
  logoUrl = '';
  isLoader = false;
  loading = false;
  save: boolean = true;
  constructor(
    public domSanitizer: DomSanitizer,
    private cludinaryService: CloudinaryService,
    private organizationalStructureService: OrganizationalStructureService,
    private location: Location,
    private errhandle: ErrorHandlerService,
    private router: Router,
  ) { }

  get isSubmitDisable() {
    return !this.nameInput?.formControler?.valid;
  }

  ngOnInit(): void {
    this.updateComponentData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateComponentData();
  }


  updateComponentData() {
    this.name = this.group.name;
    this.description = this.group.description;
    this.logoUrl = this.group.logoUrl;
  }

  onRemoveLogo(): void {
    this.logoUrl = '';
  }

  async uploadImg(ev: any) {
    this.isLoader = true
    try {
      const newImg = await this.cludinaryService.uploadImg(ev);
      this.logoUrl = newImg
    } catch (err) {
    }
  }

  imgDet() {
    if (this.group.aId > 0) {

      var uploadData = {
        fkid: this.group.aId,
        formtype: ReferForm.Nodes,
        isAttachment: false
      }

      return uploadData;
    }

  }

  async updateOrganizationalStructureData() {
    this.loading = true;
    const data = {
      name: this.name,
      description: this.description,
      logoUrl: this.logoUrl
    }
    var message: any = '';


    if (this.group.id) {
      await (await this.organizationalStructureService.updateGroupById(this.group.aId.toString(), data)).toPromise().then(data => {

        this.loading = false;
        this.errhandle.openToast({ title: 'Success', type: 'success', message: this.group.type + " successfully saved." })
        setTimeout(() => {
          this.router.navigate(['/editor']);
        }, 1500);
      }).catch(err => {
        this.loading = false;
        this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result })
      });

    } else {
      const group = await (await this.organizationalStructureService.addGroup(this.parent.id, this.name, this.description)).toPromise().then(data => {
        this.loading = false;
        this.errhandle.openToast({ title: 'Success', type: 'success', message: "" })
        this.organizationalStructureService.loadOrganizationalStructure();

        this.location.go('editor/' + data.id)
      }).catch(err => {
        this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result })
      });
    }
    this.onUpdateGroup.emit();
  }

  getImg() {
    return this.logoUrl || 'assets/images/upload-img.png'
  }
  async ngOnDestroy() {
    if (this.group.name == 'New Group') {
      if (this.save == true) {
        await (await this.organizationalStructureService.removeGroup(this.group.aId.toString())).toPromise();
      }
    }
  }
}

