import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FilesService } from 'src/app/services/files.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  account: any = this.portalAccountService.getEmptyWithOutType();
  memberIdentidier = '';
  loggedInAccount = 0;
  accountEvents: any = []
  subscription: Subscription[] = [];
  //
  imageSource: any = '';
  accTypeId: any;

  @ViewChild('description') description;
  // @Input() account;
  // @Input() accountEvents;

  constructor(
    private modalService: NgbModal,
    private portalAccountService: PortalAccountsService,
    private portalEventService: PortalEventService,
    private tokenService: TokenService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {

    this.route.paramMap.subscribe(async (params) => {
      this.loggedInAccount = Number.parseInt(params.get('accId'));
      let acc = await this.portalAccountService.getById(this.loggedInAccount).toPromise();
      if (acc.result.typeData && acc.result.typeData.length > 0) {
        acc.result.typeData[2].fieldValue = JSON.parse(acc.result.typeData[2].fieldValue)
        acc.result.typeData[3].fieldValue = JSON.parse(acc.result.typeData[3].fieldValue)
      }
      this.account = acc.result;

      this.memberIdentidier = this.account.contactInfo.member.identifier;
      this.accountEvents = this.account.events;
    })


    this.fileService.imageContent_$.subscribe((data: any) => {
      this.imageSource = data;
    })

  }

  openDescription() {
    this.modalService.open(this.description).result.then((result) => {
    }, (reason) => {
    });
  }

  get directManagerShowData() {
    return this.account.typeData[3].fieldValue.map(directManager => directManager.identifier).join(', ')
  }

}
