import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-attach-files',
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.scss']
})
export class AttachFilesComponent implements OnInit {

  private _files = []
  @Output() filesChange = new EventEmitter<any[]>();
  @Output() ChangeFile = new EventEmitter<any>();
  @Input() label
  @Output() fileClicked = new EventEmitter<any[]>();
  @Output() fileSave = new EventEmitter<any[]>();
  @Input() set files(files: any[]) {
    this._files = files;
    this.filesChange.emit(files);
  }

  get files() {
    return this._files;
  }

  constructor(
    public domSanitizer: DomSanitizer,
    private fileService: FilesService
  ) { }

  ngOnInit(): void {
  }

  onUploadFiles(event) {
    //console.log(event)
    this.ChangeFile.emit(event.target.files)
    const newFiles = Object.keys(event.target.files).map(key => event.target.files[key])
    this.files = [...this.files, ...newFiles]
  }

  removeFile(fileIdx, file) {
    this.fileClicked.emit(file);
    this.files = this.files.filter((file, idx) => idx !== fileIdx);
  }

  saveFile(file) {
    console.log(file)
    this.fileService.getFilebyId(file).subscribe((data: any) => {
      
      console.log(data)
    })
  }
}
