<div class="member">
    <!-- <img [src]="member.profileImg" alt="">
    <p>{{member.identifier}}</p> -->
    <div class="member-view">
        <p *ngIf="imageSource==''" class="col-12  circle my-auto" style="margin-bottom: 0rem;position:relative"
            [ngStyle]="{'backgroundColor':color}">
            <span
                style="font-size: 5vh;display:flex;color: white; position: absolute;top:50%;left: 50%;transform: translate(-50%, -59%);">{{currentMem}}</span>
        </p>
        <img *ngIf="imageSource!=''" class="col-12" [src]="imageSource" alt="">
        <p class="col-10">{{member.identifier}}</p>
    </div>
    <button class="button" (click)="remove.emit(member)">
        <i class="fa fa-fw" aria-hidden="true" title="{{'remove'|translatePipe}}"></i>
    </button>
</div>