<!-- <div class="control-btns">
    <button (click)="onChooseAction('add')">
        <mat-icon>add</mat-icon>
    </button>
    <button (click)="onChooseAction('edit')">
        <mat-icon>edit</mat-icon>
    </button>
    <button (click)="onChooseAction('delete')">
        <mat-icon>delete</mat-icon>
    </button>
    <button (click)="onChooseAction('members')">
        <mat-icon>people_alt</mat-icon>
    </button>
</div> -->

<div class="control-btns">
    <button (click)="onChooseAction('add')"
        [title]="group.id?.includes('O_') ? ('Add Department'|translatePipe) : group.id?.includes('D_') ? ('Add Group'|translatePipe) : ('Add Sub-Group'|translatePipe)"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw"></i>
    </button>
    <button *ngIf="group?.type ==='organization'" (click)="onChooseAction('edit',group.id)"
        title="{{group?.type+' Settings' | titlecase | translatePipe}}"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw"></i>
    </button>
    <button *ngIf="group?.type!=='organization'" (click)="onChooseAction('edit',group.id)"
        title="{{'Modify '+group?.type | titlecase| translatePipe}}"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw"></i>
    </button>
    <button *ngIf="group?.type!=='organization'" (click)="onChooseAction('delete',group.aId)"
        title="{{'Delete '+group?.type | titlecase| translatePipe}}"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw"></i>
    </button>
    <button *ngIf="group?.type!=='department'" (click)="onChooseAction('members',group.id)"
        [title]="group?.type.toLowerCase() === 'organization'?('All Members'|translatePipe):('Group Members'|translatePipe)"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw"></i>
    </button>
</div>