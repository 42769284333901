import { Injectable, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';          // import signalR
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';
import { TokenService } from '../token/token.service';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class SignalRChatService {


   private  connection: any = new signalR.HubConnectionBuilder().withUrl(`${AppConfig.settings.apiServer.domain}chatsocket`)   // mapping to the chathub as in startup.cs
                                         .configureLogging(signalR.LogLevel.Information)
                                         .build();
   readonly POST_URL = 'https://localhost:7276/chatSocket';


  private receivedMessageObject: any;
  private sharedObj = new Subject<any>();

  constructor(private http: HttpClient, private token: TokenService) { 
    this.connection.onclose(async () => {
      await this.start();
    });
   this.connection.on("ReceiveOne", (message) => { this.mapReceivedMessage(message); });
   this.start();                 
  }


  // Strart the connection
  public async start() {
    try {
      await this.connection.start();
      //console.log("connected");
      this.subscribe();
    } catch (err) {
      //console.log(err);
      setTimeout(() => this.start(), 5000);
    } 
  }

  private subscribe() {
    //console.log('subscribe called');
    let userID = this.token.decodedToken().nameid;
    this.connection.invoke("Subscribe", Number.parseInt(userID)).catch(function (err) {
      return console.error(err.toString());
    });
  }

  public unsubscribe() {
    let userID = this.token.decodedToken().nameid;
    this.connection.invoke("Unsubscribe", userID).catch(function (err) {
      return console.error(err.toString());
    });
  }

  private mapReceivedMessage(message: any): void {
    // this.receivedMessageObject.user = user;
    // this.receivedMessageObject.msgText = message;
    this.sharedObj.next(message);
    ////console.log(message);
 }

  /* ****************************** Public Mehods **************************************** */

  // Calls the controller method
  public broadcastMessage(msgDto: any) {
    this.http.post(this.POST_URL, msgDto).subscribe(data => console.log(data));
    // this.connection.invoke("SendMessage1", msgDto.user, msgDto.msgText).catch(err => console.error(err));    // This can invoke the server method named as "SendMethod1" directly.
  }

  public retrieveMappedObject(): Observable<any> {
    return this.sharedObj.asObservable();
  }


}
