import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';
import { ChartDataSets, ChartOptions } from 'chart.js';

import { ChartType } from 'ng-apexcharts';
import { Label } from 'ng2-charts';
import { Subject, Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { EventsService } from 'src/app/services/events.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store/app.store';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('calanderContainer') calanderContainer!: any;
  faCog = faCog;
  faTh = faTh;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faArrowRight = faArrowRight;
  faDotCircle = faDotCircle;
  faCheck = faCheck
  faTrash = faTrash
  faCalendarAlt = faCalendarAlt
  faArrowLeft = faArrowLeft
  timeInterval = null

  myOpts = {
    prefix: '',
    suffix: '',
    duration: 2,
  };
  date = new Date()
  refresh: Subject<any> = new Subject();
  events: any = [];
  lastRegisterted = []
  subscriotion: Subscription[] = []
  memberLength = 0;
  employeeLength = 0;
  customerLength = 0;
  loggedInUserIdentifier = ''
  greetings = ""
  time = {
    year: 0,
    month: 0,
    day: 0,
    hour: 0,
    minute: 0
  }
  myDate = Date.now();
  dashboardData = [
    {
      icon: 'pe-7s-network',
      title: 'structure',
      color: 'red',
      routerLink: '/editor',
      charthover: 'widget-chart-hover'
    },
    {
      icon: 'pe-7s-users',
      title: 'members',
      color: 'pink',
      routerLink: '/manage-members',
      charthover: 'widget-chart-hover'
    },
  ]
  dashboardData1 = [
    {
      icon: 'pe-7s-date',
      title: 'events',
      color: 'indigo',
      // routerLink: '/event-management'
    },
    {
      icon: 'pe-7s-chat',
      title: 'chat',
      color: 'red',
      routerLink: '/chat-settings',
      charthover: 'widget-chart-hover'
    },
    {
      icon: 'pe-7s-id',
      title: 'cards',
      color: 'purple2',
      // routerLink: '/card-management'
    },
    {
      icon: 'pe-7s-check',
      title: 'tasks',
      color: 'red',
      routerLink: '/task-managment',
      charthover: 'widget-chart-hover'
    },
    {
      icon: 'pe-7s-shopbag',
      title: 'customerService',
      color: 'grey2',
      // routerLink: '/custoumer-service'
    },
    {
      icon: 'pe-7s-plane',
      title: 'landingpage',
      color: 'purple',
      // routerLink: '/set-management'
    },
    {
      icon: 'pe-7s-timer',
      title: 'automations',
      color: 'amber',
      // routerLink: '/trigger-managment'
    },
    {
      icon: 'pe-7s-cart',
      title: 'ecommerce',
      color: 'blue2',
      // routerLink: '/ecommerce'
    },
    {
      icon: 'pe-7s-wallet',
      title: 'digitalWallet',
      color: 'teal',
      // routerLink: '/portal-posts'
    },
    {
      icon: 'pe-7s-comment',
      title: 'socialNetwork',
      color: 'blue',
      // routerLink: '/social-network'
    },
    {
      icon: 'pe-7s-bell',
      title: 'allnotificiations',
      color: 'green',
      routerLink: '/notifications',
      charthover: 'widget-chart-hover'
    },

    {
      icon: 'pe-7s-film',
      title: 'media',
      color: 'green2',
      // routerLink: '/media'
    },
    {
      icon: 'pe-7s-global',
      title: 'search',
      color: 'cyan',
      // routerLink: '/global-search'
    },
    {
      icon: 'pe-7s-search',
      title: 'logs',
      color: 'green',
      // routerLink: '/candidates'
    },
    {
      icon: 'pe-7s-box1',
      title: 'archive',
      color: 'purple2',
      // routerLink: '/card-management'
    },
    {
      icon: 'pe-7s-config',
      title: 'settings',
      color: 'grey2',
      routerLink: '/system_settings',
      charthover: 'widget-chart-hover'
    },

    {
      icon: 'pe-7s-mouse',
      title: 'modules',
      color: 'cyan',
      routerLink: '/module-memnegment',
      charthover: 'widget-chart-hover'
    },

    {
      icon: 'pe-7s-key',
      title: 'permissions',
      color: 'green',
      routerLink: '/local-administrators',
      charthover: 'widget-chart-hover'
    },


  ]

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  }

  eventColors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    },
    green: {
      primary: '#3ac47d',
      secondary: '#58cd91'
    }
  };

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };
  public barChartLabels: Label[] = ['Group Members'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [];
  title: any;
  accountTypes: any;
  empData: any = [];
  clr: any;

  DmemData: any = [];
  groups: any = [];
  departments: any = [];
  totallen: any;
  ats: any;
  jjj: any;


  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: [
        '#ef5350',
        '#ec407a',
        '#ab47bc',
        '#7e57c2',
        '#5c6bc0',
        '#42a5f5',
        '#29b6f6',
        '#26c6da',
        '#26a69a',
        '#66bb6a',
        '#9ccc65',
        '#d4e157',
        '#ffca28',
        '#ffee58',
        '#ffa726',
        '#ff7043',
        '#bdbdbd',
        '#78909c',
      ],
    },
  ]

  constructor(
    private memberService: MembersService,
    private accountService: PortalAccountsService,
    private router: Router,
    public utilService: UtilService,
    private organizationStructureService: OrganizationalStructureService,
    private errorHandler: ErrorHandlerService,
    private eventService: EventsService,
    private tokenService: TokenService) { }
  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);

    return '#' + ('000000' + color).slice(-6);
  }
  ngOnInit() {
    var color: any = [{
      clr: 'blue'
    },
    {
      clr: 'green'
    },
    {
      clr: 'blue2'
    },
    {
      clr: 'green'
    },
    {
      clr: 'amber'
    },
    {
      clr: 'red'
    },
    {
      clr: 'grey2'
    },
    {
      clr: 'purple'
    },
    {
      clr: 'warning'
    },
    {
      clr: 'blue2'
    },
    ]
    this.clr = color;

    // this.accountService.getAccountTypes().subscribe({
    //   next: data => {
    //     var i = 0;
    //     for (let l of data.result) {

    //       var datas = {
    //         title: l.name,
    //         color: this.clr[i],
    //         id: l.id
    //       }
    //       i++;
    //       this.empData.push(datas);
    //     }
    //   }
    // })
    this.memberService.loadDashMemList().subscribe({
      next: (data: any) => {
        this.memberLength = data.result.length
        this.lastRegisterted = data.result;
      }
    });
    this.organizationStructureService.loadOrganizationalStructure();
    this.accountService.loadPortalAccounts(1);
    this.updateTime();
    this.timeInterval = setInterval(() => {
      this.updateTime();
    }, 1000)
    // structure length
    this.organizationStructureService.organizationalStructure$.subscribe((data: any) => {
      this.groups = [];
      this.departments = [];
      for (let l of data) {
        if (l.type === 'group') {
          this.groups.push(l);
        } else if (l.type === 'department') {
          this.departments.push(l);
        }
      }
    })

    // ATS 

    this.accountService.getAccountSummary().subscribe((data: any) => {
      var i = 0;
      for (let l of data.result) {
        l.imageURL = JSON.parse(l.imageURL);
        var Pdata: any = {
          type: l.type,
          color: this.clr[i],
          count: l.count,
          imageURLD: String.fromCharCode(parseInt(l.imageURL.D, 16)),
          imageURLP: String.fromCharCode(parseInt(l.imageURL.P, 16))
        }
        i++
        this.DmemData.push(Pdata)
      }
    })
    // this.accountService.getAccountTypes().subscribe({
    //   next: (data: any) => {
    //     var i = 0;
    //     for (let l of data.result) {
    //       this.accountService.GetAccountsById(l.id).subscribe({
    //         next: (data: any) => {

    //           var Pdata: any = {
    //             title: '',
    //             color: this.clr[i],
    //             memLength: 0
    //           }
    //           i++
    //           if (data.result != 'Unable to retrieve the Accounts details.') {
    //             Pdata.title = l.name
    //             Pdata.memLength = data.result.length;
    //             this.DmemData.push(Pdata)
    //           }
    //           else {
    //             Pdata.title = l.name
    //             Pdata.memLength = 0;
    //             this.DmemData.push(Pdata)
    //           }
    //         },
    //         error: (err: any) => {
    //         }
    //       })
    //     }
    //   }
    // })
    this.loggedInUserIdentifier = this.tokenService.getUser().name;

    // this.subscriotion.push(
    //   this.accountService.portalAccounts$.subscribe(accounts => {
    //     this.customerLength = 0;
    //     this.employeeLength = 0;
    //     if (accounts && Array.isArray(accounts)) {
    //       accounts.forEach(account => {
    //         if (account.type === 'employee') this.employeeLength++;
    //         else if (account.type === 'costumer') this.customerLength++;
    //       })
    //     }
    //   })
    // )

    this.subscriotion.push(
      this.organizationStructureService.getGroupDashData().subscribe({
        next: (data: any) => {
          if (Array.isArray(data.result)) {
            const dat = data.result;
            this.barChartData = [];
            dat.forEach(grp => {
              this.barChartData.push({ data: [grp.memberCount], label: grp.groupName, backgroundColor: this.getRandomColor() })
            });
          }

        },
        error: (err: any) => {
        }
      })
    )

    this.subscriotion.push(
      this.organizationStructureService.getDeptDashData().subscribe({
        next: (data: any) => {
          if (Array.isArray(data.result)) {
            console.log(data.result);
            this.pieChartLabels = [];
            this.pieChartData = [];
            const dat = data.result;
            dat.forEach(element => {
              this.pieChartLabels.push(`${element.deptName}`);
              this.pieChartData.push(element.groupIds.length);
            });
          }
        },
        error: (err: any) => {
        }
      })
    )

    this.subscriotion.push(
      this.eventService.getEvents().subscribe({
        next: (data: any) => {
          if (!Array.isArray(data.result)) {
            return;
          }
          for (let event of data.result) {
            let evColor = this.eventColors.red;
            switch (event.nodeDetails.type) {
              case 'organization':
                color = this.eventColors.red
                break;
              case 'department':
                color = this.eventColors.yellow
                break;
              case 'group':
                color = this.eventColors.green
                break;
            };
            let hours = event.durationType === 1 ? event.duration : event.duration / 60;
            let sdate = new Date(event.eventDate);
            let edate = new Date(event.eventDate);
            var calEvt = {
              start: sdate,
              end: new Date(edate.setTime(edate.getTime() + hours * 60 * 60 * 1000)),
              title: event.name,
              color: color,
              allDay: false,
              resizable: {
                beforeStart: false,
                afterEnd: false
              },
              draggable: false,
              id: event.id,
              description: event.content,
              groupId: event.nodeDetails.aId,
              // image:event.info.image
            }
            this.events.push(calEvt);
          }
          console.log(this.events);
          this.refresh.next();
        },
        error: (err: any) => {
          this.errorHandler.openToast({ title: "ERROR", type: "error", message: "Unable to fetch events." });
        }
      })
    )
  }

  ngAfterViewInit(): void {
    this.calanderContainer.nativeElement.scrollTop = 533
  }

  ngOnDestroy(): void {
    this.subscriotion.forEach(sub => {
      sub.unsubscribe();
    })
  }

  updateTime() {
    const date = new Date()
    this.time.year = date.getFullYear();
    this.time.month = date.getMonth() + 1;
    this.time.day = date.getDate();
    this.time.hour = date.getHours();
    this.time.minute = date.getMinutes();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action === 'Clicked') {
      this.router.navigate(['/event-view/', event.id]);
    }
  }


}
