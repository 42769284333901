import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  faPlusCircle} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-add-event-mini-view',
  templateUrl: './add-event-mini-view.component.html',
  styleUrls: ['./add-event-mini-view.component.scss']
})
export class AddEventMiniViewComponent implements OnInit {

  @Input() currAccountId;

  faevent=  faPlusCircle;
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  onAddEvent() {
    this.router.navigate(['portal-event-view','account', this.currAccountId]);
  }

}
