export enum Role {
    None = 'None',
    Moderator = 'Moderator',
    Administrator = 'Administrator',
    Owner = 'Owner'
}

export enum AccountType {
    employee = 1,
    customer = 2,
    candidate = 3,
    patient = 4
}

export enum ReferForm
{
    None,
    Members,
    Organization,
    Nodes,
    Tasks,
    AccountTypes,
    Accounts,
    AccountCategories,
    AccountEventTypes,
    AccountEvents,
    AccountPosts,
    Moderator,
    Administrator,
    CalendarEvent
}