import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-portal-event-managment',
  templateUrl: './portal-event-managment.component.html',
  styleUrls: ['./portal-event-managment.component.scss']
})
export class PortalEventManagmentComponent implements OnInit {

  @ViewChild('content') content!: ElementRef;


  subscription: Subscription[] = [];
  accountEvents = [];
  accountFilterEvents = [];
  account: any = this.portalAccountService.getEmptyWithOutType();
  searchText = ''
  searchFrom = new Date().toISOString();
  searchTo = new Date().toISOString();
  searchType = '';
  isSearchByDate = false;
  isSearchByType = false;
  currCategory = ''
  typeMap = {
    'תחילת עבודה': [
      'חוזה ותנאי העסקה',
      'הסכם סודיות',
      'טופס 101',
      'טפסי הצהרה',
      'אישורים',
      'אישורי העסקה',
      'ידע מקדים',
      'תנאי סף לבדיקה',
      'שיחת קליטה מנהל ישיר',
      'יום ראשון בעבודה',
      'קבלה וחתימה על ציוד',
      'הרשאות למערכות החברה',
      'הדרכה על מערכות החברה',
      'מעבר על תקנון למניעת הטרדות מיניות',
      'הצמדת חונך / מנטור',
    ],
    'סיום העסקה': [
      'סיבת העזבה',
      'מכתב התפטרות',
      'סיבת פיטורים',
      'שימוע',
      'דיון פנים אירגוני',
      'סיכום שימוע',
    ],
    'סיום עבודה': [
      'יום אחרון בעבודה',
      'שיחת סיום',
      'החזרת ציוד',
      'ביטול הרשאות למערכות החברה',
    ],
    'החלפת תפקיד': [
      'שיחת סיכום תפקיד',
      'החלפת תפקיד שיחת קליטה',
      'קידום',
      'מעבר מחלקה',
    ],
    // 'הערכות': [],
    'שינוי מקום': [
      'שינוי מקום',
      'רילוקיישן',
    ],
    'למידה': [
      'לומדה',
      'לימודים (שאינם במסגרת עבודה)',
      'כנס',
      'השתלמות',
      'הכשרה',

    ],
    'שכר': [
      'העלאת משכורת',
    ],
    'קשר עם עובד': [
      'משוב שוטף',
      'ציון לשבח',
      'הערכת ביצועים',
      'שיחת נזיפה',
      'שיחה אישית',

    ],
    'קבצים': [
      'טופס כללי',
    ],
    'אירועים אישיים': [
      'לידה',
      'חופשת לידה',
      'מחלה',
      'פטירת קרוב משפחה',
    ]
  }
  typeMapKeys = Object.keys(this.typeMap);


  constructor(
    private portalAccountService: PortalAccountsService,
    private portalEventService: PortalEventService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(async (params) => {
      const id = params.get('accountId');
      if (id) {
        let accResp = await this.portalAccountService.getById(id).toPromise();
        this.account = accResp.result;
        this.accountEvents = this.account.events;
        //console.log(this.accountEvents);
        this.setAccountFilterEvents();
      }
    });
  }

  setAccountFilterEvents() {
    this.accountFilterEvents = this.accountEvents.filter(event => {
      const isIncludeText = event.name.toLowerCase().includes(this.searchText)
      const isBetweenDates = this.isSearchByDate ? (event.eventDate <= this.searchTo && event.eventDate >= this.searchFrom) : true
      const isInType = this.searchType ? this.searchType === event.type : true
      return isIncludeText && isBetweenDates && isInType
    })
  }

  dataChange(dataName, newData) {
    switch (dataName) {
      case 'searchText':
        this.searchText = newData;
        break;
      case 'searchFrom':
        this.searchFrom = newData;
        break;
      case 'searchTo':
        this.searchFrom = newData;
        break;
      case 'isSearchByDate':
        this.isSearchByDate = newData;
        break;

      default:
        break;
    }
    this.setAccountFilterEvents();
  }

  open() {
    this.modalService.open(this.content, {
      size: 'lg'
    })
  }

  chooseType(type, cb?) {
    if (cb) cb();
    this.searchType = type;
    this.setAccountFilterEvents()
  }

  getIconType(type) {
    switch (type) {
      case 'אישורים':
      case 'אישורי העסקה':
      case 'תנאי סף לבדיקה':
      case 'ביטול הרשאות למערכות החברה':
      case 'הרשאות למערכות החברה':
        return ''
      case 'ידע מקדים':
        return ''
      case 'ביטול הרשאות למערכות החברה קליטה מנהל ישיר':
      case 'שיחת סיום':
      case 'החלפת תפקיד שיחת קליטה':
      case 'שיחת סיכום תפקיד':
      case 'שיחת קליטה מנהל ישיר':
      case 'שיחת נזיפה':
      case 'שיחה אישית':
        return ''
      case 'יום ראשון בעבודה':
      case 'יום אחרון בעבודה':
        return ''
      case 'החזרת ציוד':
      case 'קבלה וחתימה על ציוד':
        return ''
      case 'הדרכה על מערכות החברה':
      case 'מעבר על תקנון למניעת הטרדות מיניות':
      case 'הערכת ביצועים':

        return ''
      case 'הצמדת חונך / מנטור':
        return ''
      case 'סיבת העזבה':
      case 'סיבת פיטורים':
        return ''
      case 'מכתב התפטרות':
        return ''
      case 'שימוע':
      case 'סיכום שימוע':
        return ''
      case 'דיון פנים אירגוני':
      case 'כנס':
        return ''
      case 'קידום':
      case 'העלאת משכורת':
        return ''
      case 'מעבר מחלקה':
        return ''
      case 'רילוקיישן':
      case 'שינוי מקום':
        return ''
      case 'לומדה':
      case 'השתלמות':
      case 'הכשרה':
      case 'לימודים (שאינם במסגרת עבודה)':
        return ''
      case 'ציון לשבח':
        return ''
      case 'לידה':
        return ''
      case 'חופשת לידה':
      case 'מחלה':
        return ''

      default:
        return ''
    }
  }

}
