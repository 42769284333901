import { Component, Input, OnInit } from '@angular/core';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-portal-managment-event-view',
  templateUrl: './portal-managment-event-view.component.html',
  styleUrls: ['./portal-managment-event-view.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class PortalManagmentEventViewComponent implements OnInit {

  @Input() event;
  @Input() id;

  isMenuOpen = false

  get eventParticipantsString() {
    return this.event.participants.map(member => member.identifier).join(', ')
  }

  constructor(
    private portalEventService: PortalEventService,
  ) { }


  ngOnInit(): void {

  }

  stopProp(ev) {
    ev.stopPropagation();
  }

  deleteEvent(eventId) {
    this.portalEventService.delete(eventId).toPromise();
  }

}
