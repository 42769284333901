<div class="h-100">
  <div class="row h-100 g-0">
    <div class="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center col-md-12 col-lg-7">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="mx-auto mb-3 text-center">
          <img src="/assets/images/flexyc-logo.png" style="width: 100px; height: 55px;" />
        </div>
        <h4>
          <div>Welcome,</div>
          <span>It only takes a <span class="text-success">few seconds</span> to create your account</span>
        </h4>
        <div>
          <form [formGroup]="Form" (ngSubmit)="onSubmit()">
            <div class="row">

              <div class="d-flex  gap-3">
                <div class="mb-3" style="width:50%;">
                  <label class="form-label" for="username">Email</label>
                  <input type="email" class="form-control" id="email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" placeholder="Enter email">
                  <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']">Email is required</div>
                    <div *ngIf="f['email'].errors['email']">Email must be a valid email address
                    </div>
                  </div>
                </div>

                <!-- testing -->





                <div class="mb-3" style="width:50%;">
                  <label class="form-label" for="name">Enter name</label>
                  <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="Enter name" id="password-input1"
                      formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">

                    <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                      <span *ngIf="f['name'].errors['required']">name is
                        required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex  gap-3">
                <div class="mb-3" style="width:50%;">
                  <label class="form-label" for="password-input">Enter password</label>
                  <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="Enter password" id="password-input2"
                      formControlName="password" type="password"
                      [ngClass]="{ 'is-invalid': submitted && f['password'].errors}"
                      [ngClass]="{'is-invalid':missmatch}">
                    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                      <span *ngIf="f['password'].errors['required']">password is
                        required</span>
                    </div>

                  </div>
                </div>
                <div class="mb-3" style="width:50%;">
                  <label class="form-label" for="password-input">Confirm password</label>
                  <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="confirm password" id="password-input3"
                      formControlName="cpassword" type="password"
                      [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" [ngClass]="{'is-invalid':missmatch}">
                    <div *ngIf="submitted && f['cpassword'].errors" class="invalid-feedback">
                      <span *ngIf="f['cpassword'].errors['required']">password is
                        required</span>
                    </div>
                    <div *ngIf="misspass" class="text-danger">password missmatch</div>
                  </div>
                </div>

                <div class="mb-3" style="width:50%;">
                  <label class="form-label" for="password-input">Username</label>
                  <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="Username" id="password-input3"
                      formControlName="username" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" [ngClass]="{'is-invalid':missmatch}">
                    <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                      <span *ngIf="f['username'].errors['required']">username is
                        required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group form-check">
                <input type="checkbox" formControlName="agree" id="acceptTerms" class="form-check-input"
                  [ngClass]="{ 'is-invalid': submitted && f.agree.errors }" />
                <label for="agree" class="form-check-label">Accept Terms & Conditions</label>

              </div>

              <!-- <div class="col-md-6">
                <fieldset class="mb-3" id="__BVID__132">
                  <app-input-label [(isValid)]="isEmailValid" [label]="'email'|translatePipe" name="email" [required]="true" [email]="true" type="email"
                  [(value)]="email">
                </app-input-label>
                </fieldset>
              </div> -->
              <!-- <div class="col-md-6">
                <fieldset class="mb-3" id="__BVID__134">
                  <app-input-label [label]="'name'|translatePipe" name="name" [required]="true" [name]="true" type="name" [(value)]="name">
                </app-input-label>
                </fieldset>
              </div> -->



              <!-- <div class="col-md-6">
                <fieldset class="mb-3" id="__BVID__136">
                  <app-input-label [(isValid)]="isPasswordValid" [label]="'password'|translatePipe" name="password" [required]="true" type="password"
                 [syncValidators]="['password']"  [(value)]="password">
                </app-input-label>
                </fieldset>
              </div> -->


              <!-- <div class="col-md-6">
                <fieldset class="mb-3" id="__BVID__138">
                  <app-input-label [(isValid)]="isPasswordValid" [label]="'passwordrep'|translatePipe" name="passwordrep" [required]="true" type="password"
                 [syncValidators]="['password']"  [(value)]="passwordrep">
                </app-input-label>
                </fieldset>
              </div> -->
            </div>
            <div class="mt-4 d-flex align-items-center">
              <h5 class="mb-0">
                Already have an account?
                <a routerLink="/login">Sign in</a>
              </h5>
              <div class="ms-auto">
                <button type="submit" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondary btn-lg">
                  <span class="d-flex align-items-center">Create Account<span class="spinner-border" *ngIf="loading"
                      style="width:20px;height:20px;margin-left: 10px;"></span></span>
                </button>
              </div>
              <!-- <div *ngIf="showMsg" class="alert alert-success">
                {{showMsg}}
                <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closealert"></button>
              </div> -->
            </div>
          </form>






        </div>
      </div>
    </div>
    <div class="d-lg-flex d-xs-none col-lg-5">
      <div class="slider-light">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
              <div class="slider-content text-light">
                <h3>Perfect Balance</h3>
                <p>
                  Flexyc allows you to manage your organization in a unique and intuitive way. It is a robust system, with features like, org structure management,
                  event management and chat. 
                </p>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>