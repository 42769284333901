import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { ReferForm } from 'src/app/models/eums';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-portal-event-mini-view',
  templateUrl: './portal-event-mini-view.component.html',
  styleUrls: ['./portal-event-mini-view.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class PortalEventMiniViewComponent implements OnInit {
  @Input() isInManage = false;
  @Input() isFromAccount = false;
  @Input() event?: any;
  @Input() id: any;
  isMenuOpen = false;

  @Output() deleted: EventEmitter<any> = new EventEmitter();

  imageSource: any = '';
  eventDetails: any = [];
  icon: any = [];

  get eventParticipantsStr() {

    return this.eventDetails.participants.map(member => member.identifier).join(', ')
  }

  constructor(
    private router: Router,
    private portalEventService: PortalEventService,
    private errHandler: ErrorHandlerService,
    private file: FilesService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    var getImgData: any = {
      fkId: this.event.id,
      formType: ReferForm.AccountEvents,
      isAttachment: false
    }


    this.file.getFileFkid(getImgData).subscribe((data: any) => {

      if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
        this.file.getFilebyId(data.result[0].id).subscribe((data: any) => {
          this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
        })
      }
    })

    this.calculateDiff()
  }

  calculateDiff() {
    var today: any = new Date();
    var dd: any = String(today.getDate()).padStart(2, '0');
    var mm: any = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy: any = today.getFullYear();

    var today: any = dd + '/' + mm + '/' + yyyy;

    var d1 = parseInt(this.event.eventDate.slice(0, 2));
    var d2 = parseInt(today.slice(0, 2));
    var d3 = d2 - d1;
  }

  async onChooseEvent(centerDataModal: any) {
    if (this.isFromAccount) {
      this.modalService.open(centerDataModal, { backdrop: 'static', centered: true, windowClass: 'modal-rounded', keyboard: false });
      var acc = await this.portalEventService.getById(this.event.id).toPromise();
      this.eventDetails = acc.result;
      this.icon = acc;
    }
    else this.router.navigate(['portal-event-view', 'event', 'readonly', 'account', this.id, this.event.id]);
  }

  stopProp(ev) {
    ev.stopPropagation();
  }

  deleteEvent(eventId) {
    this.portalEventService.delete(eventId).subscribe({
      next: (data: any) => {
        this.errHandler.openToast({ title: 'SUCCESS', type: 'success', message: 'Event deleted.' });
        this.isMenuOpen = false;
        this.deleted.emit(eventId);
      },
    });
  }
  warningModel(content: any) {
    this.modalService.open(content, {
      backdrop: "static",
      centered: true,
      keyboard: false,
    });
  }
}
