import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from './app.config';
import { globalComponent } from '../global-component.service';
@Injectable({
  providedIn: 'root'
})
export class PortalAccountsService {
  private _portalAccounts$ = new BehaviorSubject<any[]>([])
  public portalAccounts$ = this._portalAccounts$.asObservable();

  constructor(
    private http: HttpClient,
  ) { }


  getEmp(){
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.Account}`);
  }

  public getEmptyWithOutType() {
    return {
      "id": "",
      "type": "",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },

    }
  }

  public getEmptyCandidate() {
    return {
      "id": "",
      "type": "candidate",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "hiringDate": Date.now(),
        "candidateStatus": true,
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }
  public getEmptyEmployee() {
    return {
      "type": "employee",
      "description": "",
      "data": {
          "fullName": "",
          "title": "",
          "profileImage": null
      },
      "contactInfo": {
          "member": {
              "id": 1,
              "identifier": "",
              "email": ""
          },
          "email": "",
          "phone": "",
          "gender": "male",
          "address": ""
      },
      "typeData": [
          {
              "id": 1,
              "identifier": "Id Number",
              "name": "Text",
              "fieldType": 4,
              "fieldValue": ""
          },
          {
              "id": 2,
              "identifier": "Joining Date",
              "name": "Date",
              "fieldType": 2,
              "fieldValue": new Date().toISOString()
          },
          {
              "id": 3,
              "identifier": "Devision",
              "name": "Node",
              "fieldType": 5,
              "fieldValue": {aid:0, name: '', type: 'organization'}
          },
          {
              "id": 4,
              "identifier": "Direct Managers",
              "name": "Member",
              "fieldType": 6,
              "fieldValue": []
          },
          {
              "id": 5,
              "identifier": "Employee Status",
              "name": "Toggle",
              "fieldType": 3,
              "fieldValue": "true"
          }
      ],
      "events": null
    }
  }
  public getEmptyCostumer() {
    return {
      "id": "",
      "type": "customer",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "idNumber": "",
        "startDate": Date.now(),
        "companyStatus": true,
        "seconderyContact": [],
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }
  public getEmptypatient() {
    return {
      "id": "",
      "type": "patient",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "idNumber": "",
        "diagnosis": "",
        "status": 'KYP',
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }

  public loadPortalAccounts(accType: any) {
    this.http.get<any[]>( `${AppConfig.settings.apiServer.baseUrl}${globalComponent.AccountType}${accType}`).pipe(
      tap((portalAccounts:any) => {
        this._portalAccounts$.next(portalAccounts.result);
      })
    ).toPromise()
  }

  getById(id) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.GetAccount}${id}`)
  }

  add(account) {
    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.Account}`, account).pipe()
  }

  updateAccount(data: any) {
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.Account}`, data);
  }

  delete(id: number, accType: any) {
    return this.http.delete<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.Account}/${id}`);
  }

  getAccountTypes() {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.GetAccountType}`);
  }

  getAccountTypeById(id) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.GetAccountType}?id=${id}`);
  }

  getEventById(eventId: any) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.AccountType}${globalComponent.GetEvent}${eventId}`);
  }

  getAccountsByUserId(id: any) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.AccountType}${globalComponent.Member}${id}`);
  }

  getAccountSummary(){
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.AccountSummary}`);
  }
}
