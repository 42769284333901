<div dirControl class="main-card card  total-sets-card portal-event-view">
    <div *ngIf="event" class="card-body">
        <div class="imgs-container">
            <div class="">
                <img class="profile-image" *ngIf="accountImgSrc" [src]="accountImgSrc" alt="Account image">
                <h3 class="card-title text-center">
                    <p>{{account?.data?.fullName}}</p>
                </h3>
            </div>
            <div class="equipment">
                <img *ngIf="evtImageSource" [src]="evtImageSource" alt="Event image">
                <i *ngIf="evtImageSource == ''" class="fa fa-fw">{{icon}}</i>
                <h3 class="card-title text-center">
                    <p>{{event?.type || event?.name}}</p>
                </h3>
            </div>
        </div>
        <!-- <p>Name: {{event?.eventName}}</p>
        <p *ngIf="event.eventParticipants.length">Participants: {{eventParticipantsStr}}</p>
        <p *ngIf="event.eventDate">Date: {{event.eventDate | formatDate}}</p>
        <p *ngIf="event.eventLocation">Location: {{event.eventLocation}}</p>
        <p *ngIf="event.eventDescription">Description: {{event.eventDescription}}</p>
        <p>is visibale to account: {{event.isVisivaleToAccount}}</p> -->

        <div class="main-data-container">

            <div class="table-key-value-container">
                <div class="table-keys">
                    <div *ngIf="event.type" class="icon-text-container key-container">
                        <i class="pe-7s-file"></i>
                        <p>{{'type'|translatePipe}}</p>
                    </div>
                    <div *ngIf="event.eventLocation" class="icon-text-container key-container">
                        <i class="pe-7s-map-marker"></i>
                        <p>{{'event location'|translatePipe}}</p>
                    </div>
                    <div *ngIf="event.participants.length" class="icon-text-container key-container">
                        <i class="pe-7s-users"></i>
                        <p>{{'event participants'|translatePipe}}</p>
                    </div>
                    <div *ngIf="!isFromAccount" class="icon-text-container key-container">
                        <i class="pe-7s-look"></i>
                        <p>{{'Visible only managers'|translatePipe}}</p>
                    </div>
                    <ng-container *ngIf="event.formatData">


                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="icon-text-container key-container">
                                <i class="pe-7s-file"></i>
                                <p>{{question.title}}</p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="table-values">
                    <div *ngIf="event.type" class="value-container">
                        <p>{{event.type}}</p>
                    </div>
                    <div *ngIf="event.eventLocation" class="value-container">
                        <p>{{event.eventLocation}}</p>
                    </div>
                    <div *ngIf="event.participants.length" class="value-container">
                        <p>{{eventParticipantsStr}}</p>
                    </div>
                    <div *ngIf="!isFromAccount" class="value-container">
                        <p>{{event.isVisivaleToAccount? 'True':'False'}}</p>
                    </div>
                    <ng-container *ngIf="event.formatData">
                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="value-container">
                                <p>
                                    <app-set-preview [type]="question.property.type" [value]="question.data">
                                    </app-set-preview>
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="event.content" class="description">
                <div class="table-key-value-container">
                    <div class="table-keys">
                        <div class="icon-text-container key-container">
                            <i class="pe-7s-note"></i>
                            <p>{{'description'|translatePipe}}</p>
                        </div>
                    </div>
                    <div class="table-values">
                        <div class="value-container">
                            <p>{{event.content}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isFromAccount" class="btns-container mt-3">
            <a [routerLink]="'/account-view/account/'+id" class="btn btn-primary ">{{'Account'|translatePipe}}</a>
            <a [routerLink]="'/portal-event-view/event/'+ id + '/' +event?.id"
                class="btn btn-secondary">{{'edit'|translatePipe}}</a>
            <button (click)="warningModel(warningContent)" class="btn btn-danger">{{'Delete'|translatePipe}}</button>


            <ng-template #warningContent let-modal>
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3>{{'Delete event'|translatePipe}}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="btn-close" (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body">
                        <div class=" text-center">
                            <div class="pt-2 fs-15 mx-4 mx-sm-5">
                                <h3 class="text-muted mx-4 mb-0">{{'Are you sure?'|translatePipe}}</h3>
                            </div>
                        </div>
                        <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <button type="button" class="btn w-sm btn-danger " id="delete-product"
                                (click)="modal.close('Close click');deleteEvent()">{{'Yes, Delete
                                It!'|translatePipe}}</button>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </ng-template>
        </div>

        <!-- <ng-container *ngIf="event.formatData">
            <div class="divider"></div>

            <div class="format-data-container">
                <div class="table-key-value-container">
                    <div class="table-keys">
                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="icon-text-container key-container">
                                <i class="pe-7s-file"></i>
                                <p>{{question.title}}</p>
                            </div>
                        </ng-container>

                    </div>
                    <div class="table-values">
                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="value-container">
                                <p>
                                    <app-set-preview [type]="question.property.type" [value]="question.data">
                                    </app-set-preview>
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container> -->

    </div>
</div>