import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.scss']
})
export class ManageAccountsComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: any;

  subscriotion: Subscription[] = []
  options: any = []
  accounts: any[] = [];
  search = ''
  accountType: any;
  title: string = '';
  noData: boolean = false;
  accountTypeStr = '';
  modalTitle: any = 'Delete Account';
  info1: string;
  Account: any;
  currAction: string;
  info2?: string;
  constructor(
    private accountService: PortalAccountsService,
    private route: ActivatedRoute,
    private errHanlder: ErrorHandlerService,
    private modalService: NgbModal,
    public translateService: OtechI18nService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      if (param.type) {
        this.accountType = param.type;
        this.accountService.getAccountTypeById(this.accountType).subscribe({
          next: (data: any) => {
            this.options = []
            this.title = data.result.name + 's';
            this.options.push('Delete ' + this.title);
            this.accountTypeStr = data.result.name;
          },
          error: (err: any) => {

          }
        })

        this.accountService.loadPortalAccounts(this.accountType);

        this.subscriotion.push(
          this.accountService.portalAccounts$.subscribe((accounts: any) => {
            if (Array.isArray(accounts) && accounts.length > 0) {
              this.noData = false;
              this.title = accounts[0].type + 's';
              // this.options.push('Delete ' + this.title);
              this.updateAccounts(accounts);
            } else {
              this.noData = true;
              this.accounts = [];
            }
          })
        )
      }
    })
  }

  updateAccounts(accounts) {
    this.accounts = accounts.map(account => ({ ...account, isCheck: false }));
  }

  filterAccounts() {
    return this.accounts.filter(employee => (
      employee.data.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      employee.contactInfo.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }
  open() {
    this.modalService.open(this.modal.content).result.then((result) => {
    }, (reason) => {
    });

  }
  dataSendToModal() {
    if (this.translateService.locale === 'en') {
      if (this.Account.length > 1) {
        this.info1 = `Do you want to delete the ${this.Account.length} selected employees?`;
      }
      else if (Array.isArray(this.Account)) {
        this.info1 = " Do you want to delete " + this.accounts.filter(accounts => accounts.isCheck)[0].type + " *" + '"' + this.accounts.filter(accounts => accounts.isCheck)[0].data.fullName + '"' + "* ?";
      }
      else {
        this.info1 = " Do you want to delete " + this.Account.type + " *" + '"' + this.Account.data.fullName + '"' + "* ?";
      }
    }
    if (this.translateService.locale === 'he') {
      if (this.Account.length > 1) {
        this.info1 = `האם אתה רוצה למחוק את ${this.Account.length} עובדים נבחרים?`;
      }
      else if (Array.isArray(this.Account)) {
        this.info1 = " האם אתה רוצה למחוק " + this.accounts.filter(accounts => accounts.isCheck)[0].type + " *" + '"' + this.accounts.filter(accounts => accounts.isCheck)[0].data.fullName + '"' + "* ?";
      }
      else {
        this.info1 = " האם אתה רוצה למחוק " + this.Account.type + " *" + '"' + this.Account.data.fullName + '"' + "* ?";
      }
    }

  }


  removeAccount(account: any) {
    this.open();
    this.Account = account;
    this.dataSendToModal();
  }
  // deleteAccount(employee) {
  //   this.accountService.delete(employee.id, this.accountType).subscribe({
  //     next: (data: any) => {
  //       this.errHanlder.openToast({ title: 'SUCCESS', type: 'success', message: 'Account deleted.' });
  //       this.accountService.loadPortalAccounts(this.accountType);
  //     }
  //   })
  // }
  deleteAccount(employee: any) {
    if (this.Account.length >= 1) {
      for (let l of this.Account) {
        this.accountService.delete(l, this.accountType).subscribe({
          next: (data: any) => {
            this.errHanlder.openToast({ title: 'SUCCESS', type: 'success', message: this.title + ' deleted.' })
            this.accountService.loadPortalAccounts(this.accountType);
          },
          error: (err: any) => {
            this.errHanlder.openToast({ title: 'ERROR', type: 'error', message: '' })
          }
        })
      }
    }
    else {
      this.accountService.delete(this.Account.id, this.accountType).subscribe({
        next: (data: any) => {
          this.errHanlder.openToast({ title: 'SUCCESS', type: 'success', message: this.Account.type + ' deleted.' })
          this.accountService.loadPortalAccounts(this.accountType);
        },
        error: (err: any) => {
          this.errHanlder.openToast({ title: 'ERROR', type: 'error', message: ' ' })
        }
      })
    }
  }

  getNumOfSelected() {
    return this.accounts.reduce((acc, employee) => {
      if (employee.isCheck) acc++
      this.modalTitle = acc > 1 ? `Delete ${this.title}` : `Delete ${employee.type}`;
      return acc
    }, 0)
  }

  unselect() {
    this.updateAccounts(this.accounts)
  }

  deleteSelected() {
    // const selected = this.accounts.filter(account => account.isCheck);
    // selected.forEach(account => {
    //   this.removeAccount(account)
    // })
    const selectedMembers = this.accounts.filter(account => account.isCheck);
    const selectedMemberIds = selectedMembers.map(account => account.id);
    this.removeAccount(selectedMemberIds);

  }

}
