import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ReferForm } from 'src/app/models/eums';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { PortalPostService } from 'src/app/services/portal-post.service';
import { faClock } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss']
})
export class PostViewComponent implements OnInit {

  constructor(
    private portalPostService: PortalPostService,
    private errorHandler: ErrorHandlerService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer
  ) { }

  faCalendarAlt = faCalendarAlt;
  faClock = faClock

  @Input() post: any;
  @Input() isEditable: boolean;

  postClone;
  isEdit = false;
  loading = false;
  imageSource: any;
  DateString: any = '';
  modified: boolean = false;
  imgId: number;
  imgDetails: any;
  files: any;
  ngOnInit(): void {
    var data: any = {
      fkId: this.post.id,
      formType: ReferForm.AccountPosts
    }


    this.fileService.getFileFkid(data).subscribe({
      next: (data: any) => {


   
        var a = data.result;

        if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
          this.fileService.getFilebyId(a[0].id).subscribe((data: any) => {

            this.imgDetails = data.result;
            this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);

          })
        }
        else {
          this.imageSource = '';
        }
      }, error: (data: any) => {

      }

    }



    )
    this.calculateDiff();
  }

  onDelete() {
    this.portalPostService.delete(this.post.id).subscribe({
      next: data => {
        this.portalPostService.loadPortalPosts(this.post.accountType.id);
      }
    })
  }

  toggleIsEdit(no: any) {
    this.imgId = no;

    if (this.isEdit) {
      this.post = { ...this.postClone }
      this.postClone = null;
      this.isEdit = false
    } else {
      this.postClone = { ...this.post },
        this.isEdit = true;
    }
  }

  onUpdate() {

    this.loading = true;
    let updateData = {
      postId: this.post.id,
      title: this.post.name,
      content: this.post.content
    }
    this.portalPostService.edit(updateData, this.post.accountType.id).subscribe({
      next: data => {
        this.loading = false;
        this.portalPostService.loadPortalPosts(this.post.accountType.id);

        if (this.files != undefined) {
          let imgData = {
            files: this.files,
            fkid: data.result.id,
            formType: ReferForm.AccountPosts,
            isAttachment: false
          }
          this.fileService.uploadFile(imgData).subscribe({
            next: (data: any) => {
              this.portalPostService.loadPortalPosts(this.post.accountType.id);
              this.errorHandler.openToast({ title: 'Success', type: 'success', message: 'Updated!' })
            }
            , error: (data: any) => {

            }
          })

        }




      },
      error: err => {
        this.loading = false;
        this.errorHandler.openToast({ title: 'error', type: 'error', message: 'Could not update post. Try again later.' })
      }
    })
  }


  calculateDiff() {
    var today: any = new Date();
    var dd: any = String(today.getDate()).padStart(2, '0');
    var mm: any = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy: any = today.getFullYear();

    var today: any = dd + '/' + mm + '/' + yyyy;

    var d1 = parseInt(this.post.postedOn.slice(0, 2));
    var d2 = parseInt(today.slice(0, 2));
    var d3 = d2 - d1;

    if (d3 == 0) {
      this.DateString = 'Today';

    }
    else if (d3 == 1) {
      this.DateString = 'Yesterday';

    }

    if (this.post.postedOn != this.post.modifiedOn) {
      this.modified = true;
    }

  }

  fileFrmChild(ev: any) {

    this.files = ev[0];
  }



}
