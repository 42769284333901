import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { Subscription } from 'rxjs';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MembersService } from 'src/app/services/members.service';
import { SetsService } from 'src/app/services/sets.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss']
})
export class ManageMembersComponent implements OnInit {

  @ViewChild('messageInfo', { static: true }) messageInfo: any;
  @ViewChild('modal', { static: true }) modal: any;




  subscriotion: Subscription[] = []
  members: any[] = []
  search = '';
  currAction = 'remove'
  setsToList = []
  selectedMembersIds: string[] = [];
  message = ''
  messageTitle = ''
  Edata: any;
  mailText: string = ""
  member: any;
  modalTitle: any = 'Delete Member';
  memberName: any;
  info1: any;
  info2: any;
  memberN: any[] = [];
  options: any[];
  constructor(
    private membersService: MembersService,
    private setService: SetsService,
    private modalService: NgbModal,
    private errHanlder: ErrorHandlerService,
    public translateService: OtechI18nService,
  ) { }

  ngOnInit(): void {
    if (this.translateService.locale === 'en') {
      this.options = ['Mail Members', 'Deleter Members']
    }
    else if (this.translateService.locale === 'he') {
      this.options = ['חברי דואר', 'מחק חברים']
    }
    this.subscriotion.push(
      this.membersService.members$.subscribe(members => {
        this.updateMembers(members);
      })
    )
    // this.subscriotion.push(
    //   this.setService.set$.subscribe(sets => {
    //     this.setsToList = sets.filter(set => set.advancedOptions.isShowMemberList)
    //   })
    // )
    this.membersService.loadMembers();
    //this.setService.loadSets();
  }

  updateMembers(members) {
    this.members = members.map(member => ({ ...member, isCheck: false }));
  }

  filterMembers() {
    return this.members.filter(member => (
      member.identifier.toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(member.memberSince).includes(this.search) ||
      member.email.toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(member.lastSeen).includes(this.search)
    ))
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  getNumOfSelected() {
    return this.members.reduce((acc, member) => {
      if (member.isCheck) acc++;
      this.modalTitle = acc > 1 ? 'Delete embers' : 'Delete Member';
      return acc
    }, 0)
  }


  open() { // open modal run from selecting group with delete action

    this.modalService.open(this.modal.content).result.then((result) => {
    }, (reason) => {
    });

  }

  dataSendToModal() {
    if (this.translateService.locale === 'en') {
      if (this.member.length > 1) {
        this.info1 = `Do you want to delete the ${this.member.length} selected members?`;
      } else if (Array.isArray(this.member)) {

        this.info1 = " Do you want to delete member *" + '"' + this.members.filter(member => member.isCheck)[0].fullName + '"' + "* ?";
      } else {
        this.info1 = " Do you want to delete member *" + '"' + this.member.fullName + '"' + "* ?";
      }
      this.info2 = "* Note that the member will not be able to log in anymore.";
    }
    else if (this.translateService.locale === 'he') {
      if (this.member.length > 1) {
        this.info1 = `האם אתה רוצה למחוק את ${this.member.length} חברים נבחרים?`;
      } else if (Array.isArray(this.member)) {

        this.info1 = "האם אתה רוצה למחוק חבר *" + '"' + this.members.filter(member => member.isCheck)[0].fullName + '"' + "* ?";
      } else {
        this.info1 = "האם אתה רוצה למחוק חבר *" + '"' + this.member.fullName + '"' + "* ?";
      }
      this.info2 = "* שימו לב שהחבר לא יוכל להיכנס יותר.";
    }

  }

  removeMember(member: any) {
    this.open();
    this.member = member;
    this.memberN.push(member);
    this.dataSendToModal();
  }

  onDeleteMember(member: any) {
    if (this.member.length >= 1) {
      for (let l of this.member) {
        this.membersService.removeMember(l).subscribe({
          next: (data: any) => {
            this.errHanlder.openToast({ title: 'SUCCESS', type: 'success', message: data.result })
          },
          error: (err: any) => {
            this.errHanlder.openToast({ title: 'ERROR', type: 'error', message: err.result })
          }
        })
      }
    }
    else {
      this.membersService.removeMember(this.member.id).subscribe({
        next: (data: any) => {
          this.errHanlder.openToast({ title: 'SUCCESS', type: 'success', message: data.result })
        },
        error: (err: any) => {
          this.errHanlder.openToast({ title: 'ERROR', type: 'error', message: err.result })
        }
      })
    }
  }

  changeMemberStatus(data: ChangeMemberStatusData) {
    const { id, status } = data;
    this.membersService.changeMemberStatus(id, status).subscribe(_ => { });
  }

  onSelectedMembersAction() {
    const selectedMembers = this.members.filter(member => member.isCheck);
    const selectedMemberIds = selectedMembers.map(member => member.id);
    if (this.currAction === 'Deleter Members') {
      this.removeMember(selectedMemberIds);
    }
    else if (this.currAction === 'Mail Members') {
      const selectedMembers = this.members.filter(member => member.isCheck);
      const selectedMemberIds = selectedMembers.map(member => member.email);
      this.selectedMembersIds = selectedMemberIds;
      this.mailText = "mailto:" + this.selectedMembersIds
      window.location.href = this.mailText

    }
    else {
      selectedMemberIds.forEach(id => {
        this.changeMemberStatus({ id: id, status: this.currAction });
      })
    }
    //this.members = this.members.map(member => ({ ...member, isCheck: false }));
  }
}

