import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-members-multy-select',
  templateUrl: './members-multy-select.component.html',
  styleUrls: ['./members-multy-select.component.scss']
})
export class MembersMultySelectComponent implements OnInit, AfterViewInit {
  //To get details who currently Logged in.
  @Input() curMem: any;
  @Input() label: string
  @Input() name: string
  isFocus = false;
  members: any = [];
  filterMembers = [];
  subscription: Subscription[] = []
  input = null

  private _text = ''
  private _values = []
  @Output() valuesChange = new EventEmitter<any[]>();
  @Output() singleDpdnValChange = new EventEmitter<any[]>();
  @Input() singleDropdown: any = '';
  @Input() set values(values: any[]) {
    this._values = values ? values : [];
    if (this.singleDropdown == 'singleDropdown') {
      for (let l of values) {
        this.singleDpdnValChange.emit(l);
      }
    }
    else {
      this.valuesChange.emit(values);
    }
  }

  set text(value) {
    this._text = value
    this.setFilterMembers()
  }

  get text() {
    return this._text
  }

  get values() {
    return this._values;
  }

  constructor(
    private memberService: MembersService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members

      })
    )
    this.memberService.loadMembers();
    this.setFilterMembers()
  }

  ngAfterViewInit(): void {
    this.input = this.elementRef.nativeElement.querySelector('.member-multy-select-input')
    this.input.addEventListener('keydown', e => {
      if (e.keyCode === 8 && e.target.value === '') {
        this.values = this.values.filter((value, idx) => idx !== this.values.length - 1)
        this.setFilterMembers();
      }

    })
  }


  setFilterMembers() {
    this.filterMembers = this.members.filter((member: any) =>
      member.identifier.toLowerCase().includes(this.text.toLocaleLowerCase()) &&
      !this.values.some((value: any) => value.id === member.id),
    )
    //console.log(this.filterMembers);
  }

  onChooseMember(member) {

    if (this.singleDropdown == "singleDropdown") {
      this.values = [member]
      this.text = '';
      this.setFilterMembers()
    }
    else {
      this.values = [...this.values, member]
      this.text = '';
      this.setFilterMembers()
    }
  }

  removeValue(value) {
    this.values = this.values.filter(valuesItem => valuesItem.id !== value.id);
    this.setFilterMembers()
  }



}
