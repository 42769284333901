import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from './app.config';
import { globalComponent } from '../global-component.service';

@Injectable({
  providedIn: 'root'
})
export class PortalPostService {

  private BASE_URL = AppConfig.settings.apiServer.baseUrl;
  private _portalPosts$ = new BehaviorSubject<any[]>([])
  public portalPosts$ = this._portalPosts$.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  public loadPortalPosts(accountType: any) {
    this.http.get<any>(this.BASE_URL+globalComponent.GetAccountPosts+accountType).pipe(
      tap(portalPosts => {
        this._portalPosts$.next(portalPosts.result);
      })
    ).toPromise()
  }

  public add(post) {
    return this.http.post<any>(`${this.BASE_URL}${globalComponent.AccountPosts}`, post);
  }

  public delete(postId) {
    return this.http.delete<any>(`${this.BASE_URL}${globalComponent.AccountPosts}/${postId}`);
  }

  public edit(post, accountType) {
    post.editedAt = new Date().toISOString();
    return this.http.patch<any>(`${this.BASE_URL}${globalComponent.AccountPosts}`, post);
  }

  getPostById(id){
return this.http.get<any>(`${this.BASE_URL}${globalComponent.getPostByid}${id}`)
  }
}
