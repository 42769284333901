import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { Set } from 'src/app/interfaces/set';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';
import { AppState } from 'src/app/store/app.store';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loggedInUserId = '';
  member = null;
  memberGroups = [];
  subscription: Subscription[] = [];
  currSetGroupId = '';
  setMap: any = {}
  organizationalStructure = []
  data: any;


  constructor(
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService,
    private setService: SetsService,
    private store: Store<AppState>,
    private tokenService: TokenService
) { }

  async ngOnInit(): Promise<void> {

    let user = this.tokenService.getUser();
    this.data = user;

    let tok = this.tokenService.decodedToken();
    this.loggedInUserId = tok.nameid;
    
    let memDet = await this.memberService.getMember(this.loggedInUserId).toPromise();
    this.member = memDet.result;
    
    this.organizationalStructureService.loadOrganizationalStructure();
  


    // this.subscription.push(
    //   this.store.select('').subscribe(async auth => {
    //     if (auth.id) {
    //       this.loggedInUserId = this.tokenService.getUser().id;
    //     }
    //   })
    // )

    // this.subscription.push(
    //   this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
    //     this.organizationalStructure = groups
    //     // //console.log(this.setMap);
    //     this.memberGroups = [];
    //     groups.forEach((groups: Group) => {
    //       if (groups.members.some(memberId => memberId === this.member.id)) {
    //         this.memberGroups.push(groups)
    //         if (!this.currSetGroupId) this.currSetGroupId = groups.id
    //       }
    //     });
    //   })
    // )

    this.subscription.push(
      this.setService.set$.subscribe(sets => {
        this.setMap = {}
        sets.forEach((set: Set) => {
          // if (set.group.type === 'organization') this.setMap.global.push(set);
          if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set);
          else this.setMap[set.group.id] = [set]
        });
      })
    )
  }


  getCardImg(groupId) {
    return this.organizationalStructure.find(group => group.id === groupId)?.logoUrl
  }
}
