<div class="row-container">
    <div *ngIf="group.children.length;else noChild" class="column-one flex">
        <div *ngFor="let num of arrayOfSpace" class="space"></div>
        <button class="flex align-center" (click)="toggleSlideSituation()">
            <mat-icon *ngIf="slideSituation==='out'">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="slideSituation==='in'">{{'MatIconArrowClose'|translatePipe}}</mat-icon>
            <p>{{group.name}}</p>
        </button>
    </div>
    <ng-template #noChild>
        <div class="column-one flex">
            <div *ngFor="let num of arrayOfSpace" class="space"></div>
            <p>{{group.name}}</p>
        </div>
    </ng-template>

    <p class="column-two" [class]="group.type">{{group.type |translatePipe}}</p>
    <div class="column-three">
        <app-control-buttons-table [group]="group" [groupId]="group.id" [groupaId]="group.aId" (onSelectGroupChild)="onSelectGroupChild.emit($event)"
            (onSetCurrAction)="onSetCurrAction.emit($event)"></app-control-buttons-table>
    </div>
</div>

<div [@slide-vertical]="slideSituation" *ngFor="let child of children" table [group]="child" [numOfSpace]="numOfSpace+1"
    (onSetCurrAction)="onSetCurrAction.emit($event)"
    (onSelectGroupChild)="onSelectGroupChild.emit($event)">
</div>