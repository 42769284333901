<div dirControl class="main-card card mb-3">
    <div class="card-body">
        <div *ngIf="true" class="row data-show">
            <div class="col-12 col-lg-4 data text-center">
                <img class="mb-4" *ngIf="imageSource" [src]="imageSource" alt="account image">
                <p class="account-full-name">{{account.data.fullName}}</p>
                <div class="title-and-description">
                    <p>{{account.data.title}}</p>
                    <button *ngIf="account.description!=''" (click)="openDescription()" class="btn btn-info">
                        <i class="pe-7s-info"></i>
                    </button>
                </div>

                <div *ngIf="false" class="btns-container">
                    <a *ngIf="account.contactInfo.phone" class="m-1 btn btn-success"
                        href="{{'tel:'+account.contactInfo.phone}}">
                        <i class="fa fa-fw"></i>
                    </a>
                    <a *ngIf="account.contactInfo.email" class="m-1 btn btn-success"
                        href="{{'mailTo:'+account.contactInfo.email}}">
                        <i class="fa fa-fw"></i>
                    </a>
                    <a [href]="'https://wa.me/'+account.contactInfo.phone" *ngIf="account.contactInfo.phone"
                        class="m-1 btn btn-success">
                        <i class="fa fa-fw"></i>
                    </a>
                    <!-- <button *ngIf="account.contactInfo.email" class="btn btn-success">Email</button> -->
                    <a routerLink="/chat" *ngIf="account.contactInfo.member.id" class="m-1 btn btn-success">
                        <i class="pe-7s-chat"></i>
                    </a>
                </div>
            </div>
            <div class="col-12 col-lg-4 contact-info">
                <h3 class="card-title text-center">{{'contact info'|translatePipe}}</h3>
                <div class="table-key-value-container mb-4">
                    <div class="table-keys">
                        <div *ngIf="memberIdentidier" class="icon-text-container key-container">
                            <i class="pe-7s-users"></i>
                            <p> {{'user'|translatePipe}}</p>
                        </div>
                        <div *ngIf="account.contactInfo.email" class="icon-text-container key-container">
                            <i class="pe-7s-mail"></i>
                            <p>{{'email'|translatePipe}}</p>
                        </div>
                        <div *ngIf="account.contactInfo.phone" class="icon-text-container key-container">
                            <!-- <i class="fa fa-fw"></i> -->
                            <i class="pe-7s-call"></i>
                            <p>{{'phone'|translatePipe}}</p>
                        </div>
                        <div class="icon-text-container key-container">
                            <ng-container [ngSwitch]="account.contactInfo.gender">
                                <i *ngSwitchCase="'male'" class="pe-7s-male"></i>
                                <i *ngSwitchCase="'female'" class="pe-7s-female"></i>
                                <i *ngSwitchDefault class="fa fa-fw"></i>
                            </ng-container>
                            <p>{{'gender'|translatePipe}}</p>
                        </div>
                        <div *ngIf="account.contactInfo.address" class="icon-text-container key-container">
                            <i class="pe-7s-map-marker"></i>
                            <p>{{'home address'|translatePipe}}</p>
                        </div>
                    </div>
                    <div class="table-values">
                        <div *ngIf="memberIdentidier" class="value-container">
                            <p>{{memberIdentidier}}
                        </div>

                        <div *ngIf="account.contactInfo.email" class="value-container">
                            <p>{{account.contactInfo.email}}</p>
                        </div>


                        <div *ngIf="account.contactInfo.phone" class="value-container">
                            <p> {{account.contactInfo.phone}}</p>
                        </div>


                        <div class="value-container">
                            <p> {{account.contactInfo.gender|translatePipe}}</p>
                        </div>

                        <div *ngIf="account.contactInfo.address" class="value-container">
                            <p>{{account.contactInfo.address}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 type-info">
                <ng-container>
                    <ng-container>
                        <h3 class="card-title text-center" *ngIf="account.typeData && account.typeData.length > 0">{{
                            account.type|translatePipe}} {{'info'|translatePipe}}</h3>
                        <div *ngIf="account && account.typeData" class="table-key-value-container mb-4">
                            <div class="table-keys">
                                <div *ngFor="let typeData of account.typeData; let i = index">
                                    <div class="key-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null'">
                                        <p>{{typeData.identifier|translatePipe}}</p>
                                    </div>
                                </div>
                                <!-- <div class="key-container">
                                    <p>{{'id number'|translatePipe}}</p>
                                </div>
                                <div class=" key-container">
                                    <p>{{'start date'|translatePipe}}</p>
                                </div>
                                <div class="key-container">
                                    <p>{{'devision'|translatePipe}}</p>
                                </div>
                                <div class="key-container">
                                    <p>{{'direct managers'|translatePipe}}</p>
                                </div>
                                <div class=" key-container">
                                    <p>{{'employee status'|translatePipe}}</p>
                                </div> -->
                            </div>
                            <div class="table-values">
                                <div *ngFor="let typeData of account.typeData; let i = index">
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && (typeData.fieldType === 4 || typeData.fieldType === 7)">
                                        <p>{{typeData.fieldValue}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 2">
                                        <p>{{typeData.fieldValue | date:'dd/MM/YYYY'}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 5">
                                        <p> {{typeData.fieldValue.type | translatePipe}}:
                                            {{typeData.fieldValue.name}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 6">
                                        <p> {{directManagerShowData}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 3">
                                        <p>{{typeData.fieldValue === 'true' ?('active' | translatePipe |
                                            titlecase):('inactive'|translatePipe | titlecase)}}</p>
                                    </div>
                                </div>
                                <!-- <div class="value-container">
                                    <p>{{account.typeData[0].fieldValue}}</p>
                                </div>

                                <div class="value-container">
                                    <p>{{account.typeData[1].fieldValue | date:'dd/MM/YYYY'}}</p>
                                </div>


                                <div class="value-container"  *ngIf="account.typeData[2].fieldValue==null">
                                   <p>null</p>
                                </div> 
                                    <div  class="value-container"  *ngIf="account.typeData[2].fieldValue!=null">
                                        <p> {{account.typeData[2].fieldValue.type | translatePipe}}:
                                            {{account.typeData[2].fieldValue.name}}</p>
                                    </div>

                                <div class="value-container">
                                    <p>{{directManagerShowData}}</p>
                                </div>

                                <div class="value-container">
                                    <p>{{account.typeData[4].fieldValue ==
                                        'true'?('active'|translatePipe):('inactive'|translatePipe)}}</p>
                                </div> -->
                            </div>
                        </div>
                        <!-- <p *ngIf="account.typeData.idNumber"><span>ID number:</span> {{account.typeData.idNumber}}
                        </p>
                        <p><span>Hirring date:</span> {{account.typeData.hiringDate | formatDate}}</p>
                        <p><span>Object:</span> {{account.typeData.object.type}}: {{account.typeData.object.name}}
                        </p>
                        <p *ngIf="account.typeData.directManagers.length"><span>Direct managers:</span>
                            {{directManagerShowData}}</p>
                        <p><span>Employee status:</span> {{account.typeData.employeeStatus?'Active':'Inavtive'}}</p> -->
                    </ng-container>
                    <!-- <ng-container>
                        <h3 class="card-title">{{'customer info'|translatePipe}}</h3>
                        <div class="table-key-value-container">
                            <div class="table-keys">
                                <div class="key-container">
                                    <p>{{'id number'|translatePipe}}</p>
                                </div>
                                <div class=" key-container">
                                    <p>{{'start date'|translatePipe}}</p>
                                </div>
                                <div class="key-container">
                                    <p>{{'sector'|translatePipe}}</p>
                                </div>
                                <div *ngIf="account.typeData[3].identifier.length" class="key-container">
                                    <p>{{'secondery contact'|translatePipe}}</p>
                                </div>
                                <div class=" key-container">
                                    <p>{{'company status'|translatePipe}}</p>
                                </div>
                            </div>
                            <div class="table-values">
                                <div class="value-container">
                                    <p>{{account.typeData[0].fieldValue}}</p>
                                </div>

                                <div class="value-container">
                                    <p>{{account.typeData[1].fieldValue | date:'dd/MM/YYYY'}}</p>
                                </div>


                                <div class="value-container">
                                    <p> {{account.typeData[2].fieldValue.type | translatePipe}}:
                                        {{account.typeData[2].fieldValue.name}}</p>
                                </div>


                                <div *ngIf="account.typeData[3].identifier" class="value-container">
                                    <p> {{account.typeData[3].fieldValue[0].identifier}}</p>
                                </div>

                                <div class="value-container">
                                    <p>{{account.typeData[4].fieldValue?('active'|translatePipe):('inavtive'|translatePipe)}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                    <!-- <ng-container >
                        <h3 class="card-title">{{'candidate info'|translatePipe}}</h3>
                        <div class="table-key-value-container">
                            <div class="table-keys">

                                <div class=" key-container">
                                    <p>{{'hirring date'|translatePipe}}</p>
                                </div>
                                <div class="key-container">
                                    <p>{{'devision'}}</p>
                                </div>
                                <div class=" key-container">
                                    <p>{{'candidate status'|translatePipe}}</p>
                                </div>
                            </div>
                            <div class="table-values">
                                <div class="value-container">
                                    <p>{{account.typeData.hiringDate | formatDate}}</p>
                                </div>
                                <div class="value-container"> -->
                    <!-- <p> {{account.typeData.object.type|translatePipe}}: {{account.typeData.object.name}}
                                    </p> -->
                    <!-- </div>
                                <div class="value-container">
                                    <p>{{account.typeData.candidateStatus?('active'|translatePipe):('inavtive'|translatePipe)}}
                                    </p>
                                </div>
                            </div>
                        </div> -->
                    <!-- <p *ngIf="account.typeData.idNumber"><span>ID number:</span> {{account.typeData.idNumber}}
                        </p>
                        <p><span>Hirring date:</span> {{account.typeData.hiringDate | formatDate}}</p>
                        <p><span>Object:</span> {{account.typeData.object.type}}: {{account.typeData.object.name}}
                        </p>
                        <p *ngIf="account.typeData.directManagers.length"><span>Direct managers:</span>
                            {{directManagerShowData}}</p>
                        <p><span>Employee status:</span> {{account.typeData.employeeStatus?'Active':'Inavtive'}}</p> -->
                    <!-- </ng-container> -->
                    <!-- <ng-container>
                        <div class="spinner-container">
                            <mat-spinner></mat-spinner>
                        </div>
                    </ng-container> -->
                </ng-container>


            </div>
        </div>
    </div>
</div>

<div dirControl class="main-card card mb-3">
    <div class="card-body">
        <h3 class="card-title text-center">{{'recent events'|translatePipe}}</h3>
        <div class="row mb-3" *ngIf="accountEvents.length > 0">
            <div *ngFor="let event of accountEvents?.slice(-5)" class="col-6 col-sm-4 col-lg-2 mb-3">
                <app-portal-event-mini-view [isFromAccount]="true" [event]="event"></app-portal-event-mini-view>
            </div>
        </div>
        <div class="text-center" *ngIf="accountEvents.length === 0">
            <p>{{'No events'|translatePipe}}</p>
        </div>
    </div>
</div>

<ng-template #description let-c="close" let-d="dismiss">
    <div class="modal-header">

        <h4 class="modal-title">
            {{'description'|translatePipe}}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">

        <p>{{account.description}}</p>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Close'|translatePipe}}</button>
    </div>
</ng-template>