import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-label',
  templateUrl: './dropdown-label.component.html',
  styleUrls: ['./dropdown-label.component.scss']
})
export class DropdownLabelComponent implements OnInit {

  constructor() { }
  @Input() count: string
  @Input() label: string
  @Input() name: string
  @Input() options: string[]
  @Input() set value(val: string) {
    this._value = val;
    //console.log(this._value);
    this.valueChange.emit(val);
  }
  @Output() valueChange = new EventEmitter<string>();
  get value() {
    return this._value;
  }
  private _value;


  ngOnInit(): void {
  }

}
