import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import slideVertical from 'src/app/animations/slide-vertical.animation';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'div[table]',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    slideVertical
  ]
})
export class TableComponent implements OnInit, OnChanges {
  @Input() group !: Group;
  @Input() numOfSpace !: number;
  @Output() onSetCurrAction = new EventEmitter<string>();
  @Output() onSelectGroupChild = new EventEmitter<string>();

  children: Group[] = [];
  arrayOfSpace: any[] = [];
  slideSituation = 'out'

  constructor(private organizationalStructureService: OrganizationalStructureService) { }

  ngOnInit(): void {
    this.arrayOfSpace = [...Array(this.numOfSpace).keys()]
    this.updateChildren()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChildren();
  }

  toggleSlideSituation(): void {

    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in';
  }

  async updateChildren() {
    //if (this.children.length === 0 && this.group.children.length > 0) {
      console.log(`updateChildren - called - ${this.group.id} ${this.group.name} ${this.group.children}`);
      const newChildren: Group[] = [];
      this.group?.children.forEach(async (childId) => {
        const child = await (await this.organizationalStructureService.getGroupById(childId).toPromise());
        newChildren.push(child.result);
      });
      this.children = newChildren;
    //}
  }

}
