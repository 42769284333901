import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { OtechI18nService } from "./otech-i18n.service";
import { Subscription } from "rxjs";

@Directive({
  selector: '[text-direction-by-locale]'
})
export class TextDirectionByLocaleDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef, private translateService: OtechI18nService, private renderer: Renderer2) {
  }

  s1!: Subscription;

  ngOnInit() {
    this.setDirection();
    this.s1 = this.translateService.onLocaleChange$.subscribe(_ => {
      //console.log('change'); 
      this.setDirection()
    }
    )
  }

  @HostListener('window:unload')
  ngOnDestroy() {
    this.s1.unsubscribe();
  }

  setDirection() {
    //console.log(this.translateService.locale);
    switch (this.translateService.locale) {
      case 'he': {
        this.renderer.setStyle(this.el, 'text-direction', 'rtl')
        break;
      }
      case 'en': {
        this.renderer.setStyle(this.el, 'text-direction', 'ltr')
        break;
      }
    }
  }
}
