import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReferForm } from 'src/app/models/eums';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalPostService } from 'src/app/services/portal-post.service';

@Component({
  selector: 'app-portal-posts',
  templateUrl: './portal-posts.component.html',
  styleUrls: ['./portal-posts.component.scss']
})
export class PortalPostsComponent implements OnInit {

  files: any=[];
  accountType: any;
  @Input() currType = 'employees';
  @Input()  reset;
  subscription: Subscription[] = []
  posts = [];
  loading = false;
  accounts: any;
 
  imgReload:any;

  newPost = {
    title: '',
    image: '',
    content: ''
  }
  fileData: any = {
    fkid: 1,
    formtype: ReferForm.AccountPosts,
    isAttachment: false
  }

  constructor(
    private portalPostService: PortalPostService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private accountService: PortalAccountsService,
    private fileService: FilesService
  ) { }

  ngOnInit(): void {



    this.route.params.subscribe(param => {
      if (param.type) {
        this.accountType = param.type;
        this.accountService.getAccountTypes().subscribe({
          next: data => {
            this.accounts = data.result;
            this.currType = this.accounts.filter(account => account.id.toString() === this.accountType)[0].name;
          }
        })
     
        this.portalPostService.loadPortalPosts(this.accountType);
        this.subscription.push(
          this.portalPostService.portalPosts$.subscribe(posts => {
            if (Array.isArray(posts)) {
              this.posts = posts;
            } else {
              this.posts = [];
            }
          })
        )
      }
    });
  }


  onAddPost() {
    this.loading = true;
    let newPost = {
      typeId: this.accountType,
      title: this.newPost.title,
      content: this.newPost.content,
      image_url: this.newPost.image
    }

   this.imgReload='';


   if(newPost.content!=''&&newPost.title!=''){   
    this.portalPostService.add(newPost).subscribe({
      next: data => {
        this.loading = false;
        this.newPost = {
          title: '',
          image: '',
          content: ''
        }
        this.portalPostService.loadPortalPosts(this.accountType);
     
        if(this.files.length>0){
         
        let imgData = {
          files: this.files[0],
          fkid: data.result.id,
          formType: ReferForm.AccountPosts,
          isAttachment: false
        }
        this.reset=false;
        this.fileService.uploadFile(imgData).subscribe({
          next: (data: any) => {
            this.files=undefined;
            this.portalPostService.loadPortalPosts(this.accountType);
           this.reset=true;
           this.errorHandler.openToast({ title: 'Success', type: 'success', message: 'Post sent!' })    
          }
          , error: (data: any) => {
          
          }
        })
      
      }
      else{
        this.errorHandler.openToast({ title: 'Success', type: 'success', message: 'Post sent!' })
      }
      },
      error: err => {
        this.loading = false;
        this.errorHandler.openToast({ title: 'ERROR', type: 'error', message: 'Could not add post. Try again later.' })
      }
    })
  }
  else{
    this.loading = false;
    this.errorHandler.openToast({ title: 'ERROR', type: 'error', message: 'Fill all fields' })
  }
  }

  imgDet(det: any) {
    this.files = det;
  }

}
