import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountServerService } from 'src/app/services/account-server.service';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-manage-employees-server',
  templateUrl: './manage-employees-server.component.html',
  styleUrls: ['./manage-employees-server.component.scss']
})
export class ManageEmployeesServerComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  employees = []
  search = ''

  constructor(
    private accountService: AccountServerService,
    private globalVariableService:GlobalVariablesService,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.accounts$.subscribe(accounts => {
        this.updateEmployees(accounts);
      })
    )
    this.accountService.loadAccounts('Employee');
  }

  updateEmployees(employees) {
    this.employees = employees.map(employee => {
      const type = this.globalVariableService.AccountTypes[employee.type]
      return ({ ...employee,type, isCheck: false })
    });
  }

  filterEmployees() {
    return this.employees.filter(employee => (
      employee.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      employee.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  async deleteEmployee(employee) {
    await this.accountService.delete(this.globalVariableService.AccountTypes["Employee"],[employee]).toPromise();
    this.accountService.loadAccounts('Employee');
  }

  getNumOfSelected() {
    return this.employees.reduce((acc, employee) => {
      if (employee.isCheck) acc++
      return acc
    }, 0)
  }

  unselect() {
    this.updateEmployees(this.employees)
  }

  deleteSelected() {
    const selected = this.employees.filter(employee => employee.isCheck)
    //console.log(selected);
    selected.forEach(employee => {
      this.deleteEmployee(employee)
    })

  }

}
