<div dirControl class="main-card card mb-3 account-view">
    <button class="swich-mode" *ngIf="account.id">
        <i (click)="toggleIsEdit()" class="fa fa-fw" aria-hidden="true" title="edit"></i>
    </button>
    <div class="card-body">
        <h3 class="card-title text-center">{{account.type+ ' view'|translatePipe}}</h3>
        <ng-container *ngIf="isEdit; else dataShow">
            <div class="row">
                <div class="col-sm-4 col-lg-3 data">
                    <app-image-picker [isCircle]="true" [label]="'profile image'|translatePipe"
                        [(value)]="account.data.profileImage" [uplDetails]=" imgDet()">
                    </app-image-picker>

                    <app-input-label name="fullname" [label]="'fullname'|translatePipe"
                        [(value)]="account.data.fullName">
                    </app-input-label>
                    <app-input-label name="title" [label]="'title'|translatePipe"
                        [(value)]="account.data.title"></app-input-label>
                    <button (click)="openDescription()" class="btn btn-primary mb-4 col-12">
                        {{'edit description'|translatePipe}}</button>
                </div>
                <div class="col-sm-4 col-lg-4 contact-info">
                    <h3 class="card-title text-center">{{'contact info'|translatePipe}}</h3>

                    <app-members-multy-select [label]="'connect to member' |translatePipe" name="direct-manager"
                        [(values)]="Singlemember" [singleDropdown]="singleDropdown"
                        (singleDpdnValChange)="chooseMember($event)"></app-members-multy-select>


                    <app-input-label name="email" [label]="'email'|translatePipe"
                        [(value)]="account.contactInfo.email"></app-input-label>
                    <app-input-label name="phone" [label]="'phone'|translatePipe"
                        [(value)]="account.contactInfo.phone"></app-input-label>
                    <app-dropdown-label [label]="'gender'|translatePipe" [(value)]="account.contactInfo.gender"
                        [options]="['male','female','other']"></app-dropdown-label>
                    <app-input-label name="address" [label]="'address'|translatePipe"
                        [(value)]="account.contactInfo.address">
                    </app-input-label>
                </div>
                <div class="col-sm-4 col-lg-5 type-info"
                    *ngIf="account && account.typeData && account.typeData.length > 0">
                    <ng-container>
                        <h3 class="card-title text-center">{{account.type + ' info' | translatePipe}}</h3>
                        <ng-container *ngFor="let typeData of account.typeData; let i = index">
                            <app-input-label *ngIf="typeData.fieldType === 4" name="idNumber"
                                [label]="typeData.identifier | translatePipe" [(value)]="typeData.fieldValue">
                            </app-input-label>
                            <app-date-picker *ngIf="typeData.fieldType === 2"
                                [label]="typeData.identifier | translatePipe" [(timestamp)]="typeData.fieldValue">
                            </app-date-picker>
                            <app-group-picker-input *ngIf="typeData.fieldType === 5"
                                [label]="typeData.identifier | translatePipe"
                                (currGroupChange)="onGroupChange($event, typeData)" [currGroup]="typeData.fieldValue">
                            </app-group-picker-input>
                            <app-members-multy-select *ngIf="typeData.fieldType === 6"
                                [label]="typeData.identifier |translatePipe" name="direct-manager"
                                [(values)]="typeData.fieldValue"></app-members-multy-select>
                            <div class="flex gap-10 mb-4 mt-4" *ngIf="typeData.fieldType === 3">
                                <div class="">
                                    {{typeData.identifier | translatePipe}}:
                                </div>
                                <div class="">
                                    <bSwitch [switch-animate]="false" switch-size="mini" name="side-select"
                                        [(ngModel)]="typeData.fieldValue">
                                    </bSwitch>
                                </div>
                            </div>
                            <app-dropdown-label *ngIf="typeData.fieldType === 7" [(value)]="typeData.fieldValue"
                                [label]="account.type +' status'|translatePipe"
                                [options]="typeData.answerOption.split(',')"></app-dropdown-label>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <button (click)="saveAccount()" class="btn btn-success">{{'save'|translatePipe}}</button>
        </ng-container>

        <ng-template #dataShow>
            <div class="row data-show">
                <div class="col-12 col-lg-4 data text-center mb-4">
                    <img *ngIf="imageSource!=''" class="mb-4" [src]="imageSource" alt="account image">
                    <p class="account-full-name" style="font-size: large;">{{account.data.fullName}}</p>
                    <div class="title-and-description">
                        <p>{{account.data.title}}</p>
                        <button (click)="openDescription()" class="btn btn-info">
                            <i class="pe-7s-info"></i>
                        </button>
                    </div>

                    <div class="btns-container">
                        <a *ngIf="account.contactInfo.phone" class="m-1 btn btn-success"
                            href="{{'tel:'+account.contactInfo.phone}}">
                            <i class="fa fa-fw"></i>
                        </a>
                        <a *ngIf="account.contactInfo.email" class="m-1 btn btn-success"
                            href="{{'mailTo:'+account.contactInfo.email}}">
                            <i class="fa fa-fw"></i>
                        </a>
                        <a [href]="'https://wa.me/'+account.contactInfo.phone" *ngIf="account.contactInfo.phone"
                            class="m-1 btn btn-success">
                            <i class="fa fa-fw"></i>
                        </a>
                        <!-- <button *ngIf="account.contactInfo.email" class="btn btn-success">Email</button> -->
                        <!-- <a routerLink="/chat" *ngIf="account.contactInfo.member.id" class="m-1 btn btn-success">
                            <i class="pe-7s-chat"></i>
                        </a> -->
                    </div>
                </div>
                <div class="col-12 col-lg-4 contact-info">
                    <h3 class="card-title text-center">{{'contact info'|translatePipe}}</h3>
                    <div class="table-key-value-container mb-4">
                        <div class="table-keys">
                            <div *ngIf="memberIdentidier" class="icon-text-container key-container">
                                <i class="pe-7s-users"></i>
                                <p> {{'user'|translatePipe}}</p>
                            </div>
                            <div *ngIf="account.contactInfo.email" class="icon-text-container key-container">
                                <i class="pe-7s-mail"></i>
                                <p>{{'email'|translatePipe}}</p>
                            </div>
                            <div *ngIf="account.contactInfo.phone" class="icon-text-container key-container">
                                <!-- <i class="fa fa-fw"></i> -->
                                <i class="pe-7s-call"></i>
                                <p>{{'phone'|translatePipe}}</p>
                            </div>
                            <div class="icon-text-container key-container">
                                <ng-container [ngSwitch]="account.contactInfo.gender">
                                    <i *ngSwitchCase="'male'" class="pe-7s-male"></i>
                                    <i *ngSwitchCase="'female'" class="pe-7s-female"></i>
                                    <i *ngSwitchDefault class="fa fa-fw"></i>
                                </ng-container>
                                <p>{{'gender'|translatePipe}}</p>
                            </div>
                            <div *ngIf="account.contactInfo.address" class="icon-text-container key-container">
                                <i class="pe-7s-map-marker"></i>
                                <p>{{'home address'|translatePipe}}</p>
                            </div>
                        </div>
                        <div class="table-values">
                            <div *ngIf="memberIdentidier" class="value-container">
                                <p>{{memberIdentidier}}
                            </div>

                            <div *ngIf="account.contactInfo.email" class="value-container">
                                <p>{{account.contactInfo.email}}</p>
                            </div>


                            <div *ngIf="account.contactInfo.phone" class="value-container">
                                <p> {{account.contactInfo.phone}}</p>
                            </div>


                            <div class="value-container">
                                <p> {{account.contactInfo.gender|translatePipe}}</p>
                            </div>

                            <div *ngIf="account.contactInfo.address" class="value-container">
                                <p>{{account.contactInfo.address}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 type-info"
                    *ngIf="account && account.typeData && account.typeData.length > 0">
                    <ng-container>
                        <h3 class="card-title text-center">{{account.type +' info' | translatePipe}}</h3>
                        <div class="table-key-value-container mb-4">
                            <div class="table-keys">
                                <div *ngFor="let typeData of account.typeData; let i = index">
                                    <div class="key-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null'">
                                        <p>{{typeData.identifier|translatePipe}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="table-values">
                                <div *ngFor="let typeData of account.typeData; let i = index">
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && (typeData.fieldType === 4 || typeData.fieldType === 7)">
                                        <p>{{typeData.fieldValue}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 2">
                                        <p>{{typeData.fieldValue | date:'dd/MM/YYYY'}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 5">
                                        <p> {{typeData.fieldValue.type | translatePipe}}:
                                            {{typeData.fieldValue.name}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 6">
                                        <p> {{directManagerShowData}}</p>
                                    </div>
                                    <div class="value-container"
                                        *ngIf="typeData.fieldValue && typeData.fieldValue !== 'null' && typeData.fieldType === 3">
                                        <p>{{typeData.fieldValue === 'true' ?('active' | translatePipe |
                                            titlecase):('inactive'|translatePipe | titlecase)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

        </ng-template>
    </div>
</div>

<ng-container *ngIf="account.id" class="events-container">
    <div dirControl class="main-card card mb-3">
        <div class="card-body">
            <h3 class="card-title text-center">{{'recent events'|translatePipe}}</h3>
            <div class="row mb-3">
                <div class="col-6 col-sm-4 col-lg-2 mb-3">
                    <app-add-event-mini-view [currAccountId]="account.id"></app-add-event-mini-view>
                </div>
                <div *ngFor="let event of accountEvents.slice(-5)" class="col-6 col-sm-4 col-lg-2 mb-3">
                    <app-portal-event-mini-view [event]="event" [id]="id"
                        (deleted)="eventDeleted($event)"></app-portal-event-mini-view>
                </div>

            </div>
            <a *ngIf="accountEvents.length>5" [routerLink]="'/portal-event-managment/'+account.id"
                class="see-more btn btn-primary">
                {{'see more'|translatePipe}}
            </a>

        </div>



    </div>

</ng-container>

<ng-template #description let-c="close" let-d="dismiss">
    <div class="modal-header">

        <h4 class="modal-title">
            {{
            (!account.id) || isEdit?
            ('edit description'|translatePipe):
            ('description'|translatePipe)
            }}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click');DescriptionSave('close')">
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="(!account.id) || isEdit; else descriptionText">
            <app-textarea-label [(value)]="accountDescription" label="{{'description'|translatePipe}}"
                name="description">
            </app-textarea-label>
        </ng-container>
        <ng-template #descriptionText>
            <p>{{account.description}}</p>
        </ng-template>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            (click)="c('Close click');DescriptionSave('close')">{{'Close'|translatePipe}}</button>
        <button type="button" class="btn btn-success"
            (click)="c('Close click'); DescriptionSave('save')">{{'Save'|translatePipe}}</button>
    </div>
</ng-template>