import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { MemberServer } from 'src/app/interfaces/member-server';
import { MemberServerService } from 'src/app/services/member-server.service';

@Component({
  selector: 'app-manage-members-server',
  templateUrl: './manage-members-server.component.html',
  styleUrls: ['./manage-members-server.component.scss']
})
export class ManageMembersServerComponent implements OnInit, OnDestroy {

  @ViewChild('deleteMember') deleteMemberModal!: any;
  @ViewChild('changeStatusMember') changeStatusMemberModal!: any;



  options = ['suspension', 'freeze', 'remove', 'send messege', 'mail']

  subscriotion: Subscription[] = []
  members: any[] = []
  filterMembers$ = new BehaviorSubject<any[]>([]);
  search = '';
  currAction = 'remove'
  // setsToList = []
  selectedMembersIds: number[] = [];
  message = ''
  messageTitle = ''
  askDeleteMemberId = 0
  askChangeStatusData = null


  constructor(
    private membersService: MemberServerService,
    // private setService: SetsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.membersService.loadMembers();
    this.subscriotion.push(
      this.membersService.members$.subscribe(members => {
        this.updateMembers(members);
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriotion.forEach(sub => sub.unsubscribe())
  }

  updateMembers(members) {
    this.members = members.map(member => ({ ...member, isCheck: false }));
    this.updateFilterMembers();
  }

  updateFilterMembers() {
    const filterMembers = this.members.filter(member => (
      member.identifier.toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(member.memberSince).includes(this.search)
    ))
    this.filterMembers$.next(filterMembers);
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  getNumOfSelected() {
    return this.members.reduce((acc, member) => {
      if (member.isCheck) acc++
      return acc
    }, 0)
  }

  async removeMember(cb) {
    if (cb) cb();
    await this.membersService.remove(this.askDeleteMemberId).toPromise();
    this.membersService.loadMembers();
    // this.membersService.removeMember(id).subscribe(_ => { });
  }

  changeMemberStatus(data: ChangeMemberStatusData) {
    this.askChangeStatusData = data
    this.modalService.open(this.changeStatusMemberModal.content).result.then((result) => {
    }, (reason) => {
    });
    // const { id, status } = data;
    // this.membersService.changeMemberStatus(id, status).subscribe(_ => { });
  }

  openSureModal(memberId) {
    this.askDeleteMemberId = memberId;
    this.modalService.open(this.deleteMemberModal.content).result.then((result) => {
    }, (reason) => {
    });
  }

  async onChangeStatus(cb) {
    if(cb)cb()
    const { id, status } = this.askChangeStatusData
    //console.log(this.askChangeStatusData);
    
    await this.membersService.updateStatus(id, status).toPromise();
    this.membersService.loadMembers();
  }


  searchChange(value: string) {
    this.search = value
    this.updateFilterMembers();
  }

  async onSelectedMembersAction() {
    const selectedMembers = this.members.filter(member => member.isCheck);
    const selectedMemberIds = selectedMembers.map(member => member.id);
    switch (this.currAction) {
      case 'remove':
        await this.membersService.removeMembers(selectedMemberIds).toPromise()
        break;
      case 'suspension':
        await this.membersService.updateMembersStatus(selectedMemberIds,'suspenision').toPromise()
        break;
    
      default:
        break;
    }
    this.membersService.loadMembers();
    // if (this.currAction === 'remove') {
    //   selectedMemberIds.forEach(id => {
    //     this.removeMember(id);
    //   })
    // }
    // else if (this.currAction === 'send messege') {
    //   this.selectedMembersIds = selectedMemberIds;
    //   this.modal.open(this.messageInfo, { size: 'sm' });
    // }
    // else {
    //   selectedMemberIds.forEach(id => {
    //     this.changeMemberStatus({ id: id, status: this.currAction });
    //   })
    // }
    // this.members = this.members.map(member => ({ ...member, isCheck: false }));

  }

}
