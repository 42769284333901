import { ChangeDetectorRef, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { OtechI18nService } from './otech-i18n.service';

@Pipe({
  name: 'translatePipe',
  pure: false
})

export class TranslatePipe implements OnInit, PipeTransform, OnDestroy {
  constructor(private translateService: OtechI18nService, private cdRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.s = this.translateService.onLocaleChange$.subscribe(_ => {
      //console.log('hap');
      this.cdRef.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.s?.unsubscribe();
  }
  s!: Subscription;
  transform(value: string, ...args: unknown[]): string {
    return this.translateService.getTranslated(value);
  }

}
