<div [ngClass]="{rtl:translateService.locale==='he'}"
  [class]="'sidebar-b2c app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme"
  (mouseover)="sidebarHoverMouseIn()" (mouseout)="sidebarHoverMouseOut()">
  <div dirControl class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>

  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: calc(100vh - 60px); overflow-y: auto;">
        <div class="sidebar-container">
          <div class="v-sidebar-menu vsm-default">
            <div class="vsm-list mt-4">
              <a dirControl routerLink="/profile" routerLinkActive="active-item" class="vsm-link user"
                style="height: 60px; padding-left: 10px;width:100%">
                <div class="d-flex">
                  <span class="m-0">
                    <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                      <span dirControl
                        style="width:40px;height:40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;"
                        [ngStyle]="{
                        'backgroundColor' : color  
                      }" *ngIf="imageSource==''">
                        <p style="font-size: 30px;display:flex;margin-top: 10px;color: white;">{{currentMem}}</p>
                      </span>
                      <img *ngIf="imageSource!=''"
                        style="width:40px;height:40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;"
                        [src]="imageSource" alt="">
                    </div>
                  </span>
                  <div #logged class="d-flex w-100"
                    style="flex-direction: column;align-items: baseline;justify-content: center;">
                    <div class="vsm-title  mt-1 w-100">
                      <p class="p mb-2" [ngStyle]="{'font-size':fontSize}"><b #logName>{{loggedInUser.name | ellipsis:
                          17}}</b> </p>
                    </div>
                    <div class="orgName w-100">
                      <div class="vsm-title  " style="line-height:5px;">{{loggedInUser.organizationName}}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="divider"></div>
              <div class="vsm-item">
                <a routerLink="/dashboard/{{user.organizationId}}" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-home"></i>
                  <span class="vsm-title">{{'home'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/chat" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-chat"></i>
                  <span class="vsm-title">{{'chat'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item" title="{{'Coming soon'|translatePipe}}">
                <a routerLink="/calendar" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-date"></i>
                  <span class="vsm-title">{{'calendar'|translatePipe}}</span>
                </a>
              </div>
              <!-- <div class="vsm-item">
                <a routerLink="/sets" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-menu"></i>
                  <span class="vsm-title">{{'sets'|translatePipe}}</span>
                </a>
<<<<<<< src/app/Layout/Components/sidebar-b2c/sidebar-b2c.component.html
              </div> -->
              <div class="vsm-item" title="{{'Coming soon'|translatePipe}}">
                <a routerLink="/cards" routerLinkActive="active-item" class="vsm-link" disabled="true">
                  <i class="vsm-icon pe-7s-id"></i>
                  <span class="vsm-title">{{'cards'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/tasks" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-check"></i>
                  <span class="vsm-title">{{'tasks'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/groups" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-network"></i>
                  <span class="vsm-title">{{'groups'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/club-social-network" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-comment"></i>
                  <span class="vsm-title">{{'social'|translatePipe}}</span>
                </a>
              </div>
              <div class="divider" *ngIf="userAccTypes && userAccTypes.length > 0"></div>
              <!-- <div class="vsm-item">
                <a routerLink="/organization-ecommerce" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-cart"></i>
                  <span class="vsm-title">{{'ecommerce'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/organization-landing-page" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-plane"></i>
                  <span class="vsm-title">{{'landing page'|translatePipe}}</span>
                </a>
              </div> -->

              <div *ngFor="let account of userAccTypes">
                <div class="vsm-item">
                  <a routerLink="/account-directory/{{account.id}}" routerLinkActive="active-item" class="vsm-link">
                    <!-- <i class="vsm-icon pe-7s-user"></i> -->
                    <i class="vsm-icon fa-solid">{{account.imageURL.D}}</i>
                    <span class="vsm-title">{{account.type + ' directory'|translatePipe}}</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="/account-portal/{{account.id}}" routerLinkActive="active-item" class="vsm-link">
                    <!-- <i class="vsm-icon pe-7s-display1"></i> -->
                    <i class="vsm-icon fa-solid"> {{account.imageURL.P}}</i>
                    <span class="vsm-title">{{account.type + ' portal'|translatePipe}}</span>
                  </a>
                </div>
              </div>

              <!-- <div class="vsm-item" [ngSwitch]="loggedUser">

                <div class="vsm-item">
                  <a routerLink="/account-directory" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">{{'Employee Account'|translatePipe}}</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLink="/employee-portal" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-display1"></i>
                    <span class="vsm-title">{{'Employee Portal'|translatePipe}}</span>
                  </a>
                </div>

                <div class="vsm-item" *ngSwitchCase="'employee'">
                  <div class="vsm-item">
                    <a routerLink="/account-directory" routerLinkActive="active-item" class="vsm-link">
                      <i class="vsm-icon pe-7s-user"></i>
                      <span class="vsm-title">{{'Employee Account'|translatePipe}}</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/employee-portal" routerLinkActive="active-item" class="vsm-link">
                      <i class="vsm-icon pe-7s-display1"></i>
                      <span class="vsm-title">{{'Employee Portal'|translatePipe}}</span>
                    </a>
                  </div>
                </div>

                <div class="vsm-item" *ngSwitchCase="'customer'">

                  <div class="vsm-item">
                    <a routerLink="/customer-directory" routerLinkActive="active-item" class="vsm-link">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">{{'Customer Account'|translatePipe}}</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/customer-portal" routerLinkActive="active-item" class="vsm-link">
                      <i class="vsm-icon pe-7s-display1"></i>
                      <span class="vsm-title">{{'Customer Portal'|translatePipe}}</span>
                    </a>
                  </div>

                </div>


                <div class="vsm-item" *ngSwitchCase="'candidate'">
                  <div class="vsm-item">                              
                    <a routerLink="/candidate-directory" routerLinkActive="active-item" class="vsm-link">
                      <i class="vsm-icon pe-7s-add-user"></i>
                      <span class="vsm-title">{{'Candidate Account'|translatePipe}}</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/candidate-portal" routerLinkActive="active-item" class="vsm-link">
                      <i class="vsm-icon pe-7s-display1"></i>
                      <span class="vsm-title">{{'Candidate Portal'|translatePipe}}</span>
                    </a>
                  </div>
                </div>
              </div> -->

              <div class="divider"></div>
              <div class="vsm-item">
                <a routerLink="/user-control-pannel" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-config"></i>
                  <span class="vsm-title">{{'userControlPanel'|translatePipe}}</span>
                </a>
              </div>

              <div class="vsm-item">
                <a routerLink="/about" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-info"></i>
                  <span class="vsm-title">{{'about'|translatePipe}}</span>
                </a>
              </div>

            </div>
          </div>
          <div class="v-sidebar-menu vsm-default">
            <div class="vsm-list mt-4">
              <!-- <div class="vsm-item">
                <a routerLink="/about" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-info"></i>
                  <span class="vsm-title">{{'about'|translatePipe}}</span>
                </a>
              </div> -->
              <!-- <div class="vsm-item">
                <a routerLink="/dashboard/xgDPr3O" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-global"></i>
                  <span class="vsm-title">{{'language'|translatePipe}}</span>
                </a>
              </div> -->
            </div>
          </div>
          <div class="d-flex pe-2 ps-2" *ngIf="globals.sidebarHover == true">
            <p>{{'version'|translatePipe}} 1.0</p>
            <a href="http://www.beta.flexyc.com/" class="ms-auto">
              <p>www.beta.flexyc.com</p>
            </a>
          </div>
          <div class="d-flex pe-2 ps-2" *ngIf="globals.sidebarHover == false">
            <p>{{'version'|translatePipe}} 1.0</p>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>