import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { globalComponent } from '../global-component.service';
import { AppConfig } from './app.config';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl = AppConfig.settings.apiServer.baseUrl;
  constructor(private http: HttpClient) { }

  getNotifications(data: any) {
    let params = new HttpParams();
    params = params.append("memid", data.memid);
    params = params.append("pageNo", data.pageNo);
    params = params.append("pageLimit", data.pageLimit);
    return this.http.get<any>(`${this.baseUrl}${globalComponent.getNotification}`, { params: params });
  }

  PostNotification(data) {
    return this.http.post<any>(`${this.baseUrl}${globalComponent.sendNotification}`, {
      title: data.title,
      description: data.description,
      members: data.members
    });
  }

  markAsRead(mems: any) {
    return this.http.patch<any>(`${this.baseUrl}${globalComponent.sendNotification}`, mems);
  }

}
