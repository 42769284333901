import { E } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-organizational-structure',
  templateUrl: './organizational-structure.component.html',
  styleUrls: ['./organizational-structure.component.scss']

})
export class OrganizationalStructureComponent implements OnInit {
  @ViewChild('modal') modal!: any;

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private router: Router,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService,
    public translateService: OtechI18nService,
  ) { }

  organizationalStructure: Group[] = [this.organizationalStructureService.emptyGroup]; // contain the list of the organization structure
  currAction = ''; // contain the action that will do when choose group
  isViewA = true; // used to toggle between view a and view b (view a = tree view view b = table view)
  askedDeltedChildId = ''; // saved the id that will deleted if the user accept delete on the modal
  subscriotion!: Subscription
  idString: string = '';
  elementType: string = '';
  elementName: string = '';
  info3: any = '';
  info2: any = '';
  spinnerToggleCounter = 0;
  getInfo3mem: any = [];
  ngOnInit(): void {
    this.subscriotion = this.organizationalStructureService.organizationalStructure$.subscribe((organizationalStructure: Group[]) => {
      if (organizationalStructure.length) {
        this.organizationalStructure = organizationalStructure; // this subscription will update the organization every change on the list
      }
    })
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  onSetCurrAction(action: string): void { // set action
    if (action === this.currAction) this.currAction = '';
    else this.currAction = action
  }

  async onSelectGroupChild(id: string) {
    this.idString = id
    // when you clicked on group on the tree / choose group and action on the table this function will decide what to do
    switch (this.currAction) {
      case 'add': // add child to the choosen group and navigate to edit him
        const newChild: any = await (await this.organizationalStructureService.addGroup(id)).toPromise();

        this.router.navigate(['editor', newChild.result.uniqueId]);
        break;
      case 'edit': // navigate to edit choosen group
        const editGroup = await this.organizationalStructureService.getGroupById(id).toPromise();
        if (editGroup.result.type === 'organization') this.router.navigate(['system_settings']);
        else this.router.navigate(['editor', id]);
        break;
      case 'delete': // saved the id in property and open are you sure delete organization
        this.askedDeltedChildId = id
        this.open()
        break;
      case 'members': // navigate to group manege members

        const groupToMember = await this.organizationalStructureService.getGroupById(id).toPromise()
        if (groupToMember.result.type === 'organization') this.router.navigate(['manage-members']);
        else this.router.navigate(['editor', id, 'members']);
        break;
      default:
        break;
    }
  }



  toggleView() {
    this.isViewA = !this.isViewA
  }

  showSpinner(val: boolean) {
    if (val) {
      this.spinnerToggleCounter++;
      if (!this.ngxService.hasRunningTask(true, "loader-01")) {
        this.ngxService.startLoader("loader-01");
      }
    }
    else {
      this.spinnerToggleCounter--;
      if (this.ngxService.hasRunningTask(true, "loader-01") && this.spinnerToggleCounter <= 0) {
        this.ngxService.stopLoader("loader-01");
      }
    }
  }

  // onCloseYouSureDeleteModal() { 
  //   this.askedDeltedChildId = '';
  // }

  async onDeleteOrganizationalStructureChild(cb: Function) {
    // this function will run when the user accapted delete choosen delete on the modal
    // she get close modal function to close the modal after delete the group
    await (await this.organizationalStructureService.removeGroup(this.askedDeltedChildId)).toPromise();
    await this.organizationalStructureService.loadOrganizationalStructure();
    this.askedDeltedChildId = ''
    cb('Close click')
  }

  open() { // open modal run from selecting group with delete action
    this.modalService.open(this.modal.content).result.then((result) => {
    }, (reason) => {
    });

  }
  dataSendToModal() {
    if (this.translateService.locale === 'en') {
      var info: any = {
        info1: " Do you want to delete the *" + this.elementType + '* *' + '"' + this.elementName + '"' + "* ?",
      }
      return info;
    }
    else if (this.translateService.locale === 'he') {
      var info: any = {
        info1: "האם אתה רוצה למחוק את *" + this.elementType + '* *' + '"' + this.elementName + '"' + "*?",
      }
      return info;
    }

  }
  getSelectedDataById() {
    if (this.translateService.locale === 'en') {
      this.organizationalStructureService.getGroupById(this.idString).subscribe((data: any) => {
        this.elementType = data.result.type;
        this.elementName = data.result.name
        if (data.result.children?.length > 0) {
          this.info2 = "All groups and sub-groups under this *" + this.elementName + "* will be permanently deleted.";
        }
        else {
          this.info2 = '';
        }
        if (data.result.members?.length > 0) {
          this.info3 = "All members will automatically leave their groups under this *" + this.elementName + "*";
        }
        else {
          if (data.result.children?.length) {
            this.getInfo3mem = [];
            for (let l of data.result.children) {
              this.organizationalStructureService.getGroupById(l).subscribe((data: any) => {
                if (data.result.members?.length > 0) {
                  for (let m of data.result.members) {
                    this.getInfo3mem.push(m);
                  }
                  this.Info3Message()
                }
                else {
                  this.Info3Message()
                }
              })
            }
          }
          else {
            this.info3 = '';
          }
        }
      })
    }
    else if (this.translateService.locale === 'he') {
      this.organizationalStructureService.getGroupById(this.idString).subscribe((data: any) => {
        this.elementType = data.result.type;
        this.elementName = data.result.name
        if (data.result.children?.length > 0) {
          this.info2 = "כל הקבוצות ותתי הקבוצות תחת זה.  *" + this.elementName + "* יימחקו לצמיתות";
        }
        else {
          this.info2 = '';
        }
        if (data.result.members?.length > 0) {
          this.info3 = "כל החברים יעזבו אוטומטית את הקבוצות שלהם תחת זה *" + this.elementName + "*";
        }
        else {
          if (data.result.children?.length) {
            this.getInfo3mem = [];
            for (let l of data.result.children) {
              this.organizationalStructureService.getGroupById(l).subscribe((data: any) => {
                if (data.result.members?.length > 0) {
                  for (let m of data.result.members) {
                    this.getInfo3mem.push(m);
                  }
                  this.Info3Message()
                }
                else {
                  this.Info3Message()
                }
              })
            }
          }
          else {
            this.info3 = '';
          }
        }
      })
    }

  }

  Info3Message() {
    if (this.translateService.locale === 'en') {
      if (this.getInfo3mem.length) {
        this.info3 = "All members will automatically leave their groups under this *" + this.elementName + "*";
      }
      else {
        this.info3 = ''
      }
    }
    else if (this.translateService.locale === 'he') {
      if (this.getInfo3mem.length) {
        this.info3 = "כל החברים יעזבו אוטומטית את הקבוצות שלהם תחת זה *" + this.elementName + "*";
      }
      else {
        this.info3 = ''
      }
    }

  }
}
