<div class="view-b">
    <!-- <app-control-buttons-tree [action]="action" (onSetCurrAction)="onSetCurrAction.emit($event)">
    </app-control-buttons-tree> -->

    <div class="flipped">
        <div class="tree-container">
            <ul class="tree">
                <li tree [isShowButtons]="isShowButtons" (onSetCurrAction)="onSetCurrAction.emit($event)" [group]="group" [action]="action" 
                (onSelectChild)="onSelectChild.emit($event)" (toggleSpinner)="showSpinner($event)"></li>
            </ul>
        </div>
    </div>
</div>


