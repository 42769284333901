import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit {
  imageSource: any;
  url: any;
  @Output() ImgDataOtp = new EventEmitter<any>();
  @Input() uplDetails: any;
  @Input() disabled?= false;
  @Input() isCircle?= false;
  @Input() label?= 'logo'
  @Input() delIdfrmParentComp: any;

  //
  @Input() resetImg: boolean = false;
  //public club profile change
  @Output() ImgContent = new EventEmitter<any>();


  @Input() set value(val: string) {
    this._value = val;
    this.imageSource = val
    this.valueChange.emit(val);
  }
  @Output() valueChange = new EventEmitter<string>();
  get value() {
    return this._value;
  }
  private _value;
  isLoader = false;
  delId: any;

  //For image input field reset
  @ViewChild('inputFile') myInputVariable: ElementRef;
  reset() {
    this.myInputVariable.nativeElement.value = '';
    this.imageSource = '';
    this.ImgDataOtp.emit('')
  }


  constructor(
    public domSanitizer: DomSanitizer,
    private cludinaryService: CloudinaryService,
    private fileService: FilesService,
    private error: ErrorHandlerService
  ) { }

  ngOnInit(): void {


    //To get if any file uploaded in database  
    if (this.uplDetails != undefined) {
      var data: any = {
        fkId: this.uplDetails.fkid,
        formType: this.uplDetails.formtype
      }

      if (data != undefined) {
        this.fileService.getFileFkid(data).subscribe({
          next: (data: any) => {
            //There is no file file uploaded in the given details,this condtion  restrict  from get file Api call.         
            if (data.result === 'InvalidDetailsToRetrieveTheFile') {
              return;
            }
            else {

              var a = data.result;
              this.delId = a[0].id
              this.fileService.getFilebyId(a[0].id).subscribe((data: any) => {
                if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
                  if (data.result.isAttachment == false) {
                    this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
                    this.ImgContent.emit(this.imageSource)

                  }
                }
              })
            }

          }, error: (data: any) => {

          }
        })
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    const value: any = changes['resetImg'];
    if (value != undefined) {
      if (value.currentValue == true) {
        this.reset();
      }
    }


  }

  getImg() {

    if (this.disabled) {

      return 'assets/images/upload-img.png'
    }
    else {

      return this.value || 'assets/images/upload-img.png'
    }

  }

  async uploadImg(ev: any) {
    this.isLoader = true;
    // try {
    //   const newImg = await this.cludinaryService.uploadImg(ev);
    //   this.value = newImg
    //   this.isLoader = false
    // } catch (err) {
    // }

    //This condition for image preview.
    if (ev.target.files) {
      this.isLoader = false;
      this.resetImg = false;
      var reader = new FileReader;
      reader.readAsDataURL(ev.target.files[0]);
      reader.onload = (event: any) => {
        this.imageSource = event.target.result;

      }
      //Emit choosen file because of some pages we not able to get Fkid.
      this.ImgDataOtp.emit(ev.target.files)
    }
    //////
    if (this.uplDetails != undefined && this.uplDetails.isAttachment != undefined) {
      var fileDet = ev.target.files;
      var Fdata: any = {
        files: fileDet[0],
        fkid: this.uplDetails.fkid,
        formType: this.uplDetails.formtype,
        isAttachment: this.uplDetails.isAttachment,
      }

      this.isLoader = true

      this.fileService.uploadFile(Fdata).subscribe({
        next: (data: any) => {
          this.isLoader = false
          this.error.openToast({ title: 'Success', type: 'success', message: 'Updated!' })
          reader.onload = (event: any) => {
            this.imageSource = event.target.result;

          }
          this.ImgContent.emit(this.imageSource);
        }
        , error: (data: any) => {
          this.isLoader = false;
        }
      })
    }
    // this.value = newImg
    this.isLoader = false;
  }

  delImg() {
    if (this.delId != undefined) {
      this.fileService.deleteFile(this.delId).subscribe((data: any) => {
        this.imageSource = '';
        this.ImgContent.emit('');
        this.delId=undefined;
        this.error.openToast({ title: 'Success', type: 'success', message: 'Removed successfully!' })
      })
    }
    else if (this.delIdfrmParentComp != undefined) {
      this.fileService.deleteFile(this.delIdfrmParentComp).subscribe((data: any) => {
        this.imageSource = '';
        this.value = undefined;
        this.error.openToast({ title: 'Success', type: 'success', message: 'Removed successfully!' })
      })
    }

    else {
      if (this.uplDetails != undefined) {
        var data: any = {
          fkId: this.uplDetails.fkid,
          formType: this.uplDetails.formtype
        }

        if (data != undefined) {
          this.fileService.getFileFkid(data).subscribe({
            next: (data: any) => {
              if (data.result === 'InvalidDetailsToRetrieveTheFile') {
                return;
              }
              var a = data.result;
              this.delId = a[0].id;
              this.fileService.deleteFile(this.delId).subscribe((data: any) => {
                this.imageSource = '';
                this.ImgContent.emit('');
                this.delId=undefined;
                this.error.openToast({ title: 'Success', type: 'success', message: 'Removed successfully!' })
              })
            }, error: (data: any) => {

            }
          })
        }
      }
    }
  }
}
