<!-- <div class="main-card card">
    <div class="card-body"> -->
        <!-- <div class="tabs-btns mb-4">
            <button *ngFor="let type of postsTypes" [class.curr]="currType===type" (click)="currType= type">
                {{type| titleCase}}
            </button>
        </div> -->

        <div dirControl class="main-card card mb-2">
            <div class="card-body">
                <div class="new-post">
                    <div class="row">
                        <div class="col-sm-8 col-md-9 col-xl-10">
                            <app-input-label [(value)]="newPost.title" [label]="'title'|translatePipe" name="new-post-title"></app-input-label>
                            <app-textarea-label [(value)]="newPost.content" [label]="'content'|translatePipe" name="new-post-content">
                            </app-textarea-label>
                        </div>
                        <div class="col-sm-4 col-md-3 col-xl-2">
                            <app-image-picker [(value)]="newPost.image" [label]="'image'|translatePipe"  [resetImg]="reset"  (ImgDataOtp)="imgDet($event)"></app-image-picker>
                        </div>
                    </div>
                    <button (click)="onAddPost()"  class="btn btn-success" >{{'add post to'|translatePipe}} {{currType +' portal'|translatePipe |lowercase}} 
                        <span  class="spinner-border" *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;margin-bottom: -5px;"></span>
                    </button>
                </div>
            </div>
        </div>

        <div dirControl class="posts mb-3"   style="height: 500px;overflow: scroll;">
            <ng-container *ngFor="let post of posts">
                <app-post-view [isEditable]="true" [post]="post"  ></app-post-view>
            </ng-container>

        </div>


    <!-- </div>
</div> -->