<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'event view'|translatePipe}}</h3>

        <div class="card-body">
            <ul ngbNav #nav1="ngbNav" class="nav-tabs">
                <li ngbNavItem>
                    <a ngbNavLink><span>{{'info'|translatePipe}}</span></a>
                    <ng-template ngbNavContent>
                        <form class="mb-3 mt-4">
                            <div class="row">
                                <div class="col-12 col-lg-9 col-md-7 col-sm-6">
                                    <app-input-label [label]="'title'|translatePipe" type="text" [(value)]="events.name">
                                    </app-input-label>

                                    <app-group-picker-input name="group" [label]="'group'|translatePipe"  [options]="groupOptions"
                                        [(currGroup)]="events.eventNode">
                                    </app-group-picker-input>

                                    <div class="mb-3">
                                        <app-date-picker [label]="'date'|translatePipe"  [(timestamp)]="events.eventDate">
                                        </app-date-picker>
                                    </div>

                                    <div class="">
                                        <label class="" for="time">{{'timeLocal'|translatePipe}}:</label>
                                        <div class="">
                                            <ngb-timepicker name="time" id="time" [(ngModel)]="events.time" (ngModelChange)="onTimeChange($event)">
                                            </ngb-timepicker>
                                        </div>
                                    </div>

                                    <div class="flex gap-10">
                                        <div class="flex-1">
                                            <app-input-label name="duration" [label]="'duration'|translatePipe" type="number"
                                                [(value)]="events.length.amount">
                                            </app-input-label>
                                        </div>
                                        <div class="flex-1">
                                            <app-dropdown-label name="units" [label]="'units'|translatePipe"
                                                [(value)]="events.length.unit" [options]="unitsOptions">
                                            </app-dropdown-label>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <app-textarea-label name="description" [label]="'description'|translatePipe"
                                            [(value)]="events.content">
                                        </app-textarea-label>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3 col-md-5 col-sm-6">
                                    <app-dropdown-label [(value)]="events.canPlaceImageAbove" [label]="'image location'|translatePipe"
                                        name="location" [options]="locationsOptions"></app-dropdown-label>
                                    <app-image-picker [label]="'event image'|translatePipe" [(value)]="eventImage" [uplDetails]="imgDet()" *ngIf="events.id">
                                    </app-image-picker>
                                </div>

                            </div>
                        </form>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>{{'invitations'|translatePipe}}</a>
                    <ng-template ngbNavContent>
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <app-dropdown-input [label]="'member'|translatePipe" [(text)]="searchMember"
                                        [dropDownOpions]="searchOptions" (chooseOption)="addMemberToMemberList($event)">
                                    </app-dropdown-input>
                                </div>
                                <div class="divider"></div>
                                <div class="member-list">
                                    <div *ngFor="let member of events.members" class="member-container">
                                        <span>{{member.identifier}}</span>
                                        <button (click)="removeMemberFromMemberList(member.inviteEventMemberId)"
                                            class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only">
                                            <i class="fa fa-fw" aria-hidden="true" title="members"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="search-container flexyc-input mb-4">
                                    <label for="guest" [class.input-empty]="!input.value && !isInputFocus">{{'guest email'|translatePipe}}</label>
                                    <form>
                                        <input #input (focus)="isInputFocus=true" (blur)="isInputFocus=false" id="guest"
                                            name="guest" type="mail" autocomplete="off" class="form-control"
                                            [(ngModel)]="newGuest">
                                    </form>
                                </div>
                                <div class="divider"></div>
                                <div class="guest-list">
                                    <div *ngFor="let guest of events.guests" class="guest-container">
                                        <span>{{guest.mailId}}</span>
                                        <button (click)="removeGuest(guest)"
                                            class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only">
                                            <i class="fa fa-fw" aria-hidden="true" title="members"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav1"></div>

            <button [disabled]="loading" (click)="onSaveClick()" class="btn btn-success btn-icon">
                <span class="d-flex align-items-center"> 
                    <i class="pe-7s-diskette me-1"></i>
                    {{ 'save'|translatePipe }} 
                    <span class="spinner-border" *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;"></span>
                </span>
            </button>
        </div>
    </div>
</div>