<div class="logo-container" [class.circle]="isCircle">
    <label for="">{{label |translatePipe|titleCase}}</label>
    <div class="logo">
        <ng-container *ngIf="isLoader; else imageTemplate">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #imageTemplate>
            <ng-container *ngIf="imageSource; else emptyValue">
                <img class="image"  [src]="imageSource"  alt="logo">
           
            </ng-container>
            <ng-template #emptyValue>
                <div class="empty-image-container" [class.disabled]="disabled">
                   
                    <img class="empty-image" [src]="domSanitizer.bypassSecurityTrustResourceUrl(getImg())" alt="logo">
                   
                    <p>{{'Select an image'|translatePipe}}  <span>{{'or'|translatePipe}}</span> {{'drag and drop'|translatePipe}}</p>
                </div>
            </ng-template>


        </ng-template>
        
        <input #inputFile [disabled]="disabled" type="file" name="img-upload" id="img-upload" (change)="uploadImg($event)" />
    </div>
    <button [disabled]="disabled" class="btn btn-danger remove-button mb-4 col-12" (click)="delImg();reset()">{{'remove'|translatePipe}}</button>
   
</div>