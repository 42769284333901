<code [class]="group?.type" (click)="onSelectChild.emit(group.id)">
    <div *ngIf="isShowButtons" [class.have-action]="!!action" class="hovered-control-btns">
        <button (click)="clickedHoveredButton('add',group.id)" [title]="group.id?.includes('O_') ? ('Add Department'|translatePipe) : group.id?.includes('D_') ? ('Add Group'|translatePipe) : ('Add Sub-Group'|translatePipe)"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw" ></i>
        </button>
        <button  *ngIf="group?.type ==='organization'" (click)="clickedHoveredButton('edit',group.id)" title="{{group?.type+' Settings' | titlecase | translatePipe}}"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw" ></i>
        </button>
        <button *ngIf="group?.type!=='organization'" (click)="clickedHoveredButton('edit',group.id)" title=" {{'Modify '+group?.type | titlecase| translatePipe}}"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw"  ></i>
        </button>
        <button *ngIf="group?.type!=='organization'" (click)="clickedHoveredButton('delete',group.aId)"title=" {{'Delete '+group?.type | titlecase| translatePipe}}"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw"  ></i>
        </button>
        <button *ngIf="group?.type!=='department'" (click)="clickedHoveredButton('members',group.id)"[title]="group?.type.toLowerCase() === 'organization'?('All Members'|translatePipe):('Group Members'|translatePipe)"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw"  ></i>
        </button>
    </div>
    <div *ngIf="group?.type=='group'"  class="Mcount" >
    <div class="circle" *ngIf="group?.moderatorCount>0&&path=='/local-administrators'" title="{{'moderators'|translatePipe}}"><p>{{group?.moderatorCount}}</p></div>
    </div>
    <p>{{group?.name | ellipsis: 32}}</p>
</code>
<ul *ngIf="children?.length">
    <li [isShowButtons]="isShowButtons" (onSetCurrAction)="onSetCurrAction.emit($event)" [action]="action"
        *ngFor="let child of children" tree [group]="child" (onSelectChild)="onSelectChild.emit($event)">
    </li>
</ul>