<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="tasksToShow" [dataSource]="tasks" [dataItemKeys]="['title']">
        <ng-container options>
            <div class="col-container">
                
                <div class="col">
                    <div class="col-header cell">
                        {{'start date'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
                        {{dataItem.startDate | date:'dd/MM/yyyy'}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'deadline'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
                        {{dataItem.deadline | date:'dd/MM/yyyy'}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'object'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
                        {{dataItem.group.type | translatePipe}}: {{dataItem.group.name}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell" (click)="ChangeOrderByStatusSituation()" >
                        <div class="flex gap-10">
                            <span>{{'status'|translatePipe}}</span>
                            <app-order-situation [situation]="orderByStatusSituation"></app-order-situation>
                        </div>
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
                        <app-task-status [status]="dataItem.status" (updateStatus)="updateTaskStatus($event,dataItem)" [task]="dataItem">
                        </app-task-status>
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'members'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
                        {{getTaskMembers(dataItem.members)}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell" (click)="ChangeOrderByPrioritySituation()" >
                        <div class="flex gap-10">
                            <span>{{'priority'|translatePipe}}</span>
                            <app-order-situation [situation]="orderByPrioritySituation"></app-order-situation>
                        </div>

                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
                        {{dataItem.priority}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell cell-options">
                        <a [routerLink]="'/task-view/'+dataItem?.id"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary">
                            <i class="fa fa-fw"></i>
                        </a>
                        <button (click)="removeTask.emit(dataItem)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger">
                        <i class="fa fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="tasks.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>