<div dirControl class="row mb-3">
    <div class="col-12">
        <h3 class="card-title mt-4">{{'total'|translatePipe}} {{accounts.length}} {{title |translatePipe}}</h3>
    </div>
    <div class="col-12">
        <div class=" card">
            <div class="card-body">
                <form class="mb-3">
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="noData;else Tdata">
    <p class="d-flex align-items-center justify-content-center">{{'Nodata'|translatePipe}}</p>
</div>
<ng-template #Tdata>
    <app-manage-accounts-table (deleteAccount)="removeAccount($event)"
        [accounts]="filterAccounts()"></app-manage-accounts-table>
</ng-template>
<!-- <div *ngIf="getNumOfSelected()">
    <div class="flex">
        <button (click)="deleteSelected()" class="btn btn-danger m-1" >Delete {{title  |translatePipe}}</button>
        <button (click)="unselect()" class="btn btn-secondary m-1" >{{'unselect' | translatePipe}}</button>
    </div>
</div> -->
<div *ngIf="getNumOfSelected()" class="mb-3 card col-6">
    <div class="card-body">
        <form class="options actions-controller">
            <app-dropdown-label [label]="'actions'|translatePipe" [count]="getNumOfSelected()" [options]="options|translatePipe"
                [(value)]="currAction"></app-dropdown-label>
            <div>
                <button (click)="deleteSelected()" class="btn btn-primary">{{'go'|translatePipe}}</button>
            </div>
        </form>
    </div>
</div>
<div modals #modal [title]="modalTitle | translatePipe" [info1]="info1" (accept)="deleteAccount($event)">
</div>

<a routerLink="/account-view/type/{{accountType}}" class="flow btn-icon btn-icon-only btn btn-success"><i
        class="fa fa-fw" aria-hidden="true" title="{{'add'|translatePipe}}"></i></a>