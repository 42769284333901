import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { RecoverPasswordService } from 'src/app/services/recover-password.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {

  // slideConfig2 = {
  //   className: 'center',
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: '0',
  //   slidesToShow: 1,
  //   speed: 500,
  //   dots: true,
  // };


  email = ''
  loading: boolean;
  submitted: boolean;
  loginForm: FormGroup;
  formBuilder: any;
  newPassword: ''
  isNewPasswordValid = false;
  recpass: any;
  currYear = new Date().getFullYear();
  showMsg = false;

  constructor(
    private token: TokenService,
    private router: Router,
    private recoverPass: RecoverPasswordService,
    private fb: FormBuilder,
    private errhandle: ErrorHandlerService,
    private loaderService: NgxUiLoaderService) {

  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.loading = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    this.submitted = true;
    this.recoverPass.recover(this.f['email'].value).subscribe({
      next: data => {
        this.recpass = data;
        this.showMsg = true;
      },
      error: (err: any) => {
        //console.log(err)
        this.loading = false;
        this.showMsg = true;
      }
    })
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      // email: ['', [Validators.required, Validators.email]],
    });
    this.loaderService.startLoader("loader-01"); // start foreground spinner of the loader "loader-01" with 'default' taskId
    // Stop the foreground loading after 5s
    setTimeout(() => {
      this.loaderService.stopLoader("loader-01"); // stop foreground spinner of the loader "loader-01" with 'default' taskId
    }, 500);
  }

}
