import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { is } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],
})
export class SystemSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('generalInformation') generalInformation!: any;
  isAfterViewInit = false;
  group: any = this.organizationalStructureService.emptyGroup
  subscriotion!: Subscription
  isPublic = true;
  isEnableFreeze = false;
  isRequireLeave = false;
  loading: boolean = false;
  OrgID: any;
  contact: any = [];



  get isSubmitDisable() {
    const isNameValid = this.generalInformation?.nameInput?.formControler?.valid
    return this.isAfterViewInit && !isNameValid
  }

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private errhandle: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.organizationalStructureService.loadOrganizationalStructure();
    this.subscriotion = this.organizationalStructureService.organizationalStructure$.subscribe((organizationalStructure: Group[]) => {
      this.group = organizationalStructure[0];
      var a: any = organizationalStructure;
      a.forEach(ele => {
        if (ele.type == 'organization') {
          this.OrgID = ele.aId
          this.getContact(ele.aId)
        }
      })
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isAfterViewInit = true
    }, 1000);
  }

  getIcon(type: string) {
    switch (type) {
      case 'whatsapp':
        return ''
      case 'phone':
        return ''
      case 'email':
        return ''
      default:
        break;
    }
  }

  getText(type: string) {
    switch (type) {
      case 'whatsapp':
        return 'WhatsApp'
      case 'phone':
        return 'Phone'
      case 'email':
        return 'Email'
      default:
        break;
    }
  }

  saveOrgDetails() {
    this.loading = true;
    let obj = this.generalInformation.clubTypeOptions.filter((val) => {
      return val.Value === this.generalInformation.selectedType
    })
    let termStr = JSON.stringify(obj[0].Terminology);
    let data = {
      id: this.group.aId,
      name: this.generalInformation.name,
      terminology: termStr,
      country: this.generalInformation.selectedCountry === 'Israel' ? 117 : 0, //Right now only Israel or worldwide.
      brandColor: this.generalInformation.color,
      privacy: this.isPublic ? 4 : 2,
      domain: this.generalInformation.domain,
      description: this.generalInformation.description,
      contacts: this.contact
    }

    this.organizationalStructureService.updateOrganization(data).subscribe({
      next: (resp: any) => {
        this.loading = false;
        //console.log(resp);
        this.errhandle.openToast({ title: 'success', type: 'success', message: "Edited successfully!" })
      },
      error: (err: any) => {
        this.loading = false;
        this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result })
      }
    })
  }

  addContact(type) {
    this.contact = [...this.contact, { type, text: '' }]
  }

  getContact(id) {
    this.organizationalStructureService.getContc(id).subscribe((data: any) => {
      this.contact = data.result;
    })
  }

  removeContact(idx: any, id) {
    if (id == undefined) {
      this.contact = this.contact.filter((contact, contactIdx) => contactIdx !== idx)
    }
    else {
      this.organizationalStructureService.removeContc(id).subscribe((data: any) => {
        //console.log(data);
        this.getContact(this.OrgID)
      })
    }
  }
}
