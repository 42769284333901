<div class="mini-data mb-4 flex gap-10">
    <!-- <img [src]="group.logoUrl" alt="logo image"> -->
    <img  *ngIf="imageSource!='';" class="organization-logo mb-4 text-center" [src]="imageSource" alt="Organization image">
    <div *ngIf="imageSource==''" class="NoImg  mb-4" >
      <p style="text-align: center;" >{{'No image to show'|translatePipe}}</p>
   
    </div>
    <div class="group-data">
        <p>{{group.name}} {{group.type| translatePipe}}</p>
        <p>{{group.members.length}} {{'members'|translatePipe}}</p>
        <p>{{group.members.length}} {{'online'|translatePipe}}</p>
    </div>
</div>