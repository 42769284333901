import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/models/eums';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { RecoverPasswordService } from 'src/app/services/recover-password.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styles: []
})
export class PasswordRecoverComponent implements OnInit {

  // slideConfig2 = {
  //   className: 'center',
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: '0',
  //   slidesToShow: 1,
  //   speed: 500,
  //   dots: true,
  // };

  email = ''
  loading: boolean;
  submitted: boolean;
  loginForm: any;
  formBuilder: any;
  newPassword: 'dd'
  isNewPasswordValid = false;
  resetpass: any;
  currentUserValue: '';
  tok: any;
  userData: any;
  currDate = new Date();


  constructor(
    private token: TokenService,
    private router: Router,
    private recoverPass: RecoverPasswordService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private errhandle: ErrorHandlerService) {

  }
  get f() { return this.loginForm.controls; }

  // onSubmit() {
  //     this.recoverPass.reset(this.f['password'].value).subscribe({
  //       next: data => {
  //         this.resetpass = data;
  //       }
  //     })

  // }
  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.tok = params.reset;
    })
    this.loginForm = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }
  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    else {
      this.recoverPass.reset(this.f['password'].value, this.tok).subscribe({
        next: (data: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: 'Success', type: 'success', message: 'Password reset successfully.' })
          const storeToken = data.result.token;
          let userData = data.result;
          userData.color = this.getRandomColor();
          this.tokenService.setToken(storeToken);
          this.tokenService.setUser(JSON.stringify(userData));
          this.currentUserValue = data.result;
          var decodedToken = this.tokenService.decodedToken();
          if (decodedToken.role === Role.Administrator) {
            this.router.navigate(['/dashboard']);
          }
          else {
            this.router.navigate([`/dashboard/${decodedToken.primarysid}`])
          }
        },
        error: (err: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result })
        }
      })
    }
  }


  getRandomColor(): string {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

}
