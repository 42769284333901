import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { MembersService } from 'src/app/services/members.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SetsService } from 'src/app/services/sets.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  @ViewChild('messageInfo', { static: true }) messageInfo: any;


  options = ['suspension', 'freeze', 'remove', 'send messege','mail']

  subscriotion: Subscription[] = []
  notifications: any[] = []
  search = '';
  currAction = 'remove'
  setsToList = []
  selectedMembersIds: string[] = [];
  message=''
  messageTitle=''

  constructor(
    private notificationService: NotificationService,
    private setService: SetsService,
    private modal: NgbModal,
    private token: TokenService
  ) { }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    const curUser = this.token.decodedToken();
    const data = {
      memid: curUser.nameid,
      pageNo: 1,
      pageLimit: 25,
    }
    this.notificationService.getNotifications(data).subscribe(notifications => {
      this.updateNotifications(notifications.result);
    })
  }

  updateNotifications(notifications) {
    this.notifications = notifications.map(notification => ({ ...notification, isCheck: false }));
  }

  newNotification(event) {
    //console.log(event);
    this.getNotifications();
  }

}

