import { Component, OnInit } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MembersService } from 'src/app/services/members.service';
import { AppState } from 'src/app/store/app.store';
import { ThemeOptions } from '../../../../../theme-options';
import { TokenService } from 'src/app/token/token.service';
import Swal from 'sweetalert2';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ReferForm } from 'src/app/models/eums';
@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  faCalendar = faCalendar;
  subscription: Subscription[] = []
  loggedInMember = this.memberService.emptyMember
  member: any;
  currentMem: any;
  color = '#';
  loggedInAccount: any;

  //
  imageSource: any = ''
  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    public globals: ThemeOptions,
    private store: Store<AppState>,
    private memberService: MembersService,
    private auth: AuthService,
    private tokenService: TokenService,
    private fileServices: FilesService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    let user = this.tokenService.getUser();
    this.member = user;
    this.currentMem = this.member.name.split('')[0];
    this.color = user.backColor;

    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    if (this.loggedInAccount != undefined) {
      const data = {
        fkId: this.loggedInAccount,
        formType: ReferForm.Members
      }
      this.fileServices.getFileFkid(data).subscribe((data: any) => {
        if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
          this.fileServices.getFilebyId(data.result[0].id).subscribe((data: any) => {
            this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
            this.fileServices.imgCont(this.imageSource)
          })
          this.fileServices.imageContent_$.subscribe((data: any) => {
            this.imageSource = data
          })

        }
      })
    }
    this.fileServices.imageContent_$.subscribe((data: any) => {
      this.imageSource = data
    })



  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.auth.logout()
        this.fileServices.imgCont('')
      }
    })

  }









}
