import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-accounts-table',
  templateUrl: './manage-accounts-table.component.html',
  styleUrls: ['./manage-accounts-table.component.scss']
})
export class ManageAccountsTableComponent implements OnInit, OnChanges {

  dataSource = new MatTableDataSource
  setsColumns: string[] = []
  page = 1;
  pageSize = 10;
  // displayedColumns = ['account name', 'member identifier', 'options'];
  accountsToShow = [];
  @Input() accounts: any[];
  @Output() deleteAccount = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
    this.setAccountsToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setAccountsToShow()
  }

  getClass() {
    return `checkbox-container ${this.accounts.find(member => member.isCheck) ? 'checked' : ''}`
  }

  setAccountsToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.accountsToShow = this.accounts?.slice(start, start + this.pageSize);
  }

}
