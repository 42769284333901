<h3  class="card-title text-center mt-4">{{'send-notifications'|translatePipe}}</h3>
<div class="container my-5"  style="background-color: white;">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <form  [formGroup]="notifyForm"     (ngSubmit)="onSubmit()">
          <div class="row g-3 mb-3 mt-3">
            <div class="col-md-12">
              <label for="Title" class="form-label">Title</label>
              <input type="text" class="form-control" id="your-name" name="your-name" required  formControlName="title">
            </div>
           <div class="col-md-12">
            
            <app-members-multy-select [(values)]="participants" name="participants"
                    [label]="'event participants'|translatePipe"  [curMem]="currMem"></app-members-multy-select>
             
           </div>
            <div class="col-md-12">
              <label for="your-message" class="form-label">Description</label>
              
              <textarea class="form-control" id="your-message" name="your-message" rows="5" required  formControlName="description"></textarea>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-md-3">
                  <button  type="submit"    class="btn btn-success mb-4 col-12" >Send</button>
                </div>
              </div>
            </div>
          </div>
          
        </form>
      </div>
    </div>
  </div>
  


