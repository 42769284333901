import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { Set } from 'src/app/interfaces/set';
import { FilesService } from 'src/app/services/files.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';
import { TokenService } from 'src/app/token/token.service';
import { ReferForm } from 'src/app/models/eums';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class MemberProfileComponent implements OnInit {

  constructor(
    private memberService: MembersService,
    private route: ActivatedRoute,
    private setService: SetsService,
    private organizationalStructureService: OrganizationalStructureService,
    private router: Router,
    private fileServices: FilesService,
    private domSanitizer: DomSanitizer,
    private tokenService: TokenService
  ) { }

  subscription: Subscription[] = [];
  currLocalSetGroupId = '';
  member: any = [];
  memberGroups: Group[] = [];
  ////
  imageSource: any = '';
  currentMem: any;
  data: any;
  menuButtons = [
    {
      text: 'send chat',
      cb: () => { }
    },
    {
      text: 'share profile',
      cb: () => { }
    },
    {
      text: 'report',
      cb: () => { }
    }
  ]
  setMap = {
    global: []
  }

  ngOnInit(): void {
    var user = this.tokenService.getUser();
    this.data = user.backColor;

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const memberId = params.get('id');
        if (memberId) {
          this.memberService.getMember(memberId).subscribe((data: any) => {
            // this.setService.loadSets();
            this.member = data.result;
            this.currentMem = this.member.identifier.split('')[0];
            var data: any = {
              fkId: this.member.id,
              formType: ReferForm.Members
            }
            this.fileServices.getFileFkid(data).subscribe((data: any) => {
              if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
                this.fileServices.getFilebyId(data.result[0].id).subscribe((data: any) => {
                  this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
                })
              }
              else {
                this.imageSource = '';
              }
            })
            this.organizationalStructureService.loadOrganizationalStructure();
          })
        }
      })
    )

    // this.subscription.push(
    //   // this.setService.set$.subscribe(sets => {
    //   //   sets.forEach((set: Set) => {
    //   //     if (set.group.type === 'organization') this.setMap.global.push(set);
    //   //     else if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set);
    //   //     else this.setMap[set.group.id] = [set]
    //   //   });
    //   // })
    // )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe((groups: any) => {

        if (groups.length > 0) {
          for (let l of groups) {
            if (l.members) {
              for (let m of l.members) {
                if (m == this.member.uniqueId) {
                  if (!this.currLocalSetGroupId) this.currLocalSetGroupId = l.id
                  this.memberGroups.push(l)
                }
              }
            }
          }
        }
      })
    )
  }

  onChooseGroup(groupId) {
    this.router.navigate(['group-view', groupId]);
  }

}
