import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group } from 'src/app/interfaces/group';

@Component({
  selector: 'app-control-buttons-table',
  templateUrl: './control-buttons-table.component.html',
  styleUrls: ['./control-buttons-table.component.scss']
})
export class ControlButtonsTableComponent implements OnInit {
  @Input() groupId !: string;
  @Input() groupaId !: string;
  @Input() group;
  @Output() onSetCurrAction = new EventEmitter<string>();
  @Output() onSelectGroupChild = new EventEmitter<string>();


  constructor() { }

  onChooseAction(action: string) {
    this.onSetCurrAction.emit(action);
    // this.onSetCurrAction.emit(groupId);
    this.onSelectGroupChild.emit((action === 'delete') ? this.groupaId : this.groupId);
    this.onSetCurrAction.emit('');
  }

  ngOnInit(): void {
  }

}
