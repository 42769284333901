<!-- <div class="example-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="identifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Identifier</mat-header-cell>
            <mat-cell *matCellDef="let member">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="member.isCheck=!member.isCheck" [checked]="member.isCheck"
                            class="form-check-input" type="checkbox">
                    </div>
                </div>
                {{member.identifier}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="memberSince">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Member Since?</mat-header-cell>
            <mat-cell *matCellDef="let member"> {{formatDate(member.memberSince)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isModerator">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Moderator?</mat-header-cell>
            <mat-cell *matCellDef="let member">
                <input (click)="toggleIsModerator.emit(member.id)" [checked]="member.isModerator" type="checkbox">
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Options</mat-header-cell>
            <mat-cell *matCellDef="let member">
                <div class="options-container">
                    <a [routerLink]="'/member-view/'+member.id" class="btn btn-primary">Profile</a>
                    <button class="btn btn-danger mx-3" (click)="onRemoveMemberFromGroup.emit(member)">Delete</button>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <app-paginator [collectionSize]="members.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div> -->

<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="membersToShow" [dataSource]="members" [dataItemKeys]="['identifier']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member since'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.memberSince | date:'dd/MM/YYYY HH:mm'}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'moderator'|translatePipe}}
                    </div>
                    <div class="d-flex justify-content-center" (mouseover)="table.hoveredIdx=rowIdx"
                        (mouseleave)="table.hoveredIdx=-1" *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell" style="cursor: pointer;">
                        <fa-icon *ngIf="dataItem.isModerator" style="color: blue; margin-left: 30px;" [icon]="faCrown"
                            (mwlClick)="modClickEvent(dataItem, false)"></fa-icon>
                        <fa-icon *ngIf="!dataItem.isModerator" style="color: grey; margin-left: 30px;" [icon]="faCrown"
                            (mwlClick)="modClickEvent(dataItem, true)"></fa-icon>
                        <!-- {{dataItem.isModerator?'yes':'no'|translatePipe}} -->
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/member-view/'+dataItem.id"
                            class="btn btn-primary">{{'Profile'|translatePipe}}</a>
                        <button class="btn btn-danger mx-3"
                            (click)="onRemoveMemberFromGroup.emit(dataItem)">{{'Delete'|translatePipe}}</button>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="members.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>