<div class="attach-files mb-4 mt-4">
    <label [for]="label">{{label | titleCase}}</label>
    <div *ngIf="!!files.length" class="files-container mb-4">
        <div class="file" *ngFor="let file of files;let idx = index">
            <span *ngIf="file.name&&file.isAttachment!=false">

                {{file.name}}
                <button (click)="removeFile(idx)"><i class="pe-7s-close"></i></button>
            </span>


            <div *ngIf="file.fileName&&file.isAttachment!=false">
                <span>{{file.fileName}}</span>
                <button (click)="removeFile(idx,file)"><i class="pe-7s-close"></i></button>
            </div>



        </div>
    </div>
    <div class="files-upload-container">
        <div class="not-file-upload">
            <img class="empty-image" [src]="domSanitizer.bypassSecurityTrustResourceUrl('assets/images/upload-img.png')"
                alt="logo">
            <p>{{'Select files'|translatePipe}}<span>{{'or'|translatePipe}}</span> {{'drag and drop'|translatePipe}}
            </p>
        </div>

        <input type="file" multiple class="files-upload" (change)="onUploadFiles($event)">
    </div>

</div>