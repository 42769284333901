import { AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilesService } from 'src/app/services/files.service';
import { MembersService } from 'src/app/services/members.service';
import { RecoverPasswordService } from 'src/app/services/recover-password.service';
import { AppState } from 'src/app/store/app.store';
import { TokenService } from 'src/app/token/token.service';
import { ReferForm } from 'src/app/models/eums';
@Component({
  selector: 'app-user-control-pannel',
  templateUrl: './user-control-pannel.component.html',
  styleUrls: ['./user-control-pannel.component.scss']
})
export class UserControlPannelComponent implements OnInit {

  @ViewChild('userNameInput') userNameInput!: any;

  // loggedInUserId = ''
  loggedInAccount = 0;
  member = this.memberService.emptyMember
  subscription: Subscription[] = [];
  currPassword = '';
  isCurrPasswordValid = false;
  newEmail = '';
  isNewEmailValid = false;
  newPassword: ''
  newPasswords: ''
  isNewPasswordValid = false;
  isVerifyCodeOpen = false;
  verifyCode: any = ''
  ////////////////
  loggedInUserId: any;
  ImgFiles: any;
  imgUploadDetails: any;
  newUserName = '';
  data: any;
  res: any;
  response: any;
  get isnewUserNameValid() {
    return this.userNameInput?.formControler?.valid
  }

  constructor(
    private memberService: MembersService,
    private store: Store<AppState>,
    private tokenService: TokenService,
    private fileService: FilesService,
    private error: ErrorHandlerService,
    private recoverPass: RecoverPasswordService
  ) { }

  async ngOnInit(): Promise<void> {

    let user = this.tokenService.getUser();
    this.data = user;
    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    let memDet: any = await this.memberService.getMember(this.loggedInAccount).toPromise();
    this.member = memDet.result;
    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id

        }
      })
    )
    const member = await this.memberService.getMember(this.loggedInAccount).toPromise();

    this.member = member;
    this.newUserName = member.identifier;
    this.newEmail = member.email


    if (this.loggedInAccount != undefined) {
      const imgUploadDetails: any = {
        fkid: this.loggedInAccount,
        formtype: ReferForm.Members,
        isAttachment: false
      }
      this.imgUploadDetails = imgUploadDetails
    }

  }

  UploadedImgDetails() {
    var imageDetails: any = {
      fkid: this.loggedInAccount,
      formtype: ReferForm.Members,
    }
    return imageDetails;
  }

  imgContent(eve: any) {
    this.fileService.imgCont(eve);
  }

  onSave() {
    if (this.isVerifyCodeOpen) {
      this.recoverPass.ChangePwd(this.newPasswords, this.verifyCode).subscribe({
        next: (data: any) => {
          this.res = data;
          this.error.openToast({ title: 'success', type: 'success', message: this.res.result })
          setTimeout(() => {
            this.isVerifyCodeOpen = false
          }, 2000);
        },
        error: (err: any) => {
          this.error.openToast({ title: 'ERROR', type: 'error', message: this.res.result })
        }
      })
    }
    else {
      this.recoverPass.VerifyPwd(this.currPassword).subscribe({
        next: (data: any) => {
          this.response = data;
          this.error.openToast({ title: 'success', type: 'success', message: this.response.result })
          setTimeout(() => {
            this.isVerifyCodeOpen = true
          }, 3000);
        },
        error: (err: any) => {
          this.error.openToast({ title: 'ERROR', type: 'error', message: this.response.result })
        }
      })
    }
  }

  newPass(eve: any) {
    this.newPasswords = eve
  }
}
