import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MembersService } from 'src/app/services/members.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {

  notifyForm: FormGroup
  participants: any = '';
  currMem: any;
  file: any;
  @Output() notificationSent: EventEmitter<any> = new EventEmitter<any>()

  constructor(private formBuilder: FormBuilder,
    private memberService: MembersService,
    private notification: NotificationService,
    private token: TokenService,
    private errHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    var a = this.token.decodedToken();
    this.currMem = a.nameid

    this.notifyForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  get f() { return this.notifyForm.controls; }

  onSubmit() {
    var participantsId: any = [];
    this.participants.forEach(element => {
      participantsId.push(element.id);
    });

    var data: any = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      members: participantsId
    }

    this.notification.PostNotification(data).subscribe({
      next: (data: any) => {
        this.errHandler.openToast({ title: 'SUCCESS', type: 'success', message: 'Notification sent successfully.' });
        this.notificationSent.emit(1);
      },
      error: (err: any) => {
        this.errHandler.openToast({ title: 'ERROR', type: 'error', message: 'Notification could not be sent.' });
      }
    });
  }
}
