export const globalComponent = {
    //login
    login: 'member/login',
    //
    organization: 'Organization/structure',
    getOrgContc: 'Organization/contact/',
    //invite member
    inviteMem: 'Organization/RegisterInvitee',
    InviteeList: 'Organization/InviteeList',
    Invitee: 'Organization/Invitee?id=',
    //register member
    register: 'member/register',
    //
    organizationStruct: 'Organization',
    //edit organization
    editOrg: 'Group',
    //remove group
    removeDept: 'Group/',
    createDept: 'Group/',
    //
    getGroupByIds: 'Group/grp/',
    //new task
    task: 'Task',
    getTask: 'Task?',
    updateTask: 'Task/update?',
    deleteTask: 'Task/',
    //Update Organization Details
    updateOrg: 'Organization',

    grpMemList: 'Member?nodeId=',
    dashMemList: 'Member?dashList=',
    orgMemList: 'Member?orgid=',
    memDetails: 'Member?memid=',
    Member: 'Member/',

    addGrpMem: 'GroupMember',
    delGrpMem: 'GroupMember',
    grpMod: 'GroupMember/',
    grpDashData: 'Group/grpData',
    deptDashdata: 'Organization/dptData',

    AccountType: 'Account/',
    GetAccount: 'Account/Get/',
    Account: 'Account',
    GetAccountType: 'Account/type',
    AccountSummary: 'Account/Summary',

    AccountPosts: 'Account/post',
    getPostByid: 'Account/post?typeId=',
    GetAccountPosts: 'Account/post?typeId=',
    GetEvent: 'event?eventId=',
    getSpecEvent: 'Account/event?eventId=',
    EventType: 'Account/event/category?typeId=',
    accEventTypes: 'Account/event/category?',
    eventFields: 'Account/event/fields?eventTypeId=',
    addEvent: 'Account/event',
    //filesHandlig
    filesUploadDel: 'File/',
    getFileFkid: 'File',
    //fileGetById
    getById: 'File?id=',
    //getNotify
    getNotification: 'Notification?',
    sendNotification: 'Notification',
    //chat
    getContacts: 'Chat/Rooms',
    sendMsgGetChat: 'Chat/',
    createRoom: 'Chat/Room/',

    //recoverPassword
    recoverpass: 'Member/Recover',
    resetpass: 'Member/Reset',
    verifypass: 'Member/VerifyPwd',
    changepass: 'Member/ChangePwd',
    //calender-event
    calenderevent: 'Event',
    inviteMember: '/Invite'
}

export const statusConfig: any = [
    {
        id: 2,
        title: "pending",
        identifier: "pending",
        color: "#bdbdbd",
    },
    {
        id: 1,
        title: "assigned",
        identifier: "assigned",
        color: "#1389fd",
    },
    {
        id: 4,
        title: "working in progress",
        identifier: "work_on_it",
        color: "#ffca28",
    },
    {
        id: 3,
        title: "stuck",
        identifier: "stuck",
        color: "#ef5350",
    },
    {
        id: 5,
        title: "review",
        identifier: "review",
        color: "#66bb6a",
    },
    {
        id: 6,
        title: "done",
        identifier: "done",
        color: "#98ee99",
    },
    {
        id: 7,
        title: "on hold",
        identifier: "no_need",
        color: "#29b6f6",
    },
    {
        id: 8,
        title: "canceled",
        identifier: "cancelled",
        color: "#8d8d8d",
    },
]