<div class="event-container mb-2">

    <div *ngIf="!(isInManage||isFromAccount)" (click)="(stopProp($event))" class="menu-container">
        <button (click)="isMenuOpen=!isMenuOpen" class="btn menu-open-btn">
            <i class="fa fa-fw"></i>
        </button>
        <div (clickOutside)="isMenuOpen=false" [@slide-vertical]="isMenuOpen?'out':'in'" [class.close]="!isMenuOpen"
            class="menu">
            <a [routerLink]="'/portal-event-view/event/'+id+'/'+ event?.id">
                <i class="fa fa-fw"></i>
                <span>{{'edit'|translatePipe}}</span>

            </a>
            <button (click)="warningModel(warningContent)">
                <i class="fa fa-fw"></i>
                <span>{{'Delete'|translatePipe}}</span>
            </button>
        </div>

        <ng-template #warningContent let-modal>
            <div class="modal-content ">
                <div class="modal-header">
                    <h3>{{'Delete event'|translatePipe}}</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                        (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <div class=" text-center">
                        <div class=" pt-2 fs-15 mx-4 mx-sm-5">
                            <h3 class="text-muted mx-4 mb-0">{{'Are you sure?'|translatePipe}}</h3>
                        </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" class="btn w-sm btn-danger " id="delete-product"
                            (click)="modal.close('Close click');deleteEvent(event.id)">{{'Yes, Delete
                            It!'|translatePipe}}</button>
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </ng-template>
    </div>
    <div class="event x-auto my-auto event  ms-auto me-auto " (click)="onChooseEvent(StaticDataModal)"
        [class.inManage]="isInManage">
        <img *ngIf="imageSource" [src]="imageSource" alt="event image">
        <a class="">
            <i *ngIf="imageSource == ''" class="fa fa-fw">{{event.typeImageURL}}</i>
        </a>
        <div class="my-auto">
            <p class="event-name">{{event.name | titleCase}}</p>
            <p>{{event.eventDate | dateAgo}}</p>
        </div>
    </div>
</div>

<ng-template #StaticDataModal let-modal class="">

    <div class="modal-body  text-center p-3  ">
        <div class="mb-4">
            <h3 class="card-title text-center">{{'event'|translatePipe}}</h3>
        </div>
        <div class="d-flex w-100" style="justify-content: space-evenly; align-items: center;">

            <div>
                <div *ngIf="eventDetails.type" class="icon-text-container key-container d-flex">

                    <p><i class="pe-7s-file  i"></i><b>{{'type'|translatePipe}}</b></p>
                </div>
                <div *ngIf="eventDetails.eventLocation" class="icon-text-container key-container d-flex">

                    <p> <i class="pe-7s-map-marker i"></i><b>{{'event location'|translatePipe}}</b></p>
                </div>
                <div *ngIf="eventDetails.participants" class="icon-text-container key-container d-flex">

                    <p> <i class="pe-7s-users i"></i><b>{{'event participants'|translatePipe}}</b></p>
                </div>
                <div *ngIf="eventDetails.content" class="icon-text-container key-container d-flex">

                    <p> <i class="pe-7s-note i"></i><b>{{'description'|translatePipe}}</b></p>
                </div>

            </div>

            <div>
                <div *ngIf="eventDetails.type" class="value-container">
                    <p>{{eventDetails.type}}</p>
                </div>
                <div *ngIf="eventDetails.eventLocation" class="value-container">
                    <p>{{eventDetails.eventLocation}}</p>
                </div>
                <div *ngIf="eventDetails.participants" class="value-container">
                    <p>{{eventParticipantsStr}}</p>
                </div>
                <div *ngIf="eventDetails.content" class="value-container">
                    <p>{{event.content}}</p>
                </div>


            </div>


            <div>
                <img *ngIf="imageSource" style="max-width:150px" [src]="imageSource" alt="event image">
                <p>{{eventDetails.name}}</p>


            </div>






        </div>
        <div class="mb-1 " style="display: flex;width:100%;align-items: end;justify-content: end;">


            <button type="button" class="btn-danger"
                style="border: none;width:80px;border-radius: 2px;margin-right: 20px;" aria-hidden="true"
                (click)="modal.dismiss('Cross click')">{{'Close'|translatePipe}}</button>
        </div>
    </div>


</ng-template>