<div dirControl class="mb-3">

    <p [ngClass]="group.type" class="card-title">
        <span style="font-size: 20px;" *ngIf="group.id">{{group.type|translatePipe}}: {{group.name|translatePipe}}</span>
        <span  *ngIf="!group.id">{{'New '+group.type | translatePipe}}</span>
    </p>
    <p [ngClass]="groupParent.type" style="font-size: 15px;" class="card-title">{{'in '+groupParent.type|translatePipe}}:
        {{groupParent.name}}
    </p>
</div>

<div class="row">
    <div [class.col-12]="group.type==='department'" [class.col-8]="group.type==='group'">
        <app-general-information dirControl (onUpdateGroup)="updateEditedGroup()" [parent]="groupParent" [group]="group">
        </app-general-information>
        <div class="sub-group row">
            <div class="card">
                <div class="card-body">
                    <div dirControl class="">
                        <h3 class="title">{{'subGroup'|translatePipe}}</h3>
                        <button (click)="toggleView()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary">
                            <i class="pe-7s-look btn-icon-wrapper"></i>
                            {{ 'changeView' | translatePipe}}
                        </button>
                    </div>
                    <app-tree-container *ngIf="isViewA;else viewB" (onSetCurrAction)="onSetCurrAction($event)"
                        [action]="currAction" [group]="group" (onSelectChild)="onSelectGroupChild($event)">
                    </app-tree-container>

                    <ng-template #viewB>
                        <app-table-container (onSetCurrAction)="onSetCurrAction($event)"
                            (onSelectGroupChild)="onSelectGroupChild($event)" [group]="group"></app-table-container>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="group.type==='group'" app-group-members class="col-4" (onRemoveMember)="onRemoveMemberFromGroup($event)"
        (onAddMember)="onAddMemberToGroup($event)" [members]="editedGroupMembers" [groupId]="group.id">
    </div>

    <div modal #modal title="Delete organization group/department"
        info="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam molestiae aliquam voluptates esse totam aliquid 
    nesciunt recusandae eveniet ut fugit ipsum mollitia officiis facere sed ipsam, praesentium nulla, numquam reprehenderit?" (accept)="onDeleteGroupChild($event)"></div>

</div>