import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { AppState } from 'src/app/store/app.store';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  loggedInUserId = ''
  uniqueId = '';
  member: Member = this.memberService.emptyMember
  groups: Group[] = [];
  subscription: Subscription[] = [];


  constructor(
    private memberService: MembersService,
    private router: Router,
    private organizationStructureService: OrganizationalStructureService,
    private store: Store<AppState>,
    private tokenService: TokenService
  ) { }

  async ngOnInit(): Promise<void> {

    this.memberService.loadMembers();
    this.loggedInUserId = this.tokenService.decodedToken().nameid;
    this.uniqueId = this.tokenService.decodedToken().certthumbprint;
    // this.member = await this.memberService.getMember(this.loggedInUserId).toPromise();
    this.memberService.getMember(this.loggedInUserId).subscribe((data:any)=>{
      this.member=data.result;
    })
    const allGroups = await this.organizationStructureService.getGroups().toPromise();
    const groups = allGroups.result.filter(group => group.type.toLowerCase() === 'group');
    this.groups = groups.filter(group => group.members.find(memberId => memberId === this.uniqueId));
    // if (organization && organization.members.find(memberId => memberId === this.loggedInUserId)) {
    //   this.groups = [organization]
    // }else{
    //   // array of all the groups/department that not sun of other department/group
    // }
  }

  onChooseGroup(groupId) {
    this.router.navigate(['group-view', groupId]);
  }

}
