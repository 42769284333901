import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-eccomerce',
  templateUrl: './organization-eccomerce.component.html',
  styleUrls: ['./organization-eccomerce.component.scss']
})
export class OrganizationEccomerceComponent implements OnInit {

  constructor() { }

  wooComerceUrl = 'http://www.google.com/search?igu=1'


  ngOnInit(): void {
  }

}
