import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GroupServerService } from 'src/app/services/group-server.service';
import { MemberServerService } from 'src/app/services/member-server.service';

@Component({
  selector: 'div [app-group-members-server]',
  templateUrl: './group-member-server.component.html',
  styleUrls: ['./group-member-server.component.scss']
})
export class GroupMemberServerComponent implements OnInit {

  @Input() groupId!: string;
  // @Output() onAddMember = new EventEmitter<string>()
  // @Output() onRemoveMember = new EventEmitter<string>()
  groupMembers: any[] = []
  allMembers: any[] = []
  searchMembers$ = new BehaviorSubject<any[]>([]);


  searchMemberText = '';

  constructor(
    private groupService: GroupServerService,
    private memberService: MemberServerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.groupMembers = await this.groupService.getMembers(this.groupId).toPromise();
    this.allMembers = await this.memberService.getMembers().toPromise()

    this.updateSearchMembers()
  }

  updateSearchMembers() {
    const searchMembers = this.allMembers.filter(member => (
      member.identifier.toLowerCase().includes(this.searchMemberText.toLowerCase()) &&
      !this.groupMembers.some(memberItem => memberItem.id === member.id)
    ))
    this.searchMembers$.next(searchMembers);
  }

  onSearchMemberTextChange(value: string) {
    this.searchMemberText = value
    this.updateSearchMembers();
  }

  log(value) {
    //console.log(value);
  }

  async addMemberToGroup(memberId) {
    await this.groupService.addMember(+this.groupId, memberId).toPromise();
    this.groupMembers = await this.groupService.getMembers(this.groupId).toPromise();
    this.updateSearchMembers();
  }

  async removeMemberToGroup(memberId) {
    await this.groupService.removeMember(+this.groupId, memberId).toPromise();
    this.groupMembers = await this.groupService.getMembers(this.groupId).toPromise();
    this.updateSearchMembers();
  }






}
