import { Component, OnInit } from '@angular/core';
import {
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  startOfDay,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView
} from 'angular-calendar';
import { EventsService } from 'src/app/services/events.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { ReferForm } from 'src/app/models/eums';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from 'src/app/token/token.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  green: {
    primary: '#3ac47d',
    secondary: '#58cd91'
  }
};

@Component({
  selector: 'app-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.scss']
})
export class EventManagementComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  
  data: any = [];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('edit', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('delete', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-area-chart"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('attendance', event);
      }
    }
  ];
  events: any;
  currGroup = {
    type: 'all',
    id: 'all',
    name: 'all'
  }
  groups = [this.currGroup];
  showDayClicked: boolean = false;
  userId: any;
  showEvent: any = 0;

  constructor(
    private modal: NgbModal,
    private eventService: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private errHandle: ErrorHandlerService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.showDayClicked = !this.router.url.includes('calendar');
    this.userId = this.tokenService.decodedToken().nameid;
    this.route.params.subscribe(param => {
      let eventId = param['eventId'];
      let groupId = param['groupId'];
      if (eventId) {
        this.showEvent = eventId;
      }
      this.getEvents();
    })
    
  }

  getEvents()
  {
    this.activeDayIsOpen = false;
    this.data = [];
    this.eventService.getEvents().subscribe({
      next: (data: any) => {
        if (!Array.isArray(data.result)) {
          return;
        }
        for (let event of data.result) {
          let color = colors.red;
          switch (event.nodeDetails.type) {
            case 'organization':
              color = colors.red
              break;
            case 'department':
              color = colors.yellow
              break;
            case 'group':
              color = colors.green
              break;
          };
          let hours = event.durationType === 1 ? event.duration : event.duration / 60;
          let sdate = new Date(event.eventDate);
          let edate = new Date(event.eventDate);
          var calEvt = {
            start: sdate,
            end: new Date(edate.setTime(edate.getTime() + hours * 60 * 60 * 1000)),
            title: event.name,
            color: color,
            actions: this.showDayClicked ? this.actions : [],
            allDay: false,
            resizable: {
              beforeStart: false,
              afterEnd: false
            },
            draggable: false,
            id: event.id,
            description: event.content,
            groupId: event.nodeDetails.aId,
            imgLoc: event.canPlaceImageAbove ? 'above' : 'below',
            image: '',
            status: '',
            member: null
          }
          let mem = event.memberInvitees.filter(mem => mem.id === Number.parseInt(this.userId));
          if(mem.length > 0) {
            calEvt.member = mem[0];
            this.setStatus(calEvt.member.inviteStatus, calEvt);
          }
          this.data.push(calEvt);
        }
        this.refresh.next();
        if (this.showEvent) {
          let evtToShow = this.data.filter(evt => evt.id === Number.parseInt(this.showEvent));
          if (evtToShow.length > 0) {
            this.handleEvent('Clicked', evtToShow[0]);
          }
        }
      },
      error: (err: any) => {
        this.errHandle.openToast({ title: "ERROR", type: "error", message: "Could not fetch events." });
      }
    });
  }

  setStatus(status, event){
    switch(status) {
      case 'Declined':
        event.status = 'declined invite';
        break;
      case 'Maybe':
        event.status = 'maybe invite';
        break;
      case 'Accepted':
        event.status = 'accepted invite';
        break;
      case 'Pending':
        event.status = 'pending';
    }
  }
  
  get filterEvents() {
    if (this.currGroup.id === 'all') return this.events
    else return this.events.filter(event => event.groupId === this.currGroup.id)
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (!this.showDayClicked)
      return;
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  handleEvent(action: string, event: any): void {
    if (this.router.url.includes('calendar')) {
      var data = {
        fkId: event.id,
        formType: ReferForm.CalendarEvent,
      };
      this.fileService.getFileFkid(data).subscribe((files: any) => {
        if (files.result === 'InvalidDetailsToRetrieveTheFile') {
          return
        }
        if (files.result && files.result.length > 0) {
          this.fileService.getFilebyId(files.result[0].id).subscribe((data: any) => {
            event.image = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
          })
        }
      });
      this.modalData = { event, action };
      this.modal.open(this.modalContent, { size: 'lg' });
    }else {
      if (action === 'edit' || action === 'Clicked') {
        this.router.navigate(['/event-view/', event.id]);
      } else if (action === 'attendance') {
        this.router.navigate(['event-attendance', event.id]);
      } else {
        this.modalData = { event, action };
        this.modal.open(this.modalContent, { size: 'lg' });
      }
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  deleteEvent(eventId, cb?) {
    this.eventService.deleteEvent(eventId).subscribe({
      next: (data: any) => {
        this.errHandle.openToast({ title: "SUCCESS", type: "success", message: "Event deleted successfully" });
        this.getEvents();
      },
      error: (err: any) => {
        this.errHandle.openToast({ title: "ERROR", type: "error", message: err.message });
      }
    })
    if (cb) cb();
  }

  sendResponse(status, event) {
    this.eventService.changeStatus(event.member.inviteEventMemberId, status, event.member.identifier ? 'member' : 'guest').subscribe({
      next: (data: any) => {
        this.setStatus(status, event);
        this.errHandle.openToast({ title: "SUCCESS", type: "success", message: "Status updated." });
      },
      error: (err: any) => {
        this.errHandle.openToast({ title: "ERROR", type: "error", message: "Response could not be sent." });
      }
    })
  }

}
