<div class="header-dots">
  <!-- <div class="btn-group" ngbDropdown placement="bottom">
    <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-primary"></div>
          <i class="lnr-menu text-primary"></i>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-plum-plate">
          <div class="menu-header-image dd-header-bg-1"></div>
          <div class="menu-header-content text-white">
            <h5 class="menu-header-title">Grid Dashboard</h5>
            <h6 class="menu-header-subtitle">Easy grid navigation inside dropdowns</h6>
          </div>
        </div>
      </div>
      <div class="grid-menu grid-menu-xl grid-menu-3col">
        <div class="g-0 row">
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Automation
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Reports
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Settings
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Content
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Activity
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Contacts
            </button>
          </div>
        </div>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item-btn text-center nav-item">
          <button class="btn-shadow btn btn-primary btn-sm">Follow-ups</button>
        </li>
      </ul>
    </div>
  </div> -->
  <div class="btn-group" ngbDropdown autoClose="outside" placement="bottom">
    <button (click)="isNotificationOpen=!isNotificationOpen" type="button" class="btn btn-link p-0 me-2"
      ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-danger"></div>
          <i class="lnr-bullhorn text-danger"></i>
          <div *ngIf="isHaveUnReadNotifications" class="badge badge-dot badge-dot-sm bg-danger">
            {{'Notifications'|translatePipe}}</div>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <div class="dropdown-menu-header mb-0">
        <!-- <div class="dropdown-menu-header-inner bg-deep-blue"> -->
        <!-- <div class="menu-header-image opacity-1 dd-header-bg-2"></div> -->
        <div class="menu-header-content ">
          <h5 class="card-title mt-3">{{'Notifications'|translatePipe}}</h5>
        </div>
        <!-- </div> -->
      </div>
      <div class="divider"></div>
      <!-- scroll bar over height -->
      <div class="nav-justified" style="height:500px;overflow: scroll;overflow-x: hidden;" infinite-scroll
        [scrollWindow]="false" (scrolled)="onScrollDown($event)">
        <div class="card-tabbed-header">
          <!-- <ul ngbNav #nav="ngbNav" class="nav-tabs tabs-animated tabs-animated-shadow" justify="justified">
            <li ngbNavItem>
              <a ngbNavLink><span>Messages</span></a>
              <ng-template ngbNavContent>
                <div class="scroll-gradient">
                  <div class="scroll-area-sm shadow-overflow">
                    <perfect-scrollbar [autoPropagation]="true">
                      <div
                        class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands Meeting</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the production release</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands Meeting</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title text-success">FontAwesome Icons</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the production release</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-warning">12:00 PM</b></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink><span>Events</span></a>
              <ng-template ngbNavContent>
                <div class="scroll-gradient">
                  <div class="scroll-area-sm shadow-overflow">
                    <perfect-scrollbar [autoPropagation]="true">
                      <div
                        class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-success"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands
                                Meeting</h4>
                              <p>Lorem ipsum dolor sic amet, today at <a [routerLink]="">12:00 PM</a></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-warning"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                              <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-danger"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the
                                production release</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
                                magna elit enim at minim veniam quis nostrud</p><span
                                class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-primary"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title text-success">Something not important</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur elit enim at minim veniam quis nostrud</p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-success"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands
                                Meeting</h4>
                              <p>Lorem ipsum dolor sic amet, today at <a [routerLink]="">12:00 PM</a></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-warning"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                              <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-danger"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the
                                production release</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
                                magna elit enim at minim veniam quis nostrud</p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl bg-primary"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title text-success">Something not important</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur elit enim at minim veniam quis nostrud</p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink><span>Status</span></a>
              <ng-template ngbNavContent>
                <div class="no-results">
                  <div class="swal2-icon swal2-success swal2-animate-success-icon">
                    <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                    <span class="swal2-success-line-tip"></span><span class="swal2-success-line-long"></span>
                    <div class="swal2-success-ring"></div>
                    <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                    <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                  </div>
                  <div class="results-subtitle">All caught up!</div>
                  <div class="results-title">There are no system errors!</div>
                </div>
              </ng-template>
            </li>
          </ul> -->
          <!-- <div [ngbNavOutlet]="nav"></div> -->


          <div *ngFor="let notification of notificationData" class="notification-container">
            <div class="notification" *ngIf="notification!=undefined">
              <div class="img-and-settings">
                <div class="avatar-icon-wrapper me-1">
                  <!-- <div  class="badge badge-top btn-shine bg-danger badge-dot badge-dot-lg">
                  </div> -->
                  <div class="avatar-icon avatar-icon-lg rounded"
                    style="width:50px;margin-right: 10px;margin-left: 10px;">
                    <!-- <img alt="''" /> -->
                    <i class="opacity-6 pe-7s-user" style="font-size: 2rem;"></i>
                  </div>
                  <!-- <div class="avatar-icon avatar-icon-lg rounded">
                    <img *ngIf="notifications.settings.imgUrl && notifications.settings.imgUrl !== ''" [src]="notifications.settings.imgUrl" alt="image" />
                    <i *ngIf="!notifications.settings.imgUrl || notifications.settings.imgUrl !== ''" class="opacity-6 pe-7s-user" style="font-size: 2rem;"></i>
                  </div> -->
                  <p style="font-size: 10px;width:30px;display:flex;align-items: center;margin-left: 10px;">
                    {{notification.ownerName}}</p>

                </div>
                <div class="settings">

                  <h6 class="title">{{notification.title}}</h6>
                  <p>{{notification.description}}</p>
                  <small class="opacity-6">
                    <fa-icon [icon]="faCalendarAlt" class="me-1"></fa-icon>
                    {{notification.createdOn | date: 'dd/MM/yyyy HH:mm'}}
                  </small>
                </div>
              </div>

              <!-- <ng-container *ngIf="notification.type==='data'; else requestNotification">
                sad
              </ng-container>
              <ng-template #requestNotification>
                sad
              </ng-template> -->

              <div class="request" *ngIf="!notification.hasRead!">
                <button class="text-success" (click)="markNotificationRead(notification.id)">
                  <i class="fa fa-fw" aria-hidden="true" title="{{'Mark as read'|translatePipe}}"></i>
                </button>
                <button class="text-primary" (click)="viewDetails(notification)">
                  <i class="fa fa-eye" aria-hidden="true" title="{{'View'|translatePipe}}">
                  </i>
                </button>
              </div>

            </div>
            <div class="divider"></div>
          </div>

          <div>

          </div>

        </div>

      </div>
      <ul class="nav flex-column">
        <li class="nav-item-divider nav-item mt-0">
        </li>
        <div class="d-flex w-100">
          <li class="nav-item-btn nav-item me-auto">
            <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" (click)="goToNotifications()">{{'All
              notifications'|translatePipe}}</button>
          </li>
          <li class="nav-item-btn nav-item ms-auto">
            <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" (click)="markAllRead()">{{'Mark all
              read'|translatePipe}}</button>
          </li>
        </div>

      </ul>
    </div>
  </div>

  <div *ngIf="true" class="btn-group" ngbDropdown placement="bottom">
    <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-focus"></div>
          <div [class]="getClass()"></div>
        </div>
      </span>
    </button>
    <div ngbDropdownMenu>
      <div class="dropdown-menu-header">
        <div class="menu-header-content ">
          <h5 class="card-title mt-3">{{'Choose Language'|translatePipe}}</h5>
        </div>
        <div class="divider"></div>
      </div>
      <!-- <h6 class="dropdown-header">Popular Languages</h6> -->
      <button (click)="setLanguage('en')" type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-us"></div>
        <span>{{'English'|translatePipe}}</span>
      </button>
      <button (click)="setLanguage('he')" type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-il"></div>
        <span>{{'Hebrew'|translatePipe}}</span>
      </button>
    </div>
  </div>
</div>