import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit, AfterViewInit {
  private _data: any[] = [];
  private _value;
  private _isValid;
  isFocus = false;
  @Output() dataChange = new EventEmitter<any[]>();
  @Input() name?: string = 'multiselect';
  @Input() label?: string;
  @Input() disabled?: boolean = false;
  @Input() options: any[];
  @Input() drData: any = [];

  multiselectForm: FormGroup;
  subscription: Subscription[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: 'id',
    textField: 'string',
    selectAllText: this.translateService.locale === 'he' ? 'בחר הכל' : 'Select all',
    unSelectAllText: this.translateService.locale === 'he' ? 'בטל הכל' : 'Unselect all',
    enableCheckAll: true,
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  // @Input() data?: any[] = [];
  @Input() set data(val: any[]) {
    this._data = val;

    this.multiselectForm?.setValue({
      multiselect: this.data,

    }

    );
    this.dataChange.emit(val);
  }

  get data() {
    return this._data;
  }

  constructor(
    private fb: FormBuilder,
    public translateService: OtechI18nService
  ) { }

  ngOnInit(): void {
    this.multiselectForm = this.fb.group({
      multiselect: [this.data]
    });

    this.subscription.push(
      this.multiselectForm.valueChanges.subscribe(newVal => {
        if (newVal.multiselect.length !== this.data.length) {
          this.data = newVal.multiselect.map(formOption => this.options.find(option => formOption.id === option.id))
        }
      })
    )

    this.subscription.push(
      this.translateService.onLocaleChange$.subscribe(_ => {


        if (this.translateService.locale === 'he') {

          this.dropdownSettings.selectAllText = 'בחר הכל'
          this.dropdownSettings.unSelectAllText = 'בטל הכל'
        } else {
          this.dropdownSettings.selectAllText = 'Select all'
          this.dropdownSettings.unSelectAllText = 'Unselect all'
        }
      })
    )
  }

  ngAfterViewInit(): void {

  }
}


