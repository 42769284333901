import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group } from 'src/app/interfaces/group';

@Component({
  selector: 'app-tree-container',
  templateUrl: './tree-container.component.html',
  styleUrls: ['./tree-container.component.scss']
})
export class TreeContainerComponent implements OnInit {
  @Input() group !: Group;
  @Input() action !: string;
  @Input() isShowButtons = false;
  @Output() onSelectChild = new EventEmitter<string>()
  @Output() onSetCurrAction = new EventEmitter<any>()
  @Output() toggleSpinner = new EventEmitter<boolean>();

  children: Group[] = [];

  constructor() { }

  ngOnInit(): void {
   
  }

  showSpinner(val: boolean) {
    this.toggleSpinner.emit(val);
  }  
}
