import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'

})
export class TokenService {

  TOKEN_KEY = 'token';
  USER_KEY = 'currentUser';

  constructor(private jwt: JwtHelperService) {

  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, 'Bearer ' + token)
  }

  setUser(user: string) {
    localStorage.setItem(this.USER_KEY, user);
  }

  getToken() {
    const a = localStorage.getItem(this.TOKEN_KEY);
    return a;
  }

  getUser() {
    return JSON.parse(localStorage.getItem(this.USER_KEY));
  }

  decodedToken() {
    return this.jwt.decodeToken(this.getToken());
  }

  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  removeUser() {
    localStorage.removeItem(this.USER_KEY);
  }

  public checkTokenValid(tok: string): boolean {
    var d = new Date(0);
    var curr = Date.now();
    var diff = curr < d.setUTCSeconds(Number.parseInt(this.getTokenExpiry(tok)));
    return (tok != undefined && diff);
  }

  public getTokenExpiry(tok: string): string {
    let tokenDetails = this.jwt.decodeToken(tok!);
    return tokenDetails.exp;
  }

  decodeToken(tok: string) {
    if (this.checkTokenValid(tok)) {
      return this.jwt.decodeToken(tok);
    }else {
      return "";
    }
  }

}
