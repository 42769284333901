import { Component, OnInit } from '@angular/core';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  // animations: [

  //   trigger('architectUIAnimation', [
  //     transition('* <=> *', [
  //       query(':enter, :leave', [
  //         style({
  //           opacity: 0,
  //           display: 'flex',
  //           flex: '1',
  //           flexDirection: 'column'
  //         }),
  //       ]),
  //       query(':enter', [
  //         animate('600ms ease', style({ opacity: 1 })),
  //       ], { optional: true }),

  //       query(':leave', [
  //         animate('600ms ease', style({ opacity: 0 })),
  //       ], { optional: true })
  //     ]),
  //   ])
  // ]
})
export class PagesLayoutComponent implements OnInit {

  constructor(
    public translateService: OtechI18nService,
    private loaderService: NgxUiLoaderService
  ) {
  }

  ngOnInit() {
  }

  startLoader() {
    this.loaderService.startLoader("loader-01");
  }

  stopLoader() {
    this.loaderService.stopLoader("loader-01"); // start foreground spinner of the loader "loader-01" with 'default' taskId
  }

}
