import { Component, OnInit } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { ChatService } from 'src/app/services/chat.service';
import { DatePipe } from '@angular/common';
import { TokenService } from 'src/app/token/token.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Router } from '@angular/router';
import { ReferForm } from 'src/app/models/eums';
// import { faRefresh } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
  styleUrls: ['./dots.component.scss']
})
export class DotsComponent implements OnInit {
  datePipe: DatePipe = new DatePipe('en-US');
  // faRefresh = faRefresh;
  faCalendarAlt = faCalendarAlt;
  isHaveUnReadNotifications = true;
  notificationData: any = [];
  previous: boolean = false;
  next: boolean = true;
  page: number = 1;
  count: number = 0;
  private _isNotificationOpen = false;


  get isNotificationOpen() {
    return this._isNotificationOpen
  }

  set isNotificationOpen(value) {
    this.getNotification(false);
  }

  notifications = [
    {
      type: 'data',
      hasRead: false,
      settings: {
        title: this.notificationData.title,
        text: this.notificationData.description,
        timestamp: this.notificationData.createdOn,
        owner: this.notificationData.ownerName,
        imgUrl: '',
        link: ''
      }
    }
  ]

  constructor(
    public translateService: OtechI18nService,
    private notification: NotificationService,
    private token: TokenService,
    private errHandler: ErrorHandlerService,
    private router: Router
  ) { }

  getClass() {
    if (this.translateService.locale === 'he') return 'language-icon flag flag-icon-background flag-icon-il'
    if (this.translateService.locale === 'en') return 'language-icon flag flag-icon-background flag-icon-us'
  }

  setLanguage(language: string) {
    this.translateService.locale = language
  }


  ngOnInit() {

  }

  getNotification(increment: boolean) {

    //for pagination
    if (increment)
      this.page++;
    else {
      this.notificationData = [];
      this.page = 1;
    }

    //get the curr loggedIn user id
    const curUser = this.token.decodedToken();
    const data = {
      memid: curUser.nameid,
      pageNo: this.page,
      pageLimit: 5,
    }

    this.notification.getNotifications(data).subscribe((data: any) => {
      //restrict next button to call api when there is no data in database..
      this.next = true;
      if (this.count != data.count) {
        this.count += data.result.length;
      }
      //
      data.result.forEach(element => {
        this.notificationData.push(element);
      });
      this.isHaveUnReadNotifications = this.notificationData.filter(notification => { return !notification.hasRead; }).length > 0;
    })
  }

  onScrollDown(event: any) {
    this.getNotification(true);
  }

  markNotificationRead(id: any) {
    let ids = [
      id
    ];
    this.markRead(ids);
  }

  markRead(ids) {
    this.notification.markAsRead({ notifications: ids }).subscribe({
      next: (data: any) => {
        this.errHandler.openToast({ title: 'SUCCESS', type: 'success', message: 'Notification marked read.' });
        this.getNotification(false);
        this.isHaveUnReadNotifications = this.notificationData.filter(notification => { return !notification.hasRead; }).length > 0;
      },
      error: (err: any) => {
        this.errHandler.openToast({ title: 'ERROR', type: 'error', message: 'Please try again later.' });
      }
    })
  }

  markAllRead() {
    let ids = [];
    let unRead = this.notificationData.filter(notification => { return !notification.hasRead; });
    unRead.forEach(notif => {
      ids.push(notif.id);
    });
    this.markRead(ids);
  }

  goToNotifications() {
    this.router.navigate(['/notifications']);
  }

  viewDetails(notification: any) {
    switch (notification.notificationType) {
      case ReferForm.Nodes:
        this.router.navigate([`/editor/${notification.redirectURL.split(':')[1]}/members`]);
        break;
      case ReferForm.Members:
        this.router.navigate(['/manage-members']);
        break;
      case ReferForm.Tasks:
        if (notification.title.includes('changed the task status')) {
          this.router.navigate(['/task-managment']);
        } else {
          this.router.navigate(['/tasks']);
        }
        break;
      case ReferForm.AccountTypes:
      case ReferForm.AccountEvents:
        this.router.navigate([`/accounts/${notification.redirectURL.split(':')[1]}`]);
        break;
      case ReferForm.AccountPosts:
        this.router.navigate([`/portal-account-posts/${notification.redirectURL.split(':')[1]}`]);
        break;
      case ReferForm.Moderator:
        this.router.navigate([`/group-view/${notification.redirectURL.split(':')[1]}`]);
        break;
      case ReferForm.CalendarEvent:
        this.router.navigate([`/calendar/${notification.redirectURL.split(':')[1]}`]);
        break;
    }
  }
}
