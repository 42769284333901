import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { OtechI18nModule } from 'projects/otech-i18n/src/lib/otech-i18n.module';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.store';
import { MembersService } from 'src/app/services/members.service';
import { TokenService } from 'src/app/token/token.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar-b2c',
  templateUrl: './sidebar-b2c.component.html',
  styleUrls: ['./sidebar-b2c.component.scss']

})
export class SidebarB2cComponent implements OnInit {
  public extraParameter: any;
  subscription: Subscription[] = []
  loggedInUser: any;
  color: any;
  letters = '0123456789ABCDEF';
  currentMem: any;
  user: any;
  loggedInAccount: any;
  loggedUser: any = '';
  fontSize: any = '15px';
  //
  imageSource: any = '';
  userAccTypes: any;

  @ViewChild('logged') logged: ElementRef;
  @ViewChild('logName') logName: ElementRef;
  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private i18nService: OtechI18nService,
    public translateService: OtechI18nService,
    private tokenService: TokenService,
    private store: Store<AppState>,
    private memberService: MembersService,
    private portalAccountService: PortalAccountsService,
    private fileServices: FilesService,
    private domSanitizer: DomSanitizer
  ) { }

  setLanguage() {
    if (this.i18nService.locale == 'en') { this.i18nService.locale = 'he' }
    else { this.i18nService.locale = 'en' }
  }

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';
  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    localStorage.setItem('sb', this.globals.toggleSidebar ? 'close' : 'open');
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {
    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    this.portalAccountService.getAccountsByUserId(this.loggedInAccount).subscribe((data: any) => {
      if (Array.isArray(data.result) && data.result.length > 0) {
        this.userAccTypes = data.result;
        this.userAccTypes.forEach(acc => {
          acc.imageURL = JSON.parse(acc.imageURL);
          acc.imageURL.D = String.fromCharCode(parseInt(acc.imageURL.D, 16))
          acc.imageURL.P = String.fromCharCode(parseInt(acc.imageURL.P, 16))
        });
      }
    });

    setTimeout(() => {
      let sbState = localStorage.getItem('sb');
      this.globals.toggleSidebar = sbState === 'close';
      this.globals.sidebarHover = !this.globals.toggleSidebar;
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.loggedInUser = this.tokenService.getUser();
    this.currentMem = this.loggedInUser.name.split('')[0];
    this.color = this.loggedInUser.backColor;
    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;
    this.user = this.tokenService.getUser();

    this.fileServices.imageContent_$.subscribe((data: any) => {
      this.imageSource = data
    })

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

  nameSpaceHandle() {

    let a = this.logged.nativeElement.offsetWidth;
    let b = this.logName.nativeElement.offsetWidth;
    if (a >= 130 || b >= 130) {

      this.fontSize = '12px';
    }
    else {

      this.fontSize = '20px'
    }

  }

}
