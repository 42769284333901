import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-login-organization',
  templateUrl: './login-organization.component.html',
  styleUrls: ['./login-organization.component.scss']
})
export class LoginOrganizationComponent implements OnInit {

  subscription: Subscription[] = [];
  organization: Group

  email = ''
  isEmailValid = false;
  password = ''
  isPasswordValid = false;
  isHaveAuth = true;
  isShowAuth = false;
  verifyCode = ''


  constructor(
    public organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private authService:AuthService,private token:TokenService,private router:Router
  ) { }


  ngOnInit(): void {
    if(this.token.decodedToken()!=null){
      this.router.navigate(['/dashboard'])
      }

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const organizationId = params.get('id');
        if (organizationId) {
          this.organization = await this.organizationalStructureService.getGroupById(organizationId).toPromise()
        }
      })
    )
  }

  async login() {
    const token = await this.authService.login(this.email,this.password).toPromise();
    //console.log(token);
    const isCradentialOk = 'asd'; // send a login request if the cradential is ok return true
    if (isCradentialOk && this.isHaveAuth) {
      this.sendCode();
      this.isShowAuth = true;
    }
  }

  sendCode() {

  }

  submitVerifyCode() {

  }
}

