<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'taskView'|translatePipe}}</h3>
        <app-input-label [label]="'title'|translatePipe" type="text" [(value)]="task.title"></app-input-label>
        <app-group-picker-input [label]="'group'|translatePipe" [(currGroup)]="task.group"></app-group-picker-input>
        <app-members-multy-select name="members" [label]="'members'|translatePipe" [(values)]="task.members">
        </app-members-multy-select>


        <app-textarea-label [label]="'content'|translatePipe" [(value)]="task.content"></app-textarea-label>

        <div class="flex gap-10">
            <div class="flex-1">
                <app-date-picker id="start" [label]="'start date'|translatePipe"
                    [(timestamp)]="task.startDate"></app-date-picker>
            </div>
            <div class="flex-1">
                <app-date-picker id="end" [label]="'deadline'|translatePipe"
                    [(timestamp)]="task.deadline"></app-date-picker>
            </div>

            <div class="mb-4">
                <app-range-date-picker [(fromTimestamp)]="task.startDate" [(toTimestamp)]="task.deadline">
                </app-range-date-picker>
            </div>
        </div>
        <!-- [(value)]="task.priority" -->
        <app-rate-picker [label]="'priority'|translatePipe" [(value)]="task.priority"></app-rate-picker>

        <!-- [(files)]="task.files" -->
        <app-attach-files [label]="'files'|translatePipe"  [(files)]="files"  (fileClicked)="fileToRemove($event)"  (ChangeFile)="changeFile($event)"></app-attach-files>

        <button [disabled]="saveBtn" class="btn btn-success" style="float: right;" (click)="onSave()">{{'save'|translatePipe}}</button>
    </div>



</div>