<div dirControl class="header card mb-3">
    <div class="card-body">
        <h3 class="card-title">{{'InviteMember.email'|translatePipe}}</h3>
        <!-- <div class="checkbox-flex-gap-10 c">
            
                <app-input-label  label="email" name="email"  [required]="true" [email]="true" type="text"
                [(value)]="email">
                </app-input-label><br>
                <app-input-label label="name" name="name" [required]="true" [name]="true" type="text"
                [(value)]="name">
                </app-input-label>
               
            <div class="flexyc-input-container">
            </div>
        </div> -->

        <form [formGroup]="Form" (ngSubmit)="onSubmit()">

            <div class="mb-3" style="width:30%;">
                <label class="form-label" for="username">{{'email'|translatePipe}}</label>
                <input type="email" class="form-control" id="email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                    placeholder="{{'Enter email'|translatePipe}}">
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']">{{'Email is required'|translatePipe}}</div>
                    <div *ngIf="f['email'].errors['email']">{{'Email must be a valid email address'|translatePipe}}
                    </div>
                </div>
            </div>

            <div class="mb-3" style="width:30%;">
                <label class="form-label" for="password-input">{{'Enter Name'|translatePipe}}</label>
                <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="{{'Enter Name'|translatePipe}}" id="password-input"
                        formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">
                    <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                        <span *ngIf="f['name'].errors['required']">{{'Name is required'|translatePipe}}</span>
                    </div>
                </div>
            </div>

            <div class="mt-4">
            </div>
            <button class="btn btn-success" type="submit"><span class="d-flex align-items-center">{{'Invite to
                    Organization'|translatePipe}}<span class="spinner-border" *ngIf="loading"
                        style="width:20px;height:20px;margin-left: 10px;"></span></span></button>
        </form>
        <!-- <button  class="btn btn-success" (click)="registerinvitee()">{{'send'|translatePipe}}</button> -->
    </div>

</div>
<!-- <div class="header card">
    <div class="card-body">
        <h3 class="card-title">Invite via link</h3>
        <div class="input-group mb-3">
            <label>link</label>
            <input type="text" class="form-control" [value]="link"
                placeholder="content will be copied after click the copy button">
            <button class="btn btn-primary" type="button" ngxClipboard [cbContent]="link"
                (cbOnSuccess)="isCopied = true" (cbOnError)="onCopyFailure()"
                [class.btn-success]="isCopied">Copy</button>
        </div>

        <div class="row mb-3">
            <div class="col-2">
                <app-input-label type="number" label="Limit usage" name="limit" [(value)]="linkLimit">
                </app-input-label>
            </div>
        </div>
        <button class="btn btn-success">generate</button>
    </div>
</div> -->
<div dirControl class="header card mb-3" *ngIf="invitemem && invitemem.length > 0">
    <div class="card-body">
        <div>
            <h3 class="card-title">{{'Pending Invitation' |translatePipe}}</h3>
        </div>
        <div dirControl class="mb-4">
            <app-flexyc-table #table [dataForPage]="invitemem" [dataSource]="invitemem">
                <ng-container options>
                    <div class="col-container">
                        <div class="col">
                            <div class="col-header cell">
                                {{'name'|translatePipe}}
                            </div>
                            <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                                *ngFor="let dataItem of invitemem;let rowIdx = index"
                                [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                                {{dataItem.name}}
                            </div>
                        </div>
                    </div>
                    <div class="col-container">
                        <div class="col">
                            <div class="col-header cell">
                                {{'email'|translatePipe}}
                            </div>
                            <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                                *ngFor="let dataItem of invitemem;let rowIdx = index"
                                [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                                {{dataItem.email}}
                            </div>
                        </div>
                    </div>
                    <div class="col-container">
                        <div class="col">
                            <div class="col-header cell">
                                {{'CreatedOn'|translatePipe}}
                            </div>
                            <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                                *ngFor="let dataItem of invitemem;let rowIdx = index"
                                [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                                {{dataItem.createdOn |formatDate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-container">
                        <div class="col">
                            <div class="col-header cell">
                                {{'options'|translatePipe}}
                            </div>
                            <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                                *ngFor="let dataItem of invitemem;let rowIdx = index"
                                [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">

                                <button (click)="deleteInvitemem(dataItem.id)"
                                    class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                                        class="pe-7s-delete-user btn-icon-wrapper"> </i></button>
                            </div>
                        </div>
                    </div>

                </ng-container>

            </app-flexyc-table>
            <app-paginator [collectionSize]="invitemem?.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>

        </div>
    </div>
</div>