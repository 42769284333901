import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReferForm } from 'src/app/models/eums';
import { FilesService } from 'src/app/services/files.service';
import { MembersService } from 'src/app/services/members.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-attendance-member-view',
  templateUrl: './attendance-member-view.component.html',
  styleUrls: ['./attendance-member-view.component.scss']
})
export class AttendanceMemberViewComponent implements OnInit {

  constructor(
    private memberService: MembersService,
    private fileService: FilesService,
    private domSanitizer: DomSanitizer,
    private tokenService: TokenService
  ) { }

  
  imageSource: any = '';
  currentMem: any;
  @Input() member: any;
  color: any;
  @Output() removeMember = new EventEmitter<any>();

  async ngOnInit(): Promise<void> {
    if (this.member.identifier) {
      let user = this.tokenService.getUser();
      this.currentMem = this.member.identifier.split('')[0];
      this.color = user.emailId === this.member.email ? user.backColor : this.getRandomColor();

      var data: any = {
        fkId: this.member.id,
        formType: ReferForm.Members
      }

      this.fileService.getFileFkid(data).subscribe((resp: any) => {
        if (resp.result !== 'InvalidDetailsToRetrieveTheFile') {
          this.fileService.getFilebyId(resp.result[0].id).subscribe((fileDat: any) => {
            this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${fileDat.result.fileContent}`);
          })
        }
        else {
          this.imageSource = '';
        }
      })
    } else {
      this.currentMem = this.member.mailId.split('')[0];
      this.color = this.getRandomColor();
    }
  }

  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

}
