import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MemberServer } from '../interfaces/member-server';
import { GlobalVariablesService } from './global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class MemberServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'Members/'
  private _members$ = new BehaviorSubject<any[]>([])
  public members$ = this._members$.asObservable();

  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
  ) { }

  getEmpty() {
    return {
      id: 0,
      identifier: '',
      email: '',
      memberSince: Date.now(),
      lastSeen: Date.now(),
      isAdministrator: false,
      status: ''
    }
  }

  loadMembers() {
    this.getMembers().pipe(
      tap(members => {
        this._members$.next(members);
      })
    ).toPromise();
  }

  getMembers() {
    return this.http.get<MemberServer[]>(this.BASE_URL)
  }

  getById(id: string | number) {
    return this.http.get<MemberServer>(this.BASE_URL + id);
  }

  getGroups(memberId: string | number) {
    return this.http.get<any>(this.BASE_URL + memberId + '/groups');
  }

  isIdentifierValid(identifier: string) {
    return ''//this.http.get<boolean>(this.BASE_URL + 'identifierValid/' + identifier)
  }

  updateMember(memberData: { identifier: string, email: string, tempPassword: string }, memberId: string | number) {
    return this.http.put<any>(this.BASE_URL + memberId, memberData)
  }

  addMember(memberData: { identifier: string, email: string, tempPassword: string }) {
    return this.http.post<any>(this.BASE_URL, memberData)
  }

  updateGroups(memberId: string | number, groupsIds: number[]) {
    return this.http.put<void>(this.BASE_URL + memberId + '/groups', groupsIds)
  }

  updateStatus(memberId: number, status: string) {
    return this.http.put(this.BASE_URL + 'status', { memberId, status })
  }

  updateMembersStatus(memberIds: number[], status: string) {
    return this.http.put(this.BASE_URL + 'members_status', { memberIds, status })
  }

  remove(id: string | number) {
    return this.http.delete(this.BASE_URL + id);
  }

  removeMembers(memberIds: number []) {
    return this.http.delete(this.BASE_URL, { body: { ids:memberIds } });
  }
}
