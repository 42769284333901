<div dirControl>
    <div dirControl class="mb-3">
        <h3 class="card-title text-center mt-4">{{'total'|translatePipe}} {{tasks.length}} {{'tasks'|translatePipe}}
        </h3>
        <!-- <a routerLink="">View user statistics...</a> -->
        <div class="">
            <div class=" card">
                <div class="card-body">
                    <form class="mb-3">
                        <div class="position-relative form-group search-container">
                            <label>
                                <i class="pe-7s-search"></i>
                            </label>
                            <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                                [placeholder]="'search'|translatePipe">
                        </div>
                    </form>
                    <div class="flex gap-10">
                        <button (click)="openFilter()" class="btn btn-primary">{{'filter'|translatePipe}}</button>
                        <button (click)="removeFilterStartDate()" class="remove-filter-btn" *ngIf="filter.startDate">
                            <span>{{filter.startDate|formatDate}}</span>
                            <i class="pe-7s-close"></i>
                        </button>
                        <button (click)="removeFilterStatus()" class="remove-filter-btn" *ngIf="filter.status">
                            <span>{{filter.status}}</span>
                            <i class="pe-7s-close"></i>
                        </button>
                        <button (click)="removeFilterUsers()" class="remove-filter-btn" *ngIf="filter.users.length">
                            <span>{{filterUsersString}}</span>
                            <i class="pe-7s-close"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <!-- <app-manage-members-table [sets]="setsToList" (removeMember)="removeMember($event)"
(changeMemberStatus)="changeMemberStatus($event)"  [members]="filterMembers()"></app-manage-members-table> -->

    <app-task-managment-table (removeTask)="removeTask($event)" [tasks]="filterTasks()"
        (updateTask)="updateTask($event)">
    </app-task-managment-table>



    <div *ngIf="getNumOfSelected()" class="mb-3 card col-6">
        <div class="card-body">
            <form class="options actions-controller">
                <div class="flex gap-10">
                    <div class="flex-1">
                        <app-dropdown-label [label]="'actions'|translatePipe" [options]="actionOptions"
                            [(value)]="currAction">
                        </app-dropdown-label>
                    </div>
                    <div class="flex-1">
                        <ng-container [ngSwitch]="currAction">
                            <app-dropdown-label *ngSwitchCase="'status'" [label]="'status'|translatePipe"
                                [options]="statusOptions" [(value)]="actionData.status"></app-dropdown-label>

                            <div *ngSwitchCase="'priority'" class="mb-5 mt-4">
                                <app-rate-picker [(value)]="actionData.priority">
                                </app-rate-picker>
                            </div>

                            <app-date-picker *ngSwitchCase="'start date'" [label]="'start date'|translatePipe"
                                [(timestamp)]="actionData.startDate"></app-date-picker>
                            <app-date-picker *ngSwitchCase="'deadline'" [label]="'deadline'|translatePipe"
                                [(timestamp)]="actionData.deadline"></app-date-picker>
                            <app-group-picker-input *ngSwitchCase="'objects'" [label]="'objects'|translatePipe"
                                [(currGroup)]="actionData.object"></app-group-picker-input>
                            <app-members-multy-select name="addd members" *ngSwitchCase="'add members'"
                                [label]="'add members'|translatePipe" [(values)]="actionData.members">
                            </app-members-multy-select>
                            <app-check-box-label name="delete" *ngSwitchCase="'delete'"
                                [label]="'are you sure'|translatePipe"></app-check-box-label>
                        </ng-container>
                    </div>

                    <button (click)="onGoAction()" class="btn btn-primary">{{'Go'|translatePipe}}</button>
                </div>
            </form>
        </div>
    </div>

    <ng-template #filterPop let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">{{'filter'|translatePipe}}</h5>
            <button type="button" class="btn-close" (click)="close()">
            </button>
        </div>
        <div class="modal-body">
            <div class="filters">
                <app-date-picker [label]="'Start Date'|translatePipe" [(timestamp)]="popFilters.startDate">
                </app-date-picker>
                <app-dropdown-label [label]="'status'|translatePipe" [options]="statusOptions"
                    [(value)]="popFilters.status">
                </app-dropdown-label>
                <app-members-multy-select name="members" [label]="'members'|translatePipe"
                    [(values)]="popFilters.users">
                </app-members-multy-select>
            </div>
            <button (click)="setFilter(close)" class="btn btn-success">{{'set filters'|translatePipe}}</button>
        </div>
    </ng-template>

    <a routerLink="/task-view" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw"
            aria-hidden="true" title="{{'add'|translatePipe}}"></i></a>
</div>