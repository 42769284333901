import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { statusConfig } from 'src/app/global-component.service';
import { TaskService } from 'src/app/services/task.service';
import { AppState } from 'src/app/store/app.store';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  subscription: Subscription[] = [];
  userId: any;
  tasks = []

  constructor(
    private store: Store<AppState>,
    private taskService: TaskService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.taskService.loadTasks();
    this.userId = Number.parseInt(this.tokenService.decodedToken().nameid);
    this.subscription.push(
      this.taskService.tasks$.subscribe((tasks: any) => {
        if (this.userId) {
          this.tasks = tasks.filter((task: any) => (task.members.some(member => member.id === this.userId)) && new Date(task.startDate).getTime() <= Date.now());
        }
      })
    )
  }

  updateTask(task) {
    let status = statusConfig?.find(statusConfigItem=>statusConfigItem.title===task.status);
    this.taskService.editTaskStatus(task.id, status.id).toPromise();
  }

}
