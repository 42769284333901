import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ReferForm } from "src/app/models/eums";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { FilesService } from "src/app/services/files.service";
import { TaskService } from "src/app/services/task.service";

@Component({
  selector: "app-task-view",
  templateUrl: "./task-view.component.html",
  styleUrls: ["./task-view.component.scss"],
})
export class TaskViewComponent implements OnInit {
  task: any = this.taskService.getEmpty();
  // task:any;
  title: any;
  group: any;
  members: any = [];
  arrMem: any = [];
  content: any;
  startDate: any;
  deadline: any;
  files: any = [];
  delFile: any = [];
  fileToUpload: any = [];
  subscription: Subscription[] = [];
  ////
  saveBtn: boolean = false;
  constructor(
    private taskService: TaskService,
    private route: ActivatedRoute,
    private errhandle: ErrorHandlerService,
    private fileService: FilesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const taskId: any = params.get("id");
        if (taskId) {
          this.taskService.getTask(taskId).subscribe({
            next: (data: any) => {
              this.task = data.result[0];
            },
          });
          //get files for selceted tasks;
          var data = {
            fkId: taskId,
            formType: ReferForm.Tasks,
          };
          this.fileService.getFileFkid(data).subscribe((data: any) => {
            if (data.result === 'InvalidDetailsToRetrieveTheFile') 
            {
              return;
            }
            if (data.result == null) {
              this.files = [];
            } else {
              this.files = data.result;
            }
          });
        }
      })
    );
  }

  fileToRemove(eve: any) {
    this.delFile.push(eve.id);
  }

  async onSave() {
    this.saveBtn = true;
    if (this.task.id && this.task.status === "done") {
      this.saveBtn = false;
      this.errhandle.openToast({
        title: "ERROR",
        type: "error",
        message: "Tasks marked as done cannot be modified.",
      });
      return;
    }
    var mCount: any = this.task.members;
    var members: any = [];
    mCount.forEach((ele) => {
      members.push(ele.id);
    });

    let taskRequest: any = {
      content: this.task.content,
      objectId: this.task.group.aId,
      objectType: 2,
      priority: this.task.priority,
      members: members,
      startDate: this.task.startDate,
      deadLine: this.task.deadline,
      title: this.task.title,
    };
    if (this.task.group.type.toLowerCase() === "organization") {
      taskRequest.objectType = 1;
    } else if (this.task.group.type.toLowerCase() === "department") {
      taskRequest.objectType = 2;
    } else if (this.task.group.type.toLowerCase() === "group") {
      taskRequest.objectType = 3;
    }

    if (this.task.id) {
      taskRequest.id = this.task.id;
      this.taskService.editTask(taskRequest).subscribe({
        next: (data: any) => {
          this.sBtnDisabled(true);
          this.errhandle.openToast({
            title: "SUCCESS",
            type: "success",
            message: "Task successfully edited!",
          });
        },
        error: (err: any) => {
          this.sBtnDisabled(false);
          this.errhandle.openToast({
            title: "ERROR",
            type: "error",
            message: "Error occured while updating task. Try again later.",
          });
        },
      });

      //Edit files
      if (this.fileToUpload.length != 0) {
        for (let l of this.fileToUpload) {
          var Fdata: any = {
            files: l,
            fkid: this.task.id,
            formType: "4",
            isAttachment: "true",
          };
          this.fileService.uploadFile(Fdata).subscribe({
            next: (data: any) => {},
            error: (err: any) => {},
          });
        }
      }
      //delete selected files
      if (this.delFile.length != 0) {
        for (let l of this.delFile) {
          this.fileService.deleteFile(l).subscribe((data: any) => {
            this.delFile = "";
          });
        }
      }
    } else {
      if (taskRequest.title != "") {
        this.taskService.addTask(taskRequest).subscribe({
          next: (data: any) => {
            this.sBtnDisabled(true);
            this.errhandle.openToast({
              title: "SUCCESS",
              type: "success",
              message: "Task successfully saved!",
            });
            //for upload file's
            for (let l of this.files) {
              var Fdata: any = {
                files: l,
                fkid: data.result.id,
                formType: ReferForm.Tasks,
                isAttachment: "true",
              };
              this.fileService.uploadFile(Fdata).subscribe({
                next: (data: any) => {},
                error: (err: any) => {
                  this.errhandle.openToast({
                    title: "ERROR",
                    type: "error",
                    message: "File cannot be uploaded!",
                  });
                },
              });
            }
          },
          error: (err: any) => {
            this.sBtnDisabled(false);
            this.errhandle.openToast({
              title: "ERROR",
              type: "error",
              message: "Error occured while adding task. Try again later.",
            });
          },
        });
      } else {
        this.sBtnDisabled(false);
        this.errhandle.openToast({
          title: "ERROR",
          type: "error",
          message: "Title required",
        });
      }
    }
  }

  changeFile(ev: any) {
    this.fileToUpload.push(ev[0]);
  }

  sBtnDisabled(res: any) {
    if (res) {
      setTimeout(() => {
        this.saveBtn = false;
        this.router.navigate(["/task-managment"]);
      }, 1500);
    } else {
      this.saveBtn = false;
    }
  }
}
