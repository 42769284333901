import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { EventsService } from 'src/app/services/events.service';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-event-attendance',
  templateUrl: './event-attendance.component.html',
  styleUrls: ['./event-attendance.component.scss']
})
export class EventAttendanceComponent implements OnInit {

  @ViewChild('content') content!: ElementRef;
  public LIMIT = 12;
  public LIMIT_GUEST = 50;
  private _searchOptions: any[] = [];
  get searchOptions() {
    this.getMemberToSearch()
    return this._searchOptions
  }
  searchMember: any;
  selectedMember: any;
  members: any[] = [];
  subscription: Subscription[] = [];
  event: any = this.eventService.emptyEvent;
  events: any = this.eventService.empEvent;
  inviteMap = {
    Accepted: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
    Maybe: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
    Declined: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
    Pending: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
  }
  inviteMapKeys = Object.keys(this.inviteMap);
  currAttendance = 'accepted'

  constructor(
    private eventService: EventsService,
    private route: ActivatedRoute,
    private memberService: MembersService,
    private modalService: NgbModal,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.memberService.loadMembers();
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          let resp = await this.eventService.getEvent(id, '').toPromise();
          this.event = resp.result;
          this.events.id = this.event.id;
          this.events.eventDate = new Date(this.event.eventDate).toISOString();
          this.events.content = this.event.content;
          this.events.name = this.event.name;
          this.events.canPlaceImageAbove = this.event.canPlaceImageAbove ? 'true' : 'false';
          this.events.eventNode = {
            aId: this.event.nodeDetails.aId,
            id: this.event.nodeDetails.id,
            type: this.event.nodeDetails.type,
            name: this.event.nodeDetails.name
          };
          this.events.length = {
            unit: this.event.durationType === 1 ? 'hours' : 'minutes',
            amount: this.event.duration
          };
          this.events.canPlaceImageAbove = this.event.canPlaceImageAbove ? 'above' : 'below';
          let localtime = new Date(this.event.eventDate).toTimeString().split(':');
          this.events.time = {
            hour: Number.parseInt(localtime[0]),
            minute: Number.parseInt(localtime[1]),
            second: 0
          };
          this.events.members = this.event.memberInvitees;
          this.events.guests = this.event.guestInvitees;
          // for(let i = 0; i < 48; i++) {
          //   if (i < 12)
          //     this.inviteMap.Accepted.listMember.push(this.events.members[0]);
          //   else if (i > 12 && i < 24)
          //     this.inviteMap.Maybe.listMember.push(this.events.members[0]);
          //   else if (i > 24 && i < 36)
          //     this.inviteMap.Pending.listMember.push(this.events.members[0]);
          //   else if (i > 36 && i < 48)
          //     this.inviteMap.Declined.listMember.push(this.events.members[0]);
          // }
          this.events.members.forEach(member => {
            this.inviteMap[member.inviteStatus].listMember.push(member);
          });
          // for(let i = 0; i < 48; i++) {
          //   if (i < 12)
          //     this.inviteMap.Accepted.listGuest.push(this.events.guests[0]);
          //   else if (i > 12 && i < 24)
          //     this.inviteMap.Maybe.listGuest.push(this.events.guests[0]);
          //   else if (i > 24 && i < 36)
          //     this.inviteMap.Pending.listGuest.push(this.events.guests[0]);
          //   else if (i > 36 && i < 48)
          //     this.inviteMap.Declined.listGuest.push(this.events.guests[0]);
          // }
          this.events.guests.forEach(guest => {
            this.inviteMap[guest.inviteStatus].listGuest.push(guest);
          });
        }
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members;
      })
    );

  }

  getMemberToSearch() {
    this._searchOptions.splice(0, this._searchOptions.length)
    if (this.searchMember) {
      let members = this.members.filter(member => !this.events.members.some(listMember => listMember.id === member.id) && member.identifier.toLowerCase().includes(this.searchMember))
      members.forEach(member => {
        this._searchOptions.push(member)
      })
    }
  }

  toggleIsShowAll(key) {
    this.inviteMap[key].isShowAll = !this.inviteMap[key].isShowAll
  }

  onChooseUser(member) {
    this.searchMember = member.identifier;
    this.selectedMember = member;
  }

  async onSend() {
    this.eventService.addInvitesMemberToEvent({ eventId: this.events.id, memberId: this.selectedMember.id }).subscribe({
      next: (data: any) => {
        this.events.members = [...this.events.members, data.result];
        this.inviteMap[data.result.inviteStatus].listMember.push(data.result);
        this.errorHandler.openToast({ title: "SUCCESS", type: "success", message: "Member invited successfully" });
      },
      error: (err: any) => {
        this.errorHandler.openToast({ title: "ERROR", type: "error", message: err.message });
      }
    });
    this.searchMember = '';
  }

  async removeMember(member) {
    if (member.identifier) {
      this.eventService.removeInvitesMemberToEvent('member', member.inviteEventMemberId).subscribe({
        next: (data: any) => {
          this.events.members = this.events.members.filter(invitedMem => invitedMem.id !== member.id);
          this.inviteMap.Accepted.listMember = this.inviteMap.Accepted.listMember.filter(inviteMember => inviteMember.id !== member.id)
          this.inviteMap.Declined.listMember = this.inviteMap.Declined.listMember.filter(inviteMember => inviteMember.id !== member.id)
          this.inviteMap.Maybe.listMember = this.inviteMap.Maybe.listMember.filter(inviteMember => inviteMember.id !== member.id)
          this.inviteMap.Pending.listMember = this.inviteMap.Pending.listMember.filter(inviteMember => inviteMember.id !== member.id)
          this.errorHandler.openToast({ title: "SUCCESS", type: "success", message: "Member uninvited successfully" });
        },
        error: (err: any) => {
          this.errorHandler.openToast({ title: "ERROR", type: "error", message: err.message });
        }
      });
    } else {
      this.eventService.removeInvitesMemberToEvent('guest', member.eventGuestId).subscribe({
        next: (data: any) => {
          this.events.guests = this.events.guests.filter(listGuest => listGuest.mailId !== member.mailId)
          this.inviteMap.Accepted.listGuest = this.inviteMap.Accepted.listGuest.filter(guest => guest.email !== member.email)
          this.inviteMap.Declined.listGuest = this.inviteMap.Declined.listGuest.filter(guest => guest.email !== member.email)
          this.inviteMap.Maybe.listGuest = this.inviteMap.Maybe.listGuest.filter(guest => guest.email !== member.email)
          this.inviteMap.Pending.listGuest = this.inviteMap.Pending.listGuest.filter(guest => guest.email !== member.email)
          this.errorHandler.openToast({ title: "SUCCESS", type: "success", message: "Guest uninvited successfully" });
        },
        error: (err: any) => {
          this.errorHandler.openToast({ title: "ERROR", type: "error", message: err.message });
        }
      });
    }
  }

  isMoreThatLimit(attendance) {
    let num = attendance.listGuest.length > this.LIMIT_GUEST ? 1 : attendance.listGuest.length
    num += attendance.listMember.length
    return num > this.LIMIT
  }

  open(attendance) {
    this.currAttendance = attendance;
    this.modalService.open(this.content, { size: 'sm' });
  }

}
