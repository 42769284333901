import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-media',
  templateUrl: './organization-media.component.html',
  styleUrls: ['./organization-media.component.scss']
})
export class OrganizationMediaComponent implements OnInit {

  constructor() { }

  youtubeUrl='http://www.google.com/search?igu=1'

  ngOnInit(): void {
  }

}
