import { Component, Input, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { OrganizationServerService } from 'src/app/services/organization-server.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { TokenService } from 'src/app/token/token.service';
import { ReferForm } from 'src/app/models/eums';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-organization-header',
  templateUrl: './organization-header.component.html',
  styleUrls: ['./organization-header.component.scss']
})
export class OrganizationHeaderComponent implements OnInit {

  @Input() organization: Group

  orgName:any;
  imageSource:any='';
  constructor(    private loggedInUser:TokenService,private orgDetails:OrganizationalStructureService,
    private file:FilesService,
    private domSanitizer:DomSanitizer) {
   }

  ngOnInit(): void {
    var a = this.loggedInUser.getUser();
    this.orgName = a.organizationName;

    this.orgDetails.loadOrganizationalStructure()
    this.orgDetails.organizationalStructure$.subscribe((data: any) => {
      for (let l of data) {
        if (l.type == 'organization') {
          var uploadData = {
            fkId: l.aId,
            formType: ReferForm.Organization,
            isAttachment: false
          }
          //console.log(uploadData);
          if (uploadData.fkId != undefined) {
            this.file.getFileFkid(uploadData).subscribe((data: any) => {
              if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
                if (data.result) {
                  var id = data.result[0];
                  this.file.getFilebyId(id.id).subscribe((data: any) => {
                    
                    this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
                  })
                } 
              }
            })
          }
        }
      }

    })


  }

}
