<section dirControl class="about-section bg-white">
    <div class="container">
        <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div class="inner-column">
                    <div class="sec-title">
                        <span class="title">{{'about'|translatePipe}} Flexyc</span>
                        
                    </div>
                    <div class="text">{{'lorem1'|translatePipe}}
                    </div>
                    <div class="text">
                       {{'lorem2'|translatePipe}}
                    </div>
                    
                </div>
            </div>

            <!-- Image Column -->
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column wow fadeInLeft">
                  
                    <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img
                                 src="assets/images/logo-inverse.jpeg" alt=""></a>
                    </figure>

                </div>
            </div>

        </div>
        
    </div>
</section>