import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-manage-employees',
  templateUrl: './manage-employees.component.html',
  styleUrls: ['./manage-employees.component.scss']
})
export class ManageEmployeesComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  employees = []
  search = ''

  constructor(
    private accountService: PortalAccountsService,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.portalAccounts$.subscribe(accounts => {
        //console.log(accounts);
        const employees = accounts.filter(account => account.type === 'employee')
        this.updateEmployees(employees);
      })
    )
    this.accountService.loadPortalAccounts(1);

  }

  updateEmployees(employees) {
    this.employees = employees.map(employee => ({ ...employee, isCheck: false }));
  }

  filterEmployees() {
    return this.employees.filter(employee => (
      employee.data.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      employee.contactInfo.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  deleteEmployee(employee) {
    this.accountService.delete(employee.id, 1).toPromise();
  }

  getNumOfSelected() {
    return this.employees.reduce((acc, employee) => {
      if (employee.isCheck) acc++
      return acc
    }, 0)
  }

  unselect(){
    this.updateEmployees(this.employees)
  }

  deleteSelected(){
    const selected = this.employees.filter(employee=>employee.isCheck)
    //console.log(selected);
    selected.forEach(employee=>{
      this.deleteEmployee(employee)
    })
    
  }
}
