import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'li[tree]',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() group !: Group;
  @Input() action !: string;
  @Input() isShowButtons = false;
  @Output() onSelectChild = new EventEmitter<string>()
  @Output() onSetCurrAction = new EventEmitter<string>()
  @Output() toggleSpinner = new EventEmitter<boolean>();
  children: Group[] = [];
  path: any;

  constructor(private organizationalStructureService: OrganizationalStructureService,
    private router: Router) { }

  ngOnInit(): void {
    this.path = this.router.url;
    this.updateChildren()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChildren();
  }

  async updateChildren() {
    //if (this.children.length === 0 && this.group.children.length > 0) {
      //console.log(`updateChildren - called - ${this.group.id} ${this.group.name} ${this.group.children}`);
      this.toggleSpinner.emit(true);
      const newChildren: Group[] = this.group?.children?.map(value => this.organizationalStructureService.emptyGroup);
      let promises = this.group?.children?.map(async (childId, idx) => {
        const child: any = await this.organizationalStructureService.getGroupById(childId).toPromise()
        return child.result;
      });
      if (promises) {
        Promise.all(promises).then(resolve => {
          this.children = resolve;
          this.toggleSpinner.emit(false);
        })
      }
    //}
  }

  clickedHoveredButton(action: string, groupId) {
    this.onSetCurrAction.emit(action);
    this.onSelectChild.emit(groupId);
    this.onSetCurrAction.emit('');
  }
}
