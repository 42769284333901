import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { Set } from 'src/app/interfaces/set';

@Component({
  selector: 'app-notifications-table',
  templateUrl: './notifications-table.component.html',
  styleUrls: ['./notifications-table.component.scss']
})
export class NotificationsTableComponent implements OnInit {
  constructor() { }

  page = 1;
  pageSize = 10;

  @Input() notifications: any[];
  membersToShow = [];

  ngOnInit(): void {
    this.setMembersToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setMembersToShow()
  }

  getClass() {
    return `checkbox-container ${this.notifications.find(member => member.isCheck) ? 'checked' : ''}`
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  setMembersToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.membersToShow = this.notifications?.slice(start, start + this.pageSize);
  }
}
