<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-6">
      <!-- <div class="app-logo-inverse mx-auto mb-3"></div> -->
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-auto mb-3 text-center">
              <img src="/assets/images/flexyc-logo.png" style="width: 100px; height: 55px;"/>
            </div>
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <span>Reset your Password?</span>
              </h4>
            </div>
            <div>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  
                <div class="mb-3">
                  <label class="form-label" for="password-input">Enter new password</label><span class="text-danger">*</span>
                  <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="Enter password" id="password-input"
                      formControlName="password" type="password"
                      [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                      <span *ngIf="f['password'].errors['required']">Password is
                        required</span>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label" for="password-input">Confirm password</label><span class="text-danger">*</span>
                  <div class="position-relative auth-pass-inputgroup mb-3">
                    <input class="form-control pe-5" placeholder="Enter password" id="password-input"
                      formControlName="confirmPassword" type="password"
                      [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
                    <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                      <span *ngIf="f['confirmPassword'].errors['required']">Confirm your new password</span>
                    </div>
                  </div>
                </div>
  
                <div class="d-flex">
                  <button type="submit"  class="btn btn-primary btn-lg ms-auto">
                    <span class="d-flex align-items-center"> {{ 'reset pass'|translatePipe }} <span class="spinner-border"
                      *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;"></span></span>
                  </button>
                </div>
  
  
              </form>
            </div>
            <!-- <div class="divider"></div> -->
          </div>
          <!-- <div class="modal-footer clearfix">
            <button (click)="onSubmit()" type="submit" class="btn btn-primary btn-lg">
              <span class="d-flex align-items-center"> {{ 'Reset'|translatePipe }} <span class="spinner-border"
                  *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;"></span></span>
            </button>
          </div> -->
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Flexyc {{currDate | date:'yyyy'}}
      </div>
    </div>
  </div>
</div>