<div dirControl class="row mb-3">
    <div class="col-12 mb-3">
        <p [ngClass]="group.type" style="font-size: 20px;" class="card-title">{{'members of '+group.type|translatePipe}}: {{group.name}}</p>
        <p [ngClass]="groupParent.type" style="font-size: 15px;" class="card-title">{{'in '+groupParent.type|translatePipe}}: {{groupParent.name}}
        </p>
    </div>
    <div class="col-12">
        <div class="card search-card">
            <div class="card-body">
                <form>
                    <div class="position-relative form-group">
                        <form class="">
                            <div class="position-relative form-group search-container">
                                <label>
                                    <i class="pe-7s-search"></i>
                                </label>
                                <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                                    [placeholder]="'search'|translatePipe">
                            </div>
                        </form>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="mb-5">
    <app-members-table (onRemoveMemberFromGroup)="openSureModal($event,true)" (mem)="mem($event)"
        (toggleIsModerator)="toggleIsModerator($event)" [groupId]="group.id" [members]="filterMembers()">
    </app-members-table>
</div>
<div *ngIf="isHaveChecked()" class="header mb-3 card">
    <div class="card-body">
        <div class="row">
            <div class="col-6 p-3 text-center">
                <button (click)="unselectMembers()" class="btn btn-primary">{{'Unselect Members'|translatePipe}}</button>
            </div>
            <div class="col-6 p-3 text-center">
                <button (click)="onRemoveSelectedMembers()" class="btn btn-danger">{{'Delete Selected Members'|translatePipe}}</button>
            </div>
        </div>
    </div>
</div>

<button (click)="open()" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw" aria-hidden="true"
        title="{{'add'|translatePipe}}"></i>
</button>


<ng-template #addMember let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{'Add administrators'|translatePipe}}</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-dropdown-input [(text)]="addMemberSearch" [dropDownOpions]="getMemberToSearch()"
            (chooseOption)="openSureModal($event,false,close)">
        </app-dropdown-input>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            {{'Close'|translatePipe}}
        </button>
    </div>
</ng-template>

<ng-template #sureModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{isSureDelete?('remove'|translatePipe):('add'|translatePipe)}} {{'member'|translatePipe}}
        </h5>

        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            isSureDelete?
            ('Are you sure you want to remove member'|translatePipe):
            ('Are you sure to add member'|translatePipe)
            }} {{choosenMember.identifier}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" [class.btn-danger]="isSureDelete" [class.btn-primary]="!isSureDelete"
            (click)="onAcceptModal(close)">
            {{isSureDelete?('remove'|translatePipe):('add'|translatePipe)}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            {{'Close'|translatePipe}}
        </button>
    </div>
</ng-template>