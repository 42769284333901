import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TokenService } from './token/token.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  orgName: any;
  titles = 'Flexyc';

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tokService: TokenService) {

  }

  ngOnInit() {
    var user = this.tokService.getUser();
    if (user) {
      this.orgName = user.organizationName;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        if (data.title != null) {
          this.titleService.setTitle(data.title + " • " + this.orgName + " • " + "Flexyc");
        }
        else if (data.title === null) {
          this.titleService.setTitle("Flexyc")
        }

      });
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

}
