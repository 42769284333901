import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppState } from 'src/app/store/app.store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from 'src/app/token/token.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SignalRChatService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chatWrapper') elChatWrapper;
  @ViewChild('audioOption') audioPlayerRef: ElementRef;

  loggedInMember = this.memberService.emptyMember
  toggleMobileSidebar: any;
  faStar = faStar;
  faBusinessTime = faBusinessTime;
  faCog = faCog;
  faCalendarAlt = faCalendarAlt;
  faSearch = faSearch;
  loggedInUserId = "";
  member: Member = this.memberService.emptyMember;
  memberGroups: Group[] = []
  newMsgText = '';
  currChat = null
  chatsWithArr = [];
  chatsWithFilterArr = [];
  isShowChatsWith = true
  allMemberOfmemberGroups: Member[] = []
  filteredMemberOfmemberGroups: Member[] = []
  subscription: Subscription[] = [];
  private _searchChatWith = '';
  private _searchNewChatWith = '';
  isTyping$ = this.chatService.isTyping$
  scrollBehavihor = 'auto';

  get searchChatWith() {
    return this._searchChatWith
  }

  set searchChatWith(val) {
    this._searchChatWith = val
    this.setChatsWithFilterArr();
  }

  get searchNewChatWith() {
    return this._searchNewChatWith
  }

  set searchNewChatWith(val) {
    this._searchNewChatWith = val
    this.setFilteredMemberOfmemberGroups()
  }

  chatsMap = this.chatService.getChats()


  ///v
  members: any = [];
  contacts: any = [];
  channels: any = [];
  chatId: any;
  messages: any = [];
  addBtn: boolean = false;
  addChannel: string;
  topBar: string = 'channels';
  activeId: number = 1;
  constructor(
    private memberService: MembersService,
    private organizationStructureService: OrganizationalStructureService,
    private elementRef: ElementRef,
    private store: Store<AppState>,
    private chatService: ChatService,
    public sanitizer: DomSanitizer,
    private token: TokenService,
    private errHandle: ErrorHandlerService,
    private signalRService: SignalRChatService
  ) { }
  
  
  ngOnDestroy(): void {
    this.signalRService.unsubscribe();
  }


  async ngOnInit(): Promise<void> {
    var a = this.token.decodedToken()
    this.loggedInUserId = a.nameid
    this.memberService.loadMembers();
    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        if (members.length > 0) {
          this.members = members
        }
      })
    )
    this.getContacts()

    this.signalRService.retrieveMappedObject().subscribe(data => {
      //console.log(data);
      if (data.ownerId !== parseInt(this.loggedInUserId)) {
        if (this.activeId === 1 && data.chatType === 1) {
          this.channels.filter(channel => { return channel.id === data.chatId; })[0].message = data.message;
          if (this.chatId !== data.chatId) {
            //console.log('Message received in channel not selected.');
            this.onAudioPlay();
          }
        }else if (this.activeId === 2 && data.chatType === 2) {
          this.contacts.filter(contact => { return contact.id === data.chatId; })[0].message = data.message;
          if (this.chatId !== data.chatId) {
            //console.log('Message received in channel not selected.');
            this.onAudioPlay();
          }
        }
        if (this.chatId === data.chatId) {
          this.messages[this.messages.length - 1].messageList.push(data);
        }
        this.scrollBehavihor = 'smooth'
        setTimeout(() => {
          this.scrollChatWrapper();
        }, 1);
      }
    });
    //////////
    // this.subscription.push(
    //   this.store.select('auth').subscribe(async auth => {
    //     if (auth.id) {
    //       this.loggedInUserId = auth.id
    //       this.loggedInMember = await this.memberService.getMember(this.loggedInUserId).toPromise();
    //     }
    //   })
    // )

    // this.member = await this.memberService.getMember(this.loggedInUserId).toPromise();
    // const allGroups = await this.organizationStructureService.getGroups().toPromise()
    // this.memberGroups = allGroups.filter(group => group.members.some(memberId => memberId === this.loggedInUserId))
    // const allMemberIdsOfmemberGroups = this.memberGroups.reduce((acc, group) => {
    //   group.members.forEach(memberId => {
    //     if (!acc.some(accMemberId => accMemberId === memberId)) acc.push(memberId)
    //   })
    //   return acc
    // }, [])
    // const allMembers = await this.memberService.getMembersPromise();
    // this.allMemberOfmemberGroups = allMembers.filter(member => allMemberIdsOfmemberGroups.some(memberId => memberId === member.id) && member.id !== this.loggedInUserId)
    // this.setFilteredMemberOfmemberGroups()
    // this.memberGroups.forEach(group => {
    //   if (!this.chatsMap[group.id]) this.chatService.addChat(group.id)
    // })
    // this.setChatWithArr()
  }

  ngAfterViewInit(): void {


  }

  newMsgTextChange(newMsg) {
    this.newMsgText = newMsg
    this.chatService.onTyping();
  }

  sendMsg(ev) {

    const data = {
      chatId: this.chatId,
      message: this.newMsgText
    }
    if (this.newMsgText == "") {
      this.errHandle.openToast({ title: 'ALERT', type: 'warning', message: 'Type something!' })
      return;
    }
    this.chatService.sendNewMsg(data).subscribe({
      next: (data: any) => {
        //console.log(data);
        if (this.activeId === 1) {
          this.channels.filter(channel => { return channel.id === data.result.chatId; })[0].message = data.result.message;
        }else {
          this.contacts.filter(contact => { return contact.id === data.result.chatId; })[0].message = data.result.message;
        }
        if (this.messages.length > 0) {
          this.messages[this.messages.length - 1].messageList.push(data.result);
        } else {
          this.messages.push({ 
            date: new Date().toISOString(),
            messageList: [data.result]
          });
        }
        
        this.scrollBehavihor = 'smooth'
        setTimeout(() => {
          this.scrollChatWrapper();
        }, 1);

      },
      error: (err: any) => {
        this.errHandle.openToast({ title: 'ERROR', type: 'error', message: 'Message cannot able to send!' })
      }
    })

    ev.preventDefault();
    // this.chatService.sendMsg(this.newMsgText, this.currChat.id, this.loggedInMember);
    // this.newMsgText = '';
    //this.scrollBehavihor = 'smooth'
    this.newMsgText = ''
    // setTimeout(() => {
    //   this.scrollChatWrapper();
    //   // this.onAudioPlay();
    // }, 100)
  }

  scrollChatWrapper() {
    this.elChatWrapper.nativeElement.scrollTop = this.elChatWrapper.nativeElement.scrollHeight
    setTimeout(() => {
      this.scrollBehavihor = 'auto';
    }, 100)
    this.elChatWrapper.scrollTop = this.elChatWrapper.scrollHeight;
  }

  getChatData(id) {
    let lastMsgAt = 0
    if (this.chatsMap[id][this.chatsMap[id]]) {
      lastMsgAt = this.chatsMap[id][this.chatsMap[id].length - 1][this.chatsMap[id][this.chatsMap[id].length - 1].length - 1]?.sendAt
    }
    const order = lastMsgAt ? Date.now() - lastMsgAt : Date.now()
    const member = this.allMemberOfmemberGroups.find(member => member.id === id)
    if (member) return {
      name: member.identifier,
      imgUrl: member.profileImg,
      id,
      order,
      email: member.email,
      type: 'member'
    }
    const group = this.memberGroups.find(group => group.id === id)
    if (group) return {
      name: group.name,
      imgUrl: group.logoUrl,
      id,
      order,
      type: 'group'
    }
  }



  //v-Click add button  and Then room created when select  a member.
  createNewChat(member) {
    var data = {
      chatId: 0,
      receipientId: member.id,
      message: "Welcome to this tribal group"
    }
    this.chatService.createRoom(data).subscribe({
      next: (data: any) => {

        this.getContacts();
        this.currChat = false;
        this.activeId = 2;

        this.errHandle.openToast({ title: 'SUCCESS', type: 'success', message: 'Contact created successfully!' })
      }, error: (err: any) => {

        this.getContacts();
        this.currChat = false;
        this.activeId = 2;

        this.errHandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result })
      }
    })


    this.isShowChatsWith = true;
    this.searchNewChatWith = '';
    const currChat = {
      name: member.identifier,
      imgUrl: member.profileImg,
      id: member.id,
      order: Date.now(),
      email: member.email
    }
    this.currChat = currChat
    this.chatsMap[member.id] = [];
    // this.setChatWithArr();

  }

  createNewContacts(member) {
    //console.log(member);
    this.chatId = member.id
    this.getChat();
    this.isShowChatsWith = true;
    this.searchNewChatWith = '';
    const currChat = {
      name: member.title,
      imgUrl: member.profileImg,
      id: member.id,
      order: Date.now(),
      email: member.email
    }
    this.currChat = currChat
    this.chatsMap[member.id] = [];
    // this.setChatWithArr();


  }

  returnToChatWith() {
    this.isShowChatsWith = true;
    this.searchNewChatWith = '';
  }

  chooseChat(chatWith) {
    this.currChat = chatWith;
    // this.scrollChatWrapper();
    setTimeout(() => {
      this.scrollChatWrapper();
    }, 10);
  }

  setChatsWithFilterArr() {
    this.chatsWithFilterArr = this.chatsWithArr.filter(chatWith => chatWith.name.toLowerCase().includes(this.searchChatWith.toLocaleLowerCase()))
  }

  setFilteredMemberOfmemberGroups() {
    this.filteredMemberOfmemberGroups = this.allMemberOfmemberGroups.filter(member => member.identifier.toLowerCase().includes(this.searchNewChatWith.toLocaleLowerCase()))
  }

  setChatWithArr() {
    //console.log('setChatWithArr');
    this.chatsWithArr = Object.keys(this.chatsMap).map(key => this.getChatData(key));

    this.setChatsWithFilterArr();
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }


  //v-Get channenl and contact details,stored in global variable  of two(channels and contacts)
  getContacts() {
    this.contacts = [];
    this.channels = [];
    this.chatService.getContacts().subscribe((data: any) => {

      for (let l of data.result) {

        if (l.chatType == 1) {
          this.channels.push(l);
        }
        else if (l.chatType == 2) {
          this.contacts.push(l);
        }
      }
    })
  }


  //v-get chat when click a contact or channel list.
  getChat() {
    this.chatService.getChatById(this.chatId).subscribe((data: any) => {
      this.messages = data.result;
      this.scrollBehavihor = 'smooth'
        setTimeout(() => {
          this.scrollChatWrapper();
        }, 1);
      //console.log(this.messages);
    });
  }

}
