import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FullScreenImgService } from 'src/app/services/full-screen-img.service';

@Component({
  selector: 'app-full-screen-img',
  templateUrl: './full-screen-img.component.html',
  styleUrls: ['./full-screen-img.component.scss']
})
export class FullScreenImgComponent implements OnInit {

  constructor(
    private fullScreenImgService: FullScreenImgService,
    private domSanitizer:DomSanitizer
  ) { }

  imgSrc:any = ''
  subscription: Subscription

  ngOnInit(): void {
    this.subscription = this.fullScreenImgService.img$.subscribe(imgSrc =>{ this.imgSrc =
     imgSrc
    }
      )
  }

  closeImg(){
    this.fullScreenImgService.onCloseImage();
  }

}
