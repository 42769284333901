import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { TokenService } from 'src/app/token/token.service';

@Component({
  selector: 'app-organization-dashboard',
  templateUrl: './organization-dashboard.component.html',
  styleUrls: ['./organization-dashboard.component.scss']
})
export class OrganizationDashboardComponent implements OnInit {

  subscription: Subscription[] = [];
  organization: Group
  title: string = '';
  empData: any;
  empData1: any;
  loggedInAccount: any = '';
  dashboardData = [
    {
      icon: 'pe-7s-user',
      title: 'My Profile',
      color: 'red',
      routerLink: '/profile',
      class: 'widget-chart-hover'
    },
    {
      icon: 'pe-7s-chat',
      title: 'chat',
      color: 'red',
      routerLink: '/chat',
      class: 'widget-chart-hover'
    },
    {
      icon: 'pe-7s-date',
      title: 'calendar',
      color: 'indigo',
      routerLink: '/calendar',
      class: 'widget-chart-hover'
    },
    // {
    //   icon: 'pe-7s-menu',
    //   title: 'sets',
    //   color: 'purple',
    //   routerLink: '/sets'
    // },
    {
      icon: 'pe-7s-id',
      title: 'cards',
      color: 'purple2',
      // routerLink: '/cards'
    },
    {
      icon: 'vsm-icon pe-7s-check',
      title: 'tasks',
      color: 'purple',
      routerLink: '/tasks',
      class: 'widget-chart-hover'
    },
    {
      icon: 'pe-7s-network',
      title: 'groups',
      color: 'pink',
      routerLink: '/groups',
      class: 'widget-chart-hover'
    },
    // {
    //   icon: 'pe-7s-film',
    //   title: 'media',
    //   color: 'green2',
    //   routerLink: '/organization-media'
    // },
    // {
    //   icon: 'pe-7s-cart',
    //   title: 'ecommerce',
    //   color: 'blue2',
    //   routerLink: '/organization-ecommerce'
    // },

    {
      icon: 'pe-7s-comment',
      title: 'social',
      color: 'blue',
      routerLink: '/club-social-network',
      class: 'widget-chart-hover'
    },

  ]


  userAccTypes: any;
  accData = [];

  constructor(
    public organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private portalAccountService: PortalAccountsService
  ) { }


  async ngOnInit(): Promise<void> {
    this.loggedInAccount = this.tokenService.decodedToken().nameid;
    let acc = await this.portalAccountService.getById(this.loggedInAccount).toPromise();

    this.portalAccountService.getAccountsByUserId(this.loggedInAccount).subscribe((data: any) => {
      if (Array.isArray(data.result) && data.result.length > 0) {
        this.userAccTypes = data.result;
        this.userAccTypes.forEach((account: any) => {
          this.accData.push({
            icon: 'pe-7s-display1',
            title: account.type + ' portal',
            color: 'green',
            routerLink: `/account-portal/${account.id}`
          });
          this.accData.push({
            icon: 'pe-7s-user',
            title: account.type + ' directory',
            color: 'blue',
            routerLink: `/account-directory/${account.id}`
          });
        })
      }
    });

    // this.title = acc.result.type;
    // if (this.title != undefined) {
    //   let data: any = [{
    //     icon: 'pe-7s-display1',
    //     title: this.title + ' portal',
    //     color: 'green',
    //     routerLink: '/customer-portal'
    //   }]

    //   this.empData = data;
    //   let data2: any = [{
    //     icon: 'pe-7s-user',
    //     title: this.title + ' account',
    //     color: 'blue',
    //     routerLink: '/' + this.title + '-directory'
    //   }]
    //   this.empData1 = data2;
    // }

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const organizationId = params.get('id');
        if (organizationId) {
          this.organization = await this.organizationalStructureService.getGroupById(organizationId).toPromise()
        }
      })
    )


  }

  onChooseGroup(groupId) {
    this.router.navigate(['group-view', groupId]);
  }

}
