import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from '../interfaces/group';
import { Department } from './organizational-structure.type';
import { UtilService } from './util.service';
import { environment } from 'src/environments/environment';
import { globalComponent } from '../global-component.service';
import { TokenService } from '../token/token.service';
import { AppConfig } from './app.config';
@Injectable({
  providedIn: 'root'
})

export class OrganizationalStructureService {

  department: Department;

  constructor(
    private http: HttpClient,
    private utilService: UtilService, private token: TokenService) { }

  private BASE_URL = AppConfig.settings.apiServer.baseUrl + 'Organization/structure';
  public emptyGroup: Group = {
    id: '', aId: 0, type: 'group', name: '', description: '', logoUrl: '', parent: "", title: '', members: [], children: [], chatSettings: {
      isAllowChat: false, lastActivity: Date.now()

    }
  }
  private _organizationalStructure$ = new BehaviorSubject<Group[]>([])
  public organizationalStructure$ = this._organizationalStructure$.asObservable();

  private action$ = new BehaviorSubject<any>('');
  // public _getobs$= new BehaviorSubject<group1[]>([])
  // public getobs$= this._getobs$.asObservable();

  public loadOrganizationalStructure() {
    this.http.get<any>(AppConfig.settings.apiServer.baseUrl + globalComponent.organization)
      .subscribe(organizationalStructure => {

        this._organizationalStructure$.next(organizationalStructure.result);
        //this._organizationalStructure$.next(organizationalStructure.result);
      });
  }


getContc(id:any){
 return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl }${ globalComponent.getOrgContc}${id}`)
}

removeContc(id){
  return this.http.delete<any>(`${AppConfig.settings.apiServer.baseUrl }${ globalComponent.getOrgContc}${id}`)
}


  public getGroups() {
    return this.http.get<any>(this.BASE_URL);
  }

  public getGroupById(id: string) {

    return this.http.get<any>(`${this.BASE_URL}/${id}`)
  }

  //get groupby  specific id
  public getGroupByIds(id: string) {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.getGroupByIds}` + id)

  }
  //get current organization
  getCurrentOrganization(id) {
    return this.http.get<any>(AppConfig.settings.apiServer.baseUrl + "Organization/" + id);
  }

  public getOrganizationalStructure() {
    return this._organizationalStructure$.getValue();
  }

  public async addGroup(idParent: string, name?: string, description?: string) {

    const parent = await this.getGroupByIds(idParent).toPromise()

    const child = {
      name: parent.result.type === 'organization' ? 'New Department' : 'New Group',
      type: parent.result.type === 'organization' ? 'department' : 'group',
      // parent: parent.result.aid,
      // description: '',
      // logoUrl: '',
      // members: [],
      // children: [],
      // chatSettings: {
      //   isAllowChat: false,
      //   lastActivity: Date.now()
      // }
      parent: parent.result.aId,
      description: '',
    }

    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.createDept}`, child).pipe(
      map(fullChild => {
        return fullChild
      })
    )
  }

  //N-createDept

  addGroups(datas: any) {

    return this.http.post<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.createDept}`, datas)
  }

  public async removeGroup(id: any) {
    return this.http.delete<Group>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.removeDept}${id}`)
  }

  updateIds(data: any) {
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.editOrg}`, data)
  }

  public async updateGroupById(ids: string, data: any) {
    const { name, description, logoUrl } = data
    const afterChanges: any = {
      ids,
      name,
      description,
      logoUrl
    }

    return this._updateGroup(afterChanges)

  }

  public async updateGroupByIds(data: any) {


    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.editOrg}`, data);

  }

  public addGroupMember(groupId: number, memberId: number) {

    return this.http.post(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.addGrpMem}`, { groupId: groupId, memberId: memberId })
  }
  //remove specific members from group
  removeMem(groupId: any, memberId: number[]) {

    return this.http.delete<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.delGrpMem}`, {
      headers: new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('token') }),
      body: {
        groupId: groupId,
        members: memberId
      }
    })

  }


  public async removeGroupMember(groupId: string, memberId: string) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      members: beforeChanges.members.filter(member => member !== memberId)
    }
    return this._updateGroup(afterChanges);
  }

  public async toggleGroupIsAllowChat(groupId: string) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      chatSettings: {
        ...beforeChanges.chatSettings,
        isAllowChat: !beforeChanges.chatSettings.isAllowChat,
      }
    }
    return this._updateGroup(afterChanges);
  }

  public async removeGroupMembers(groupId: string, memberIds: string[]) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      members: beforeChanges.members.filter(member => !memberIds.some(memberId => memberId === member))
    }
    return this._updateGroup(afterChanges)
  }

  private _updateGroup(group: any) {
    //console.log(group);
    let data: any = {
      id: group.ids,
      name: group.name,
      description: group.description
    }
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.editOrg}`, data).pipe(
      map(result => {
        this.loadOrganizationalStructure();
        return group
      })
    )
  }

  updateOrganization(data: any) {
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.updateOrg}`, data).pipe(
      map(result => {
        return result;
      })
    );
  }

  getGroupDashData() {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.grpDashData}`);
  }

  getDeptDashData() {
    return this.http.get<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.deptDashdata}`);
  }

  toggleModeratorStatus(gid: number, uid: number) {
    return this.http.patch<any>(`${AppConfig.settings.apiServer.baseUrl}${globalComponent.grpMod}${gid}/${uid}`, {}).pipe(
      map(result => {
        return result;
      })
    );
  }

}
