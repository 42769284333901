<app-system-settings-general-information #generalInformation [group]="group">
</app-system-settings-general-information>

<div dirControl class="card mb-3">
    <div class="card-body">
        <h3 class="card-title">{{'privacy'|translatePipe}}</h3>
        <!-- <fieldset class="mb-3">
            <label class="form-label">
            </label>
            <div class="flex gap-10 mb-3">
                <input class="" type="radio" name="typeRadios" [(ngModel)]="isPublic" [value]="true"
                    id="public">
                <label class="" for="public">
                    {{'SystemSettings.publicClub'|translatePipe}}

                </label>
            </div>
            <div class="flex gap-10 mb-3">
                <input class="" type="radio" name="typeRadios" [(ngModel)]="isPublic" [value]="false"
                    id="private">
                <label class="" for="private">
                    {{'SystemSettings.privateClub'|translatePipe}}
                </label>
            </div>
        </fieldset> -->
        <div class="options-container">
            <div (click)="isPublic=true" [ngClass]="isPublic?'curr':''" class="privacy-option">
                <h3 class="card-title">{{'publiclub' | translatePipe}}</h3>
                <p>{{'Allow new users to sign up in independently'|translatePipe}}</p>
            </div>
            <div (click)="isPublic=false" [ngClass]="!isPublic?'curr':''" class="privacy-option">
                <h3 class="card-title">{{'Private club'|translatePipe}}</h3>
                <p>{{'Users can only be added by an admin'|translatePipe}}</p>
            </div>
        </div>
    </div>
</div>

<!-- <div class="card mb-3">
    <div class="card-body">
        <h3 class="card-title">Options</h3>
        <app-check-box-label [(isCheck)]="isEnableFreeze" name="freaze" label="Enable freeze status">
        </app-check-box-label>
        <app-check-box-label [(isCheck)]="isRequireLeave" name="require-leave" label="Require leave request system">
        </app-check-box-label>
    </div>
</div> -->

<div dirControl class="card mb-3">
    <div class="card-body">
        <h3 class="card-title">{{'contact'|translatePipe}}</h3>
        <div class="">
            <label class="mb-2">
                {{'SystemSettings.addNewContact'|translatePipe}}
            </label>
            <div class="flex gap-10 mb-3">
                <button (click)="addContact('phone')" class="btn-icon btn btn-primary"><i class="fa fa-fw"></i>
                    {{'phone' | translatePipe}}</button>
                <button (click)="addContact('email')" class="btn-icon btn btn-primary"><i class="fa fa-fw"></i>
                    {{'email' | translatePipe}}</button>
                <button (click)="addContact('whatsapp')" class="btn-icon btn btn-primary"><i class="fa fa-fw"></i>
                    {{'whatsapp' | translatePipe}}</button>
            </div>

            <div class="contact-container col-md-9 col-lg-8 col-xl-6">
                <div *ngFor="let contact of contact;let idx = index" class="contact flex gap-10 mb-2">
                    <div class=" flex-1 text-center contact-type btn btn-primary" style="cursor: default;">
                        <label>
                            <i class="fa fa-fw">{{getIcon(contact.type)}}</i>
                            {{contact.type|translatePipe}}
                        </label>
                    </div>
                    <div class="flex-1">
                        <!-- <label for="">{{getText(contact.type)}}</label> -->
                        <input id="name" name="name" type="text" class="form-control" placeholder="Contact"
                            [(ngModel)]="contact.text">
                    </div>
                    <div class="">
                        <button (click)="removeContact(idx,contact.id)"
                            class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only">
                            <i class="fa fa-fw" aria-hidden="true" title="members"></i>
                        </button>
                    </div>
                </div>

                <div *ngIf="!contact.length">{{'Add your first contact info'|translatePipe}}</div>
            </div>
        </div>
    </div>
</div>

<div class="placeholder mb-3"></div>

<div dirControl class="card flow-all-screen">
    <div class="card-body">
        <button [disabled]="isSubmitDisable" class="btn-icon btn col-12 btn-success" (click)="saveOrgDetails()">
            <i class="pe-7s-config btn-icon-wrapper"></i>
            {{'saveSettings'|translatePipe}} <span class="spinner-border" *ngIf="loading"
                style="width:20px;height:20px;margin-left: 10px;margin-bottom: -5px;"></span>
        </button>
    </div>
</div>