import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FilesService } from 'src/app/services/files.service';
import { TokenService } from 'src/app/token/token.service';
import { ReferForm } from 'src/app/models/eums';
@Component({
  selector: 'app-member-view',
  templateUrl: './member-view.component.html',
  styleUrls: ['./member-view.component.scss']
})
export class MemberViewComponent implements OnInit {

  @Input() member?: any;
  @Input() noy: any;
  currentMem: any;
  data: any;
  user: any;
  imageSource: any = ''
  constructor(private tokenService: TokenService,
    private fileServices: FilesService,
    private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {


    let user = this.tokenService.getUser();
    // this.member = user;

    this.currentMem = this.member.identifier.split('')[0];
    this.user = this.tokenService.getUser();
    this.data = user.backColor;

  }

  ngOnChanges(changes: SimpleChange) {

    const value: any = changes['member'];
    this.currentMem = value.currentValue.identifier.split('')[0];
    var data: any = {
      fkId: value.currentValue.id,
      formType: ReferForm.Members
    }

    if (data.fkId != '') {

      this.fileServices.getFileFkid(data).subscribe((data: any) => {
        if (data.result !== 'InvalidDetailsToRetrieveTheFile') {
          this.fileServices.getFilebyId(data.result[0].id).subscribe((data: any) => {
            this.imageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data.result.fileContent}`);
          })
        }
        else {
          this.imageSource = '';
        }
      })
    }
  }
}