import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { globalComponent } from '../global-component.service';
import { AppConfig } from './app.config';
import { BehaviorSubject, Observable, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  private imageContent$ = new BehaviorSubject<any>('');

  private BASE_URL = AppConfig.settings.apiServer.baseUrl;

  constructor(private http: HttpClient) {

  }

  uploadFile(data: any) {
    const formData = new FormData();
    formData.append("File", data.files);
    formData.append("fkid", data.fkid)
    formData.append("formType", data.formType)
    formData.append("isAttachment", data.isAttachment)
    return this.http.post<any>(`${this.BASE_URL}${globalComponent.filesUploadDel}`, formData);
  }

  getFileFkid(data, tok?) {
    let params = new HttpParams();
    // params = params.append('id', data.id);
    params = params.append('fkId', data.fkId);
    params = params.append('formType', data.formType);
    if (tok) {
      var header = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${tok}` }),
        params: params
      }
      return this.http.get<any>(`${this.BASE_URL}${globalComponent.getFileFkid}`, header)
    } else {
      return this.http.get<any>(`${this.BASE_URL}${globalComponent.getFileFkid}`, { params: params })
    }
  }

  deleteFile(id) {
    return this.http.delete<any>(`${this.BASE_URL}${globalComponent.filesUploadDel}${id}`)
  }

  getFilebyId(id, tok?) {
    if (tok) {
      var header = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${tok}` })
      }
      return this.http.get<any>(`${this.BASE_URL}${globalComponent.getById}${id}`, header);
    } else {
      return this.http.get<any>(`${this.BASE_URL}${globalComponent.getById}${id}`);
    }
  }

  get imageContent_$() {
    return this.imageContent$.asObservable();
  }

  imgCont(fileContent: any) {
    this.imageContent$.next(fileContent);
  }
}
