import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { id } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/interfaces/event';
import { Member } from 'src/app/interfaces/member';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { EventsService } from 'src/app/services/events.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import * as moment from "moment";
import { ReferForm } from 'src/app/models/eums';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {


  subscription: Subscription[] = [];
  searchMember = ''
  members: any[] = [];
  private _searchOptions: any[] = [];
  newGuest = '';
  groupOptions: any[] = [];
  unitsOptions = ['hours', 'minutes']
  locationsOptions = ['above', 'below']
  event: any = this.eventService.emptyEvent;
  events: any = this.eventService.empEvent;
  isInputFocus = false;
  myDate = new Date();
  group: any = '';
  eventImage = '';
  loading = false;

  constructor(
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private location: Location,
    private errhandle: ErrorHandlerService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.memberService.loadMembers()
    this.organizationalStructureService.loadOrganizationalStructure();
    this.event = Object.assign({}, this.eventService.emptyEvent);
    this.events = Object.assign({}, this.eventService.empEvent);
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          let resp: any = await this.eventService.getEvent(id, '').toPromise();
          this.event = resp.result;
          this.events.id = this.event.id;
          this.events.eventDate = new Date(this.event.eventDate).toISOString();
          this.events.content = this.event.content;
          this.events.name = this.event.name;
          this.events.canPlaceImageAbove = this.event.canPlaceImageAbove ? 'true': 'false';
          this.events.eventNode = {
            aId: this.event.nodeDetails.aId,
            id: this.event.nodeDetails.id,
            type: this.event.nodeDetails.type,
            name: this.event.nodeDetails.name
          };
          this.events.length = {
            unit: this.event.durationType === 1 ? 'hours' : 'minutes',
            amount: this.event.duration
          };
          this.events.canPlaceImageAbove = this.event.canPlaceImageAbove ? 'above' : 'below';
          let localtime = new Date(this.event.eventDate).toTimeString().split(':');
          this.events.time = {
            hour: Number.parseInt(localtime[0]),
            minute: Number.parseInt(localtime[1]),
            second: 0
          };
          this.events.members = this.event.memberInvitees;
          this.events.guests = this.event.guestInvitees;
          this.onTimeChange(this.events.time);
        }
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members; 
      })
    )

    this.subscription.push(this.organizationalStructureService.organizationalStructure$.subscribe(data => {
      if(this.event.id !== '') {
        this.events.eventNode = {
          aId: this.event.nodeDetails.aId,
          id: this.event.nodeDetails.id,
          type: this.event.nodeDetails.type,
          name: this.event.nodeDetails.name
        };
      }
    }));

    // this.subscription.push(
    //   this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
    //     this.groupOptions = organizationalStructure.map(group => (
    //       { id: group.id, type: group.type, name: group.name })).sort((groupA, groupB) => {
    //         if (groupA.name > groupB.name) return -1
    //         if (groupA.name < groupB.name) return 1
    //         return 0
    //       })
    //     if (!this.event.id) this.event.info.group = this.groupOptions.find(group => group.type === 'organization')
    //     else this.event.info.group = this.groupOptions.find(option => option.id === this.event.info.group.id);
    //   })
    // )
  }

  get searchOptions() {
    this.getMemberToSearch()
    return this._searchOptions
  }

  getMemberToSearch() {
    this._searchOptions.splice(0, this._searchOptions.length);
    if (this.searchMember) {
      let members = this.members.filter(member => !this.events.members.some(listMember => listMember.id === member.id) && member.identifier.toLowerCase().includes(this.searchMember))
      members.forEach(member => {
        this._searchOptions.push(member)
      })
    }
  }

  addMemberToMemberList(member) {
    this.loading = true;
    this.eventService.addInvitesMemberToEvent({eventId: this.events.id, memberId: member.id}).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.events.members = [...this.events.members, data.result];
        this.errhandle.openToast({ title: "SUCCESS", type: "success", message: "Member invited successfully" });
      },
      error: (err: any) => {
        this.loading = false;
        this.errhandle.openToast({ title: "ERROR", type: "error", message: err.message });
      }
    });
    this.searchMember = '';
  }

  removeMemberFromMemberList(memberId) {
    this.loading = true;
    this.eventService.removeInvitesMemberToEvent('member', memberId).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.events.members = this.events.members.filter(member => memberId !== member.inviteEventMemberId);
        this.errhandle.openToast({ title: "SUCCESS", type: "success", message: "Member uninvited successfully" });
      },
      error: (err: any) => {
        this.loading = false;
        this.errhandle.openToast({ title: "ERROR", type: "error", message: err.message });
      }
    });
  }

  addGuest() {
    this.eventService.addInvitesMemberToEvent({eventId: this.events.id, guestMail: this.newGuest}).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.events.guests = [...this.events.guests, data.result];
        this.errhandle.openToast({ title: "SUCCESS", type: "success", message: "Guest invited successfully" });
      },
      error: (err: any) => {
        this.loading = false;
        this.errhandle.openToast({ title: "ERROR", type: "error", message: err.message });
      }
    });
    this.newGuest = ''
  }

  removeGuest(guest) {
    this.loading = true;
    this.eventService.removeInvitesMemberToEvent('guest', guest.eventGuestId).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.events.guests = this.events.guests.filter(listGuest => listGuest.mailId !== guest.mailId)
        this.errhandle.openToast({ title: "SUCCESS", type: "success", message: "Guest uninvited successfully" });
      },
      error: (err: any) => {
        this.loading = false;
        this.errhandle.openToast({ title: "ERROR", type: "error", message: err.message });
      }
    });
  }

  onTimeChange(value: any)
  {
    let hours = value.hour > 9 ? value.hour.toString() : '0'+value.hour.toString();
    let minutes = value.minute > 9 ? value.minute.toString() : '0'+value.minute.toString();
    this.events.timeToSend = this.events.eventDate.split('T')[0] + 'T' + hours + ':' + minutes + ':' + '00.000';
  }

  onSaveClick() {
    this.loading = true;
    if (this.newGuest !== '') {
      this.addGuest();
      return;
    }
    this.onTimeChange(this.events.time);
    let reqData = {
      id: 0,
      name: this.events.name,
      description: this.events.content,
      eventDate: new Date(this.events.timeToSend).toISOString(),
      duration: this.events.length.amount,
      durationType: this.events.length.unit === 'hours' ? 1 : 2,
      canPlaceImageAbove: this.events.canPlaceImageAbove === 'above',
      eventNode: this.events.eventNode.id
    }

    if (this.events.id) {
      reqData.id = this.events.id;
      this.eventService.editEvent(reqData).subscribe({
        next: (data: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: "SUCCESS", type: "success", message: "Event added successfully" });
          this.location.back();
        },
        error: (err: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: "ERROR", type: "error", message: err.message });
        }
      });
    } else {
      this.eventService.addEvent(reqData).subscribe({
        next: (data: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: "SUCCESS", type: "success", message: "Event added successfully" });
          this.location.back();
        },
        error: (err: any) => {
          this.loading = false;
          this.errhandle.openToast({ title: "ERROR", type: "error", message: err.message });
        }
      });
    }
  }

  getImg() {
    return this.eventImage || 'assets/images/upload-img.png'
  }

  onRemoveLogo(): void {
    this.eventImage = '';
  }

  imgDet() {
    if (this.events.id !== '') {
      var uploadData = {
        fkid: this.events.id,
        formtype: ReferForm.CalendarEvent,
        isAttachment: false
      }
      return uploadData;
    }
  }
}
