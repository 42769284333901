import { Component, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { Location } from '@angular/common';
import { MembersService } from 'src/app/services/members.service';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Member } from 'src/app/interfaces/member';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';




@Component({
  selector: 'app-organizational-structure-members',
  templateUrl: './organizational-structure-members.component.html',
  styleUrls: ['./organizational-structure-members.component.scss']
})
export class OrganizationalStructureMembersComponent implements OnInit {

  @ViewChild('addMember', { static: true }) addMember: any;
  @ViewChild('sureModal', { static: true }) sureModal: TemplateRef<any>;

  group: Group = { ...this.organizationalStructureService.emptyGroup }
  groupParent: Group = { ...this.organizationalStructureService.emptyGroup }
  editedGroupId = ''
  editedGroupMembers = [];
  subscriotion!: Subscription;
  displayedColumns = ['identifier', 'memberSince', 'isModerator', 'options'];
  _addMemberSearch = ''
  // addMemberSearch=''
  notGroupMembers = []
  filteredNotGroupMembers = [];
  choosenMember: Member;
  isSureDelete = false;
  memList:any=[];
  get addMemberSearch() {
    return this._addMemberSearch
  }
  set addMemberSearch(value) {
    this._addMemberSearch = value
    this.filteredNotGroupMembers = this.notGroupMembers.filter(member => member.identifier.toLowerCase().includes(value.toLowerCase()))
  }

  _search = '';

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private location: Location,
    private membersService: MembersService,
    private modal: NgbModal,
    private errorHandler: ErrorHandlerService
  ) {
   
   }



  ngOnInit(): void {
    this.organizationalStructureService.loadOrganizationalStructure();
    this.route.paramMap.subscribe(params => {
      const childId = params.get('id');
      if (childId) {
        this.editedGroupId = childId;
        this.updateEditedGroup();
      } else this.location.go('editor');
    })
  }

  get search() {
    return this._search;
  }
  set search(val: any) {
    this._search = val;
  }

  filterMembers() {
    return this.editedGroupMembers.filter(member => (
      member.identifier.toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(member.memberSince).includes(this.search)
    ))
  }

  async updateEditedGroup() {
    let groupDetails = await (await this.organizationalStructureService.getGroupById(this.editedGroupId)).toPromise();
    this.group = groupDetails.result;
    this.membersService.getGroupMembers(this.group.aId).subscribe({
      next: (data: any) => {
        this.editedGroupMembers = data.result;
      }
    })
    // this.subscriotion = this.membersService.members$.subscribe(members => {
    //   //console.log(members);
    //   this.updateMembers();
    // })
    this.membersService.loadMembers();
    let parentDetails = await (await this.organizationalStructureService.getGroupById(this.group.parent)).toPromise();
    this.groupParent = parentDetails.result;
  }

  mem(data:any){
      this.memList=data
  }

  updateMembers() {
    const allMembers = this.membersService.getMembers().map(member => ({ ...member, isCheck: false, isModerator: member.moderatorOf?.some(id => id === this.group.id) }))
    this.editedGroupMembers = [];
    this.notGroupMembers = [];
    allMembers.forEach(member => {
      if (this.group.members.some(memberId => memberId === member.id)) this.editedGroupMembers.push(member)
      else this.notGroupMembers.push(member)

    })
    // this.editedGroupMembers = allMembers.filter(allMembersMember => {
    //   return this.group.members.find(memberId => memberId === allMembersMember.id)
    // })
  }

  getMemberToSearch() {
    return this.membersService.getMembers().filter(allMembersMember => {
      return allMembersMember.identifier.toLowerCase().includes(this.addMemberSearch.toLowerCase()) &&
        !this.memList.find(member => member.id === allMembersMember.id)
    })
  }

  toggleIsModerator(data: any) {
    this.organizationalStructureService.toggleModeratorStatus(this.group.aId, Number.parseInt(data.member.id)).subscribe({
      next: (res: any) => {
        if (data.setMod) {
          this.errorHandler.openToast({ title: 'Success', type: 'success', message: `Moderator permission granted to ${data.member.identifier}` });
        } else{
          this.errorHandler.openToast({ title: 'Success', type: 'success', message: `Moderator permission removed from ${data.member.identifier}` });
        }
        this.updateEditedGroup();
      },
      error: (err:any) => {
        this.errorHandler.openToast({ title: 'Error', type: 'error', message: `${err.message}` });
      }
    })
    //this.membersService.toggleMemberIsModerator(id, this.group.aId.toString()).toPromise();
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  async onRemoveMemberFromGroup(memberId:number) {
    this.group = await (await this.organizationalStructureService. removeMem(this.group.aId, [memberId])).toPromise();
    this.updateMembers();
  }

  async onRemoveSelectedMembers() {
    const selectedMember = this.editedGroupMembers.filter(member => member.isCheck);
    const memberIds:any = selectedMember.map(selectedMember => selectedMember.id);
    this.organizationalStructureService.removeMem(this.group.aId, memberIds).subscribe({
      next:(data:any)=>{
this.updateMembers();
      },
      error:(err:any)=>{
        //console.log(err)
        this.updateMembers();
      }
    })
    // this.group = await (await this.organizationalStructureService.removeMem(this.group.aId, memberIds)).toPromise();
 
  }

  unselectMembers() {
    this.editedGroupMembers = this.editedGroupMembers.map(member => ({ ...member, isCheck: false }))
  }

  isHaveChecked() {
    return this.editedGroupMembers.find(member => member.isCheck)
  }

  open() {
    this.modal.open(this.addMember, { size: 'lg' });
  }

  openSureModal(member: Member, isSureDelete, closeFn?: CallableFunction) {
    this.choosenMember = member
    this.isSureDelete = isSureDelete
    this.modal.open(this.sureModal, { size: 'sm' });
    if (closeFn) closeFn()
  }

  async onAcceptModal(cb) {
    cb();
    if (this.isSureDelete) await (await this.organizationalStructureService.removeMem(this.group.aId, [this.choosenMember.id])).toPromise()
    else {
      this.organizationalStructureService.addGroupMember(this.group.aId, Number.parseInt(this.choosenMember.id)).subscribe({
        next: (data: any) => {
          this.updateEditedGroup();
        }
      });
    }
    this.updateEditedGroup();
  }

}



