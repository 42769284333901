<div dirControl class="search-members-container">
    <div class="search-condition-container">
        <div class="main-card card">
            <div class="card-body">
                <div class="space-between">
                    <h3 class="card-title">{{'search members'| translatePipe}}</h3>
                    <button (click)="isSavedFilterMobileOpen=true" class="btn btn-primary saved-filter-toggle-btn"><i
                            class="fa fa-fw"></i> {{'saved filter'|translatePipe}}
                    </button>
                </div>
                <form>
                    <div class="flex gap-10">
                        <div class="flex-1">
                            <app-dropdown-label name="searchOptionUserName" [label]="'options'|translatePipe"
                                [(value)]="userName.option" [options]="textOptions">
                            </app-dropdown-label>
                        </div>
                        <div class="flex-1">
                            <app-input-label name="userName" type="text" [label]="'user name'|translatePipe"
                                [(value)]="userName.text">
                            </app-input-label>
                        </div>
                    </div>


                    <div class="flex gap-10">
                        <div class="flex-1">
                            <app-dropdown-label name="searchOptionUserName" [label]="'options'|translatePipe"
                                [(value)]="email.option" [options]="textOptions">
                            </app-dropdown-label>
                        </div>
                        <div class="flex-1">
                            <app-input-label name="email" type="email" [label]="'email'|translatePipe"
                                [(value)]="email.text">
                            </app-input-label>
                        </div>
                    </div>
                    <div class="flex gap-10">
                        <div class="flex-1">
                            <app-date-picker [label]="'member since'|translatePipe"
                                [(timestamp)]="fromTimestamp"></app-date-picker>
                        </div>
                        <div class="flex-1">
                            <app-date-picker [label]="'to'|translatePipe" [(timestamp)]="toTimestamp"></app-date-picker>
                        </div>
                        <div class="">
                            <div class="mb-4">
                                <app-range-date-picker [(toTimestamp)]="toTimestamp" [(fromTimestamp)]="fromTimestamp">
                                </app-range-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="space-between">
                        <div class="flex gap-10">
                            <button (click)="getFilteredMembers($event)" class="btn-icon btn btn-primary"><i
                                    class="fa fa-fw"></i>{{'search'|translatePipe}}</button>
                            <button (click)="clearSearch()" class="btn-icon btn btn-danger"><i
                                    class="fa fa-fw"></i>{{'clear'|translatePipe}}</button>
                        </div>
                        <div class="">
                            <button (click)="open(content1)" class="btn-icon btn btn-primary"><i
                                    class="fa fa-fw"></i>{{'save filter'|translatePipe}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div [class.mobileClose]="!isSavedFilterMobileOpen" class="saved-filter-container">
        <div class="main-card card saved-filter-container">
            <div class="card-body">
                <div class="space-between">
                    <h3 class="card-title">{{'saved filter'| translatePipe}}</h3>
                    <button (click)="isSavedFilterMobileOpen=false" class="btn saved-filter-toggle-btn"><i
                            class="fa fa-fw"></i></button>
                </div>
                <div class="saved-filter">

                    <div class="filter-container">
                        <div *ngFor="let filter of savedFilter" [class.choosen]="filter.name===choosenFilter?.name"
                            (click)="onChooseFilter(filter)" class="col-12 filters">
                            <p class="mb-2 mt-2">{{filter.name}}</p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="btns-container row">
                        <div class="col-4">
                            <button (click)="onLoadFilter()" class="mb-2 me-2 btn-icon btn btn-success col-12"><i
                                    class="fa fa-fw"></i>{{'load'|translatePipe}}</button>
                        </div>
                        <div class="col-4">
                            <button (click)="openRename(content2)"
                                class="mb-2 me-2 btn btn-primary col-12">{{'rename'|translatePipe}}</button>
                        </div>
                        <div (click)="onDeleteFilter()" class="col-4">
                            <button class="mb-2 me-2 btn-icon btn btn-danger col-12"><i
                                    class="fa fa-fw"></i>{{"delete"|translatePipe}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content1 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{'Choose a filter name'|translatePipe}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 150px;" [config]="config">
            <p>{{'You must choose a none exist name'|translatePipe}}</p>
            <input autocomplete="off" type="text" name="name" placeholder="{{'name'|translatePipe}}"
                class="form-control" [(ngModel)]="filterName">
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Close'|translatePipe}}</button>
        <button [disabled]="disableSaveFilterButton()" (click)="addNewFilter(c)" type="button"
            class="btn btn-primary">{{'save filter'|translatePipe}}</button>
    </div>
</ng-template>

<ng-template #content2 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{'Choose new name'|translatePipe}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 150px;" [config]="config">
            <p>{{'You must choose a none exist name'|translatePipe}}</p>
            <input autocomplete="off" type="text" name="name" placeholder="name" class="form-control"
                [(ngModel)]="filterName">
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Close'|translatePipe}}</button>
        <button [disabled]="disableSaveFilterButton()" (click)="onRenameFilter(c)" type="button"
            class="btn btn-primary">{{'save filter'|translatePipe}}</button>
    </div>
</ng-template>