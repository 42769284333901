import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountServerService } from 'src/app/services/account-server.service';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-manage-employees-table-server',
  templateUrl: './manage-employees-table-server.component.html',
  styleUrls: ['./manage-employees-table-server.component.scss']
})
export class ManageEmployeesTableServerComponent implements OnInit {

  dataSource = new MatTableDataSource
  setsColumns: string[] = []
  page = 1;
  pageSize = 10;
  // displayedColumns = ['account name', 'member identifier', 'options'];
  employeesToShow=[];
  @Input() employees: any[];
  @Output() deleteEmployee = new EventEmitter<string>();

  constructor(
    public globalVariableService:GlobalVariablesService,
    public accountService:AccountServerService
  ) { }

  ngOnInit(): void {
    this.setEmployeesToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setEmployeesToShow()
  }

  getClass() {
    return `checkbox-container ${this.employees.find(member => member.isCheck) ? 'checked' : ''}`
  }

  setEmployeesToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.employeesToShow = this.employees?.slice(start, start + this.pageSize);
  }
}
