import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-archive-table',
  templateUrl: './archive-table.component.html',
  styleUrls: ['./archive-table.component.scss']
})
export class ArchiveTableComponent implements OnInit, OnChanges {

  @Input() archiveList: any[];

  dataSource = new MatTableDataSource
  displayedColumns = ['title', 'type', 'options'];
  private _page = 1;
  pageSize = 10;

  get page() {
    return this._page
  }

  set page(value) {
    this._page = value;
    this.dataSource = new MatTableDataSource(this.archiveListToShow());
  }

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.archiveListToShow())
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.archiveListToShow())
  }

  archiveListToShow() {
    const start = (this.page - 1) * this.pageSize;
    return this.archiveList?.slice(start, start + this.pageSize);
  }

  getClass() {
    return `checkbox-container ${this.archiveList.find(card => card.isCheck) ? 'checked' : ''}`
  }


}
