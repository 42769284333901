<div class="container member-view">
    <p *ngIf="imageSource===''"  class="col-12 circle" style="margin-bottom: 0rem;position:relative" [ngStyle]="{'backgroundColor':color}" >
        <span style="font-size: 15vh;display:flex;color: white; position: absolute;top:50%;left: 50%;transform: translate(-50%, -59%);">{{currentMem}}</span>
    </p>
    <img *ngIf="imageSource!==''" class="col-12" [src]="imageSource" alt="">
    <ng-container *ngIf="member.identifier; else elseTemplate">
        <p class="text-center">{{member.identifier}}</p>
    </ng-container>
    <ng-template #elseTemplate>
        <p class="text-center">{{member.mailId}}</p>
    </ng-template>
    <button (click)="removeMember.emit(member)">
        <i class="fa fa-fw" aria-hidden="true" title="{{member.identifier ? 'Remove Member' : 'Remove Guest'}}"></i>
    </button>
</div>