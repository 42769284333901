import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxLoadingService } from 'ngx-loading';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { TokenService } from 'src/app/token/token.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {
  subscription: Subscription[] = [];
  organization: Group;
  loading: boolean = false;
  click = '';
  email = '';
  isEmailValid = false;
  name = '';
  isPasswordValid = false;
  isPasswordrepValid = false;
  password = '';
  passwordrep = '';
  showMsg: string;
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: false,

  };
  //
  Form: FormGroup;
  submitted: boolean = false;
  missmatch: boolean;
  misspass: boolean;
  success: boolean = false;

  constructor(
    public organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private errhandle: ErrorHandlerService, private formBuilder: FormBuilder, private token: TokenService,
    private loaderService: NgxUiLoaderService) {
    this.Form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      password: ['', [Validators.required]],
      cpassword: ['', [Validators.required]],
      agree: [false, [Validators.requiredTrue]],
      username: ['', [Validators.required]]
    })
  }
  ngOnInit() {

    if (this.token.decodedToken() != null) {
      this.router.navigate(['/dashboard'])
    }



    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const organizationId = params.get('id');
        if (organizationId) {
          this.organization = await this.organizationalStructureService.getGroupById(organizationId).toPromise()
        }
      })
    )

    
    this.loaderService.startLoader("loader-01"); // start foreground spinner of the loader "loader-01" with 'default' taskId
    // Stop the foreground loading after 5s
    setTimeout(() => {
      this.loaderService.stopLoader("loader-01"); // stop foreground spinner of the loader "loader-01" with 'default' taskId
    }, 500);
  }
  get f() { return this.Form.controls }

  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.Form.invalid) {
      this.loading = false;
      return;
    }
    else if (this.f['password'].value != this.f['cpassword'].value) {
      //console.log(this.f['password'].value, this.f['cpassword'].value)
      this.missmatch = true;
      this.misspass = true;
      this.loading = false;
      this.eHandle()
      //console.log("missmatch")
    }
    else {
      let reqBody = { identifier: this.f['username'].value, fullName: this.f['name'].value, email: this.f['email'].value, password: this.f['password'].value, isSelfRegistration: true }
      this.authService.register(reqBody).subscribe({
        next: (data: any) => {
          this.errhandle.openToast({ title: 'Success', type: 'success', message: 'Registered successfully!' })
          this.router.navigate(['/login']);
          this.Form.reset();
          this.loading = false;
        },
        error: (err: any) => {
          this.errhandle.openToast({ title: 'ERROR', type: 'error', message: err.error.result });
          this.loading = false;
        }
      })

    }



  }





  //   async register()

  //   {
  //      var data={
  //       name:this.name,
  //       email:this.email,
  //       password:this.password,
  //       passwordrep:this.passwordrep

  //     }
  // if(data.email==''||data.name==''||data.password==''||data.passwordrep==''){
  //   this.validate=true;
  //  this.eHandle();
  // return;
  // }
  // else if(this.value==false){
  // this.values=true;
  // this.eHandle();
  // return;
  // }
  // else{

  //      this.authService.register(this.email,this.name,this.password).subscribe({

  //       next:(data:any)=>{

  //         this.errhandle.openToast({title:'Success',type:'success',message:'Registered successfully!'})
  //         this.router.navigate(['/login']);
  //       },
  //       error:(err:any)=>{

  //         this.errhandle.openToast({title:'error',type:'error',message:err.error.result})
  //       }
  //      })

  // }

  //   }





  closealert() {

    this.showMsg = '';

  }
  navigateToDash() {
  }

  // register(){

  // this.authService.register(this.email, this.name, this.password, this.passwordrep).subscribe((data:any)=>
  // {
  //   //console.log(data)
  // })

  eHandle() {
    setTimeout(() => {
      this.misspass = false;
    }, 3000);
  }
}
