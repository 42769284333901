<div [ngClass]="{rtl:translateService.locale==='he'}"
  [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()">
  <div dirControl class="app-header__logo">
    <div routerLink="/dashboard" class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: calc(100vh - 60px); overflow-y: auto;">




        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list mt-4">

            <!-- <div class="vsm-header"> -->

            <a dirControl routerLink="/profile" routerLinkActive="active-item" class="vsm-link user"
              style="height: 60px; padding-left: 10px;width:100%">

              <div class="d-flex">
                <span class="m-0">
                  <div class="icon-wrapper icon-wrapper-alt rounded-circle">

                    <span dirControl
                      style="width:40px;height:40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;"
                      [ngStyle]="{
                        'backgroundColor' : color  
                      }" *ngIf="imageSource==''">
                      <p style="font-size: 30px;display:flex;margin-top: 10px;color: white;">{{currentMem}}</p>

                    </span>
                    <img *ngIf="imageSource!=''"
                      style="width:40px;height:40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;"
                      [src]="imageSource" alt="">
                  </div>
                </span>
                <div #logged class="d-flex w-100"
                  style="flex-direction: column;align-items: baseline;justify-content: center;">
                  <div class="vsm-title  mt-1 w-100">
                    <p class="p mb-2" [ngStyle]="{'font-size':fontSize}"><b #logName>{{loggedInUser.name | ellipsis:
                        17}}</b> </p>
                  </div>
                  <div class="orgName w-100">
                    <div class="vsm-title  " style="line-height:5px;">{{loggedInUser.organizationName}}
                    </div>
                  </div>
                </div>
              </div>

            </a>
            <!-- </div> -->
            <div class="divider"></div>
            <div class="vsm-item">
              <a routerLink="/dashboard" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-home"></i>
                <span class="vsm-title">{{'home'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/dashboard/{{user.organizationId}}" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-refresh"></i>
                <span class="vsm-title">{{'publiclub'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-header">{{'organization' | translatePipe}}</div>

            <div class="vsm-item">
              <a routerLink="/editor" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-network"></i>
                <span class="vsm-title">{{'structure' | translatePipe}}</span>
              </a>
            </div>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="false" #acc="ngbAccordion"
              activeIds="{{extraParameter}}">
              <ngb-panel id="members">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-users"></i>
                    <span class="vsm-title">{{'members'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="manage-members" class="vsm-link">
                          <span class="vsm-title">{{'manageMembers'| translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/search-members" class="vsm-link">
                          <span class="vsm-title">{{'searchMember'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="member-view" class="vsm-link">
                          <span class="vsm-title">{{'AddNewMember'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/invite-member" class="vsm-link">
                          <span class="vsm-title">{{'inviteMember'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">{{'ats'|translatePipe}}</div>
                </ng-template>
              </ngb-panel>

              <ng-container *ngFor="let account of accountTypes; let i = index">
                <ngb-panel id="{{account.name}}">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <!-- <i class="vsm-icon fa-solid fa-user-nurse"></i> -->
                      <i class="vsm-icon fa-solid">{{account.imageURL.D}}</i>
                      <!-- <i class="vsm-icon pe-7s-users" *ngIf="account.name=='Customer'"></i>
                        <i class="vsm-icon pe-7s-id" *ngIf="account.name=='Candidate'"></i>
                        <i class="vsm-icon pe-7s-plus" *ngIf="account.name=='Patient'"></i> -->
                      <span class="vsm-title">{{account.name | translatePipe}}</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLinkActive="active-item" routerLink="/portal-account-posts/{{account.id}}"
                            class="vsm-link">
                            <span class="vsm-title">{{account.name + ' Portal'|translatePipe}}</span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLinkActive="active-item" routerLink="/accounts/{{account.id}}" class="vsm-link">
                            <span class="vsm-title">{{account.name +' Directory' |translatePipe}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ng-container>

              <!-- <ngb-panel id="employee">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">{{'Employees'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/portal-employee-posts" class="vsm-link">
                          <span class="vsm-title">{{'Employees posts'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/employees" class="vsm-link">
                          <span class="vsm-title">{{'manage employees'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="customer">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-note2"></i>
                    <span class="vsm-title">{{'customers'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/portal-customer-posts" class="vsm-link">
                          <span class="vsm-title">{{'customers posts'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/customers" class="vsm-link">
                          <span class="vsm-title">{{'manage customers'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="candidate">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-add-user"></i>
                    <span class="vsm-title">{{'candidates'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/portal-candidate-posts" class="vsm-link">
                          <span class="vsm-title">{{'candidates posts'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/candidates" class="vsm-link">
                          <span class="vsm-title">{{'manage candidates'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="patient">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon fa fa-fw"></i>
                    <span class="vsm-title">{{'patients'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/portal-patient-posts" class="vsm-link">
                          <span class="vsm-title">{{'patients posts'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/patients" class="vsm-link">
                          <span class="vsm-title">{{'manage patients'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->



              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">{{'modules'|translatePipe}}</div>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="events">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLink="/event-management">
                    <i class="vsm-icon pe-7s-date"></i>
                    <span class="vsm-title">{{'events'|translatePipe}}</span>
                    <!-- <i class="vsm-arrow"></i> -->
                  </button>
                </ng-template>
                <!-- <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/event-management" class="vsm-link">
                          <span class="vsm-title">{{'calendarManagement'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template> -->
              </ngb-panel>

              <ngb-panel id="chat">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLink="/chat-settings">
                    <i class="vsm-icon pe-7s-chat"></i>
                    <span class="vsm-title">{{'chat'|translatePipe}}</span>
                    <!-- <i class="vsm-arrow"></i> -->
                  </button>
                </ng-template>
                <!-- <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/chat-settings" class="vsm-link">
                          <span class="vsm-title">{{'chatSettings'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template> -->
              </ngb-panel>

              <ngb-panel id="cards" disabled="true">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-id"></i>
                    <span class="vsm-title">{{'cards'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/card-management" class="vsm-link">
                          <span class="vsm-title">{{'CardManagement'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/card-usage" class="vsm-link">
                          <span class="vsm-title">{{'CardUsage'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <!-- <ngb-panel id="sets">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-menu"></i>
                    <span class="vsm-title">{{'sets'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/set-management" class="vsm-link">
                          <span class="vsm-title">{{'setManagement'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/property-list" class="vsm-link">
                          <span class="vsm-title">{{'propertyList'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
              <!-- <ngb-panel id="ecommerce">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-cart"></i>
                    <span class="vsm-title">{{'ecommerce'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/apps/material-tabs" class="vsm-link">
                          <span class="vsm-title">{{'stockManagement'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/apps/material-tabs" class="vsm-link">
                          <span class="vsm-title">{{'itemsManagement'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">{{'ExtraModules'|translatePipe}}</div>
                </ng-template>
              </ngb-panel> -->
            </ngb-accordion>

            <div class="vsm-item">
              <a routerLink="/task-managment" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-check"></i>
                <span class="vsm-title">{{'tasks'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/custoumer-service" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-shopbag"></i>
                <span class="vsm-title">{{'customerService'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/landing-page" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-plane"></i>
                <span class="vsm-title">{{'landingpage'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/trigger-managment" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-timer"></i>
                <span class="vsm-title">{{'automations'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/ecommerce" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-cart"></i>
                <span class="vsm-title">{{'ecommerce'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/digital-wallet" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-wallet"></i>
                <span class="vsm-title">{{'digitalWallet'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/social-network" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-comment"></i>
                <span class="vsm-title">{{'socialNetwork'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/notifications" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-bell"></i>
                <span class="vsm-title">{{'allnotificiations'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/media" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-film"></i>
                <span class="vsm-title">{{'media'|translatePipe}}</span>
              </a>
            </div>
            <!-- <div class="vsm-item">
              <a routerLink="/charts/apexcharts" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-display2"></i>
                <span class="vsm-title">{{'portal'|translatePipe}}</span>
              </a>
            </div> -->
            <div class="vsm-header">{{'tools'|translatePipe}}</div>
            <div class="vsm-item">
              <a routerLink="/global-search" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-global"></i>
                <span class="vsm-title">{{'search'|translatePipe}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/log-search" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-search"></i>
                <span class="vsm-title">{{'logs'|translatePipe}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/archive" routerLinkActive="active-item" class="vsm-link" disabled="true">
                <i class="vsm-icon pe-7s-box1"></i>
                <span class="vsm-title">{{'archive'|translatePipe}}</span>
              </a>
            </div>
            <!-- <div class="vsm-item">
              <a routerLink="/backups" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-cloud-download"></i>
                <span class="vsm-title">{{'backups'|translatePipe}}</span>
              </a>
            </div> -->

            <div class="vsm-header">{{'settings' | translatePipe}}</div>



            <div class="vsm-item">
              <a routerLink="/system_settings" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-config"></i>
                <span class="vsm-title">{{'settings'|translatePipe}}</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/module-memnegment" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-mouse"></i>
                <span class="vsm-title">{{'modules'|translatePipe}}</span>
              </a>
            </div>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="false" #acc="ngbAccordion"
              activeIds="{{extraParameter}}">
              <ngb-panel id="Permissions">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-key"></i>
                    <span class="vsm-title">{{'permissions'|translatePipe}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <!-- <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/apps/material-tabs" class="vsm-link">
                          <span class="vsm-title">{{'roleManagement'|translatePipe}}</span>
                        </a>
                      </div> -->
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/global-administrators" class="vsm-link">
                          <span class="vsm-title">{{'administrators'|translatePipe}}</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/local-administrators" class="vsm-link">
                          <span class="vsm-title">{{'moderators'|translatePipe}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>