<div class="d-flex">
 <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <span *ngIf="imageSource==''"  style="width:40px;height:40px;border-radius: 50%;display: flex;position: relative;justify-content: center;align-items: center;"   [ngStyle] = "{
                    'backgroundColor' : color  
                  }"><span style="font-size: 30px;display:flex;color: white; position: absolute;top:50%;left: 50%;transform: translate(-50%, -59%);">{{currentMem}}</span></span>

                  <img *ngIf="imageSource!=''"   style="width:40px;height:40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;" [src]="imageSource" alt=""> 
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>

              <!-- logout -->
              <ul class="nav flex-column">
                <li class="nav-item-header nav-item"><a  routerLink="profile">{{member.name}}</a></li>
                <li class="nav-item-header nav-item" style="color: #39393f; text-transform: lowercase;">{{member.emailId}}</li>
                <li class="nav-item"><a class="nav-link" (click)="logout()"><i class="fa fa-sign-out" style="font-size:16px; color: gray;"></i>{{'Logout'|translatePipe}}
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="widget-content-left ms-3 header-user-info">
          <div class="widget-heading">{{member}}</div>
        </div> -->
        <div class="widget-content-right header-user-info ms-3">
          <!-- <button type="button" class="btn btn-shadow p-1 btn-info btn-sm" placement="bottom"
            ngbTooltip="Tooltip Example!">
            <fa-icon [icon]="faCalendar" class="me-1 ms-1"></fa-icon>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="header-btn-lg">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()">
      <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
  </div> -->




</div>