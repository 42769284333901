import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import slideVertical from 'src/app/animations/slide-vertical.animation';
import { Card } from 'src/app/interfaces/card';
import { CardService } from 'src/app/services/card.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss'],
  animations: [
    slideVertical
  ]
})
export class CardViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public cardService: CardService,
    public organizationalStructureService: OrganizationalStructureService,
  ) {
  }
  expriedAfterTimeOptions = ['hours', 'days', 'weeks', 'month', 'years']
  subscription: Subscription[] = [];
  groupOptions = [];
  title = ''
  card: Card = this.cardService.emptyCard
  slideSituation = 'out'

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(params => {
        const cardId = params.get('id');
        if (cardId) {
          this.subscription.push(this.cardService.getCard(cardId).subscribe(card => {
            this.card = card;
          }))
        }
        this.organizationalStructureService.loadOrganizationalStructure()
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.groupOptions = organizationalStructure.map(child => ({
          id: child.id,
          type: child.type,
          name: child.name
        }))

        this.sortGroupOptions();
        if (!this.card.id) this.card.group = this.groupOptions.find(option => option.type === 'organization')
        else {
          const currOption = this.groupOptions.find(option => option.id === this.card.group.id)
          this.card.group = currOption
        }


      })
    )
  }

  sortGroupOptions() {
    this.groupOptions.sort((optionA, optionB) => {
      if (optionA.type > optionB.type) return 1
      if (optionA.type < optionB.type) return -1
      else return 0
    })
  }

  toggleSlideSituation() {
    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in'
  }

  onSave() {
    //console.log(!!this.card.id);

    if (!this.card.id) {
      this.cardService.addCard(this.card);
    }
    else {
      this.cardService.editCard(this.card);
    }
  }
}
