<div class="member-container">
    <div class="col-3">
        <app-member-view [member]="member"></app-member-view>
    </div>
</div>
<div *ngIf="groups.length > 0">
    <app-button-box-tree-container *ngFor="let group of groups" (onChooseGroup)="onChooseGroup($event)"
        [group]="group"></app-button-box-tree-container>
</div>
<div *ngIf="groups.length == 0">
    <p class="text-center"><b>{{'Not added to any groups'|translatePipe}}</b></p>
</div>