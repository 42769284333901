import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.store';
import { exhaustMap, map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private store: Store<AppState>,
    private route: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq = req.clone();
    if ((!req.url.includes('/Member/Reset')) && (!this.route.url.includes('event?reply='))) {
      modifiedReq = req.clone({ setHeaders: {'Authorization': `${localStorage.getItem('token')}`} });
    }
    return next.handle(modifiedReq);
  }


}
