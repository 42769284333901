<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">  
      <div *ngIf="expired || eventNotFetched || fetchingDetails" class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-auto mb-3 text-center">
              <img src="/assets/images/flexyc-logo.png" style="width: 100px; height: 55px;" />
            </div>
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>Welcome</div>
              </h4>
            </div>
            <div class="data-container">
              <div class="data text-center">
                  <p *ngIf="expired"><b>{{'expired' | translatePipe}}</b></p>
                  <p *ngIf="eventNotFetched"><b>{{'event unavailable' | translatePipe}}</b></p>
                  <p *ngIf="fetchingDetails"><b>{{'fetching' | translatePipe}}</b></p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalContent>
  <div  class="modal-header">
      <h5 *ngIf="event" class="modal-title">{{event.title | titleCase}} event</h5>
  </div>
  <div class="modal-body">
    <div *ngIf="event" class="data-container" [ngClass]="event.imgLoc">
        <div *ngIf="event.image" class="img-container">
            <img [src]="event.image" alt="event image">
        </div>
        <div class="data">
            <p *ngIf="currentStatus" [ngClass]="currentStatus === 'maybe invite' ? 'text-primary' : currentStatus === 'accepted invite' ? 'text-success' : currentStatus === 'pending' ? 'text-secondary' : 'text-danger' " >
              <b>{{'status' | translatePipe}}: {{currentStatus | translatePipe}}</b>
            </p>
            <p><b>{{'start' | translatePipe}}:</b> {{event.start.getTime() | formatDateTime}}</p>
            <p><b>{{'end' | translatePipe}}:</b> {{event.end.getTime() | formatDateTime}}</p>
            <div class="divider"></div>
            <p><b>{{'description' | translatePipe}}:</b> {{event.description}}</p>
        </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="event">
    <div class="float-start">
      <a *ngIf="currentStatus !== 'maybe invite'" (click)="sendResponse('Maybe')" class="btn-lg btn btn-link">{{'Maybe' | translatePipe}}</a>
    </div>
    <div class="float-end">
      <button *ngIf="currentStatus !== 'accepted invite'" (click)="sendResponse('Accepted')" class="btn btn-primary btn-lg">
        <span class="d-flex align-items-center"> {{ 'Accept' | translatePipe }} <span class="spinner-border"
            *ngIf="loading" style="width:20px;height:20px;margin-left: 10px;"></span></span>
      </button>
      <a *ngIf="currentStatus !== 'declined invite'" (click)="sendResponse('Declined')" class="btn btn-link" style="color: red;">{{'Decline' | translatePipe}}</a>
    </div>
  </div>
</ng-template>