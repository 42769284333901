import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-task-managment-table',
  templateUrl: './task-managment-table.component.html',
  styleUrls: ['./task-managment-table.component.scss']
})
export class TaskManagmentTableComponent implements OnInit, OnChanges {

  @Output() updateTask = new EventEmitter<any>();
  @Output() removeTask = new EventEmitter<any>();
  @Input() tasks: any[];
  tasksToShow = [];
  page = 1;
  pageSize = 10;
  orderByPrioritySituation = 0;
  orderByStatusSituation = 0;
  statusPriorityMap = {
    pending: 1,
    "working on it": 2,
    review: 3,
    done: 4,
    stuck: 5,
    "no need": 6,
    canceld: 7
  }


  constructor() { }

  ngOnInit(): void {
    this.setTasksToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTasksToShow()
  }

  getClass() {
    return `checkbox-container ${this.tasks.find(task => task.isCheck) ? 'checked' : ''}`
  }

  setTasksToShow() {
    const start = (this.page - 1) * this.pageSize;
    const orderedTasks = this.tasks.sort((task1, task2) => {
      if (this.orderByPrioritySituation === 0 && this.orderByStatusSituation === 0) return 0
      const prioritySortResault = task2.priority - task1.priority;
      const statusSortResault = this.statusPriorityMap[task2.status] - this.statusPriorityMap[task1.status]
      if (this.orderByPrioritySituation === 1) {
        if (prioritySortResault || !this.orderByStatusSituation || !statusSortResault) return prioritySortResault
        return this.orderByStatusSituation === 1 ? statusSortResault : -statusSortResault;
      } else if (this.orderByPrioritySituation === 2) {
        if (prioritySortResault || !this.orderByStatusSituation || !statusSortResault) return -prioritySortResault
        return this.orderByStatusSituation === 1 ? statusSortResault : -statusSortResault;
      }
      else return this.orderByStatusSituation === 1 ? statusSortResault : -statusSortResault;
    })
    this.tasksToShow = this.tasks?.slice(start, start + this.pageSize);
  }

  getTaskMembers(members) {
    return members.map(member => member.identifier).join(', ')
  }

  updateTaskStatus(status, task) {
    task.status = status;
    this.updateTask.emit(task);
  }

  ChangeOrderByPrioritySituation(){
    if(this.orderByPrioritySituation===2) this.orderByPrioritySituation=0
    else this.orderByPrioritySituation ++;
  }

  ChangeOrderByStatusSituation(){
    if(this.orderByStatusSituation===2) this.orderByStatusSituation=0
    else this.orderByStatusSituation ++;
  }

}
